export default `Cookie Policy

Allow necessary cookies
This type of Cookie helps us to secure and better manage the performance of our Services. They are strictly necessary to bring you a safe and well working service.
Allow personalized Ads
These Cookies are used to show more relevant advertising to you based on information like whether or not you’ve seen a type of ad online, how you interacted with such an ad, and how long it has been since you’ve seen it. We also use Cookies to help us with targeted advertising. We may use Cookies set by another organization, so we can more accurately target advertisements to you.
Allow analytics cookies
Every time you visit our services, analytics cookies are generated to tell us whether or not you have visited the services in the past, and provide additional information regarding how our services are being used (such as number of visitors, how often they visit or which service tools are being accessed).
Cookie Policy of www.iloveifc.com
This document informs Users about the technologies that help this Application to achieve the purposes described below. Such technologies allow the Owner to access and store information (for example by using a Cookie) or use resources (for example by running a script) on a User’s device as they interact with this Application.
For simplicity, all such technologies are defined as "Trackers" within this document – unless there is a reason to differentiate.
For example, while Cookies can be used on both web and mobile browsers, it would be inaccurate to talk about Cookies in the context of mobile apps as they are a browser-based Tracker. For this reason, within this document, the term Cookies is only used where it is specifically meant to indicate that particular type of Tracker.
Some of the purposes for which Trackers are used may also require the User's consent. Whenever consent is given, it can be freely withdrawn at any time following the instructions provided in this document.
This Application uses Trackers managed directly by the Owner (so-called “first-party” Trackers) and Trackers that enable services provided by a third-party (so-called “third-party” Trackers). Unless otherwise specified within this document, third-party providers may access the Trackers managed by them.
The validity and expiration periods of Cookies and other similar Trackers may vary depending on the lifetime set by the Owner or the relevant provider. Some of them expire upon termination of the User’s browsing session.
In addition to what’s specified in the descriptions within each of the categories below, Users may find more precise and updated information regarding lifetime specification as well as any other relevant information – such as the presence of other Trackers - in the linked privacy policies of the respective third-party providers or by contacting the Owner.
Activities strictly necessary for the operation of this Application and delivery of the Service
This Application uses so-called “technical” Cookies and other similar Trackers to carry out activities that are strictly necessary for the operation or delivery of the Service.
Third-party Trackers
Traffic optimization and distribution
This type of service allows this Application to distribute their content using servers located across different countries and to optimize their performance.
Which Personal Data are processed depends on the characteristics and the way these services are implemented. Their function is to filter communications between this Application and the User's browser.
Considering the widespread distribution of this system, it is difficult to determine the locations to which the contents that may contain Personal Information of the User are transferred.
Cloudflare (Cloudflare)
Cloudflare is a traffic optimization and distribution service provided by Cloudflare Inc.
The way Cloudflare is integrated means that it filters all the traffic through this Application, i.e., communication between this Application and the User's browser, while also allowing analytical data from this Application to be collected.
Personal Data processed: Cookies and various types of Data as specified in the privacy policy of the service.
Place of processing: US – Privacy Policy.
Other activities involving the use of Trackers
Measurement
This Application uses Trackers to measure traffic and analyze User behavior with the goal of improving the Service.
Analytics
The services contained in this section enable the Owner to monitor and analyze web traffic and can be used to keep track of User behavior.
Google Analytics (Google Inc.)
Google Analytics is a web analysis service provided by Google Inc. (“Google”). Google utilizes the Data collected to track and examine the use of this Application, to prepare reports on its activities and share them with other Google services.
Google may use the Data collected to contextualize and personalize the ads of its own advertising network.
Personal Data processed: Cookies and Usage Data.
Place of processing: US – Privacy Policy – Opt Out.
Anonymized analytics services
The services contained in this section allow the Owner, through the use of third-party Trackers, to collect and manage analytics in an anonymized form.
Google Analytics with anonymized IP (Google Ireland Limited)
Google Analytics is a web analysis service provided by Google Ireland Limited (“Google”). Google utilizes the Data collected to track and examine the use of this Application, to prepare reports on its activities and share them with other Google services.
Google may use the Data collected to contextualize and personalize the ads of its own advertising network.
This integration of Google Analytics anonymizes your IP address. It works by shortening Users' IP addresses within member states of the European Union or in other contracting states to the Agreement on the European Economic Area. Only in exceptional cases will the complete IP address be sent to a Google server and shortened within the US.
Personal Data processed: Tracker and Usage Data.
Place of processing: Ireland – Privacy Policy – Opt Out.
Targeting & Advertising
This Application uses Trackers to deliver personalized marketing content based on User behavior and to operate, serve and track ads.
Advertising
This type of service allows User Data to be utilized for advertising communication purposes. These communications are displayed in the form of banners and other advertisements on this Application, possibly based on User interests.
This does not mean that all Personal Data are used for this purpose. Information and conditions of use are shown below.
Some of the services listed below may use Trackers to identify Users or they may use the behavioral retargeting technique, i.e. displaying ads tailored to the User’s interests and behavior, including those detected outside this Application. For more information, please check the privacy policies of the relevant services.
Services of this kind usually offer the possibility to opt out of such tracking. In addition to any opt-out feature offered by any of the services below, Users may learn more on how to generally opt out of interest-based advertising within the dedicated section "How to opt-out of interest-based advertising" in this document.
Google AdSense (Google Inc.)
Google AdSense is an advertising service provided by Google LLC or by Google Ireland Limited, depending on the location this Application is accessed from. This service uses the “DoubleClick” Cookie, which tracks use of this Application and User behavior concerning ads, products and services offered.
Users may decide to disable all the DoubleClick Cookies by going to: Google Ad Settings. In order to understand Google's use of data, consult Google's partner policy.
Personal Data processed: Cookies and Usage Data.
Place of processing: US – Privacy Policy – Opt Out.
How to manage preferences and provide or withdraw consent
There are various ways to manage Tracker related preferences and to provide and withdraw consent, where relevant:
Users can manage preferences related to Trackers from directly within their own device settings, for example, by preventing the use or storage of Trackers.
Additionally, whenever the use of Trackers is based on consent, Users can provide or withdraw such consent by setting their preferences within the cookie notice or by updating such preferences accordingly via the relevant consent-preferences widget, if available.
It is also possible, via relevant browser or device features, to delete previously stored Trackers, including those used to remember the User’s initial consent.
Other Trackers in the browser’s local memory may be cleared by deleting the browsing history.
With regard to any third-party Trackers, Users can manage their preferences and withdraw their consent via the related opt-out link (where provided), by using the means indicated in the third party's privacy policy, or by contacting the third party.
Locating Tracker Settings
Users can, for example, find information about how to manage Cookies in the most commonly used browsers at the following addresses:
Google Chrome
Mozilla Firefox
Apple Safari
Microsoft Internet Explorer
Microsoft Edge
Brave
Opera
Users may also manage certain categories of Trackers used on mobile apps by opting out through relevant device settings such as the device advertising settings for mobile devices, or tracking settings in general (Users may open the device settings and look for the relevant setting).
How to opt out of interest-based advertising
Notwithstanding the above, Users may follow the instructions provided by YourOnlineChoices (EU), the Network Advertising Initiative (US) and the Digital Advertising Alliance (US), DAAC (Canada), DDAI (Japan) or other similar services. Such initiatives allow Users to select their tracking preferences for most of the advertising tools. The Owner thus recommends that Users make use of these resources in addition to the information provided in this document.
The Digital Advertising Alliance offers an application called AppChoices that helps Users to control interest-based advertising on mobile apps.
Owner and Data Controller
SPA Planeamento SL
B36477527
Serra 9, 1º der
36001 Pontevedra
Owner contact email: info@iloveifc.com
Since the use of third-party Trackers through this Application cannot be fully controlled by the Owner, any specific references to third-party Trackers are to be considered indicative. In order to obtain complete information, Users are kindly requested to consult the privacy policies of the respective third-party services listed in this document.
Given the objective complexity surrounding tracking technologies, Users are encouraged to contact the Owner should they wish to receive any further information on the use of such technologies by this Application.
Definitions and legal references
Personal Data (or Data)
Any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.
Usage Data
Information collected automatically through this Application (or third-party services employed in this Application), which can include: the IP addresses or domain names of the computers utilized by the Users who use this Application, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Application) and the details about the path followed within the Application with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment.
User
The individual using this Application who, unless otherwise specified, coincides with the Data Subject.
Data Subject
The natural person to whom the Personal Data refers.
Data Processor (or Data Supervisor)
The natural or legal person, public authority, agency or other body which processes Personal Data on behalf of the Controller, as described in this privacy policy.
Data Controller (or Owner)
The natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of Personal Data, including the security measures concerning the operation and use of this Application. The Data Controller, unless otherwise specified, is the Owner of this Application.
This Application
The means by which the Personal Data of the User is collected and processed.
Service
The service provided by this Application as described in the relative terms (if available) and on this site/application.
European Union (or EU)
Unless otherwise specified, all references made within this document to the European Union include all current member states to the European Union and the European Economic Area.
Cookie
Cookies are Trackers consisting of small sets of data stored in the User's browser.
Tracker
Tracker indicates any technology - e.g Cookies, unique identifiers, web beacons, embedded scripts, e-tags and fingerprinting - that enables the tracking of Users, for example by accessing or storing information on the User’s device.

Legal information
This privacy statement has been prepared based on provisions of multiple legislations, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation).
This privacy policy relates solely to this Application, if not stated otherwise within this document.

Latest update: June 23, 2022
`
