
import { Vue, Component } from 'vue-property-decorator'
import IconBars from '@/assets/icons/bars.svg'
import IconList from '@/assets/icons/list.svg'
import IconDropdown from '@/assets/icons/list-dropdown.svg'
import IconLeftArrow from '@/assets/icons/left-to-line.svg'
import IconRightArrow from '@/assets/icons/right-to-line.svg'
import Store from '@/store/modules/Store'

@Component({
  components: {
    IconBars,
    IconList,
    IconDropdown,
    IconLeftArrow,
    IconRightArrow
  }
})
export default class AssetSubactions extends Vue {
  private showAssetDropdown: boolean = false
  private collapse: boolean = false

  mounted() {
    const config = JSON.parse(
      localStorage.getItem(process.env.VUE_APP_CONFIG_COOKIE)
    )

    if (config) {
      switch (config.assetsList) {
        case 0:
          this.showAssetDropdown = false

          break
        case 1:
          this.showAssetDropdown = true

          break
      }

      switch (config.mapExpanded) {
        case 0:
          this.collapse = false

          break
        case 1:
          this.collapse = true

          break
      }
    }
  }

  private get showOptionsHub() {
    return Store.showOptionsHub
  }

  private sendClickHubs() {
    Store.setShowOptionsHub(!this.showOptionsHub)
  }

  private sendChangeViewMode(mode: boolean) {
    let config = JSON.parse(
      localStorage.getItem(process.env.VUE_APP_CONFIG_COOKIE)
    )

    if (mode) {
      const updateConfig = {
        assetsList: 1,
        analyticsList: config.analyticsList,
        mapExpanded: config.mapExpanded
      }

      localStorage.setItem(
        process.env.VUE_APP_CONFIG_COOKIE,
        JSON.stringify(updateConfig)
      )
    } else {
      const updateConfig = {
        assetsList: 0,
        analyticsList: config.analyticsList,
        mapExpanded: config.mapExpanded
      }

      localStorage.setItem(
        process.env.VUE_APP_CONFIG_COOKIE,
        JSON.stringify(updateConfig)
      )
    }

    this.showAssetDropdown = mode

    this.$root.$emit('send-change-view-mode', mode)
  }

  private async collapseFunction(status: boolean) {
    const config = JSON.parse(
      localStorage.getItem(process.env.VUE_APP_CONFIG_COOKIE)
    )

    if (status) {
      const updateConfig = {
        assetsList: config.assetsList,
        analyticsList: config.analyticsList,
        mapExpanded: 1
      }

      localStorage.setItem(
        process.env.VUE_APP_CONFIG_COOKIE,
        JSON.stringify(updateConfig)
      )
    } else {
      const updateConfig = {
        assetsList: config.assetsList,
        analyticsList: config.analyticsList,
        mapExpanded: 0
      }

      localStorage.setItem(
        process.env.VUE_APP_CONFIG_COOKIE,
        JSON.stringify(updateConfig)
      )
    }

    this.collapse = status

    this.$root.$emit('collapse', status)
  }
}
