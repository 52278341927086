
import { Vue, Component, Emit, Prop } from 'vue-property-decorator'
import colors from '@/config/menus/colors'

@Component
export default class ColorPicker extends Vue {
  @Prop({ default: false }) fromTable!: boolean

  private colors: { title: string; value: string }[] = colors

  @Emit()
  private pick(color: string): string {
    return color
  }
}
