import { apolloProvider } from '@/vue-apollo'
import Store from '@/store/modules/Store'

export default async (selections: any[]) => {
  try {
    const { defaultClient } = apolloProvider()
    const assetId = Store.assetForViewer.id

    const {
      data: { getSelectionsData }
    } = await defaultClient.query({
      variables: { selections, assetId },
      query: require('@/graphql/queries/get-selections-data.graphql')
    })

    if (getSelectionsData) {
      Store.setSelectionData(getSelectionsData)

      Store.setActiveSelection(true)
    }
  } catch (error) {
    console.log('⭐error⭐', error)
  }
}
