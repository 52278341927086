var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"min-w-64"},[_c('p',{staticClass:"mb-2 text-xs",domProps:{"textContent":_vm._s(_vm.title)}}),(_vm.fields && _vm.fields.length)?_c('div',{staticClass:"modal-map-fields-wrapper max-h-64"},[_c('div',{staticClass:"modal-map-fields-container"},[_c('div',[(_vm.selectedFields.some(s => s))?_c('IconSelected',{staticClass:"h-5 w-4 cursor-pointer hover:text-red-400",on:{"click":function($event){return _vm.swapAllFieldsStatus(false)}}}):_vm._e(),(_vm.selectedFields.every(s => !s))?_c('IconUnselected',{staticClass:"h-5 w-4 cursor-pointer hover:text-green-400",on:{"click":function($event){return _vm.swapAllFieldsStatus(true)}}}):_vm._e()],1),_c('p',{staticClass:"font-bold",domProps:{"textContent":_vm._s(
          _vm.selectedFields.every(s => s) ? _vm.$t('deselectAll') : _vm.$t('selectAll')
        )}})]),_vm._l((_vm.fields),function(field,index){return _c('div',{key:index,staticClass:"modal-map-fields-items",attrs:{"field":field}},[_c('div',{staticClass:"flex w-1/6 space-x-2",on:{"click":function($event){return _vm.selectMainField(index)}}},[(field.selected)?_c('IconSelected',{staticClass:"h-5 w-6 cursor-pointer hover:text-red-400",on:{"click":function($event){$event.stopPropagation();return _vm.swapSelect(index)}}}):_vm._e(),(!field.selected)?_c('IconUnselected',{staticClass:"h-5 w-6 cursor-pointer hover:text-green-400",on:{"click":function($event){$event.stopPropagation();return _vm.swapSelect(index)}}}):_vm._e(),_c('IconStar',{staticClass:"w-5 h-5 rounded-full cursor-pointer",class:index === _vm.mapFieldIndex
              ? 'text-yellow-500 bg-yellow-50 hover:text-gray-400'
              : _vm.checkTableXlsxImport(field.header)
              ? 'text-gray-400 hover:text-yellow-500'
              : 'disabled'})],1),_c('p',{staticClass:"w-5/6 text-xs",class:index === _vm.mapFieldIndex ? 'font-semibold' : '',domProps:{"textContent":_vm._s(field.header)}})])})],2):_c('p',{staticClass:"text-sm text-red-500",domProps:{"textContent":_vm._s(_vm.$t('noFields'))}})])
}
var staticRenderFns = []

export { render, staticRenderFns }