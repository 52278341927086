
import { Vue, Component, Emit } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'

@Component({
  components: {},
  directives: { onClickaway }
})
export default class ConfirmCreateHub extends Vue {
  @Emit() confirm() {}
  @Emit() closeModal() {}
}
