
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator'
import Store from '@/store/modules/Store'
import Cell from '@/components/Viewer/TableData/TableCells/Cell.vue'
import { ViewerModel } from '@/store/Models'
import IconCaretDown from '@/assets/icons/caret-down.svg'

@Component({
  components: { Cell, IconCaretDown }
})
export default class TableGroups extends Vue {
  @Prop() maximize!: boolean
  @Prop() minimize!: boolean
  @Prop() wrapperWidth!: number
  @Prop() groups!: any
  @Prop() groupsPayload!: any
  @Prop() scrollRowsCounter!: number
  @Prop() filterActive!: boolean
  @Prop() activeTable!: {
    title: string
    origin: string
    isEmpty: boolean
  }
  @Prop() model!: ViewerModel

  private clickedAtTable: boolean = false
  private lastSelection: any = null

  private get activeHeaders(): any[] {
    return Store.activeHeaders
  }

  private get filterList(): any[] {
    return Store.filterList
  }

  private get activeSearchIndex() {
    return Store.activeSearchIndex
  }

  private get bimtableRows() {
    return Store.bimtableRows
  }

  private get selectedGeometries() {
    return Store.selectedGeometries
  }

  private selected(viewerId: string) {
    return this.selectedGeometries.includes(viewerId)
  }

  private get selectedTableName(): string {
    return Store.selectedTableName
  }

  mounted() {
    this.$root.$on(
      'drag-values',
      async ({ value, counter, /*isNegative,*/ index, pos, groupIndex }) => {
        const group = this.groups[groupIndex]

        for (let i = index + 1; i <= index + counter; i++) {
          if (i < group.bimtableRows.length) {
            group.bimtableRows[i][pos] = value

            const reference = group.rows[i][1]

            const defIndexRow = Store.activeRows.findIndex(
              r => r[1] === reference
            )

            Store.bimtableRows[defIndexRow][pos] = value
          }
        }

        this.$forceUpdate()
        await this.storeBimtableRowsValues()
      }
    )
  }

  private selectRow(viewerId: string) {
    this.lastSelection = {
      viewerIds: [parseInt(viewerId)],
      objectId: this.model.objectId
    }

    this.selection()
  }

  private selection() {
    this.$root.$emit('pick-geometries', this.lastSelection)
    this.clickedAtTable = true
  }

  private sendShowRowModalGroups(index: number, i: number) {
    const tableName = this.activeTable.title
    const status = true
    const isGroups = true
    const indexRow = i

    this.$root.$emit('show-row-modal-groups', {
      index,
      indexRow,
      status,
      tableName,
      isGroups
    })
  }

  private goToLink(value: string) {
    if (
      typeof value === 'string' &&
      value.startsWith('https://' || 'http://')
    ) {
      const win = window.open(value, '_blank')
      win.focus()
    }
  }

  private checkLink(value: string): boolean {
    if (
      typeof value === 'string' &&
      value.startsWith('https://' || 'http://')
    ) {
      return true
    } else {
      return false
    }
  }

  private async setInputValueGroup(
    { value, rowIndex, headerIndex },
    groupIndex: number
  ) {
    const group = this.groups[groupIndex]

    group.bimtableRows[rowIndex][headerIndex] = value

    const reference = group.rows[rowIndex][1]
    const defIndexRow = Store.activeRows.findIndex(r => r[1] === reference)
    Store.bimtableRows[defIndexRow][headerIndex] = value

    this.$forceUpdate()
    await this.storeBimtableRowsValues()
  }

  private async storeBimtableRowsValues() {
    const assetId = Store.assetForViewer.id
    const tableName = this.activeTable.title
    const modelId = this.model.objectId
    const modelName = this.model.objectKey
    const rows = JSON.stringify(this.bimtableRows)

    await this.$apollo.mutate({
      variables: { rows, assetId, modelId, modelName, tableName },
      mutation: require('@/graphql/mutations/edit-bimtable-value.graphql')
    })
  }

  private scrollWrapper(amount: number) {
    const wrapper = this.$refs.wrapper as HTMLElement

    this.setScrollAmount(amount)

    setTimeout(() => {
      wrapper.scroll({
        top: amount
      })
    }, 50)
  }

  @Watch('selectedGeometries')
  onSelectedGeometriesChange() {
    if (!this.clickedAtTable) {
      const firstSelected = this.selectedGeometries[0]
      let index = 0

      if (Store.activeRows.find(row => row[1] === firstSelected)) {
        for (const key in this.groups) {
          if (this.groups[key].rows.some(r => r[1] === firstSelected)) {
            break
          }

          index++
        }

        this.scrollWrapper(index === -1 ? 0 : index * 80)
      }
    }

    setTimeout(() => {
      this.clickedAtTable = false
    }, 100)
  }

  @Emit() setScrollAmount(scroll: number): number {
    return scroll
  }
}
