
import { Vue, Component, Prop } from 'vue-property-decorator'
import IconConfirm from '@/assets/icons/check.svg'
import Store from '@/store/modules/Store'

@Component({
  components: { IconConfirm }
})
export default class SharedAssetNotification extends Vue {
  @Prop() notification!: any
  @Prop() activeTab!: 'read' | 'unread'

  private get assetTitle(): string {
    return this.notification.concept.split('·')[0]
  }

  private get email(): string {
    if (this.notification && this.notification.concept) {
      const p = this.notification.concept.split('·')

      const c = p[1].split('#')[0]

      return c
    } else {
      return ''
    }
  }

  private async notificationSeen() {
    if (!this.notification.seen) {
      try {
        const notificationId = this.notification.notificationId
        const ownerId = this.notification.ownerId

        const {
          data: { markAsSeen }
        } = await this.$apollo.mutate({
          variables: { notificationId, ownerId },
          mutation: require('@/graphql/mutations/mark-as-seen.graphql')
        })

        if (markAsSeen) {
          Store.clearNotificationAndMarkRead(notificationId)
        }
      } catch (error) {
        console.error('error', error)
      }
    }
  }

  private async acceptParticipate() {
    try {
      const parts = this.notification.concept.split('#')

      // todo: duplicated - bring to use case file
      // todo: estructura acoplada a .concept desde back · invite.ts
      const assetId = parts[1]
      const tempId = parts[2]
      const memberId = parts[3]

      const {
        data: { acceptParticipate }
      } = await this.$apollo.mutate({
        variables: { assetId, memberId, tempId },
        mutation: require('@/graphql/mutations/accept-participate.graphql')
      })

      if (acceptParticipate) {
        this.$toasted.success(this.$i18n.t('success.shared') as string)
        this.$root.$emit('refresh-shared-assets')

        await this.notificationSeen()
      }
    } catch {
      this.$toasted.error(this.$i18n.t('errors.shared') as string)
    }
  }
}
