
import { Vue, Component, Emit } from 'vue-property-decorator'
import viewerSidebar from '@/config/menus/viewerSidebar'
import Store from '@/store/modules/Store'

@Component
export default class ViewerSidebar extends Vue {
  private menu = viewerSidebar
  private active: number = null
  private fromWeb: boolean = false

  async beforeMount() {
    this.fromWeb =
      window.parent.origin === 'http://localhost:8080' ||
      window.parent.origin === 'https://bimtable.app' ||
      window.parent.origin === 'https://bimtable-beta-env.web.app'
  }

  mounted() {
    this.$root.$on('reset-viewer-sidebar', () => {
      this.active = null
    })

    this.$root.$on('active-models-list', () => {
      this.action(1)
    })
  }

  private get isOwner(): boolean {
    return Store.assetForViewer.ownerId === Store.user.id
  }

  private get isDemoModel(): boolean {
    return Store.assetForViewer.id === process.env.VUE_APP_DEMO_ASSET_ID
  }

  @Emit() action(index: number): number | null {
    this.active = index === this.active ? null : index
    return this.active
  }
}
