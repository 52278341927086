import { apolloProvider, onLogin } from '../vue-apollo'
import StoreUser from '../store/modules/Store'
import router from '../router'

export default async (token: string) => {
  try {
    const { defaultClient } = apolloProvider()

    const {
      errors,
      data: { verifyJwt }
    } = await defaultClient.query({
      variables: { token },
      query: require('@/graphql/queries/verify-jwt.graphql')
    })

    if (!errors && verifyJwt) {
      await onLogin(defaultClient, token)
      StoreUser.setUser(token)
    } else if (errors) {
      console.error('errors-auth')
    }
  } catch (error) {
    localStorage.clear()
    console.error('error', error)
    await router.push({ name: 'Login' })
  }
}
