
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'

@Component
export default class Input extends Vue {
  @Prop({ default: '' }) value!: string
  @Prop({ default: 'text' }) inputType!: string
  @Prop({ default: 'off' }) autoComplete!: string
  @Prop({ default: '' }) label!: string
  @Prop({ default: 'input' }) inputId!: string
  @Prop({ default: true }) isFull!: boolean
  @Prop({ default: false }) increasePadding!: boolean
  @Prop({ default: '' }) placeholder!: string
  @Prop({ default: '' }) description!: string
  @Prop({ default: false }) isRequired!: boolean
  @Prop({ default: false }) row!: boolean
  @Prop({ default: false }) isCentered!: boolean
  @Prop({ default: 100 }) maxLength!: number

  private reachedLimit: boolean = false

  mounted() {
    if (this.row) {
      const input = document.getElementById('input')
      input.focus()
    }
  }

  private emitEnter() {
    if (this.row) this.$root.$emit('input-enter')
  }

  @Emit()
  onInput(value: string): string {
    if (this.maxLength) {
      this.reachedLimit =
        value.length === this.maxLength || value.length > this.maxLength
    }

    if (value.length > this.maxLength) {
      return value.slice(0, this.maxLength)
    } else {
      return value
    }
  }

  @Emit()
  onReachedLimit(status: boolean): boolean {
    return status
  }

  @Watch('reachedLimit')
  onReachedLimitChange() {
    this.onReachedLimit(this.reachedLimit)
  }
}
