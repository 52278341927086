import Vue from 'vue'
import store from './store'
import App from './App.vue'
import router from './router'
import './styles/main.scss'
import i18n from './i18n'
import ToastedPlugin from 'vue-toasted'
import VueCumulioDashboard from '@cumul.io/vue-cumulio-dashboard/vue2'
import { apolloProvider } from './vue-apollo'
import VueLuzmo from '@luzmo/vue-embed/vue2'

Vue.config.productionTip = false

Vue.use(VueCumulioDashboard)
Vue.use(VueLuzmo)

Vue.use(ToastedPlugin, {
  duration: 3 * 1000,
  singleton: true,
  keepOnHover: true,
  position: 'top-center',
  theme: 'bubble'
})

new Vue({
  router,
  store,
  i18n,
  apolloProvider: apolloProvider(),
  render: h => h(App)
}).$mount('#app')
