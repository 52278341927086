
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Hub, Asset } from '@/store/Models'
import AddAssetBtn from '@/components/Dashboard/Assets/AddAssetBtn.vue'
import IconSearch from '@/assets/icons/magnifying-glass.svg'

@Component({
  components: { AddAssetBtn, IconSearch }
})
export default class AssetActions extends Vue {
  @Prop() hub!: Hub

  private titleClicked: boolean = false
  private loading: boolean = false
  private showAddAssetModal: boolean = false
  private inFocus: boolean = false
  private assets: Asset[] = []
  private searchTerm: string = ''

  private inputStyles: string =
    'background: none !important; border: none !important; box-shadow: none !important;'

  private sendButtonAsset() {
    this.showAddAssetModal = !this.showAddAssetModal
    this.$root.$emit('send-button-asset', this.showAddAssetModal)
  }

  @Watch('searchTerm')
  onSearchTermChange() {
    this.$root.$emit('search-term-change', this.searchTerm)
  }
}
