import { apolloProvider } from '../../vue-apollo'
import Store from '../../store/modules/Store'

export default async (type: string, modelName: string, payload: any) => {
  try {
    const assetId = Store.assetForViewer.id

    await apolloProvider().defaultClient.mutate({
      variables: { type, modelName, assetId, payload: JSON.stringify(payload) },
      mutation: require('@/graphql/mutations/set-config.graphql')
    })
  } catch (error) {
    console.error('error', error)
  }
}
