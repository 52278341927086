import setSelections from '@/controllers/dataManagement/setSelections'
import getSelectionsData from '@/controllers/dataManagement/getSelectionsData'
import i18n from '@/i18n'
import Store from '@/store/modules/Store'

export default viewer => {
  // @ts-ignore
  const button = new Autodesk.Viewing.UI.Button('zoom'),
    // @ts-ignore
    button2 = new Autodesk.Viewing.UI.Button('selected')
  // @ts-ignore
  const button3 = new Autodesk.Viewing.UI.Button('tool')

  button.addClass('forge-button-home')
  button.setToolTip(i18n.t('zoomExtended') as string)

  button.onClick = () => {
    viewer.fitToView(5)
  }

  button2.addClass('forge-button-info')
  button2.setToolTip(i18n.t('selectedItemsData') as string)

  button2.onClick = async () => {
    const selections = await setSelections(viewer)

    if (selections.length) {
      await getSelectionsData(selections)
    } else {
      document.dispatchEvent(new CustomEvent('show-selection-error'))
    }
  }

  button3.addClass('forge-button-overlay')
  button3.setToolTip(i18n.t('forge.overlay') as string)

  let status: boolean = false

  button3.onClick = () => {
    status = !status
    Store.setShowOverlay(status)
  }

  // @ts-ignore
  const toolbar = new Autodesk.Viewing.UI.ControlGroup('custom')
  toolbar.addControl(button)
  toolbar.addControl(button2)
  toolbar.addControl(button3)

  viewer.toolbar.addControl(toolbar)
}
