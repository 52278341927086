var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.headerRequested !== '')?_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.handleHideActionsHeaders),expression:"handleHideActionsHeaders"}],staticClass:"panel-header-action",class:_vm.headerRequested === 'name'
      ? 'fixed ml-2 top-36 z-5000'
      : 'absolute top-6 z-5000',style:(_vm.actionsHeadersStyle)},_vm._l((_vm.headerActions),function(action,actionIndex){return _c('p',{key:actionIndex,staticClass:"panel-header-subaction",class:{
      'disabled': !_vm.checkBimtableHeader && actionIndex === 4,
      'bg-gray-300': _vm.checkHideData && actionIndex === 3
    },on:{"click":function($event){return _vm.execAction(actionIndex)}}},[_c(action.icon,{tag:"Component",staticClass:"h-4"}),_c('span',{staticClass:"ml-2",domProps:{"textContent":_vm._s(
        actionIndex === 3 && _vm.selectedHeader.showOnlyOwner === true
          ? _vm.$t('titles.showData')
          : action.title
      )}})],1)}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }