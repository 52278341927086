
import { Vue, Component, Emit } from 'vue-property-decorator'
import Store from '@/store/modules/Store'
import IconEye from '@/assets/icons/eye.svg'
import { directive as onClickaway } from 'vue-clickaway'

@Component({
  components: { IconEye },
  directives: { onClickaway }
})
export default class HiddenFieldsPanel extends Vue {
  private get hiddenHeaders(): string[] {
    return Store.hiddenHeaders
  }

  private recoverField(header: string) {
    Store.removeHiddenHeader(header)

    this.$root.$emit('remove-hidden-header', header)

    this.close()
  }

  @Emit() close() {}
}
