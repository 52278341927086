
import { Vue, Component, Prop } from 'vue-property-decorator'
import IconConfirm from '@/assets/icons/check.svg'
import Store from '@/store/modules/Store'

@Component({
  components: { IconConfirm }
})
export default class SyncNotification extends Vue {
  @Prop() notification!: any
  @Prop() activeTab!: 'read' | 'unread'
  @Prop({ default: false }) confirmDelete!: boolean

  private async notificationSeen() {
    if (!this.notification.seen) {
      try {
        const notificationId = this.notification.notificationId
        const ownerId = this.notification.ownerId

        const {
          data: { markAsSeen }
        } = await this.$apollo.mutate({
          variables: { notificationId, ownerId },
          mutation: require('@/graphql/mutations/mark-as-seen.graphql')
        })

        if (markAsSeen) {
          Store.clearNotificationAndMarkRead(notificationId)
        }
      } catch (error) {
        console.error('error', error)
      }
    }
  }
}
