import { apolloProvider } from '@/vue-apollo'

export default async (objectId: string) => {
  try {
    const { defaultClient } = apolloProvider()

    const {
      data: { getGuids }
    } = await defaultClient.query({
      variables: { objectId },
      query: require('@/graphql/queries/get-guids.graphql')
    })

    return getGuids
  } catch {
    console.error('error')
    return null
  }
}
