
import { Vue, Component, Emit, Prop } from 'vue-property-decorator'
import Button from '@/components/shared/Button.vue'
import IconArrowRight from '@/assets/icons/arrow-right.svg'
import Store from '@/store/modules/Store'
import IconX from '@/assets/icons/x.svg'
import IconTable from '@/assets/icons/table.svg'
import { ViewerModel } from '@/store/Models'

@Component({
  components: { Button, IconArrowRight, IconTable, IconX }
})
export default class TableViews extends Vue {
  @Prop() tableNameTitle!: string
  @Prop() selected!: string
  @Prop() model!: ViewerModel

  private title: string = ''
  private showInput: boolean = false
  private selectedTableView: any = null
  private selectedTitle: string = ''

  private tableView = {
    title: '',
    tableName: '',
    config: {
      filters: {
        filters: []
      },
      groups: {
        groups: []
      },
      hiddenHeaders: {
        fields: []
      }
    }
  }

  private get filterConditions(): any[] {
    return Store.filterConditions
  }

  private get groupsItems(): any[] {
    return Store.groupsItems
  }

  private get tableViews(): any[] {
    return Store.tableViews
  }

  private get selectedTableName(): string {
    return Store.selectedTableName
  }

  private get hiddenHeaders(): string[] {
    return Store.hiddenHeaders
  }

  private showInputView() {
    this.showInput = true
    const input = document.getElementById('table-view-title')
    setTimeout(() => {
      input.focus()
    }, 200)
  }

  private async addTableView() {
    if (
      this.title !== '' &&
      !Store.tableViews.map(t => t.title).includes(this.title)
    ) {
      // @ts-ignore
      let groups = this.groupsItems.groups

      if (groups) {
        delete groups[0].__typename
      } else if (groups === null) {
        groups = []
      }

      let filters = this.filterConditions[0]

      if (this.filterConditions.length) {
        delete filters.__typename
      } else {
        filters = []
      }

      this.tableView = {
        title: this.title,
        tableName: this.tableNameTitle,
        config: {
          filters: {
            filters: filters
          },
          groups,
          hiddenHeaders: {
            fields: this.hiddenHeaders
          }
        }
      }

      Store.addTableView({
        title: this.title,
        // @ts-ignore
        tableName: this.tableNameTitle,
        config: {
          filters: filters,
          groups,
          hiddenHeaders: this.hiddenHeaders
        }
      })

      Store.setActiveTableView(this.tableViews.length - 1)

      this.setSelectedTitle(this.title)
      this.sendStoreData()
    } else if (
      this.title !== '' &&
      Store.tableViews.map(t => t.title).includes(this.title)
    ) {
      this.$toasted.error(this.$i18n.t('tableViews.errorTitle') as string)
    } else {
      this.$toasted.error(this.$i18n.t('tableViews.enterTitle') as string)
    }
  }

  private async deleteTableView(title: string) {
    let tableViews: any[]
    tableViews = Store.tableViews.filter(t => t.title !== title)

    const assetId = Store.assetForViewer.id
    // @ts-ignore
    const tableName = this.tableNameTitle
    const modelName = this.model.objectKey

    Store.setTableViews(tableViews)
    Store.addDeleteTableViews(title, tableName)

    try {
      const {
        data: { removeTableView }
      } = await this.$apollo.mutate({
        variables: { title, assetId, tableName, modelName },
        mutation: require('@/graphql/mutations/remove-table-view.graphql')
      })
    } catch (e) {
      console.log(e, 'error')
    }
  }

  private async sendStoreData() {
    try {
      const tableView = this.tableViews[this.tableViews.length - 1]
      const assetId = Store.assetForViewer.id
      const modelName = this.model.objectKey

      const {
        data: { setTableViews }
      } = await this.$apollo.mutate({
        variables: { tableView, assetId, modelName },
        mutation: require('@/graphql/mutations/set-table-views.graphql')
      })

      if (setTableViews) {
        this.$toasted.success(this.$i18n.t('tableViews.created') as string)
      }
    } catch (err) {
      console.log(err, 'error')
    }
  }

  private setTableView(index: number) {
    Store.setActiveTableView(index)

    this.selectedTableView = this.tableViews[index]

    if (this.selected === this.tableViews[index].title) {
      this.setSelectedTitle('')
      Store.setActiveTableView(null)

      this.restartStores()

      this.$root.$emit('disable-mode-table-view')
    } else {
      this.restartStores()

      this.setSelectedTitle(this.tableViews[index].title)

      if (this.selectedTableView.config.filters.length) {
        Store.setFilterConditions(this.selectedTableView.config.filters)

        this.$root.$emit('set-filters-table-view')
      } else if (this.selectedTableView.config.groups.length > 0) {
        const header = this.selectedTableView.config.groups[0].header
        const operator = this.selectedTableView.config.groups[0].operator

        const payload = {
          tableName: this.selectedTableView.tableName,
          groups: [
            {
              header,
              operator,
              child: null,
              root: true
            }
          ]
        }

        Store.setGroupsItems(this.selectedTableView.config.groups)

        this.$root.$emit('set-groups-table-view', payload)
      } else if (this.selectedTableView.config.hiddenHeaders.length) {
        Store.setHiddenHeaders(this.selectedTableView.config.hiddenHeaders)
      }
    }
  }

  private restartStores() {
    // Reset groups
    Store.resetGroupsItems()
    Store.resetGroups()
    this.$root.$emit('delete-groups-payload')

    // Reset Filters
    Store.resetFilterConditions()
    Store.resetFilterList()

    // Reset HiddenHeaders
    Store.resetHiddenHeaders()
  }

  @Emit()
  setSelectedTitle(title: string) {
    return title
  }
}
