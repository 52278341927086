
import { Vue, Component, Emit, Prop } from 'vue-property-decorator'
import Input from '@/components/shared/Input.vue'
import IconCheck from '@/assets/icons/check.svg'

@Component({
  components: { Input, IconCheck }
})
export default class AddFieldPopup extends Vue {
  @Prop({ default: false }) isAtLeft!: boolean

  private fieldName: string = ''
  private fieldTitle: string = null

  async mounted() {
    this.$root.$on('headers-includes-input', (fieldTitle: string) => {
      this.fieldTitle = fieldTitle
      setTimeout(() => {
        this.fieldTitle = null
      }, 3000)
    })

    const container = document.getElementById('input')
    if (container) container.focus()
  }

  private checkAddField() {
    if (this.fieldName.length) this.addField()
  }

  @Emit() addField(): string {
    return this.fieldName
  }
}
