import Product from '@/views/landing/Product.vue'
import Discover from '@/views/landing/Discover.vue'
import Pricing from '@/views/landing/Pricing.vue'
import Contact from '@/views/landing/Contact.vue'
import Login from '@/components/Login/Login.vue'
import Solutions from '@/views/landing/Solutions.vue'
import VerifyAccount from '@/views/landing/VerifyAccount.vue'
import Invite from '@/views/Invite.vue'
import Legal from '@/views/Legal.vue'
import InviteTeam from '@/views/InviteTeam.vue'

export default [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/product',
    name: 'Product',
    component: Product
  },
  {
    path: '/discover',
    name: 'Discover',
    component: Discover
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/legal',
    name: 'Legal',
    component: Legal
  },
  {
    path: '/solutions',
    name: 'Solutions',
    component: Solutions
  },
  {
    path: '/verify-account/:userId/:emailToken',
    component: VerifyAccount
  },
  {
    path: '/invite/:memberId/:tempId/:assetId',
    name: 'Invite',
    component: Invite
  },
  {
    path: '/invite-team/:teamId/:hash',
    name: 'InviteTeam',
    component: InviteTeam
  }
]
