var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.hideDrop),expression:"hideDrop"}],staticClass:"border-box",class:{ 'bg-green-50': !_vm.isSelected }},[_c('div',{staticClass:"relative flex items-center justify-between w-full cursor-pointer",class:{
      'border border-green-600': _vm.showDrop
    },on:{"click":function($event){_vm.showDrop = true
      _vm.showInput = false},"dblclick":_vm.handleShowInput}},[(_vm.onDrag)?_c('div',{staticClass:"absolute top-0 left-0 bg-gray-100 border-2 border-green-500 rounded-md max-w-64 min-w-64 z-5000",style:(`height: ${(_vm.counter + 1) * _vm.threshold}px`)},[_c('p',{domProps:{"textContent":_vm._s(_vm.text)}})]):_vm._e(),(!_vm.showInput)?_c('p',{staticClass:"cursor-pointer panel-cell min-w-64 max-w-64 hover:bg-gray-100",class:{
        'bg-yellow-100 font-bold': _vm.isSelected,
        'border bg-gray-300 ': _vm.showDrop
      },domProps:{"textContent":_vm._s(_vm.text)}}):_c('Input',{staticClass:"w-64",attrs:{"input-id":"input","value":_vm.text,"row":true},on:{"on-input":function($event){_vm.inputText = $event}}}),(_vm.showDrop)?_c('div',{style:('cursor: n-resize'),attrs:{"draggable":true,"title":_vm.$t('dragInstructions')},on:{"dragstart":_vm.dragStart,"dragend":_vm.sendValues,"drag":_vm.calcDrag}},[_c('div',{staticClass:"absolute w-3 h-3 mt-1 border border-gray-500 rounded-full -right-2 z-5000 hover:border-blue-500",class:{ 'opacity-0': _vm.onDrag }})]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }