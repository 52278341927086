
import { Vue, Component, Prop } from 'vue-property-decorator'
import IconFolderOpen from '@/assets/icons/folder-open.svg'
import IconFolderClosed from '@/assets/icons/folder.svg'
import IconFile from '@/assets/icons/file.svg'
import IconVersion from '@/assets/icons/cube.svg'
import Store from '@/store/modules/Store'
import { Folder } from '@/store/Models'
import moment from 'moment'
import getSubFolders from '@/controllers/dataManagement/getSubFolders'
import getVersions from '@/controllers/dataManagement/getVersions'

@Component({
  components: { FoldersTree }
})
export default class FoldersTree extends Vue {
  @Prop() folder!: Folder
  @Prop({ default: null }) versionNum!: number
  @Prop() openedModels!: string[]

  @Prop({ default: { project: null, team: { id: '' } } }) selected!: {
    project: null
    team: null
  }

  private clicked: boolean = false
  private subFolders: Folder[] = []
  private noFolders: boolean = false
  private comp: Vue.Component = FoldersTree

  private icons = {
    folderOpen: IconFolderOpen,
    folderClosed: IconFolderClosed,
    file: IconFile,
    version: IconVersion
  }

  private get icon(): Vue.Component {
    return this.folder.type === 'folders'
      ? this.clicked
        ? this.icons.folderOpen
        : this.icons.folderClosed
      : this.folder.type === 'items'
      ? this.icons.file
      : this.icons.version
  }

  private get lastModified(): string {
    return moment(this.folder.lastModified).format('DD/MM/YY hh:mm') as string
  }

  private toggleOpenFolder() {
    this.clicked = !this.clicked

    if (this.clicked) {
      this.folder.type === 'folders'
        ? this.getSubFolders()
        : this.folder.type === 'items'
        ? this.selectModel()
        : this.processVersion()
    }
  }

  private async getSubFolders() {
    this.subFolders = await getSubFolders(
      this.folder.id,
      // @ts-ignore
      this.selected.project.id ? this.selected.project.id : ''
    )

    if (!this.subFolders.length) {
      this.noFolders = true
    }
  }

  private async selectModel() {
    this.subFolders = await getVersions(
      this.folder.id,
      // @ts-ignore
      this.selected.project.id ? this.selected.project.id : ''
    )
  }

  private get randomColor(): string {
    const random = () => {
      return Math.floor(Math.random() * 255)
    }

    return `rgba(${random()}, ${random()}, ${random()}, 0.5)`
  }

  private processVersion() {
    Store.setModelFromAutodeskBrowser(this.folder)
  }
}
