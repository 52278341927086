var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('TransitionGroup',{staticClass:"viewer-sidebar-wrapper",attrs:{"name":"fade"}},_vm._l((_vm.menu),function(item,index){return _c('div',{key:index,staticClass:"viewer-sidebar-container",class:{
      'viewer-sidebar-container-active': index === _vm.active,
      'hidden opacity-0 pointer-events-none': index === 2 && !_vm.fromWeb,
      'disabled':
        (index === 3 && (!_vm.fromWeb || !_vm.isOwner || _vm.isDemoModel)) ||
        (index === 2 && _vm.isDemoModel)
    },attrs:{"title":item.description},on:{"click":function($event){return _vm.action(index)}}},[_c(item.icon,{tag:"Component",staticClass:"viewer-sidebar-icon"})],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }