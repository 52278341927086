
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import Store from '@/store/modules/Store'
import IconCaretDown from '@/assets/icons/caret-down.svg'
import { directive as onClickaway } from 'vue-clickaway'
import IconEye from '@/assets/icons/eye.svg'
import IconClose from '@/assets/icons/x.svg'
import setModelConfig from '@/controllers/forgeViewer/setModelConfig'
import setTableViewConfig from '@/controllers/forgeViewer/setTableViewConfig'
import { ViewerModel } from '@/store/Models'

@Component({
  components: { IconCaretDown, IconClose, IconEye },
  directives: { onClickaway }
})
export default class Group extends Vue {
  @Prop() headersUsedList!: string[]
  @Prop() model: ViewerModel

  // private headersGroups: string[] = []

  // private get hgs() {
  //   return this.headersGroups
  // }

  mounted() {
    if (this.groups) this.initSelectors()
  }

  private initSelectors() {
    const { header, operator } = this.groups[0]

    // const index = this.activeHeaders.findIndex(h => h.header === header)
    //
    // const replaceHeader = this.activeHeaders[index]
    //
    // this.headersGroups = Array.from(this.activeHeaders)
    //
    // this.headersGroups.splice(index, 1)
    //
    // this.headersGroups.unshift(replaceHeader)

    const indexOperator = this.operators.indexOf(operator)

    this.operators.splice(indexOperator, 1)

    this.operators.unshift(operator)
  }

  private operators: string[] = [
    this.$t('group.operators.aToZ') as string,
    this.$t('group.operators.zToA') as string
    // this.$t('group.operators.firstLast') as string,
    // this.$t('group.operators.lastFirst') as string
  ]

  private hiddenIndexHeaders(header: string): number {
    return this.activeHeaders.findIndex(h => h.header === header)
  }

  private get activeHeaders(): any[] {
    return Store.activeHeaders
  }

  private get groups(): any {
    return Store.groupsItems.groups
  }

  private get tableViews(): any[] {
    return Store.tableViews
  }

  private get selectedTableName(): string {
    return Store.selectedTableName
  }

  private async deleteGroup() {
    if (Store.activeTableView !== null) {
      this.tableViews[Store.activeTableView].config.groups = []
      const { title } = await this.tableViews[Store.activeTableView]

      await setTableViewConfig(
        'groups',
        this.selectedTableName,
        this.model.objectKey,
        title,
        {
          groups: []
        }
      )
    } else {
      await setModelConfig('remove-groups', this.model.objectKey, {
        tableName: this.selectedTableName,
        groups: []
      })

      const modelConfig: any = Store.assetForViewer.configs.find(
        c => c.modelName === this.model.objectKey
      )

      if (modelConfig && modelConfig.groups) {
        const index = modelConfig.groups.findIndex(
          g => g.tableName === this.selectedTableName
        )
        modelConfig.groups.splice(index, 1)
      }
    }

    Store.resetGroupsItems()
    Store.resetGroups()

    this.$root.$emit('delete-groups-payload')
  }

  private setChangeHeader(value: string) {
    this.groups[0].header = value
    this.modifyGroup()
  }

  private setChangeOperator(value: string) {
    this.groups[0].operator = value
    this.modifyGroup()
  }

  @Emit() modifyGroup() {}
}
