import autodeskClientThree from '../../helpers/autodeskClientThree'
import { ProjectsApi } from 'forge-apis'
import Store from '../../store/modules/Store'

export default async (teamId: string) => {
  try {
    const projectsApi = new ProjectsApi()
    const credentials = Store.autodeskCredentials

    const {
      body: { data }
    } = await projectsApi.getHubProjects(
      teamId,
      {},
      autodeskClientThree,
      credentials
    )

    const projects = data.map(project => {
      return {
        name: project.attributes.name,
        id: project.id
      }
    })

    const topFolders = await projectsApi.getProjectTopFolders(
      teamId,
      projects[Store.projectIndex].id,
      autodeskClientThree,
      credentials
    )

    const folders = topFolders.body.data.map(folder => {
      return {
        name: folder.attributes.name,
        id: folder.id,
        type: folder.type
      }
    })

    return {
      projects,
      folders
    }
  } catch (error) {
    if (error.statusCode === 403) {
      console.log('403')
    }

    throw new Error(error)
  }
}
