
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'
import IconSpinner from '@/assets/icons/spinner-solid.svg'
import IconChevron from '@/assets/icons/chevron-down.svg'
import { directive as onClickaway } from 'vue-clickaway'
import ConnectionModal from '@/components/Viewer/modals/ConnectionModal.vue'
import Store from '@/store/modules/Store'

@Component({
  components: { IconSpinner, IconChevron, ConnectionModal },
  directives: { onClickaway }
})
export default class TableNames extends Vue {
  @Prop() width!: string
  @Prop() tableNames!: {
    title: string
    origin: string
    isEmpty: boolean
  }[]
  @Prop() activeIndex!: number
  @Prop() sidebarOpen!: boolean

  private indexHovering: number = null
  private scrollAmount: number = 0
  private hoverConnections: boolean = false
  private loading: boolean = true
  private actionIndex: number = null
  private actionWidth: number = null
  private actionXPosition: number = null
  private isIsolate: boolean = false

  mounted() {
    this.checkExistingTableNames()

    this.$root.$on('scroll-tablenames-right', () => {
      this.scrollAmount = this.tableNames.length * 100

      document.getElementById('bar').scroll({
        left: this.scrollAmount,
        behavior: 'smooth'
      })
    })
  }

  private switchIsolate() {
    Store.switchIsolation()
  }

  private checkExistingTableNames() {
    if (this.tableNames.length) this.loading = false
  }

  private scroll(direction: 'right' | 'left') {
    this.scrollAmount += direction === 'right' ? 500 : -500

    document.getElementById('bar').scroll({
      left: this.scrollAmount,
      behavior: 'smooth'
    })
  }

  private tableNameStyles(index: number) {
    return {
      'bg-blue-100 border-gray-300 border-b hover:bg-blue-200':
        this.activeIndex !== index,
      'rounded-br-lg': this.activeIndex === index + 1,
      'rounded-bl-lg': this.activeIndex === index - 1,
      'border border-blue-200':
        index === this.indexHovering &&
        (index !== this.activeIndex + 1 || index !== this.activeIndex - 1) &&
        index !== this.activeIndex,
      'flex items-center': index !== this.tableNames.length - 1,
      'relative': index === this.tableNames.length - 1,
      'bg-blue-100':
        index === this.activeIndex && index === this.tableNames.length - 1,
      'font-bold': index === this.activeIndex
    }
  }

  private tableTextStyles(index: number) {
    return {
      'border-gray-300 border-r':
        index !== this.activeIndex &&
        index !== this.activeIndex - 1 &&
        index !== this.indexHovering - 1 &&
        index !== this.tableNames.length - 1,
      'inline-flex items-center justify-center':
        index !== this.tableNames.length - 1,
      'inline-flex items-center justify-start h-full':
        index === this.tableNames.length - 1,
      'bg-white':
        index === this.activeIndex && index === this.tableNames.length - 1
    }
  }

  private showAction(
    event,
    index: number,
    tableName: {
      title: string
      origin: string
      isEmpty: boolean
    }
  ) {
    this.setActiveIndex(tableName)
    this.actionIndex = index
    this.actionWidth = event.target.parentElement.clientWidth

    if (index < this.tableNames.length - 1) {
      this.actionXPosition =
        event.x - this.actionWidth - (this.sidebarOpen ? 370 : 50)
    } else {
      this.actionXPosition = event.x - (this.sidebarOpen ? 370 : 50) - 100
    }
  }

  private hideAction() {
    this.actionIndex = null
  }

  private get isDemo(): boolean {
    return Store.assetForViewer.id === process.env.VUE_APP_DEMO_ASSET_ID
  }

  private isolateCategory() {
    this.isIsolate = true
    this.switchIsolate()

    const ids = Store.activeRows.map(row => parseInt(row[1]))
    this.$root.$emit('isolate-items', ids)
  }

  private undoIsolate() {
    this.isIsolate = false
    this.switchIsolate()
  }

  private checkHideCategory() {
    this.actionIndex = null
    this.hideCategory()
  }

  private exportCsv() {
    this.$root.$emit('exportCsv')
  }

  @Watch('tableNames')
  onTableNamesChange() {
    this.checkExistingTableNames()
  }

  @Emit()
  setActiveIndex(tableName: {
    title: string
    origin: string
    isEmpty: boolean
  }): string {
    Store.setSelectedTable(tableName)

    return tableName.title
  }

  @Emit()
  showConnectionModal(): number {
    return this.activeIndex
  }

  @Emit() newTable() {}

  @Emit() hideCategory(): number {
    return this.activeIndex
  }
}
