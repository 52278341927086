
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class AutodeskOAuth extends Vue {
  private codeQuery: string = ''

  async mounted() {
    const { code, error } = this.$route.query

    if (error) {
      this.$toasted.error(this.$i18n.t('errors.login') as string)
      this.codeQuery = 'error'
    } else if (typeof code === 'string') {
      const {
        data: { autodeskToken }
      } = await this.$apollo.query({
        variables: { code },
        query: require('@/graphql/queries/autodesk-token.graphql')
      })

      localStorage.setItem('autodesk-token', JSON.stringify(autodeskToken))
      window.close()
    }
  }
}
