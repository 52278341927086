export default (filters, where, dataset_id) => {
  filters.forEach((filter: any) => {
    const expression = filter.expression

    if (filter.parameters[1] !== undefined) {
      where.push({
        expression,
        parameters: [
          {
            dataset_id,
            column_id: filter.parameters[0].columnId
          },
          filter.parameters[1]
        ]
      })
    }
  })
}
