
import { Vue, Component, Emit, Prop } from 'vue-property-decorator'
import IconAirtable from '@/assets/icons/airtable.svg'
import IconColorize from '@/assets/icons/palette.svg'
import IconCompare from '@/assets/icons/compare.svg'
import IconSpinner from '@/assets/icons/spinner-solid.svg'
import IconUpdate from '@/assets/icons/up-to-line.svg'

@Component({
  components: { IconSpinner }
})
export default class BimtableButtons extends Vue {
  @Prop() hasMetadata!: boolean

  private moveUp: boolean = false
  private colorClicked: boolean = false

  private menu = [
    {
      action: 'metadata',
      icon: IconAirtable,
      description: this.$i18n.t('descriptions.airtable') as string
    },
    {
      action: this.colorClicked ? 'decolorize' : 'colorize',
      icon: IconColorize,
      description: this.$i18n.t('descriptions.colorize') as string
    },
    {
      action: 'compare',
      icon: IconCompare,
      description: this.$i18n.t('descriptions.compare') as string
    },
    {
      action: 'update',
      icon: IconUpdate,
      description: this.$i18n.t('update')
    }
  ]

  private loading = this.menu.map(_ => false)

  private get isLoadingMetadata(): boolean {
    return this.loading[0]
  }

  mounted() {
    this.$root.$on('move-viewer-buttons', (maximize: boolean) => {
      this.moveUp = maximize
    })

    this.$root.$on('stop-loading', () => {
      this.loading = this.menu.map(_ => false)
    })

    this.hearWindowMessages()
  }

  private hearWindowMessages() {
    window.addEventListener('message', ({ data }) => {
      const { type } = data

      if (type === 'extract-metadata-finished') {
        this.extractMetadataFinished(data)
      } else if (type === 'update-complete') {
        this.$toasted.success(this.$i18n.t('success.updatedData') as string)
      }
    })
  }

  private extractMetadataFinished(data) {
    const { ellapsedTime } = data

    this.$toasted.success(
      // @ts-ignore
      this.$i18n.t('success.metadataFinish', { ellapsedTime }) as string
    )

    this.loading = this.loading.map((load, index) => {
      if (index === 0) {
        return false
      } else {
        return load
      }
    })
  }

  private preAction(action: string) {
    if (action === 'colorize') {
      this.colorClicked = !this.colorClicked
      if (!this.colorClicked) action = 'decolorize'
    }

    if (action === 'metadata') {
      this.loading = this.loading.map((_, i) => i === 0)
    }

    this.action(action)
  }

  @Emit()
  action(action: string): string {
    return action
  }
}
