
import { Vue, Component, Emit, Prop } from 'vue-property-decorator'
import Input from '@/components/shared/Input.vue'
import Button from '@/components/shared/Button.vue'
import Store from '@/store/modules/Store'

@Component({
  components: { Button, Input }
})
export default class NewTableInput extends Vue {
  @Prop() tableNames: any[]
  @Prop() modelName!: string

  private tableName: string = ''

  mounted() {
    document.getElementById('input').focus()
  }

  private get notAllowed(): boolean {
    return this.tableName === '' || this.tableNames.includes(this.tableName)
  }

  private preCreateNewTable() {
    if (!this.notAllowed) this.createNewTable()
  }

  private async createNewTable() {
    try {
      const tableName = this.tableName
      const assetId = Store.assetForViewer.id
      const modelName = this.modelName
      const hubId = Store.hub.id

      const {
        data: { createNewTable }
      } = await this.$apollo.mutate({
        variables: { tableName, assetId, modelName, hubId },
        mutation: require('@/graphql/mutations/create-new-table.graphql')
      })

      if (createNewTable) {
        this.$toasted.success(this.$i18n.t('success.newTable') as string)
        Store.setActiveHeaders([])
        this.$root.$emit('refresh-tableNames')

        setTimeout(() => {
          this.$root.$emit('set-active-last-index')
          this.$root.$emit('scroll-tablenames-right')
          this.$root.$emit('show-new-column', true)
        }, 300)

        this.close()
      }
    } catch (error) {
      console.error('error', error)
    }
  }

  @Emit() close() {}
}
