var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"modal-wrapper"},[_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.close),expression:"close"}],staticClass:"pb-8 modal-container"},[(_vm.data.isGroups === false)?_c('div',{staticClass:"modal-row-detail-container"},[_c('div',{staticClass:"modal-row-detail-header"},[_c('p',{domProps:{"textContent":_vm._s(_vm.data.tableName)}}),_c('p',{domProps:{"textContent":_vm._s(_vm.activeRows[_vm.data.index][0])}})]),_c('div',{staticClass:"modal-row-detail-list"},[_c('div',{staticClass:"flex flex-col w-full space-y-2"},[_vm._l((_vm.activeRows[_vm.data.index]),function(r,i){return _c('div',{key:i,staticClass:"border-b",class:{ 'hidden': i === 1 || i === 2 }},[_c('p',{staticClass:"modal-row-detail-title",domProps:{"textContent":_vm._s(_vm.activeHeaders[i].header)}}),_c('p',{staticClass:"text-sm",class:{
                  'modal-row-detail-link': _vm.checkLink(r)
                },domProps:{"textContent":_vm._s(_vm.checkRow(r))},on:{"click":function($event){if($event.target !== $event.currentTarget)return null;;(r.startsWith('https://') || r.startsWith('http://')) &&
                    _vm.goToLink(r)}}})])}),(_vm.bimtableRows && _vm.bimtableRows.length)?_c('div',{staticClass:"pb-4"},_vm._l((_vm.bimtableRows[_vm.data.index]),function(r,i){return _c('div',{key:i,staticClass:"pb-2 bg-green-100 border-b"},[_c('p',{staticClass:"rounded modal-row-detail-title",domProps:{"textContent":_vm._s(
                    _vm.activeHeaders[i + _vm.activeRows[_vm.data.index].length].header
                  )}}),_c('Input',{staticClass:"px-2 bg-transparent",attrs:{"value":r,"isFull":true},on:{"on-input":function($event){return _vm.setInputRow(r)}}})],1)}),0):_vm._e()],2)])]):_vm._e(),(_vm.data.isGroups === true)?_c('div',{staticClass:"modal-row-detail-container"},[_c('div',{staticClass:"modal-row-detail-header"},[_c('p',{domProps:{"textContent":_vm._s(_vm.data.tableName)}}),_c('p',{domProps:{"textContent":_vm._s(_vm.groups[_vm.data.index].rows[_vm.data.indexRow][0])}})]),_c('div',{staticClass:"modal-row-detail-listF"},[_c('div',{staticClass:"flex flex-col w-full space-y-2"},[_vm._l((_vm.groups[_vm.data.index].rows[_vm.data.indexRow]),function(r,i){return _c('div',{key:i,staticClass:"border-b",class:{ 'hidden': i === 1 || i === 2 }},[_c('p',{staticClass:"modal-row-detail-title",domProps:{"textContent":_vm._s(_vm.activeHeaders[i].header)}}),_c('p',{staticClass:"text-sm",class:{
                  'modal-row-detail-link': _vm.checkLink(r)
                },domProps:{"textContent":_vm._s(_vm.checkRow(r))},on:{"click":function($event){if($event.target !== $event.currentTarget)return null;;(r.startsWith('https://') || r.startsWith('http://')) &&
                    _vm.goToLink(r)}}})])}),(
                _vm.groups[_vm.data.index].bimtableRows &&
                _vm.groups[_vm.data.index].bimtableRows.length
              )?_c('div',{staticClass:"pb-4"},_vm._l((_vm.groups[_vm.data.index].bimtableRows[
                  _vm.data.indexRow
                ]),function(r,i){return _c('div',{key:i,staticClass:"pb-2 bg-green-100 border-b"},[_c('p',{staticClass:"rounded modal-row-detail-title",domProps:{"textContent":_vm._s(
                    _vm.activeHeaders[
                      i + _vm.groups[_vm.data.index].rows[_vm.data.indexRow].length
                    ].header
                  )}}),_c('Input',{staticClass:"px-2 bg-transparent",attrs:{"value":r,"isFull":true},on:{"on-input":function($event){return _vm.setInputRow(r)}}})],1)}),0):_vm._e()],2)])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }