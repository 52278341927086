// @ts-ignore
const THREE = window.THREE

export default new THREE.MeshBasicMaterial({
  opacity: 0.025,
  transparent: true,
  side: THREE.DoubleSide,
  reflectivity: 1,
  overdraw: 0.025,
  shading: THREE.SmoothShading,
  wireframe: false,
  fog: true,
  depthWrite: false,
  wireframeLinewidth: 0,
  refractionRatio: 1
})
