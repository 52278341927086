
import { Component, Vue } from 'vue-property-decorator'
import Landing from '@/components/Landing/Landing.vue'
import Navbar from '@/components/shared/Navbar.vue'
import Footer from '@/components/shared/Footer.vue'
import IconSpinnerPro from '@/components/shared/IconSpinnerPro.vue'
import Logo from '@/components/shared/Logo.vue'

@Component({
  components: { Logo, IconSpinnerPro, Landing, Navbar, Footer }
})
export default class Home extends Vue {
  created() {
    const token = localStorage.getItem(
      process.env.VUE_APP_LOCAL_STORAGE_AUTH_KEY
    )

    setTimeout(() => {
      this.$router.replace({ name: token ? 'Dashboard' : 'Login' })
    }, 200)
  }
}
