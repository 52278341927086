
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class InviteTeam extends Vue {
  beforeMount() {
    const { teamId, hash } = this.$route.params
    this.checkTeamInvite(teamId, hash)
  }

  private async checkTeamInvite(teamId: string, hash: string) {
    try {
      const {
        data: { teamInvite }
      } = await this.$apollo.mutate({
        variables: { teamId, hash },
        mutation: require('@/graphql/mutations/team-invite.graphql')
      })

      if (teamInvite) {
        this.$toasted.success(this.$i18n.t('success.inviteTeam') as string)
      } else {
        this.$toasted.error(this.$i18n.t('errors.inviteTeam') as string)
      }
    } catch (error) {
      console.error('error', error)
    } finally {
      await this.$router.push({ name: 'Dashboard' })
    }
  }
}
