
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'
import Store from '@/store/modules/Store'
import IconCaretDown from '@/assets/icons/caret-down.svg'
import IconXlsx from '@/assets/icons/file-spreadsheet.svg'
import IconStar from '@/assets/icons/crown.svg'

@Component({
  components: { IconCaretDown, IconXlsx, IconStar }
})
export default class TableHeaders extends Vue {
  @Prop() wrapperWidth!: number

  private importAddColumn: boolean = false
  private tableOrigin: string = ''

  async mounted() {
    this.$root.$on('set-wrapper-width', () => {
      this.setWrapperWidth()
    })

    this.$root.$on('show-new-column', (status: boolean) => {
      this.importAddColumn = status
    })
  }

  private get activeRows(): any[][] {
    return Store.activeRows
  }

  private get isOwner(): boolean {
    return Store.isOwner
  }

  private get isDemoModel(): boolean {
    return Store.assetForViewer.id === process.env.VUE_APP_DEMO_ASSET_ID
  }

  private get activeHeaders(): any[] {
    return Store.activeHeaders
  }

  private get filteredHeaders(): any[] {
    if (this.activeHeaders) {
      return this.activeHeaders.filter(
        h => !Store.hiddenHeaders.includes(h.header)
      )
    } else {
      return []
    }
  }

  private get selectedTableName(): string {
    return Store.selectedTableName
  }

  private get selectedTable(): {
    title: string
    origin: string
    isEmpty: boolean
  } {
    return Store.selectedTable
  }

  private setWrapperWidth() {
    if (this.filteredHeaders.length) {
      const ref = this.$refs.headers as HTMLElement

      if (ref) {
        this.sendWrapperWidth(ref.scrollWidth)
      }
    }
  }

  private handleHeader(header: {
    header: string
    origin: string
    showOnlyOwner: boolean
  }) {
    this.selectedHeader(header)
    this.setHeaderRequested(header.header)
  }

  @Emit() showAddField() {}

  @Emit() sendWrapperWidth(width: number): number {
    return width
  }

  @Emit() setHeaderRequested(header: string): string {
    return header
  }

  @Emit() selectedHeader(header: {
    header: string
    origin: string
    showOnlyOwner: boolean
  }): { header: string; origin: string; showOnlyOwner: boolean } {
    return header
  }
  @Watch('selectedTable', { deep: true })
  onSelectedTableChange() {
    this.tableOrigin = this.selectedTable.origin
  }
}
