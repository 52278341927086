export default (color: string) => {
  switch (color) {
    case 'red':
      // @ts-ignore
      return new THREE.Color('firebrick')
    case 'blue':
      // @ts-ignore
      return new THREE.Color('navy')
    case 'green':
      // @ts-ignore
      return new THREE.Color('seagreen')
    case 'yellow':
      // @ts-ignore
      return new THREE.Color('gold')
    case 'pink':
      // @ts-ignore
      return new THREE.Color('pink')
    case 'purple':
      // @ts-ignore
      return new THREE.Color('violet')
    case 'lime':
      // @ts-ignore
      return new THREE.Color('lime')
    case 'gray':
      // @ts-ignore
      return new THREE.Color('gray')
    case 'wireframe':
      // @ts-ignore
      return new THREE.Color('cornflowerblue')
  }
}
