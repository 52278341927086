import { AuthClientThreeLegged } from 'forge-apis'
import scopes from '../config/scopes'

export default new AuthClientThreeLegged(
  process.env.FORGE_CLIENT_ID,
  process.env.FORGE_SECRET,
  process.env.CALLBACK_URL,
  scopes,
  true
)
