import autodeskClientThree from '../../helpers/autodeskClientThree'
import { ApiResponse, ItemsApi } from 'forge-apis'
import Store from '../../store/modules/Store'

export default async (folderId: string, projectId) => {
  try {
    const itemsApi = new ItemsApi()

    const {
      body: { data }
    }: ApiResponse = await itemsApi.getItemVersions(
      projectId,
      folderId,
      {},
      autodeskClientThree,
      Store.autodeskCredentials
    )

    return data.map(version => {
      return {
        type: version.type,
        id: version.id,
        name: version.attributes.displayName,
        creator: version.attributes.createUserName,
        lastModified: version.attributes.lastModifiedTime
      }
    })
  } catch (error) {
    throw new Error(error)
  }
}
