export default () => {
  const div = document.createElement('div')

  div.style.width = '100vw'
  div.style.height = '100vh'
  div.style.zIndex = '1000'
  div.style.position = 'absolute'
  div.style.top = '0'
  div.style.left = '0'

  const p = document.createElement('p')
  p.textContent = 'Loading site...'
  p.style.position = 'absolute'
  p.style.right = '1.5rem'
  p.style.bottom = '1.5rem'
  p.style.color = '#555'
  p.style.fontSize = '0.8rem'
  p.style.zIndex = '2000'

  div.appendChild(p)
  document.body.appendChild(div)

  return div
}
