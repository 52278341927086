
import { Vue, Component, Emit, Prop } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'
import Button from '@/components/shared/Button.vue'
import Input from '@/components/shared/Input.vue'
import IconSpinner from '@/assets/icons/spinner-solid.svg'
import IconCheck from '@/assets/icons/check.svg'

@Component({
  components: { Button, Input, IconSpinner, IconCheck },
  directives: { onClickaway }
})
export default class DeleteModelMetadataWarning extends Vue {
  @Prop() modelName!: string
  @Prop() statusTexts!: string[]

  private randomNum: string = ''
  private numsConfirmed: string = ''
  private clicked: boolean = false

  beforeMount() {
    for (let i = 0; i < 4; i++) {
      this.randomNum += Math.floor(Math.random() * 10)
    }
  }

  mounted() {
    const input = document.getElementById('delete')
    input.focus()

    this.$root.$on('close-delete-warning', this.close)
  }

  private preConfirmDelete() {
    if (this.numsConfirmed === this.randomNum) this.confirmDelete()
  }

  @Emit() confirmDelete() {
    this.clicked = true
  }

  @Emit() close() {}
}
