
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import IconSelected from '@/assets/icons/square-check.svg'
import IconUnselected from '@/assets/icons/square.svg'
import IconStar from '@/assets/icons/star.svg'

@Component({
  components: { IconSelected, IconUnselected, IconStar }
})
export default class MapFields extends Vue {
  @Prop() title!: string
  @Prop() fields!: any[]
  @Prop() selectedFields?: boolean[]
  @Prop() mapFieldIndex!: number
  @Prop({ default: true }) includeSelect!: boolean
  @Prop({ default: false }) hiddenCheckbox?: boolean
  @Prop({ default: false }) hideStar?: boolean
  @Prop({ default: false }) overwriteRead?: boolean
  @Prop({ default: false }) hiddenAllCheckbox?: boolean
  @Prop({ default: false }) isUpdateXlsx?: boolean

  @Emit()
  swapSelect(index: number): number {
    return index
  }

  @Emit()
  selectMainField(index: number): number {
    return index
  }

  @Emit()
  allSelectedFields(status: boolean): boolean {
    return status
  }
}
