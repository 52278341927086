
import { Vue, Component, Emit, Prop } from 'vue-property-decorator'
import Store from '@/store/modules/Store'
import { Hub } from '@/store/Models'
import Hubs from './Hubs.vue'
import ConfirmCreateHub from '@/components/Dashboard/Sidebar/ConfirmCreateHub.vue'
import SharedAssets from '@/components/Dashboard/Sidebar/SharedAssets.vue'

@Component({
  apollo: {
    hubs: {
      query: require('@/graphql/queries/hubs.graphql'),
      watchLoading(isLoading) {
        this.loading = isLoading
      }
    }
  },
  components: {
    SharedAssets,
    Hubs,
    ConfirmCreateHub
  }
})
export default class Sidebar extends Vue {
  @Prop({ default: false }) showHomeButton!: boolean

  private hubs = []
  private fromWeb: boolean = false
  private loading: boolean = false

  private get getHubs(): any[] {
    return this.hubs
  }

  created() {
    this.refreshHubs()
  }

  async beforeMount() {
    this.fromWeb =
      window.parent.origin === 'http://localhost:8080' ||
      window.parent.origin === 'https://bimtable.app' ||
      window.parent.origin === 'https://bimtable-beta-env.web.app'
  }

  mounted() {
    this.$root.$on('refresh-hubs', () => {
      setTimeout(this.refreshHubs, 500)
    })

    this.$root.$on('refresh-hubs-last', () => {
      setTimeout(() => this.refreshHubs(true), 500)
    })
  }

  private async refreshHubs(last = false) {
    const {
      data: { hubs }
    } = await this.$apollo.queries.hubs.refetch()

    if (hubs) {
      this.hubs = hubs

      if (!Store.hub) {
        Store.setHub(this.hubs[last ? this.hubs.length - 1 : 0])
      }
    }
  }

  @Emit()
  changePlan(hub: Hub): Hub {
    return hub
  }
}
