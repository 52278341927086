
import { Vue, Component } from 'vue-property-decorator'
import Store from '@/store/modules/Store'
import { directive as onClickaway } from 'vue-clickaway'

@Component({
  directives: { onClickaway }
})
export default class DropdownAssets extends Vue {
  private options: string[] = Store.assets.map(asset => asset.title)
  private selected: string = Store.assetForViewer.title
  private description: string = Store.assetForViewer.description
  private clicked: boolean = false
  private prefix: string = process.env.VUE_APP_CLOUDINARY_IMG_PREFIX
  private urlNoImage: string = process.env.VUE_APP_NO_IMAGE_URL

  private get image(): string {
    return Store.assetForViewer.image
  }

  private selectAsset(option: string) {
    const asset = Store.assets.find(a => a.title === option)
    Store.setAssetForViewer(asset)
    this.selected = option
    this.clicked = false
    this.$root.$emit('refresh-models')
  }

  private hideDropdown() {
    if (this.clicked) this.clicked = false
  }
}
