
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import Store from '@/store/modules/Store'
import { directive as onClickaway } from 'vue-clickaway'
import Avatar from '@/components/Dashboard/Sidebar/Avatar.vue'
import Button from '@/components/shared/Button.vue'

@Component({
  components: { Button, Avatar },
  directives: { onClickaway }
})
export default class SharedPending extends Vue {
  @Prop() assetId!: string

  private asset = null
  private prefix: string = process.env.VUE_APP_CLOUDINARY_IMG_PREFIX

  private get userEmail(): string {
    return Store.user.email
  }

  private get filteredTeamMembers() {
    return this.asset.teamMembers.filter(
      member => member.email !== this.userEmail
    )
  }

  async created() {
    const assetId = this.assetId

    const {
      data: { assetById }
    } = await this.$apollo.query({
      variables: { assetId },
      query: require('@/graphql/queries/asset-by-id.graphql')
    })

    this.asset = assetById
  }

  @Emit() close() {
    Store.setInvitation(null)
  }

  @Emit() acceptParticipate() {}
}
