
import { Vue, Component, Watch, Emit, Prop } from 'vue-property-decorator'
import IconList from '@/assets/icons/list.svg'
import IconDropdown from '@/assets/icons/list-dropdown.svg'

@Component({
  components: { IconList, IconDropdown }
})
export default class ViewMenu extends Vue {
  @Prop({ default: false }) changeModeView!: boolean

  private sendChangeViewMode(mode: boolean) {
    this.changeMode(mode)
  }

  @Emit() changeMode(mode: boolean): boolean {
    return mode
  }
}
