
import { Vue, Component, Emit } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'
import Input from '@/components/shared/Input.vue'
import Button from '@/components/shared/Button.vue'
import isEmail from '@/helpers/isEmail'

@Component({
  components: { Button, Input },
  directives: { onClickaway }
})
export default class RecoverPasswordModal extends Vue {
  private email: string = ''
  private emailSent: boolean = false
  private confirmationCode: string = ''
  private confirmationCodeSent: boolean = false
  private password: string = ''

  private get isEmail(): boolean {
    return isEmail(this.email)
  }

  private async sendEmail() {
    const email = this.email

    try {
      const {
        data: { resetPassword }
      } = await this.$apollo.mutate({
        variables: { email },
        mutation: require('@/graphql/mutations/reset-password.graphql')
      })

      if (resetPassword) {
        this.$toasted.success(this.$i18n.t('success.checkEmailTray') as string)
        this.emailSent = true
      } else {
        this.$toasted.error(this.$i18n.t('errors.resetPassword') as string)
      }
    } catch {
      this.$toasted.error(this.$i18n.t('errors.resetPassword') as string)
    }
  }

  private async sendConfirmationCode() {
    const email = this.email
    const confirmationCode = this.confirmationCode

    try {
      const {
        data: { sendConfirmationCode }
      } = await this.$apollo.mutate({
        variables: { email, confirmationCode },
        mutation: require('@/graphql/mutations/send-confirmation-code.graphql')
      })

      if (sendConfirmationCode) {
        this.$toasted.success(
          this.$i18n.t('success.confirmationCode') as string
        )
        this.confirmationCodeSent = true
      } else {
        this.$toasted.error(this.$i18n.t('errors.confirmationCode') as string)
      }
    } catch {
      this.$toasted.error(this.$i18n.t('errors.confirmationCode') as string)
    }
  }

  private async updatePassword() {
    const email = this.email
    const password = this.password

    try {
      const {
        data: { updatePassword }
      } = await this.$apollo.mutate({
        variables: { email, password },
        mutation: require('@/graphql/mutations/update-password.graphql')
      })

      if (updatePassword) {
        this.$toasted.success(this.$i18n.t('success.resetPassword') as string)
        this.close()
      } else {
        this.$toasted.error(this.$i18n.t('errors.resetPassword') as string)
      }
    } catch {
      this.$toasted.error(this.$i18n.t('errors.resetPassword') as string)
    }
  }

  @Emit() close() {}
}
