
import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator'
import IconTable from '@/assets/icons/compare.svg'
import IconTrash from '@/assets/icons/trash.svg'
import { directive as onClickaway } from 'vue-clickaway'
import Store from '@/store/modules/Store'

@Component({
  directives: { onClickaway }
})
export default class ModelActions extends Vue {
  @Prop() isMain!: boolean
  @Prop() isDemo!: boolean
  @Prop() hasNoMetadata!: boolean

  private actions = []

  private get isOwner(): boolean {
    return Store.isOwner
  }

  mounted() {
    this.actions = [
      {
        icon: IconTable,
        title: this.setActionTitle()
      },
      {
        icon: IconTrash,
        title: this.$i18n.t('deleteModel') as string
      }
    ]
  }

  private setActionTitle() {
    return this.isMain
      ? (this.$i18n.t('hideDataPanel') as string)
      : (this.$i18n.t('showDataPanel') as string)
  }

  private actionCss(index: number) {
    return {
      'hover:text-green-500': index === 0,
      'hover:text-purple-500': index === 1,
      'hover:text-red-500': index === 2,
      'disabled':
        (index === 1 && !this.isOwner) ||
        (index === 1 && this.isDemo) ||
        (index === 0 && this.hasNoMetadata)
    }
  }

  @Watch('isMain')
  onIsMainChange() {
    this.actions[0].title = this.setActionTitle()
  }

  @Emit() selectAction(action) {
    return action.title
  }

  @Emit() close() {}
}
