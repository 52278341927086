
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { Asset } from '@/store/Models'
import Input from '@/components/shared/Input.vue'
import Button from '@/components/shared/Button.vue'
import { directive as onClickaway } from 'vue-clickaway'

@Component({
  components: { Button, Input },
  directives: { onClickaway }
})
export default class ConfirmDelete extends Vue {
  @Prop() asset!: Asset

  private prefix: string = process.env.VUE_APP_CLOUDINARY_IMG_PREFIX
  private titleConfirmed: string = ''

  mounted() {
    const input = document.getElementById('titleConfirmed')
    input.focus()
  }

  private preConfirmDelete() {
    if (this.titleConfirmed === this.asset.title) this.confirmDelete()
  }

  @Emit() confirmDelete() {}
  @Emit() close() {}
}
