import { twoLeggedViewerInitializer } from '@/helpers/twoLeggedViewerInitializer'

// @ts-ignore
export default async (container): Promise<Autodesk.Viewing.GuiViewer3D> => {
  return await new Promise((resolve, reject) => {
    // @ts-ignore
    Autodesk.Viewing.Initializer(twoLeggedViewerInitializer, () => {
      // @ts-ignore
      const viewer = new Autodesk.Viewing.GuiViewer3D(container, {})

      if (viewer.start() > 0) {
        reject()
      }

      viewer.setUp(twoLeggedViewerInitializer)

      resolve(viewerInitialized(viewer))
    })
  })
}

const viewerInitialized = (
  // @ts-ignore
  viewer: Autodesk.Viewing.GuiViewer3D
  // @ts-ignore
): Autodesk.Viewing.GuiViewer3D => {
  // @ts-ignore
  const profile = new Autodesk.Viewing.Profile(viewerOptions)
  // @ts-ignore
  viewer.setProfile(profile)
  viewer.setTheme('light-theme')
  // @ts-ignore
  viewer.setSelectionMode(Autodesk.Viewing.SelectionMode.LEAF_OBJECT)
  viewer.setQualityLevel(true, true)

  return viewer
}

const viewerOptions = {
  name: 'story-view-mode-settings',
  settings: {
    ambientShadows: true,
    groundShadow: true,
    optimizeNavigation: true,
    antiAliasing: true,
    displayLines: true,
    displayPoints: true,
    displayEdges: true,
    lineRendering: true,
    edgeRendering: true,
    ghostHidden: false,
    ghosting: false,
    progressiveDisplay: true,
    smoothNavigation: true,
    swapBlackAndWhite: false,
    firstPersonToolPopup: false,
    reverseMouseZoomDir: true,
    bimWalkToolPopup: false,
    lightPreset: 18,
    forceDoubleSided: true,
    viewType: 1,
    displayUnits: 'm',
    memory: {
      limit: 4096
    }
  }
}
