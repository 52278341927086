import { apolloProvider } from '@/vue-apollo'
import Store from '@/store/modules/Store'

export default async (
  type: string,
  tableName: string,
  modelName: string,
  title: string,
  payload: any
) => {
  try {
    const assetId = Store.assetForViewer.id

    await apolloProvider().defaultClient.mutate({
      variables: {
        type,
        tableName,
        modelName,
        assetId,
        title,
        payload: JSON.stringify(payload)
      },
      mutation: require('@/graphql/mutations/set-table-view-config.graphql')
    })
  } catch (error) {
    console.error('error', error)
  }
}
