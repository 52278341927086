var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sidebar-navbar-notifications-wrapper",class:_vm.confirmDelete ? '-z-1' : 'z-0'},[_c('p',{staticClass:"sidebar-navbar-notifications-type",class:_vm.notification.type === 'sync' || _vm.notification.type === 'sync-in-progress'
        ? 'bg-indigo-400'
        : 'bg-green-500',domProps:{"textContent":_vm._s(
      _vm.notification.type === 'sync'
        ? _vm.$t('sync')
        : _vm.notification.type === 'update-xlsx'
        ? _vm.$t('update')
        : _vm.notification.type === 'sync-in-progress'
        ? _vm.$t('isSyncing')
        : _vm.$t('storedModel')
    )}}),_c('p',{domProps:{"textContent":_vm._s(_vm.notification.concept)}}),_c('p',{staticClass:"text-xs text-gray-400",domProps:{"textContent":_vm._s(_vm.notification.created_at)}}),_c('div',{staticClass:"absolute top-1 right-2",class:{ 'disabled': _vm.activeTab === 'read' },attrs:{"title":_vm.$t('markAsSeen')}},[_c('IconConfirm',{staticClass:"sidebar-navbar-notifications-seen",on:{"click":function($event){$event.stopPropagation();return _vm.notificationSeen.apply(null, arguments)}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }