
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'
import Button from '@/components/shared/Button.vue'
import IconCheckbox from '@/assets/icons/square-check.svg'
import { directive as onClickaway } from 'vue-clickaway'
import IconSpinner from '@/assets/icons/spinner-solid.svg'
import IconChevron from '@/assets/icons/chevron-down.svg'
import IconAsterisk from '@/assets/icons/asterisk.svg'

@Component({
  components: { Button, IconCheckbox, IconSpinner, IconChevron, IconAsterisk },
  directives: { onClickaway }
})
export default class CategoriesSelection extends Vue {
  @Prop() upload!: any

  @Prop() metadata!: {
    categories: string[]
    properties: string[][]
    geometriesAmount: number[]
    headersUnits: { header: string; unit?: string }[][]
  }

  private activeIndex: number = 0
  private menu = this.setMenu()
  private selectAllProps: boolean = false
  private clicked: boolean = false
  private discardData: boolean = false

  private showUnits: { prop: any; index: string | number; main: any } = {
    index: null,
    main: null,
    prop: null
  }

  mounted() {
    this.$root.$on('reset-categories-submit', () => {
      this.clicked = false
    })
  }

  private setMenu() {
    return {
      categories: this.metadata.categories.map(title => {
        return {
          title
        }
      }),
      ...this.setProperties()
    }
  }

  private setProperties() {
    const properties = []

    for (const group of this.metadata.properties) {
      const propsSet: Set<string> = new Set()

      for (const property of group) {
        propsSet.add(property.split(' · ')[1])
      }

      const propsGroup = []

      for (const propMain of Array.from(propsSet).sort()) {
        const innerGroup = {
          propMain,
          show: false,
          subProperties: []
        }

        for (const property of group) {
          const props = property.split(' · ')

          if (props[1] === propMain) {
            innerGroup.subProperties.push({
              title: props[0],
              selected: true
            })
          }
        }

        propsGroup.push(innerGroup)
      }

      properties.push(propsGroup)
    }

    return { properties }
  }

  private handleSelectAllProperties() {
    this.selectAllProps = !this.selectAllProps

    this.menu = {
      ...this.menu,
      properties: this.menu.properties.map((properties, index) => {
        return index !== this.activeIndex
          ? properties
          : properties.map(p => ({
              ...p,
              subProperties: p.subProperties.map(pr => {
                return {
                  ...pr,
                  selected: !this.selectAllProps
                }
              })
            }))
      })
    }
  }

  private propertiesClasses(property, index: number): any {
    return {
      'text-gray-300 hover:bg-transparent': !property.selected,
      'text-gray-700 hover:bg-gray-200': property.selected,
      'hidden': index === 0
    }
  }

  private switchSelectAllSubProperties(propGroup) {
    propGroup.subProperties = propGroup.subProperties.map(p => {
      return {
        ...p,
        selected: !propGroup.subProperties.every(p => p.selected)
      }
    })
  }

  private numeric(family: string, param: string): boolean {
    if (this.metadata.headersUnits !== null) {
      const header = `${param} · ${family}`

      const headers = this.metadata.headersUnits.map(head => {
        return head.map(h => h.header)
      })

      return headers.find(heads => heads.includes(header)) !== undefined
    } else {
      return false
    }
  }

  private getUnit(family: string, param: string): string {
    const header = `${param} · ${family}`

    if (this.metadata.headersUnits !== null) {
      const headers = this.metadata.headersUnits.map(head => {
        return head.map(h => h)
      })

      let unit = ''

      headers.find(heads => {
        if (heads.map(h => h.header).includes(header)) {
          return heads.find(h => {
            if (h.header === header) {
              unit = h.unit
            }
          })
        }
      })

      return unit
    } else {
      return ''
    }
  }

  private checkSend() {
    this.discardData ? this.close() : this.sendCategoriesProperties()
  }

  @Watch('activeIndex')
  onActiveIndexChange() {
    this.selectAllProps = false
  }

  @Emit() sendCategoriesProperties(): { categories; properties } {
    return this.menu
  }

  @Emit() close() {}
}
