var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sidebar-tab-wrapper"},[_c('DropdownAssets'),(_vm.showModelsGroups)?_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"relative"},[_c('Input',{attrs:{"input-type":"text","placeholder":_vm.$t('modelsGroupPlaceholder'),"row":true,"value":_vm.modelsGroupName},on:{"on-input":function($event){_vm.modelsGroupName = $event}}}),_c('IconCheck',{staticClass:"absolute right-1.5 top-1 border border-green-500 rounded-full w-5 text-green-600 cursor-pointer p-0.5 hover:text-green-700 hover:bg-green-100",class:{ 'disabled': _vm.disableSubmit },on:{"click":_vm.createModelsGroup}})],1),_c('div',{staticClass:"max-h-12 text-xs overflow-y-auto"},[_c('p',{staticClass:"m-2",domProps:{"textContent":_vm._s(`${_vm.$t('selected')}: ${_vm.selectedModels.length}`)}})])]):_vm._e(),_c('div',{staticClass:"flex justify-between items-center text-gray-500 text-sm mt-4 p-2 border-b"},[_c('p',{domProps:{"textContent":_vm._s(_vm.$t('models'))}}),_c('IconFolder',{staticClass:"w-4 hover:text-blue-400 cursor-pointer",on:{"click":function($event){_vm.showModelsGroups = !_vm.showModelsGroups}}})],1),(!_vm.models.length && !_vm.isLoadingModels && _vm.fromWeb)?_c('p',{staticClass:"sidebar-link",domProps:{"textContent":_vm._s(_vm.$t('noModels'))},on:{"click":_vm.showUploadModal}}):_vm._e(),_c('div',{staticClass:"sidebar-tab-container",style:(`height: ${
      _vm.showModelsGroups ? _vm.scrollHeight - _vm.scrollGroupsOffset : _vm.scrollHeight
    }px; max-height: ${
      _vm.showModelsGroups ? _vm.scrollHeight - _vm.scrollGroupsOffset : _vm.scrollHeight
    }px`)},[_vm._l((_vm.models.filter(
        m => !_vm.modelsAtGroups.includes(m.objectKey)
      )),function(model,index){return _c('ModelItem',{key:index,attrs:{"model":model,"from-web":_vm.fromWeb,"show-selectable-checkbox":_vm.showModelsGroups,"selected-models-group":_vm.selectedModels},on:{"select-model":_vm.selectModel,"reset-picker":_vm.resetPicker,"set-main-model":_vm.setMainModel,"hide-paneldata":function($event){return _vm.hidePaneldata(index)},"swap-lock-model":function($event){return _vm.swapLockModel(model)},"swap-active":function($event){return _vm.swapActive(model)},"change-selection-group":_vm.changeSelectedModels,"delete-model":function($event){_vm.modelToDelete = $event
        _vm.deleteObjectKey = $event.objectKey}}})}),_vm._l((_vm.assetModelsGroups),function(group,key){return _c('div',{key:`${key}_`,staticClass:"pt-2"},[_c('div',{staticClass:"flex justify-between items-center cursor-pointer text-sm pl-2 hover:text-blue-400 hover:underline",on:{"click":function($event){return _vm.handleShowGroup(group, key)}}},[_c('div',{staticClass:"flex"},[_c('IconFolder',{staticClass:"w-4 mr-2"}),_c('p',{domProps:{"textContent":_vm._s(group.groupName)}})],1),_c('div',{staticClass:"flex space-x-2 mr-4"},[(group.active)?_c('IconEye',{staticClass:"h-4 hover:text-red-500",on:{"click":function($event){$event.stopPropagation();return _vm.unactiveGroup(group, key)}}}):_c('IconEyeSlash',{staticClass:"sidebar-model-item-eye",on:{"click":function($event){$event.stopPropagation();return _vm.activeGroup(group, key)}}}),(!group.locked)?_c('IconUnlock',{staticClass:"h-4 hover:text-green-500",on:{"click":function($event){$event.stopPropagation();return _vm.lockGroup(group, key)}}}):_c('IconLock',{staticClass:"h-4 hover:text-red-500",on:{"click":function($event){$event.stopPropagation();return _vm.unlockGroup(group, key)}}})],1)]),(group.show)?_c('div',{staticClass:"space-y-3 mt-2 p-2 border"},_vm._l((_vm.getGroupModels(group)),function(model,index){return _c('ModelItem',{key:`${_vm.uuid}_${index}`,attrs:{"model":model,"from-web":_vm.fromWeb,"show-selectable-checkbox":false},on:{"select-model":_vm.selectModel,"reset-picker":_vm.resetPicker,"set-main-model":_vm.setMainModel,"hide-paneldata":function($event){return _vm.hidePaneldata(index)},"swap-lock-model":function($event){return _vm.swapLockModel(model)},"swap-active":function($event){return _vm.swapActive(model)},"change-selection-group":_vm.changeSelectedModels,"delete-model":function($event){_vm.modelToDelete = $event
            _vm.deleteObjectKey = $event.objectKey}}})}),1):_vm._e()])})],2),(_vm.fromWeb)?_c('div',{staticClass:"sidebar-upload-wrapper",class:{ 'disabled': _vm.isUploading },attrs:{"title":_vm.allowUpload ? '' : _vm.$t('allowUpload')}},[_c('p',{staticClass:"button-fx",class:{ 'disabled': !_vm.allowUpload },on:{"click":_vm.showUploadModal}},[_c('span'),_c('span'),_c('span'),_c('span'),_c('IconUpload',{staticClass:"h-5 mr-2"}),_vm._v(" "+_vm._s(_vm.$t('modelUpload'))+" "),(_vm.isUploading)?_c('IconSpinner',{staticClass:"absolute mt-1 loader-spin-sm right-6"}):_vm._e()],1)]):_vm._e(),_c('Transition',{attrs:{"name":"fade"}},[(_vm.modelToDelete)?_c('DeleteModelMetadataWarning',{attrs:{"model-name":_vm.deleteObjectKey,"status-texts":_vm.statusTexts},on:{"confirm-delete":_vm.deleteModel,"close":function($event){_vm.modelToDelete = null
        _vm.deleteObjectKey = ''}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }