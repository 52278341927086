var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"z-0 bg-opacity-50 text-xs sidebar-tab-item",class:`${_vm.model.active ? 'border-gray-400' : 'border-gray-100'} ${
    _vm.model.showColorPicker || _vm.showActions ? 'z-5000' : 'z-0'
  }`,style:(`background-color: ${
    _vm.colors.find(c => c.title === _vm.model.color).bg
  }; background-opacity: 0.5`),on:{"click":_vm.checkSwapTable,"mouseover":function($event){_vm.isHovering = true},"mouseleave":function($event){_vm.isHovering = false}}},[_c('div',{staticClass:"flex"},[(
        _vm.showSelectableCheckbox &&
        !_vm.selectedModelsGroup.includes(_vm.model.objectKey)
      )?_c('IconCheckboxOff',{staticClass:"w-4 mr-2 hover:text-blue-400",on:{"click":function($event){$event.stopPropagation();return _vm.changeSelectionGroup(_vm.model.objectKey, true)}}}):_vm._e(),(
        _vm.showSelectableCheckbox &&
        _vm.selectedModelsGroup.includes(_vm.model.objectKey)
      )?_c('IconCheckboxOn',{staticClass:"w-4 mr-2 hover:text-blue-400",on:{"click":function($event){$event.stopPropagation();return _vm.changeSelectionGroup(_vm.model.objectKey, false)}}}):_vm._e(),(!_vm.showEyeSlash && _vm.model.active)?_c('div',{staticClass:"flex",attrs:{"title":_vm.$t('titles.hideModel')},on:{"click":function($event){$event.stopPropagation();return _vm.swapActive.apply(null, arguments)}}},[_c('IconEye',{staticClass:"h-4 hover:text-red-500"})],1):_vm._e(),(_vm.showEyeSlash || !_vm.model.active)?_c('div',{staticClass:"flex",attrs:{"title":_vm.$t('titles.showModel')},on:{"click":function($event){$event.stopPropagation();return _vm.swapActive.apply(null, arguments)}}},[_c('IconEyeSlash',{staticClass:"sidebar-model-item-eye"})],1):_vm._e()],1),_c('div',{staticClass:"flex items-center w-full ml-2",attrs:{"title":_vm.model.objectKey}},[(_vm.model.isMain)?_c('IconTick',{staticClass:"h-3 mr-1 hover:text-blue-500"}):_vm._e(),_c('p',{staticClass:"sidebar-model-item-name",class:{
        'text-gray-300': !_vm.model.active,
        'font-bold': _vm.model.isMain
      },domProps:{"textContent":_vm._s(_vm.model.objectKey)}})],1),_c('div',{staticClass:"flex items-center justify-between space-x-1.5 pr-1"},[_c('div',{staticClass:"z-0 flex items-center"},[(!_vm.model.lock)?_c('div',{staticClass:"flex",attrs:{"title":_vm.$t('titles.lockModel')},on:{"click":function($event){$event.stopPropagation();return _vm.lockModel.apply(null, arguments)}}},[_c('IconUnlock',{staticClass:"h-4 hover:text-green-500"})],1):_c('div',{staticClass:"flex",attrs:{"title":_vm.$t('titles.unlockModel')},on:{"click":function($event){$event.stopPropagation();return _vm.lockModel.apply(null, arguments)}}},[_c('IconLock',{staticClass:"h-4 hover:text-red-500"})],1)]),_c('div',{staticClass:"sidebar-colors-picker",class:{
        'disabled': !_vm.model.active,
        'border-2 border-gray-600': _vm.model.showColorPicker,
        'border border-gray-400': !_vm.model.showColorPicker
      },style:(`background-color: ${
        _vm.colors.find(c => c.title === _vm.model.color).value
      }`),attrs:{"title":_vm.$t('titles.colorPicker')},on:{"click":function($event){$event.stopPropagation();_vm.model.showColorPicker = !_vm.model.showColorPicker}}}),_c('Transition',{attrs:{"name":"fade"}},[(_vm.model.showColorPicker)?_c('ColorPicker',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.resetPicker),expression:"resetPicker"}],staticClass:"z-5000",on:{"pick":_vm.handleModelColor}}):_vm._e()],1),_c('Transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"flex",attrs:{"title":_vm.$t('titles.deleteModel')}},[_c('IconTrash',{staticClass:"h-4 duration-default hover:text-red-500",class:{ 'disabled': !_vm.isHovering },on:{"click":function($event){$event.stopPropagation();return _vm.deleteModel.apply(null, arguments)}}})],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }