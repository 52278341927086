
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import defaultAvatarPic from '@/config/defaultAvatarPic'
import Store from '@/store/modules/Store'

@Component
export default class Avatar extends Vue {
  @Prop({ default: 'sm' }) size!: 'xs' | 'sm' | 'md' | 'lg'
  @Prop() providedImage!: string
  @Prop({ default: false }) fromCollaborator!: boolean

  private prefix: string = process.env.VUE_APP_CLOUDINARY_IMG_PREFIX

  private imageUrl: string = ''

  mounted() {
    this.$root.$on('refresh-avatar', () => {
      this.imageUrl = `${this.prefix}${Store.user.avatar}`
    })

    this.imageUrl = this.fromCollaborator
      ? `${this.prefix}${this.providedImage}`
      : this.getAvatar
  }

  private get getAvatar(): string {
    return Store.user.avatar
      ? `${this.prefix}${Store.user.avatar}`
      : defaultAvatarPic
  }

  private get getSize(): string {
    return this.size === 'sm'
      ? 'w-10 h-10'
      : this.size === 'lg'
      ? 'w-32 h-32'
      : this.size === 'md'
      ? 'w-16 h-16'
      : 'w-8 h-8'
  }

  @Watch('providedImage')
  onProvidedImageChange() {
    this.imageUrl = this.providedImage
    if (this.imageUrl === '') this.imageUrl = defaultAvatarPic
  }
}
