
import { Vue, Component, Emit } from 'vue-property-decorator'
import isEmail from '@/helpers/isEmail'
import Store from '@/store/modules/Store'

@Component
export default class ContactForm extends Vue {
  private contact = {
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    phone: ''
  }

  private loading: boolean = false

  mounted() {
    this.contact.email = Store.user.email
  }

  private async sendContact() {
    try {
      this.loading = true
      const contact = this.contact

      const {
        data: { createContact }
      } = await this.$apollo.mutate({
        variables: { contact },
        mutation: require('@/graphql/mutations/create-contact.graphql')
      })

      if (createContact) {
        this.$toasted.success(this.$i18n.t('success.contact') as string)
        this.closeForm()
      }
    } catch (error) {
      this.$toasted.success(this.$i18n.t('errors.contact') as string)
    } finally {
      this.loading = false
    }
  }

  private get isEmail(): boolean {
    return isEmail(this.contact.email)
  }

  private get forbiddenDomainEmail(): boolean {
    const check = term => this.contact.email.includes(term)

    return check('@gmail.com') || check('@hotmail') || check('@msn')
  }

  private get dataFault(): boolean {
    return (
      this.forbiddenDomainEmail ||
      this.contact.phone.length !== 9 ||
      this.contact.company === ''
    )
  }

  @Emit() closeForm() {}
}
