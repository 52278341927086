import Home from '../../views/Home.vue'
import Dashboard from '../../views/Dashboard.vue'
import beforeEnter from '../../router/beforeEnter'
import Viewer from '../../views/Viewer.vue'
import DatasetDetail from '../../components/Dashboard/Datasets/DatasetDetail.vue'
import AutodeskCodeVerification from '../../views/AutodeskCodeVerification.vue'
import ViewerInstance from '@/views/ViewerInstance.vue'

export default [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter
  },
  {
    path: '/viewer',
    name: 'Viewer',
    component: Viewer,
    beforeEnter
  },
  {
    path: '/datasets/:id',
    name: 'DatasetDetail',
    component: DatasetDetail,
    beforeEnter
  },
  {
    path: '/autodesk-auth',
    name: 'AutodeskAuth',
    component: AutodeskCodeVerification
  },
  {
    path: '/i/:apiKey/:hash',
    name: 'ViewerInstance',
    component: ViewerInstance
  }
]
