export default `Privacy Policy
Personal Data processed for the following purposes and using the following services:

Contacting the User
Contact form and Mailing List or Newsletter
Personal Data: email address; first name
Handling payments
PayPal and Stripe
Personal Data: various types of Data as specified in the privacy policy of the service
Managing contacts and sending messages
Mailrelay
Personal Data: email address
Registration and authentication
Direct registration
Personal Data: company name; country; email address; first name; password; VAT Number
Traffic optimization and distribution
Cloudflare
Personal Data: Cookies; various types of Data as specified in the privacy policy of the service
Information on opting out of interest-based advertising
In addition to any opt-out feature provided by any of the services listed in this document, Users may learn more on how to generally opt out of interest-based advertising within the dedicated section of the Cookie Policy.
Further information about the processing of Personal Data
Uploaded files to iloveifc.com
Absolutely all uploaded files on iloveifc.com are deleted from our servers one, two or twenty-four hours (depending on if a user is non registered, registered or premium) after been processed.
Contact information
Owner and Data Controller
SPA Planeamento SL
B36477527
Serra 9, 1º der
36001 Pontevedra
Owner contact email: info@iloveifc.com
Full policy
Owner and Data Controller
SPA Planeamento SL
B36477527
Serra 9, 1º der
36001 Pontevedra
Owner contact email: info@iloveifc.com
Types of Data collected
Among the types of Personal Data that this Application collects, by itself or through third parties, there are: Cookies; Usage Data; first name; email address; password; company name; VAT Number; country.
Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data collection.
Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when using this Application.
Unless specified otherwise, all Data requested by this Application is mandatory and failure to provide this Data may make it impossible for this Application to provide its services. In cases where this Application specifically states that some Data is not mandatory, Users are free not to communicate this Data without consequences to the availability or the functioning of the Service.
Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner.
Any use of Cookies – or of other tracking tools – by this Application or by the owners of third-party services used by this Application serves the purpose of providing the Service required by the User, in addition to any other purposes described in the present document and in the Cookie Policy, if available.
Users are responsible for any third-party Personal Data obtained, published or shared through this Application and confirm that they have the third party's consent to provide the Data to the Owner.
Mode and place of processing the Data
Methods of processing
The Owner takes appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data.
The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Owner, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of this Application (administration, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Owner at any time.
Legal basis of processing
The Owner may process Personal Data relating to Users if one of the following applies:
Users have given their consent for one or more specific purposes. Note: Under some legislations the Owner may be allowed to process Personal Data until the User objects to such processing (“opt-out”), without having to rely on consent or any other of the following legal bases. This, however, does not apply, whenever the processing of Personal Data is subject to European data protection law;
provision of Data is necessary for the performance of an agreement with the User and/or for any pre-contractual obligations thereof;
processing is necessary for compliance with a legal obligation to which the Owner is subject;
processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Owner;
processing is necessary for the purposes of the legitimate interests pursued by the Owner or by a third party.
In any case, the Owner will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.
Place
The Data is processed at the Owner's operating offices and in any other places where the parties involved in the processing are located.
Depending on the User's location, data transfers may involve transferring the User's Data to a country other than their own. To find out more about the place of processing of such transferred Data, Users can check the section containing details about the processing of Personal Data.
Users are also entitled to learn about the legal basis of Data transfers to a country outside the European Union or to any international organization governed by public international law or set up by two or more countries, such as the UN, and about the security measures taken by the Owner to safeguard their Data.
If any such transfer takes place, Users can find out more by checking the relevant sections of this document or inquire with the Owner using the information provided in the contact section.
Retention time
Personal Data shall be processed and stored for as long as required by the purpose they have been collected for.
Therefore:
Personal Data collected for purposes related to the performance of a contract between the Owner and the User shall be retained until such contract has been fully performed.
Personal Data collected for the purposes of the Owner’s legitimate interests shall be retained as long as needed to fulfill such purposes. Users may find specific information regarding the legitimate interests pursued by the Owner within the relevant sections of this document or by contacting the Owner.
The Owner may be allowed to retain Personal Data for a longer period whenever the User has given consent to such processing, as long as such consent is not withdrawn. Furthermore, the Owner may be obliged to retain Personal Data for a longer period whenever required to do so for the performance of a legal obligation or upon order of an authority.
Once the retention period expires, Personal Data shall be deleted. Therefore, the right of access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after expiration of the retention period.
The purposes of processing
The Data concerning the User is collected to allow the Owner to provide its Service, comply with its legal obligations, respond to enforcement requests, protect its rights and interests (or those of its Users or third parties), detect any malicious or fraudulent activity, as well as the following: Analytics, Registration and authentication, Contacting the User, Managing contacts and sending messages, Handling payments, Traffic optimization and distribution, Advertising and Infrastructure monitoring.
For specific information about the Personal Data used for each purpose, the User may refer to the section “Detailed information on the processing of Personal Data”.
Detailed information on the processing of Personal Data
Personal Data is collected for the following purposes and using the following services:
Advertising
This type of service allows User Data to be utilized for advertising communication purposes. These communications are displayed in the form of banners and other advertisements on this Application, possibly based on User interests.
This does not mean that all Personal Data is used for this purpose. Information and conditions of use are shown below.
Some of the services listed below may use Trackers to identify Users or they may use the behavioral retargeting technique, i.e. displaying ads tailored to the User’s interests and behavior, including those detected outside this Application. For more information, please check the privacy policies of the relevant services.
Services of this kind usually offer the possibility to opt out of such tracking. In addition to any opt-out feature offered by any of the services below, Users may learn more on how to generally opt out of interest-based advertising within the dedicated section "How to opt-out of interest-based advertising" in this document.
Google AdSense (Google Inc.)
Google AdSense is an advertising service provided by Google LLC or by Google Ireland Limited, depending on the location this Application is accessed from. This service uses the “DoubleClick” Cookie, which tracks use of this Application and User behavior concerning ads, products and services offered.
Users may decide to disable all the DoubleClick Cookies by going to: Google Ad Settings. In order to understand Google's use of data, consult Google's partner policy.
Personal Data processed: Cookies; Usage Data.
Place of processing: US – Privacy Policy – Opt Out.
Analytics
The services contained in this section enable the Owner to monitor and analyze web traffic and can be used to keep track of User behavior.
Google Analytics (Google Inc.)
Google Analytics is a web analysis service provided by Google Inc. (“Google”). Google utilizes the Data collected to track and examine the use of this Application, to prepare reports on its activities and share them with other Google services.
Google may use the Data collected to contextualize and personalize the ads of its own advertising network.
Personal Data processed: Cookies; Usage Data.
Place of processing: US – Privacy Policy – Opt Out.
Google Analytics with anonymized IP (Google Ireland Limited)
Google Analytics is a web analysis service provided by Google Ireland Limited (“Google”). Google utilizes the Data collected to track and examine the use of this Application, to prepare reports on its activities and share them with other Google services.
Google may use the Data collected to contextualize and personalize the ads of its own advertising network.
This integration of Google Analytics anonymizes your IP address. It works by shortening Users' IP addresses within member states of the European Union or in other contracting states to the Agreement on the European Economic Area. Only in exceptional cases will the complete IP address be sent to a Google server and shortened within the US.
Personal Data processed: Tracker; Usage Data.
Place of processing: Ireland – Privacy Policy – Opt Out.
Contacting the User
Contact form (This Application)
By filling in the contact form with their Data, the User authorizes this Application to use these details to reply to requests for information, quotes or any other kind of request as indicated by the form’s header.
Personal Data processed: email address; first name.
Mailing List or Newsletter (This Application)
By registering on the mailing list or for the newsletter, the User’s email address will be added to the contact list of those who may receive email messages containing information of commercial or promotional nature concerning this Application. Your email address might also be added to this list as a result of signing up to this Application or after making a purchase.
Personal Data processed: email address; first name.
Handling payments
Payment processing services enable this Application to process payments by credit card, bank transfer or other means. To ensure greater security, this Application shares only the information necessary to execute the transaction with the financial intermediaries handling the transaction.
Some of these services may also enable the sending of timed messages to the User, such as emails containing invoices or notifications concerning the payment.
PayPal (PayPal Inc.)
PayPal is a payment service provided by PayPal Inc., which allows Users to make online payments using their PayPal credentials.
Personal Data processed: various types of Data as specified in the privacy policy of the service.
Stripe (Stripe Inc)
Stripe is a payment service provided by Stripe Inc.
Personal Data processed: various types of Data as specified in the privacy policy of the service.
Place of processing: US – Privacy Policy.
Infrastructure monitoring
This type of service allows this Application to monitor the use and behavior of its components so its performance, operation, maintenance and troubleshooting can be improved.
Which Personal Data are processed depends on the characteristics and mode of implementation of these services, whose function is to filter the activities of this Application.
Sentry (Functional Software, Inc. )
Sentry is a monitoring service provided by Functional Software, Inc. .
Personal Data processed: various types of Data as specified in the privacy policy of the service.
Place of processing: United States – Privacy Policy.
Managing contacts and sending messages
This type of services makes it possible to manage a database of email contacts, phone contacts or any other contact information to communicate with the User.
These services may also collect data concerning the date and time when the message was viewed by the User, as well as when the User interacted with it, such as by clicking on links included in the message.
Mailrelay (CPC Servicios Informáticos Aplicados a Nuevas Tecnologías S.L.)
Mailrelay is an email address management and message sending service provided by CPC Servicios Informáticos Aplicados a Nuevas Tecnologías S.L.
Personal Data processed: email address.
Place of processing: Europe – Privacy Policy.
Registration and authentication
By registering or authenticating, Users allow this Application to identify them and give them access to dedicated services.
Depending on what is described below, third parties may provide registration and authentication services. In this case, this Application will be able to access some Data, stored by these third party services, for registration or identification purposes.
Direct registration (This Application)
The User registers by filling out the registration form and providing the Personal Data directly to this Application.
Personal Data processed: company name; country; email address; first name; password; VAT Number.
Traffic optimization and distribution
This type of service allows this Application to distribute their content using servers located across different countries and to optimize their performance.
Which Personal Data are processed depends on the characteristics and the way these services are implemented. Their function is to filter communications between this Application and the User's browser.
Considering the widespread distribution of this system, it is difficult to determine the locations to which the contents that may contain Personal Information of the User are transferred.
Cloudflare (Cloudflare)
Cloudflare is a traffic optimization and distribution service provided by Cloudflare Inc.
The way Cloudflare is integrated means that it filters all the traffic through this Application, i.e., communication between this Application and the User's browser, while also allowing analytical data from this Application to be collected.
Personal Data processed: Cookies; various types of Data as specified in the privacy policy of the service.
Place of processing: US – Privacy Policy.
Information on opting out of interest-based advertising
In addition to any opt-out feature provided by any of the services listed in this document, Users may learn more on how to generally opt out of interest-based advertising within the dedicated section of the Cookie Policy.
Further information about the processing of Personal Data
Uploaded files to iloveifc.com
Absolutely all uploaded files on iloveifc.com are deleted from our servers one, two or twenty-four hours (depending on if a user is non-registered or registered) after been processed.
The rights of Users
Users may exercise certain rights regarding their Data processed by the Owner.
In particular, Users have the right to do the following:
Withdraw their consent at any time. Users have the right to withdraw consent where they have previously given their consent to the processing of their Personal Data.
Object to processing of their Data. Users have the right to object to the processing of their Data if the processing is carried out on a legal basis other than consent. Further details are provided in the dedicated section below.
Access their Data. Users have the right to learn if Data is being processed by the Owner, obtain disclosure regarding certain aspects of the processing and obtain a copy of the Data undergoing processing.
Verify and seek rectification. Users have the right to verify the accuracy of their Data and ask for it to be updated or corrected.
Restrict the processing of their Data. Users have the right, under certain circumstances, to restrict the processing of their Data. In this case, the Owner will not process their Data for any purpose other than storing it.
Have their Personal Data deleted or otherwise removed. Users have the right, under certain circumstances, to obtain the erasure of their Data from the Owner.
Receive their Data and have it transferred to another controller. Users have the right to receive their Data in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that the Data is processed by automated means and that the processing is based on the User's consent, on a contract which the User is part of or on pre-contractual obligations thereof.
Lodge a complaint. Users have the right to bring a claim before their competent data protection authority.
Details about the right to object to processing
Where Personal Data is processed for a public interest, in the exercise of an official authority vested in the Owner or for the purposes of the legitimate interests pursued by the Owner, Users may object to such processing by providing a ground related to their particular situation to justify the objection.
Users must know that, however, should their Personal Data be processed for direct marketing purposes, they can object to that processing at any time without providing any justification. To learn, whether the Owner is processing Personal Data for direct marketing purposes, Users may refer to the relevant sections of this document.
How to exercise these rights
Any requests to exercise User rights can be directed to the Owner through the contact details provided in this document. These requests can be exercised free of charge and will be addressed by the Owner as early as possible and always within one month.
Cookie Policy
This Application uses Trackers. To learn more, the User may consult the Cookie Policy.
Additional information about Data collection and processing
Legal action
The User's Personal Data may be used for legal purposes by the Owner in Court or in the stages leading to possible legal action arising from improper use of this Application or the related Services.
The User declares to be aware that the Owner may be required to reveal personal data upon request of public authorities.
Additional information about User's Personal Data
In addition to the information contained in this privacy policy, this Application may provide the User with additional and contextual information concerning particular Services or the collection and processing of Personal Data upon request.
System logs and maintenance
For operation and maintenance purposes, this Application and any third-party services may collect files that record interaction with this Application (System logs) use other Personal Data (such as the IP Address) for this purpose.
Information not contained in this policy
More details concerning the collection or processing of Personal Data may be requested from the Owner at any time. Please see the contact information at the beginning of this document.
How “Do Not Track” requests are handled
This Application does not support “Do Not Track” requests.
To determine whether any of the third-party services it uses honor the “Do Not Track” requests, please read their privacy policies.
Changes to this privacy policy
The Owner reserves the right to make changes to this privacy policy at any time by notifying its Users on this page and possibly within this Application and/or - as far as technically and legally feasible - sending a notice to Users via any contact information available to the Owner. It is strongly recommended to check this page often, referring to the date of the last modification listed at the bottom.
Should the changes affect processing activities performed on the basis of the User’s consent, the Owner shall collect new consent from the User, where required.
Information for Users residing in Brazil
This part of the document integrates with and supplements the information contained in the rest of the privacy policy and is provided by the entity running this Application and, if the case may be, its parent, subsidiaries and affiliates (for the purposes of this section referred to collectively as “we”, “us”, “our”).
The provisions contained in this section apply to all Users who reside in Brazil, according to the "Lei Geral de Proteção de Dados" (Users are referred to below, simply as “you”, “your”, “yours”). For such Users, these provisions supersede any other possibly divergent or conflicting provisions contained in the privacy policy.
This part of the document uses the term “personal information“ as it is defined in the Lei Geral de Proteção de Dados (LGPD).
The grounds on which we process your personal information
We can process your personal information solely if we have a legal basis for such processing. Legal bases are as follows:
your consent to the relevant processing activities;
compliance with a legal or regulatory obligation that lies with us;
the carrying out of public policies provided in laws or regulations or based on contracts, agreements and similar legal instruments;
studies conducted by research entities, preferably carried out on anonymized personal information;
the carrying out of a contract and its preliminary procedures, in cases where you are a party to said contract;
the exercising of our rights in judicial, administrative or arbitration procedures;
protection or physical safety of yourself or a third party;
the protection of health – in procedures carried out by health entities or professionals;
our legitimate interests, provided that your fundamental rights and liberties do not prevail over such interests; and
credit protection.
To find out more about the legal bases, you can contact us at any time using the contact details provided in this document.
Categories of personal information processed
To find out what categories of your personal information are processed, you can read the section titled “Detailed information on the processing of Personal Data” within this document.
Why we process your personal information
To find out why we process your personal information, you can read the sections titled “Detailed information on the processing of Personal Data” and “The purposes of processing” within this document.
Your Brazilian privacy rights, how to file a request and our response to your requests
Your Brazilian privacy rights
You have the right to:
obtain confirmation of the existence of processing activities on your personal information;
access to your personal information;
have incomplete, inaccurate or outdated personal information rectified;
obtain the anonymization, blocking or elimination of your unnecessary or excessive personal information, or of information that is not being processed in compliance with the LGPD;
obtain information on the possibility to provide or deny your consent and the consequences thereof;
obtain information about the third parties with whom we share your personal information;
obtain, upon your express request, the portability of your personal information (except for anonymized information) to another service or product provider, provided that our commercial and industrial secrets are safeguarded;
obtain the deletion of your personal information being processed if the processing was based upon your consent, unless one or more exceptions provided for in art. 16 of the LGPD apply;
revoke your consent at any time;
lodge a complaint related to your personal information with the ANPD (the National Data Protection Authority) or with consumer protection bodies;
oppose a processing activity in cases where the processing is not carried out in compliance with the provisions of the law;
request clear and adequate information regarding the criteria and procedures used for an automated decision; and
request the review of decisions made solely on the basis of the automated processing of your personal information, which affect your interests. These include decisions to define your personal, professional, consumer and credit profile, or aspects of your personality.
You will never be discriminated against, or otherwise suffer any sort of detriment, if you exercise your rights.
How to file your request
You can file your express request to exercise your rights free from any charge, at any time, by using the contact details provided in this document, or via your legal representative.
How and when we will respond to your request
We will strive to promptly respond to your requests.
In any case, should it be impossible for us to do so, we’ll make sure to communicate to you the factual or legal reasons that prevent us from immediately, or otherwise ever, complying with your requests. In cases where we are not processing your personal information, we will indicate to you the physical or legal person to whom you should address your requests, if we are in the position to do so.
In the event that you file an access or personal information processing confirmation request, please make sure that you specify whether you’d like your personal information to be delivered in electronic or printed form.
You will also need to let us know whether you want us to answer your request immediately, in which case we will answer in a simplified fashion, or if you need a complete disclosure instead.
In the latter case, we’ll respond within 15 days from the time of your request, providing you with all the information on the origin of your personal information, confirmation on whether or not records exist, any criteria used for the processing and the purposes of the processing, while safeguarding our commercial and industrial secrets.
In the event that you file a rectification, deletion, anonymization or personal information blocking request, we will make sure to immediately communicate your request to other parties with whom we have shared your personal information in order to enable such third parties to also comply with your request – except in cases where such communication is proven impossible or involves disproportionate effort on our side.
Transfer of personal information outside of Brazil permitted by the law
We are allowed to transfer your personal information outside of the Brazilian territory in the following cases:
when the transfer is necessary for international legal cooperation between public intelligence, investigation and prosecution bodies, according to the legal means provided by the international law;
when the transfer is necessary to protect your life or physical security or those of a third party;
when the transfer is authorized by the ANPD;
when the transfer results from a commitment undertaken in an international cooperation agreement;
when the transfer is necessary for the execution of a public policy or legal attribution of public service;
when the transfer is necessary for compliance with a legal or regulatory obligation, the carrying out of a contract or preliminary procedures related to a contract, or the regular exercise of rights in judicial, administrative or arbitration procedures.
Definitions and legal references
Personal Data (or Data)
Any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.
Usage Data
Information collected automatically through this Application (or third-party services employed in this Application), which can include: the IP addresses or domain names of the computers utilized by the Users who use this Application, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Application) and the details about the path followed within the Application with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment.
User
The individual using this Application who, unless otherwise specified, coincides with the Data Subject.
Data Subject
The natural person to whom the Personal Data refers.
Data Processor (or Data Supervisor)
The natural or legal person, public authority, agency or other body which processes Personal Data on behalf of the Controller, as described in this privacy policy.
Data Controller (or Owner)
The natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of Personal Data, including the security measures concerning the operation and use of this Application. The Data Controller, unless otherwise specified, is the Owner of this Application.
This Application
The means by which the Personal Data of the User is collected and processed.
Service
The service provided by this Application as described in the relative terms (if available) and on this site/application.
European Union (or EU)
Unless otherwise specified, all references made within this document to the European Union include all current member states to the European Union and the European Economic Area.
Cookie
Cookies are Trackers consisting of small sets of data stored in the User's browser.
Tracker
Tracker indicates any technology - e.g Cookies, unique identifiers, web beacons, embedded scripts, e-tags and fingerprinting - that enables the tracking of Users, for example by accessing or storing information on the User’s device.

Legal information
This privacy statement has been prepared based on provisions of multiple legislations, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation).
This privacy policy relates solely to this Application, if not stated otherwise within this document.

Latest update: June 22, 2022
`
