import store from '../../store'
import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import {
  Asset,
  GoogleSearchData,
  Hub,
  User,
  AutodeskToken,
  Folder,
  Model
} from '../Models'
import decodeToken from '../../helpers/decodeToken'

@Module({
  name: 'StoreUser',
  namespaced: true,
  store,
  dynamic: true
})
class Store extends VuexModule {
  private _user: User | null = null
  private _searchData: GoogleSearchData = null
  private _assets: Asset[] = []
  private _assetForViewer: Asset = null

  private _progress: { progress: string; rate: string } = {
    progress: '',
    rate: ''
  }

  private _hub: Hub = null
  private _lastHubSelected: Hub = null
  private _invitation: any = null
  private _file: any = null
  private _autodeskCredentials: AutodeskToken = null
  private _modelFromAutodeskBrowser: Folder = null
  private _changeAnalyticsAction: boolean = null
  private _backToAnalytics: boolean = null
  private _isOwner: boolean = false
  private _showRooms: boolean = false
  private _analyticsSync: boolean = false
  private _activeRows: any = []
  private _activeBimtableRows: any = []
  private _selectedGeometries: string[] = []
  private _wrapperWidth: number = null
  private _finderResults: number = 0
  private _search: string = ''
  private _searchIndexes: number[][] = []
  private _activeSearchIndex: number[] = null
  private _showOverlay: boolean = false

  private _activeHeaders: {
    header: string
    origin?: string
    showOnlyOwner?: boolean
  }[] = []

  private _notifications: any[] = []
  private _notificationsRead: any[] = []
  private _hasChanged: boolean = false
  private _hiddenHeaders: string[] = []
  private _filterConditions: any[] = []
  private _filterList: number[] = []
  private _fitGeometries: boolean = false
  private _isolation: boolean = false
  private _groups: any = []
  private _groupsItems: any = []
  private _bimtableRows: any[][] = []
  private _tableViews: any[] = []
  private _activeTableView: number = null
  private _deletedTableViews: any[] = []
  private _integrationNames: any[] = []
  private _tableNames: { title: string; origin: string; isEmpty: boolean }[] =
    []
  private _importTableNames: any[] = []
  private _preImportTableNames: {
    title: string
    origin: string
    isEmpty: boolean
  }[] = []
  private _preImportHeaders: any[] = []
  private _preImportRows: any[] = []
  private _selectedTableName: string = ''
  private _teamIndex: number = 0
  private _projectIndex: number = 0
  private _maximizeTable: boolean = false
  private _activeSelection: boolean = false
  private _selectionData: any = null
  private _showOptionsHub: boolean = false
  private _urlLikes: string[] = []

  private _selectedTable: {
    title: string
    origin: string
    isEmpty: boolean
  } = {
    title: '',
    origin: '',
    isEmpty: false
  }

  @Mutation
  setSearchData(searchData: GoogleSearchData) {
    this._searchData = searchData
  }

  get searchData(): GoogleSearchData {
    return this._searchData
  }

  @Mutation
  setUser(token: string) {
    this._user = decodeToken(token)
  }

  public get user(): User {
    return this._user
  }

  @Mutation
  setAssets(assets: Asset[]) {
    this._assets = assets
  }

  public get assets(): Asset[] {
    return this._assets
  }

  @Mutation
  setAssetForViewer(assetForViewer: Asset) {
    this._assetForViewer = assetForViewer
  }

  public get assetForViewer(): Asset {
    return this._assetForViewer
  }

  @Mutation
  refreshAssetBucketKey(bucketKey: string) {
    this._assetForViewer.bucketKey = bucketKey
  }

  @Mutation
  setProgress(progress: { progress: string; rate: string }) {
    this._progress = progress
  }

  public get progress(): { progress: string; rate: string } {
    return this._progress
  }

  @Mutation
  setHub(hub: Hub) {
    this._hub = hub
  }

  public get hub(): Hub {
    return this._hub
  }

  @Mutation
  setLastHubSelected(lastHubSelected: Hub) {
    this._lastHubSelected = lastHubSelected
  }

  public get lastHubSelected(): Hub {
    return this._lastHubSelected
  }

  @Mutation
  setInvitation(invitation: any) {
    this._invitation = invitation
  }

  public get invitation(): any {
    return this._invitation
  }

  @Mutation
  setFile(file: any) {
    this._file = file
  }

  public get file(): File {
    return this._file
  }

  @Mutation
  setAutodeskCredentials(autodeskCredentials: AutodeskToken) {
    this._autodeskCredentials = autodeskCredentials
  }

  public get autodeskCredentials(): AutodeskToken {
    return this._autodeskCredentials
  }

  @Mutation
  setModelFromAutodeskBrowser(modelFromAutodeskBrowser: Folder) {
    this._modelFromAutodeskBrowser = modelFromAutodeskBrowser
  }

  public get modelFromAutodeskBrowser(): Folder {
    return this._modelFromAutodeskBrowser
  }

  @Mutation
  setChangeAnalyticsAction(changeAnalyticsAction: boolean) {
    this._changeAnalyticsAction = changeAnalyticsAction
  }

  public get changeAnalyticsAction(): boolean {
    return this._changeAnalyticsAction
  }

  @Mutation
  setBackToAnalytics(backToAnalytics: boolean) {
    this._backToAnalytics = backToAnalytics
  }

  public get backToAnalytics(): boolean {
    return this._backToAnalytics
  }

  @Mutation
  setIsOwner(isOwner: boolean) {
    this._isOwner = isOwner
  }

  public get isOwner(): boolean {
    return this._isOwner
  }

  @Mutation
  switchShowRooms() {
    this._showRooms = !this._showRooms
  }

  public get showRooms(): boolean {
    return this._showRooms
  }

  @Mutation
  setUserTempImage(image: string) {
    this._user.avatar = image
  }

  @Mutation
  setAnalyticsSync(analyticsSync: boolean) {
    this._analyticsSync = analyticsSync
  }

  public get analyticsSync(): boolean {
    return this._analyticsSync
  }

  @Mutation
  setActiveRows(activeRows: any) {
    this._activeRows = activeRows
  }

  @Mutation
  setActiveBimtableRows(activeBimtableRows: string[][]) {
    this._activeBimtableRows = activeBimtableRows
  }

  public get activeBimtableRows() {
    return this._activeBimtableRows
  }

  @Mutation
  addActiveRow(activeRow: any[]) {
    this._activeRows.push(activeRow)
  }

  public get activeRows(): any {
    return this._activeRows
  }

  @Mutation
  resetActiveRows() {
    this._activeRows = []
  }

  @Mutation
  setSelectedGeometries(selectedGeometries: string[]) {
    this._selectedGeometries = selectedGeometries
  }

  public get selectedGeometries(): string[] {
    return this._selectedGeometries
  }

  @Mutation
  setWrapperWidth(wrapperWidth: number) {
    this._wrapperWidth = wrapperWidth
  }

  public get wrapperWidth(): number {
    return this._wrapperWidth
  }

  public get finderResults(): number {
    return this._finderResults
  }

  @Mutation
  setFinderResults(finderResults: number) {
    this._finderResults = finderResults
  }

  @Mutation
  setSearch(search: string) {
    this._search = search
  }

  public get search(): string {
    return this._search
  }

  @Mutation
  setSearchIndexes(searchIndexes: any) {
    this._searchIndexes = searchIndexes
  }

  public get searchIndexes(): number[][] {
    return this._searchIndexes
  }

  @Mutation
  setActiveSearchIndex(activeSearchIndex: number[]) {
    this._activeSearchIndex = activeSearchIndex
  }

  public get activeSearchIndex(): number[] {
    return this._activeSearchIndex
  }

  @Mutation
  setActiveHeaders(activeHeaders: { header: string; origin?: string }[]) {
    this._activeHeaders = activeHeaders
  }

  public get activeHeaders(): {
    header: string
    origin?: string
    showOnlyOwner?: boolean
  }[] {
    return this._activeHeaders
  }

  @Mutation
  removeActiveHeader(index: number) {
    this._activeHeaders.splice(index, 1)
  }

  @Mutation
  updateRow(payload: { row; rowIndex: number }) {
    const { row, rowIndex } = payload
    this._activeRows.splice(rowIndex, 1, row)
  }

  @Mutation
  setNotifications(notifications: any[]) {
    this._notifications = notifications
  }

  public get notifications(): any[] {
    return this._notifications
  }

  @Mutation
  setNotificationsRead(notificationsRead: any) {
    this._notificationsRead = notificationsRead
  }

  @Mutation
  clearNotificationAndMarkRead(id: string) {
    const notification = this._notifications.find(n => n.notificationId === id)

    this._notifications = this._notifications.filter(
      n => n.notificationId !== id
    )

    this._notificationsRead.unshift(notification)
  }

  @Mutation
  setHasChanged(hasChanged: boolean) {
    this._hasChanged = hasChanged
  }

  public get hasChanged(): boolean {
    return this._hasChanged
  }

  @Mutation
  setHiddenHeaders(hiddenHeaders: any) {
    this._hiddenHeaders = hiddenHeaders
  }

  @Mutation
  addHiddenHeader(header: string) {
    this._hiddenHeaders.push(header)
  }

  public get hiddenHeaders(): string[] {
    return this._hiddenHeaders
  }

  @Mutation
  removeHiddenHeader(header: string) {
    this._hiddenHeaders = this._hiddenHeaders.filter(h => h !== header)
  }

  @Mutation
  resetHiddenHeaders() {
    this._hiddenHeaders = []
  }

  public get notificationsRead(): any[] {
    return this._notificationsRead
  }

  @Mutation
  setFilterConditions(conditions: any) {
    this._filterConditions = conditions
  }

  public get filterConditions(): any[] {
    return this._filterConditions
  }

  @Mutation
  resetFilterConditions() {
    this._filterConditions = []
  }

  @Mutation
  addThreeLeggedModel(model: Model) {
    this._assetForViewer.threeLeggedModels.push(model)
  }

  @Mutation
  setFilterList(filterList: number[]) {
    this._filterList = filterList
  }

  public get filterList(): number[] {
    return this._filterList
  }

  @Mutation
  resetFilterList() {
    this._filterList = []
  }

  @Mutation
  switchFitGeometries() {
    this._fitGeometries = !this._fitGeometries
  }

  public get fitGeometries(): boolean {
    return this._fitGeometries
  }

  @Mutation
  switchIsolation() {
    this._isolation = !this._isolation
  }

  public get isolation(): boolean {
    return this._isolation
  }

  @Mutation
  setGroups(groups: any) {
    this._groups = groups
  }

  public get groups(): any {
    return this._groups
  }

  @Mutation
  resetGroups() {
    this._groups = []
  }

  @Mutation
  setGroupsItems(groupsItems: any) {
    this._groupsItems = groupsItems
  }

  public get groupsItems(): any {
    return this._groupsItems
  }

  @Mutation
  resetGroupsItems() {
    this._groupsItems = []
  }

  @Mutation
  setBimtableRows(bimtableRows: any) {
    this._bimtableRows = bimtableRows
  }

  @Mutation
  addBimtableRows(rows: any[]) {
    this._bimtableRows.push(rows)
  }

  public get bimtableRows(): any[][] {
    return this._bimtableRows
  }

  @Mutation
  setTableViews(tableViews: any) {
    this._tableViews = tableViews
  }

  @Mutation
  addTableView(tableView: any) {
    this._tableViews.push(tableView)
  }

  public get tableViews(): any[] {
    return this._tableViews
  }

  @Mutation
  setActiveTableView(index: number) {
    this._activeTableView = index
  }

  public get activeTableView(): number {
    return this._activeTableView
  }

  @Mutation
  addDeleteTableViews(title: string, tableName: string) {
    this._deletedTableViews.push({ title, tableName })
  }

  public get deletedTableViews(): any[] {
    return this._deletedTableViews
  }

  @Mutation
  resetDeleteTableViews() {
    this._deletedTableViews = []
  }

  @Mutation
  setIntegrationNames(integrationNames: any[]) {
    this._integrationNames = integrationNames
  }

  public get integrationNames(): string[] {
    return this._integrationNames
  }

  @Mutation
  setTableNames(object: { title: string; origin: string; isEmpty: boolean }[]) {
    this._tableNames = object
  }

  public get tableName(): {
    title: string
    origin: string
    isEmpty: boolean
  }[] {
    return this._tableNames
  }

  @Mutation
  resetTableName() {
    this._tableNames = []
  }

  @Mutation
  setImportTableNames(tableNames: string[]) {
    this._importTableNames = tableNames
  }

  public get importTableNames(): string[] {
    return this._importTableNames
  }

  @Mutation
  setPreImportTableNames(
    tableNames: {
      title: string
      origin: string
      isEmpty: boolean
    }[]
  ) {
    this._preImportTableNames = tableNames
  }

  public get preImportTableNames(): {
    title: string
    origin: string
    isEmpty: boolean
  }[] {
    return this._preImportTableNames
  }

  @Mutation
  setPreImportHeaders(headers: any[]) {
    this._preImportHeaders = headers
  }

  public get preImportHeaders(): any[] {
    return this._preImportHeaders
  }

  @Mutation
  setPreImportRows(rows: any[]) {
    this._preImportRows = rows
  }

  public get preImportRows(): any[] {
    return this._preImportRows
  }

  @Mutation
  setSelectedTableName(tableName: string) {
    this._selectedTableName = tableName
  }

  public get selectedTableName(): string {
    return this._selectedTableName
  }

  @Mutation
  setSelectedTable(tableName: {
    title: string
    origin: string
    isEmpty: boolean
  }) {
    this._selectedTable = tableName
  }

  public get selectedTable(): {
    title: string
    origin: string
    isEmpty: boolean
  } {
    return this._selectedTable
  }

  @Mutation
  setTeamIndex(index: number) {
    this._teamIndex = index
  }

  public get teamIndex(): number {
    return this._teamIndex
  }

  @Mutation
  setProjectIndex(index: number) {
    this._projectIndex = index
  }

  public get projectIndex(): number {
    return this._projectIndex
  }

  @Mutation
  setSizeTable(maximize: boolean) {
    this._maximizeTable = maximize
  }

  public get sizeTable(): boolean {
    return this._maximizeTable
  }

  @Mutation
  setSelectionData(selectionData: any) {
    this._selectionData = selectionData
  }

  public get selectionData(): any {
    return this._selectionData
  }

  @Mutation
  setActiveSelection(activeSelection: boolean) {
    this._activeSelection = activeSelection
  }

  public get activeSelection(): boolean {
    return this._activeSelection
  }

  @Mutation
  setShowOptionsHub(showOptionsHub: boolean) {
    this._showOptionsHub = showOptionsHub
  }

  public get showOptionsHub(): boolean {
    return this._showOptionsHub
  }

  @Mutation
  setShowOverlay(showOverlay: boolean) {
    this._showOverlay = showOverlay
  }

  public get showOverlay(): boolean {
    return this._showOverlay
  }

  @Mutation
  pushToUrlLikes(url: string) {
    this._urlLikes.push(url)
  }

  @Mutation
  removeFromUrlLikes(url: string) {
    this._urlLikes = this._urlLikes.filter(u => u !== url)
  }

  @Mutation
  resetUrlLikes() {
    this._urlLikes = []
  }

  public get urlLikes(): string[] {
    return this._urlLikes
  }
}

export default getModule(Store)
