
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Button from '@/components/shared/Button.vue'
import Store from '@/store/modules/Store'
import { AutodeskToken } from '@/store/Models'
import Dropdown from '@/components/shared/Dropdown.vue'
import FoldersTree from '@/components/Viewer/modals/FoldersTree.vue'
import setInitialTeamsData from '@/controllers/dataManagement/setInitialTeamsData'
import getProjectsData from '@/controllers/dataManagement/getProjectsData'
import getFolders from '@/controllers/dataManagement/getFolders'
import IconSpinner from '@/assets/icons/spinner-solid.svg'
import IconPointer from '@/assets/icons/hand-pointer.svg'

@Component({
  components: { Dropdown, Button, FoldersTree, IconSpinner, IconPointer }
})
export default class AutodeskThreeLeggedBrowser extends Vue {
  @Prop() openedModels!: string[]

  private interval = null
  private teamsData: { teams: any; projects: any; folders: any } = null
  private selected = { project: null, team: null }

  private get autodeskCredentials(): AutodeskToken {
    return Store.autodeskCredentials
  }

  mounted() {
    if (Store.autodeskCredentials) {
      this.setTeamsData()
    }
  }

  private async auth() {
    const {
      data: { authUrl }
    } = await this.$apollo.query({
      query: require('@/graphql/queries/auth-url.graphql')
    })

    window.open(
      authUrl,
      'BIMTable - Autodesk© Auth',
      'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=400,height=800,modal=yes'
    )

    this.interval = setInterval(() => {
      const storage = localStorage.getItem('autodesk-token')

      if (storage) {
        this.setAutodeskCredentials(storage)
      }
    }, 1000)
  }

  private async setAutodeskCredentials(creds: string) {
    Store.setAutodeskCredentials(JSON.parse(creds))
    clearInterval(this.interval)
    localStorage.removeItem('autodesk-token')

    await this.setTeamsData()
  }

  private async setTeamsData() {
    this.teamsData = await setInitialTeamsData()

    this.selected = {
      project: this.teamsData.projects[Store.projectIndex],
      team: this.teamsData.teams[Store.teamIndex]
    }
  }

  private clearAutodeskCredentials() {
    Store.setAutodeskCredentials(null)
  }

  private async handleTeamChange(teamTitle) {
    try {
      this.teamsData.folders = null

      const team = this.teamsData.teams.find(p => p.name === teamTitle)

      const teamIndex = this.teamsData.teams.findIndex(
        p => p.name === teamTitle
      )

      const { folders, projects } = await getProjectsData(team.id)

      this.teamsData = {
        ...this.teamsData,
        folders,
        projects
      }

      Store.setTeamIndex(teamIndex)
    } catch {
      console.error('error')
      this.$toasted.error(this.$i18n.t('errors.browser') as string)
    }
  }

  private async handleProjectChange(projectTitle: string) {
    try {
      this.teamsData.folders = null

      const project = this.teamsData.projects.find(p => p.name === projectTitle)

      const projectIndex = this.teamsData.projects.findIndex(
        p => p.name === projectTitle
      )

      this.teamsData.folders = await getFolders(
        this.selected.team.id,
        project.id
      )

      Store.setProjectIndex(projectIndex)
    } catch {
      console.error('error')
      this.$toasted.error(this.$i18n.t('errors.browser') as string)
    }
  }
}
