
import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator'
import Store from '@/store/modules/Store'

@Component
export default class AddAssetBtn extends Vue {
  @Prop() loading!: boolean

  private isHovering: boolean = false
  private showImage: boolean = false

  private get pricingPlan(): string {
    return Store.hub.pricingPlan
  }

  @Watch('isHovering')
  onIsHoveringChange() {
    if (!this.isHovering) {
      this.showImage = false
    }

    setTimeout(() => {
      this.showImage = this.isHovering
    }, 700)
  }

  @Watch('showImage')
  onShowImageChange() {
    if (this.pricingPlan === 'free') {
      this.$root.$emit('change-plan-indication', this.showImage)
    }
  }

  @Emit() clicked() {}
}
