export default [
  {
    title: 'red',
    value: '#ef4444',
    bg: '#ef444426'
  },
  {
    title: 'blue',
    value: '#3b82f6',
    bg: '#3b82f626'
  },
  {
    title: 'green',
    value: '#22c55e',
    bg: '#22c55e26'
  },
  {
    title: 'yellow',
    value: '#eab308',
    bg: '#eab30826'
  },
  {
    title: 'pink',
    value: '#ec4899',
    bg: '#ec489926'
  },
  {
    title: 'lime',
    value: '#84cc16',
    bg: '#84cc1626'
  },
  {
    title: 'gray',
    value: '#6b7280',
    bg: '#6b728026'
  },
  {
    title: 'purple',
    value: '#a855f7',
    bg: '#a855f726'
  },
  {
    title: '',
    value: '',
    bg: ''
  },
  {
    title: 'wireframe',
    value: '',
    bg: ''
  }
]
