
import { Component, Vue, Watch } from 'vue-property-decorator'
import AssetCard from '@/components/Dashboard/Assets/AssetCard.vue'
import AddAssetBtn from '@/components/Dashboard/Assets/AddAssetBtn.vue'
import CreateAsset from '@/components/Dashboard/Assets/Modals/CreateAsset.vue'
import { Asset } from '@/store/Models'
import Store from '@/store/modules/Store'
import { directive as onClickaway } from 'vue-clickaway'
import IconSpinner from '@/assets/icons/spinner-solid.svg'
import ShareAsset from '@/components/Dashboard/Assets/Modals/ShareAsset.vue'
import ConfirmDelete from '@/components/Dashboard/Assets/Modals/ConfirmDelete.vue'
import EditAsset from '@/components/Dashboard/Assets/Modals/EditAsset.vue'
import Mapbox from '@/components/Dashboard/Assets/Mapbox.vue'
import IconList from '@/assets/icons/list.svg'
import IconDropdown from '@/assets/icons/list-dropdown.svg'
import IconLeftArrow from '@/assets/icons/left-to-line.svg'
import IconRightArrow from '@/assets/icons/right-to-line.svg'
import AssetShare from '@/components/Dashboard/Assets/AssetShare.vue'

@Component({
  apollo: {
    assets: {
      query: require('@/graphql/queries/assets.graphql'),
      variables() {
        return { hubId: this.hub.id }
      },
      watchLoading(isLoading) {
        this.loading = isLoading
      },
      result({ data: { assets } }) {
        this.assets = assets
        this.filteredAssets = assets
      }
    }
  },
  components: {
    AssetShare,
    Mapbox,
    EditAsset,
    ConfirmDelete,
    ShareAsset,
    AssetCard,
    AddAssetBtn,
    CreateAsset,
    IconLeftArrow,
    IconSpinner,
    IconRightArrow,
    IconList,
    IconDropdown
  },
  directives: { onClickaway }
})
export default class Assets extends Vue {
  private showAddAssetModal: boolean = false
  private titleClicked: boolean = false
  private assets: Asset[] = []
  private hubTitle: string = ''
  private assetToShare: Asset = null
  private refreshSelectedAssetId: string = ''
  private loading: boolean = false
  private sharedAsset: Asset = null
  private assetToDelete: Asset = null
  private assetToEdit: Asset = null
  private collapse: boolean = false
  private searchTerm: string = ''
  private filteredAssets: Asset[] = []
  private inFocus: boolean = false
  private showAssetsList: boolean = false
  private prefix: string = process.env.VUE_APP_CLOUDINARY_IMG_PREFIX
  private urlNoImage: string = process.env.VUE_APP_NO_IMAGE_URL
  private showActions: number | null = null

  private get hub() {
    return Store.hub
  }

  async beforeMount() {
    this.showAssetsList = document.documentElement.clientWidth > 640
  }

  async mounted() {
    this.$root.$on('refresh-assets', () => {
      this.$apollo.queries.assets.refetch()
    })

    this.$root.$on('refresh-selected-asset', (assetId: string) => {
      this.refreshSelectedAssetId = assetId
      this.$apollo.queries.assets.refetch()
    })

    this.$root.$on('collapse', (status: boolean) => {
      this.collapse = status

      const interval = setInterval(() => this.$root.$emit('refresh-map'), 10)

      setTimeout(() => {
        clearInterval(interval)
      }, 500)
    })

    this.hubTitle = this.hub.title

    if (this.hub.isShared) {
      await this.getSharedAsset()
    }

    this.filteredAssets = this.assets

    this.$root.$on('action-asset', (asset: Asset, action: string) => {
      this.handleActions(asset, action)
    })

    this.$root.$on('send-change-view-mode', (status: boolean) => {
      this.showAssetsList = status
    })

    this.$root.$on('send-button-asset', (status: boolean) => {
      this.showAddAssetModal = status
    })

    this.$root.$on('search-term-change', (searchTerm: string) => {
      this.searchTerm = searchTerm
    })

    this.checkLocalStorage()
  }

  private async getSharedAsset() {
    try {
      const {
        data: { assetById }
      } = await this.$apollo.query({
        variables: {
          assetId: this.hub.assetIds[0]
        },
        query: require('@/graphql/queries/asset-by-id.graphql')
      })

      setTimeout(() => {
        if (assetById) {
          this.sharedAsset = assetById
          this.filteredAssets = [assetById]
        }
      }, 50)
    } catch (error) {
      this.$toasted.error(this.$i18n.t('errors.sharedAsset') as string)
    }
  }

  private handleActions(asset: Asset, action: string) {
    switch (action) {
      case 'delete':
        this.assetToDelete = asset
        break
      case 'edit':
        this.assetToEdit = asset
        break
      case 'share':
        this.assetToShare = asset
    }
  }

  private async deleteAsset() {
    this.loading = true
    const assetId: string = this.assetToDelete.id
    const hubId: string = this.hub.id

    try {
      const {
        data: { deleteAsset }
      } = await this.$apollo.mutate({
        variables: { assetId, hubId },
        mutation: require('@/graphql/mutations/delete-asset.graphql')
      })

      if (deleteAsset) {
        this.$toasted.success(this.$i18n.t('success.deleteAsset') as string)
        this.assetToDelete = null
        this.$root.$emit('refresh-hubs')
        this.$root.$emit('refresh-assets')
      }
    } catch (error) {
      this.$toasted.error(this.$i18n.t('errors.deleteAsset') as string)
    }
  }

  private highlight(asset) {
    if (asset.coordinates) {
      this.$root.$emit('highlight-pushpin', asset.coordinates)
    }
  }

  private refreshSelectedAsset() {
    this.assetToShare = Store.assets.find(
      asset => asset.id === this.refreshSelectedAssetId
    )

    this.refreshSelectedAssetId = ''
  }

  private closeEdit() {
    this.assetToEdit = null
    this.$apollo.queries.assets.refetch()
  }

  private changeViewMode(mode: boolean) {
    this.showAssetsList = mode
  }

  private handleOpenAsset(asset: Asset) {
    if (asset) {
      Store.setAssetForViewer(asset)
      setTimeout(() => this.$router.replace({ name: 'Viewer' }), 150)
    }
  }

  private checkLocalStorage() {
    const config = JSON.parse(
      localStorage.getItem(process.env.VUE_APP_CONFIG_COOKIE)
    )
    switch (config.assetsList) {
      case 0:
        this.showAssetsList = false
        break
      case 1:
        this.showAssetsList = true
        break
    }
    switch (config.mapExpanded) {
      case 0:
        this.collapse = false
        break
      case 1:
        this.collapse = true
        break
    }
  }

  @Watch('searchTerm')
  onSearchTermChange() {
    if (!this.searchTerm) {
      this.filteredAssets = this.assets
    } else {
      this.filteredAssets = this.assets.filter(asset =>
        asset.title.toLowerCase().includes(this.searchTerm.toLowerCase())
      )
    }
  }

  @Watch('titleClicked')
  onTitleClickedChange() {
    if (this.titleClicked) {
      setTimeout(() => {
        const input = this.$refs.titleInput as HTMLInputElement
        input.select()
      }, 100)
    }
  }

  @Watch('hub')
  onHubChange() {
    if (this.hub.isShared) {
      this.getSharedAsset()
    } else {
      this.sharedAsset = null
      this.$apollo.queries.assets.refetch()
    }
  }

  @Watch('assets')
  onAssetsChange() {
    if (this.assets && this.assets.length) {
      Store.setAssets(this.assets)
    }

    if (this.refreshSelectedAssetId !== '') {
      this.refreshSelectedAsset()
    }
  }

  @Watch('sharedAsset')
  onSharedAssetChange() {
    this.assets = [this.sharedAsset]
  }
}
