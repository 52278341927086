
import { Vue, Component, Watch } from 'vue-property-decorator'
import LegalCard from '@/components/Legal/LegalCard.vue'
import legalItems from '@/config/legalItems'
import ModalLegal from '@/components/Legal/ModalLegal.vue'
import Logo from '@/components/shared/Logo.vue'
import Navbar from '@/components/Dashboard/Navbar/Navbar.vue'

@Component({
  components: { LegalCard, Logo, ModalLegal, Navbar }
})
export default class Legal extends Vue {
  private items: any[] = legalItems
  private selectedIndex: number | null = null

  mounted() {
    if (this.$route.hash !== '') {
      switch (this.$route.hash) {
        case '#legal-notice':
          this.selectedIndex = 0
          break
        case '#privacy':
          this.selectedIndex = 1
          break
        case '#terms':
          this.selectedIndex = 2
          break
        case '#cookies':
          this.selectedIndex = 3
          break
      }
    }
  }

  private closeModal() {
    this.selectedIndex = null
    this.$router.replace({
      hash: ''
    })
  }

  @Watch('selectedIndex')
  onSelectedIndexChange() {
    if (this.selectedIndex !== null) {
      this.$router.replace({
        hash: this.items[this.selectedIndex].title
          .toLowerCase()
          .replace(' ', '-')
      })
    }
  }
}
