import { apolloProvider } from '../../vue-apollo'

export default async uploadPayload => {
  try {
    const { defaultClient } = apolloProvider()

    const {
      data: { categsProperties }
    } = await defaultClient.mutate({
      variables: { uploadPayload },
      mutation: require('@/graphql/mutations/categs-properties.graphql')
    })

    return categsProperties
  } catch (error) {
    throw new Error(error)
  }
}
