import autodeskClientThree from '../../helpers/autodeskClientThree'
import { ApiResponse, FoldersApi } from 'forge-apis'
import Store from '../../store/modules/Store'

export default async (folderId: string, projectId: string) => {
  const foldersApi = new FoldersApi()

  const {
    body: { data }
  }: ApiResponse = await foldersApi.getFolderContents(
    projectId,
    folderId,
    {},
    autodeskClientThree,
    Store.autodeskCredentials
  )

  return data.map(folder => {
    return {
      type: folder.type,
      id: folder.id,
      name: folder.attributes.displayName,
      creator: folder.attributes.createUserName,
      lastModified: folder.attributes.lastModifiedTime
    }
  })
}
