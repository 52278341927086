
import { Vue, Component, Emit, Watch } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'
import Input from '@/components/shared/Input.vue'
import UploadImageInput from '@/components/shared/UploadImageInput.vue'
import UploadImagePlaceholder from '@/components/shared/UploadImagePlaceholder.vue'
import Button from '@/components/shared/Button.vue'
import { GoogleSearchData } from '@/store/Models'
import Store from '@/store/modules/Store'
import axios from 'axios'
import IconLocationSite from '@/assets/icons/location-site.svg'

@Component({
  components: {
    Input,
    UploadImageInput,
    UploadImagePlaceholder,
    IconLocationSite,
    Button
  },
  directives: { onClickaway }
})
export default class CreateAsset extends Vue {
  private imageDom: string = ''
  private loading: boolean = false
  private showConfirmClose: boolean = false
  private autoComplete = null
  private debounce: any = null
  private address: string | null = null
  private showResultsFind: boolean = false
  private search: string = ''

  private results: {
    place_name_main: string
    place_name_sub: string
    center: [number, number]
  }[] = []

  private asset = {
    title: '',
    description: '',
    image: null,
    coordinates: {
      address: '',
      latitude: 0,
      longitude: 0
    }
  }

  mounted() {
    // this.autoComplete = googleSearch()

    setTimeout(() => {
      const input = document.getElementById('title')
      input.focus()
    }, 100)
  }

  private async handleImage(image: File | null) {
    if (image) {
      this.asset.image = image
      const decoded = await image.arrayBuffer()
      const data = new Uint8Array(decoded)
      const blob = new Blob([data.buffer], { type: 'image/png' })
      this.imageDom = URL.createObjectURL(blob)
    }
  }

  private resetImage() {
    this.asset.image = null
    this.imageDom = ''
  }

  private get valuesNotValid(): boolean {
    return this.asset.title.length < 3
  }

  private get searchData(): GoogleSearchData {
    return Store.searchData
  }

  private get getImageName(): string {
    return this.asset.image ? this.asset.image.name : ''
  }

  private async createAsset() {
    this.loading = true
    const asset = this.asset

    try {
      const {
        data: { createAsset }
      } = await this.$apollo.mutate({
        variables: { asset, hubId: Store.hub.id },
        mutation: require('@/graphql/mutations/create-asset.graphql')
      })

      if (createAsset) {
        this.$toasted.success(this.$i18n.t('success.createAsset') as string)
        this.$root.$emit('refresh-assets')
        this.closeModal()
      }
    } catch (error) {
      this.$toasted.error(this.$i18n.t('errors.createAsset') as string)
    } finally {
      this.loading = false
    }
  }

  private showConfirm() {
    this.showConfirmClose = true
  }

  private quitConfirm() {
    this.showConfirmClose = false
  }

  private debounceSearch(search: string) {
    this.search = search
    this.asset.coordinates.address = search

    clearTimeout(this.debounce)

    this.debounce = setTimeout(() => {
      this.address = search.replaceAll(' ', '%20') // todo: cambiarlo a safe-url-encoded
      if (this.address != null && this.address.length > 3)
        this.submitGeolocation(search)
    }, 600)
  }

  private async submitGeolocation(search: string) {
    const {
      data: { features }
    } = await axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${search}.json?access_token=${process.env.VUE_APP_MAPBOX_ACCESS_TOKEN}`
    )

    this.results = features.map((f: { place_name: any; center: any }) => {
      const { place_name, center } = f

      const place_name_main = place_name.substr(0, place_name.indexOf(','))
      const place_name_sub = place_name.substr(place_name.indexOf(',') + 1)

      return { place_name_main, place_name_sub, center }
    })
  }

  private changeLocation(result: {
    place_name_main: string | null
    place_name_sub: string | null
    center: any
  }) {
    const address = result.place_name_main
    const longitude = result.center[0]
    const latitude = result.center[1]

    this.asset.coordinates.longitude = longitude
    this.asset.coordinates.latitude = latitude
    this.asset.coordinates.address = address

    this.showResultsFind = false

    Store.setSearchData({
      address,
      latitude,
      longitude
    })
  }

  private closeResults() {
    this.showResultsFind = false
  }

  @Watch('searchData')
  onSearchDataChange() {
    const { address, latitude, longitude } = this.searchData
    this.asset.coordinates = { address, latitude, longitude }
  }

  @Watch('search')
  onSearchChange() {
    if (this.search !== '') {
      this.showResultsFind = true
    }
  }

  @Emit() closeModal() {}
}
