
import { Component, Prop, Vue } from 'vue-property-decorator'
import Store from '@/store/modules/Store'

@Component
export default class LoadingBar extends Vue {
  @Prop() rate!: string
  @Prop() progress!: string
  @Prop({ default: true }) isForModel!: boolean

  private get storeProgress() {
    return Store.progress
  }
}
