
import { Vue, Component, Watch, Emit, Prop } from 'vue-property-decorator'
import Store from '@/store/modules/Store'
import IconCaretDown from '@/assets/icons/caret-down.svg'
import { directive as onClickaway } from 'vue-clickaway'
import FilterInput from '@/components/Viewer/TableData/FilterInput.vue'
import { ViewerModel } from '@/store/Models'

@Component({
  components: { FilterInput, IconCaretDown },
  directives: { onClickaway }
})
export default class ImportTable extends Vue {
  @Prop() model!: ViewerModel

  private tableName: string = ''
  private subTable: string = ''
  private condition: string = ''
  private value: string = ''
  private showTableNames: boolean = false
  private showSubtables: boolean = false
  private showConditions: boolean = false
  private importConditionList: any[] = []
  private showConfirmImport: boolean = false
  private bimtableRows: any[] = []

  private conditions: string[] = [
    this.$t('filter.operators.contains') as string,
    this.$t('filter.operators.doesNotContain') as string,
    this.$t('filter.operators.is') as string,
    this.$t('filter.operators.isNot') as string,
    this.$t('filter.operators.isEmpty') as string,
    this.$t('filter.operators.isNotEmpty') as string
  ]

  private importCondition = {
    tableName: '',
    subTable: '',
    condition: '',
    value: ''
  }

  private get route(): string {
    return `${this.tableName}/${this.subTable}/${this.condition}/${this.value}`
  }
  private get importTableNames(): string[] {
    return Store.importTableNames
  }

  private get preImportTableNames(): {
    title: string
    origin: string
    isEmpty: boolean
  }[] {
    return Store.preImportTableNames
  }

  private get preImportHeaders(): { header: string; origin?: string }[] {
    return Store.preImportHeaders
  }

  private get preImportRows(): any[] {
    return Store.preImportRows
  }

  private get filterList(): any[] {
    return Store.filterList
  }

  private get selectedTableName(): string {
    return Store.selectedTableName
  }

  private get storeBimtableRows(): any[] {
    return Store.bimtableRows
  }

  private get sizeTable(): boolean {
    return Store.sizeTable
  }

  private async getHeadersTableName() {
    const objectId = this.model.objectId
    const modelName = this.model.objectKey
    const assetId = Store.assetForViewer.id
    const tableName = this.tableName

    try {
      const {
        data: { getHeaders }
      } = await this.$apollo.query({
        variables: { objectId, modelName, assetId, tableName },
        query: require('@/graphql/queries/get-headers.graphql')
      })
      if (getHeaders) {
        Store.setPreImportHeaders(getHeaders)
      }
    } catch {
      console.log('error***PRE_IMPORT_HEADERS')
    }
  }

  private async getRowsTableName() {
    const objectId = this.model.objectId
    const modelName = this.model.objectKey
    const assetId = Store.assetForViewer.id
    const tableName = this.tableName

    try {
      const {
        data: { getRows }
      } = await this.$apollo.query({
        variables: { objectId, modelName, assetId, tableName },
        query: require('@/graphql/queries/get-rows.graphql')
      })

      if (getRows) {
        const payload = await JSON.parse(getRows)
        if (payload) {
          Store.setPreImportRows(payload.rows)
          if (payload.bimtableRows) {
            this.bimtableRows = payload.bimtableRows
          }
        }
      }
    } catch {
      console.log('error***PRE_IMPORT_ROWS')
    }
  }

  private setInput(input: string, type: string) {
    if (type === 'tableName') {
      this.tableName = input

      this.reset()

      this.getHeadersTableName()
      this.getRowsTableName()
    } else if (type === 'header') {
      this.subTable = input
      this.condition = ''
      this.value = ''
    } else if (type === 'condition') this.condition = input
  }

  private setValueInput(value: string) {
    this.value = value
  }

  private closeShowTableNames() {
    this.showTableNames = false
  }

  private closeShowHeaders() {
    this.showSubtables = false
  }

  private closeShowConditions() {
    this.showConditions = false
  }

  private get checkVoid(): boolean {
    return (
      this.tableName !== '' &&
      this.subTable !== '' &&
      this.condition !== '' &&
      this.value !== ''
    )
  }

  private setImportCondition() {
    if (this.checkVoid) {
      this.importCondition = {
        tableName: this.tableName,
        subTable: this.subTable,
        condition: this.condition,
        value: this.value
      }
      this.findDataTable()
    } else {
      this.$toasted.error('Antes debes completar todos los campos')
    }
  }

  private reset() {
    this.importConditionList = []
    this.showConfirmImport = false

    this.subTable = ''
    this.condition = ''
    this.value = ''

    Store.setPreImportHeaders([])
    Store.setPreImportRows([])
  }

  private findDataTable() {
    const rows = this.preImportRows
    const headers = this.preImportHeaders

    const indexesSet: Set<number>[] = []
    indexesSet.push(new Set())

    const { subTable, condition, value } = this.importCondition

    const headerIndex =
      subTable === 'name'
        ? 0
        : // @ts-ignore
          headers.findIndex(h => h.header === subTable) + 2

    switch (condition) {
      case this.$i18n.t('filter.operators.contains') as string:
        rows.forEach((row, index) => {
          {
            if (row[headerIndex].includes(value)) {
              indexesSet[indexesSet.length - 1].add(index)
            }
          }
        })
        break
      case this.$t('filter.operators.doesNotContain') as string:
        rows.forEach((row, index) => {
          if (!row[headerIndex].includes(value)) {
            indexesSet[indexesSet.length - 1].add(index)
          }
        })
        break
      case this.$i18n.t('filter.operators.is') as string:
        rows.forEach((row, index) => {
          if (row[headerIndex] === value) {
            indexesSet[indexesSet.length - 1].add(index)
          }
        })
        break
      case this.$i18n.t('filter.operators.isNot') as string:
        rows.forEach((row, index) => {
          if (row[headerIndex] !== value) {
            indexesSet[indexesSet.length - 1].add(index)
          }
        })
        break
      case this.$i18n.t('filter.operators.isEmpty') as string:
        rows.forEach((row, index) => {
          if (row[headerIndex] === '') {
            indexesSet[indexesSet.length - 1].add(index)
          }
        })
        break
      case this.$i18n.t('filter.operators.isNotEmpty') as string:
        rows.forEach((row, index) => {
          if (row[headerIndex] !== '') {
            indexesSet[indexesSet.length - 1].add(index)
          }
        })
        break
    }

    const defIndexesArr = indexesSet.map(iSet => Array.from(iSet))

    const defIndexesRaw = []

    if (defIndexesArr[0].length) {
      defIndexesArr[0].forEach(index => {
        if (defIndexesArr.every(i => i.includes(index))) {
          defIndexesRaw.push(index)
        }
      })
    }

    Store.setFilterList(defIndexesRaw)

    this.importConditionList.push(this.importCondition)
  }

  private saveImportTable() {
    Store.setActiveHeaders(this.preImportHeaders)
    Store.setBimtableRows(this.bimtableRows)
    Store.setActiveRows(this.preImportRows)

    this.$root.$emit('close-modal-import')
  }

  @Watch('importConditionList')
  onImportConditionListChage() {
    if (this.importConditionList.length) {
      this.showConfirmImport = true
    }
  }

  @Emit()
  close() {
    this.$root.$emit('enable-import', false)
  }
}
