
import { Vue, Component, Watch } from 'vue-property-decorator'
import Sidebar from '@/components/Dashboard/Sidebar/Sidebar.vue'
import Assets from '@/components/Dashboard/Assets/Assets.vue'
import Analytics from '@/components/Dashboard/Analytics/Analytics.vue'
import Store from '@/store/modules/Store'
import Datasets from '@/components/Dashboard/Datasets/Datasets.vue'
import ConfirmCreateHub from '@/components/Dashboard/Sidebar/ConfirmCreateHub.vue'
import { Hub, Asset } from '@/store/Models'
import ChangeHubPlanModal from '@/components/Dashboard/Assets/Modals/ChangeHubPlan.vue'
import SharedPending from '@/components/Dashboard/Assets/Modals/SharedPending.vue'
import Navbar from '@/components/Dashboard/Navbar/Navbar.vue'
import Mapbox from '@/components/Dashboard/Assets/Mapbox.vue'
import AssetActions from '@/components/Dashboard/Assets/AssetActions.vue'
import AssetSubactions from '@/components/Dashboard/Assets/AssetSubactions.vue'
import IconMessage from '@/assets/icons/message.svg'
import Suggestion from '@/components/Dashboard/Suggestion.vue'

@Component({
  components: {
    Suggestion,
    AssetActions,
    AssetSubactions,
    Navbar,
    SharedPending,
    ChangeHubPlanModal,
    Mapbox,
    Sidebar,
    Assets,
    Analytics,
    Datasets,
    ConfirmCreateHub,
    IconMessage
  }
})
export default class Dashboard extends Vue {
  private active: string = ''
  private showConfirmModal: boolean = false
  private hubToChangePlan: Hub = null
  private assetIdPendingToAprove: string = ''
  private isCollapsable: boolean = true
  private fromWeb: boolean = false
  private collapse: boolean = false
  private showSuggestion: boolean = false

  private get hub(): any {
    return Store.hub
  }

  private get collapsable(): boolean {
    return this.isCollapsable
  }

  private get assets(): Asset[] {
    return Store.assets
  }

  beforeMount() {
    if (Store.invitation && Store.user.id === Store.invitation.userId) {
      this.assetIdPendingToAprove = Store.invitation.assetId
    }

    this.fromWeb =
      window.parent.origin === 'http://localhost:8080' ||
      window.parent.origin === 'https://bimtable.app' ||
      window.parent.origin === 'https://bimtable-beta-env.web.app'
  }

  mounted() {
    this.checkLocalStorage()

    this.$root.$on('show-model-create-hub', () => {
      this.showConfirmModal = true
    })
  }

  private createHub() {
    try {
      this.$apollo.mutate({
        mutation: require('@/graphql/mutations/create-hub.graphql')
      })

      this.$toasted.success(this.$i18n.t('success.createHub') as string)
      this.$root.$emit('refresh-hubs-last')
    } catch (error) {
      this.$toasted.error(this.$i18n.t('errors.createHub') as string)
    } finally {
      this.showConfirmModal = false
    }
  }

  private changePlan(hub: Hub) {
    this.hubToChangePlan = hub
  }

  private async updatePricingPlan({ hubId, pricingPlan }) {
    try {
      const {
        data: { updatePricingPlan }
      } = await this.$apollo.mutate({
        variables: { hubId, pricingPlan },
        mutation: require('@/graphql/mutations/update-pricing-plan.graphql')
      })

      if (updatePricingPlan) {
        this.$toasted.success(this.$i18n.t('success.updatedPlan') as string)
        this.hubToChangePlan = null
      }
    } catch {
      this.$toasted.error(this.$i18n.t('errors.updatePricing') as string)
    }
  }

  // todo: duplicated - bring to use case file
  private async acceptParticipate() {
    try {
      const assetId = this.assetIdPendingToAprove
      const memberId = Store.invitation.memberId
      const tempId = Store.invitation.tempId

      const {
        data: { acceptParticipate }
      } = await this.$apollo.mutate({
        variables: { assetId, memberId, tempId },
        mutation: require('@/graphql/mutations/accept-participate.graphql')
      })

      this.assetIdPendingToAprove = ''

      if (acceptParticipate) {
        this.$toasted.success(this.$i18n.t('success.shared') as string)
        this.$root.$emit('refresh-shared-assets')
      }
    } catch {
      this.$toasted.error(this.$i18n.t('errors.shared') as string)
    }
  }

  private resetActive() {
    this.active = ''
    this.isCollapsable = true
  }

  private switchTabs({ title }) {
    switch (title) {
      case 'Hubs':
        this.active = ''
        Store.setHub(Store.lastHubSelected)
        break
      case this.$i18n.t('analyticsNavbar') as string:
        Store.setLastHubSelected(Store.hub)
        Store.setHub(null)
        this.active = title
        break
    }
  }

  private checkLocalStorage() {
    const initialConfig = JSON.parse(
      localStorage.getItem(process.env.VUE_APP_CONFIG_COOKIE)
    )

    // Reset config localStorage,****Delete
    // localStorage.removeItem(process.env.VUE_APP_CONFIG_COOKIE)

    if (initialConfig === null) {
      const config = {
        assetsList: 0,
        analyticsList: 0,
        mapExpanded: 0
      }

      localStorage.setItem(
        process.env.VUE_APP_CONFIG_COOKIE,
        JSON.stringify(config)
      )
    } else {
      let status: boolean
      const mode = initialConfig.assetsList

      if (mode === 0) {
        status = false
      } else {
        status = true
      }

      setTimeout(() => {
        this.$root.$emit('send-change-view-mode', status)
      }, 500)

      //Check if the setting should be applied to the expansion of the map
    }
  }

  @Watch('active')
  onActiveChange() {
    if (this.active !== '') {
      Store.setHub(null)

      if (this.active === (this.$i18n.t('analytics') as string)) {
        this.isCollapsable = false
      }
    }
  }

  @Watch('hub')
  onHubChange() {
    if (this.hub !== null) {
      this.active = ''
      this.isCollapsable = true
    }
  }
}
