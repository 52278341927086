
import { Vue, Component, Watch, Emit, Prop } from 'vue-property-decorator'
import IconErase from '@/assets/icons/trash.svg'
import IconSpinner from '@/assets/icons/spinner-solid.svg'
import { directive as onClickaway } from 'vue-clickaway'
import IconCancel from '@/assets/icons/x.svg'
import IconCheck from '@/assets/icons/check.svg'

@Component({
  components: { IconCancel, IconCheck, IconErase, IconSpinner },
  directives: { onClickaway }
})
export default class CumulioDashboardList extends Vue {
  @Prop() dashboard!: any
  @Prop() ssoToken!: { id: string; token: string }

  private showDelete: boolean = false
  private idToErase: string = ''
  private imgSrc: string = ''
  private clickedToDelete: boolean = false

  mounted() {
    this.imgSrc = `https://app.cumul.io/thumbnail/securable/256px/${this.dashboard.id}.png?key=${this.ssoToken.id}&token=${this.ssoToken.token}`
  }
  private handleDelete() {
    this.clickedToDelete = true

    setTimeout(() => this.deleteDashboard(), 500)
  }

  private closeDelete() {
    this.idToErase = ''
  }

  private checkIdToErase(status: boolean) {
    if (this.dashboard.id !== this.idToErase && status) {
      this.showDelete = true
    } else {
      this.showDelete = false
    }
  }

  private setIdToErase() {
    this.idToErase = this.dashboard.id
    this.showDelete = false
  }

  @Watch('showDelete')
  onshowDeleteChange() {
    if (this.showDelete) {
      this.clickedToDelete = false
    }
  }

  @Emit()
  dashboardDetail(id: string): string {
    return id
  }

  @Emit() deleteDashboard() {
    this.clickedToDelete = false
  }
}
