import { SdkManager, SdkManagerBuilder } from '@aps_sdk/autodesk-sdkmanager'
import { AuthenticationClient, Scopes } from '@aps_sdk/authentication'

const sdkmanager: SdkManager = SdkManagerBuilder.create().build()
const authenticationClient = new AuthenticationClient(sdkmanager)

export const twoLeggedViewerInitializer = {
  env: 'AutodeskProduction2',
  api: 'streamingV2',
  useConsolidation: true,
  consolidationMemoryLimit: 150 * 1024 * 1024,
  getAccessToken: async tokenReady => {
    const token = await getTwoLeggedToken()
    tokenReady(token, 3600)
  }
}

export const getTwoLeggedToken = async () => {
  try {
    const { access_token } = await authenticationClient.getTwoLeggedToken(
      process.env.VUE_APP_FORGE_CLIENT_ID,
      process.env.VUE_APP_FORGE_SECRET,
      [
        Scopes.ViewablesRead,
        Scopes.AccountRead,
        Scopes.AccountWrite,
        Scopes.BucketCreate,
        Scopes.BucketRead,
        Scopes.BucketDelete,
        Scopes.BucketUpdate,
        Scopes.CodeAll
      ]
    )

    return access_token
  } catch (error) {
    console.error(error)
  }
}
