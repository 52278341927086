
import { Vue, Component, Emit } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'
import Button from '@/components/shared/Button.vue'
import IconCopy from '@/assets/icons/clipboard.svg'
import Dropdown from '@/components/shared/Dropdown.vue'
import Store from '@/store/modules/Store'

@Component({
  components: { Dropdown, Button, IconCopy },
  directives: { onClickaway }
})
export default class IntegrationModal extends Vue {
  private hash: string = ''
  private apiKey: string = ''

  async beforeMount() {
    if (!this.integrationNames.length) {
      const {
        data: { integrationNames }
      } = await this.$apollo.query({
        query: require('@/graphql/queries/integration-names.graphql')
      })

      if (integrationNames) {
        Store.setIntegrationNames(integrationNames)
      }
    }
  }

  private get integrationNames(): any[] {
    return Store.integrationNames
  }

  private get assetHash(): string {
    return this.hash
      ? this.hash
      : (this.$i18n.t('pleaseGenerateHash') as string)
  }

  private getIframe() {
    const url = `${process.env.VUE_APP_BASE_URL}/i/${this.apiKey}/${this.assetHash}`
    return `<iframe \n  src="${url}"\n  height="920px"\n  width="1400px"\n/>`
  }

  private async hashGenerate() {
    try {
      const apiKey = this.apiKey
      const assetId = Store.assetForViewer.id

      const {
        data: { hashGenerate }
      } = await this.$apollo.mutate({
        variables: { apiKey, assetId },
        mutation: require('@/graphql/mutations/hash-generate.graphql')
      })

      if (hashGenerate) {
        this.hash = hashGenerate
      }
    } catch (error) {
      console.error('error', error)
    }
  }

  private copyIframe() {
    navigator.clipboard.writeText(this.getIframe())
    this.$toasted.success(this.$i18n.t('success.copy') as string)
  }

  private async handleSelection(selection: string) {
    const integration = this.integrationNames.find(i => i.name === selection)

    if (integration) {
      const assetId = Store.assetForViewer.id
      const integrationId = integration.id
      const apiKey = integration.apiKey
      this.apiKey = apiKey

      const {
        data: { checkInstance }
      } = await this.$apollo.query({
        variables: { assetId, integrationId, apiKey },
        query: require('@/graphql/queries/check-instance.graphql')
      })

      if (checkInstance) {
        this.hash = checkInstance

        this.$toasted.success(this.$i18n.t('success.hashRetrieved') as string)
      }
    }
  }

  @Emit() close() {}
}
