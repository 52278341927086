import i18n from '../../i18n'
import IconAnalytics from '../../assets/icons/chart-pie.svg'
import IconHubs from '../../assets/icons/tags.svg'

export default [
  {
    title: 'Hubs',
    description: i18n.t('sidebar.descriptions.hubs') as string,
    icon: IconHubs,
    active: true
  },
  {
    title: i18n.t('analytics') as string,
    description: i18n.t('sidebar.descriptions.analytics') as string,
    icon: IconAnalytics,
    active: false
  }
]
