import Store from '@/store/modules/Store'
import addImageContent from '@/helpers/add-image-content'
import startIframeContent from '@/helpers/start-iframe-content'
import closeIframeContent from '@/helpers/close-iframe-content'

export default async () => {
  const likes = Store.urlLikes

  const iframe = document.createElement('iframe')

  document.body.appendChild(iframe)

  let iframeContent = startIframeContent

  const objectsURL = []

  const imagePromises = likes.map(async url => {
    const response = await fetch(url)

    if (!response.ok) {
      console.error(`HTTP error! Status: ${response.status}`)
      return
    }

    const blob = await response.blob()

    const objectURL = URL.createObjectURL(blob)

    objectsURL.push(objectURL)

    const img = document.createElement('img')

    img.src = objectURL

    return new Promise<void>(resolve => {
      img.onload = () => {
        iframeContent += addImageContent(objectURL)
        resolve()
      }

      img.onerror = err => {
        console.error('Image failed to load:', err)
        resolve()
      }
    })
  })

  await Promise.all(imagePromises)

  iframeContent += closeIframeContent

  iframe.contentDocument.open()

  iframe.contentDocument.write(iframeContent)

  iframe.contentDocument.close()

  iframe.onload = () => {
    iframe.contentWindow.focus()

    iframe.contentWindow.print()

    document.body.removeChild(iframe)

    for (const objectURL of objectsURL) {
      URL.revokeObjectURL(objectURL)
    }
  }
}
