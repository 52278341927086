
import { Vue, Component } from 'vue-property-decorator'
import Sidebar from '@/components/Dashboard/Sidebar/Sidebar.vue'

@Component({
  apollo: {
    datasetDetail: {
      query: require('@/graphql/queries/dataset-detail.graphql')
    }
  },
  components: { Sidebar }
})
export default class DatasetDetail extends Vue {
  private datasetDetail = null

  async created() {
    const datasetId = this.$route.params.id

    const {
      data: { datasetDetail }
    } = await this.$apollo.queries.datasetDetail.refetch({ datasetId })

    this.datasetDetail = JSON.parse(datasetDetail)
  }
}
