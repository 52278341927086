export default (event: Event) => {
  const maxFileSize = 1933244600
  const target = event.target as HTMLInputElement
  const file: File = target.files[0] as File

  if (file.size > maxFileSize) {
    return null
  }

  if (target.files && target.files.length) {
    return file
  }

  return null
}
