
import { Component, Vue, Watch } from 'vue-property-decorator'
import Store from '@/store/modules/Store'
import Sidebar from '@/components/Dashboard/Sidebar/Sidebar.vue'
import { Asset, Folder, ViewerModel } from '@/store/Models'
import ModelsList from '@/components/Viewer/ModelsList.vue'
import initViewer from '@/controllers/forgeViewer/initViewer'
import loadModels from '@/controllers/forgeViewer/loadModels'
import makeUrn from '@/helpers/makeUrn'
import urlModelDerivatives from '@/config/urlModelDerivatives'
import { getTwoLeggedToken } from '@/helpers/twoLeggedViewerInitializer'
import axios from 'axios'
import LoadingBar from '@/components/Viewer/LoadingBar.vue'
import BimtableButtons from '@/components/Viewer/BimtableButtons.vue'
import ViewerSidebar from '@/components/Viewer/ViewerSidebar.vue'
import translateColor from '@/helpers/translateColor'
import getViews from '@/controllers/forgeViewer/getViews'
import ViewsSelection from '@/components/Viewer/modals/ViewsSelection.vue'
import getCategoriesProperties from '@/controllers/forgeViewer/getCategoriesProperties'
import UploadModel from '@/components/Viewer/modals/UploadModel.vue'
import CumulioPanel from '@/components/Viewer/CumulioPanel.vue'
import IconEye from '@/assets/icons/eye.svg'
import ConfirmUpdate from '@/components/Viewer/modals/ConfirmUpdate.vue'
import createCustomNavbar from '@/controllers/forgeViewer/createCustomNavbar'
import IconSpinner from '@/assets/icons/spinner-solid.svg'
import OverWriteWarning from '@/components/Viewer/modals/OverWriteWarning.vue'
import roomMaterial from '@/controllers/forgeViewer/roomMaterial'
import TableData from '@/components/Viewer/TableData/TableData.vue'
import ConnectionModal from '@/components/Viewer/modals/ConnectionModal.vue'
import CategoriesSelection from '@/components/Viewer/modals/CategoriesSelection.vue'
import ViewerNavbar from '@/components/Viewer/ViewerNavbar.vue'
import translateColorVector from '@/helpers/translateColorVector'
import Logo from '@/components/shared/Logo.vue'
import translateColorCumulio from '@/helpers/translateColorCumulio'
import RowModal from '@/components/Viewer/TableData/RowModal.vue'
import IntegrationModal from '@/components/Viewer/modals/IframeViewerModal.vue'
import SelectionData from '@/components/Viewer/SelectionData/SelectionData.vue'
import getSelectionsData from '@/controllers/dataManagement/getSelectionsData'
import setSelections from '@/controllers/dataManagement/setSelections'
import Modal360 from '@/components/Viewer/modals/Modal360.vue'
import IconGallery from '@/assets/icons/gallery.svg'
import printLikes from '@/controllers/forgeViewer/print-likes'
import LikeGallery from '@/components/Viewer/LikeGallery.vue'
import { v4 as uuidv4 } from 'uuid'
// import {
//   CSS2DObject,
//   CSS2DRenderer
// } from '@/controllers/forgeViewer/css2DRenderer'

// @ts-ignore
const THREE = window.THREE

@Component({
  components: {
    LikeGallery,
    Modal360,
    SelectionData,
    IntegrationModal,
    Logo,
    ViewerNavbar,
    CategoriesSelection,
    TableData,
    OverWriteWarning,
    CumulioPanel,
    UploadModel,
    ViewsSelection,
    ViewerSidebar,
    ModelsList,
    Sidebar,
    LoadingBar,
    BimtableButtons,
    IconEye,
    ConfirmUpdate,
    IconSpinner,
    ConnectionModal,
    RowModal,
    IconGallery
  }
})
export default class Viewer extends Vue {
  private models: ViewerModel[] = []
  // @ts-ignore
  private viewer!: Autodesk.Viewing.GuiViewer3D
  private progress: string = ''
  private rate: string = ''
  private isUploading: boolean = false
  private isLoadingModels: boolean = true
  private fromWeb: boolean = null
  private views: any[] = []
  private clickedAtTable: boolean = false
  private isSelectedFromBimtable: boolean = false
  private previousIdList: number[] = []
  private attempts: number = 0
  private resizing: boolean = false
  private position: number = 0
  private showConfirmUpdateDataModal: boolean = false
  private analyticsSync: boolean = false
  private rooms: any[] = []
  private hasMetadata: boolean = false
  private selectionDone: boolean = true
  private showConnection: boolean = false
  private activeTableName: string = ''
  private selectedView: string = ''
  private loadingQueue: boolean = false
  private loadedModelsCounter: number = 0
  private selectionCumulioMode: string = 'color'
  private colorInactive: boolean = false
  private showRowModal: boolean = false
  private dataSetModalRow: any = null
  private isFirstLoad: boolean = true
  private show360Modal = false
  private url360: string = ''
  private showLikeGallery: boolean = false
  private allowViewer: boolean = false
  // private innerSelection: boolean = false
  // private outerDbId: any = -1
  // private viewport: any = {}

  private wireframeModelsMaterials: {
    [p: string]: {
      [K: string]: { material: any }
    }
  } = {}

  private categoriesPropertiesData: {
    categories: any
    properties: any
    geometriesAmount: number[]
    headersUnits: [{ header: string; unit?: string }[]]
  } = null

  private show = {
    views: false,
    cumulioDashboards: false,
    modelsList: false,
    uploadModal: false,
    integrationModal: false
  }

  private upload: {
    objectId: string
    objectKey: string
    assetId: string
    guid: string
  } = null

  // eslint-disable-next-line no-undef
  private statusInterval: any = null

  private get asset(): Asset {
    return Store.assetForViewer
  }

  private get modelFromAutodeskBrowser(): Folder {
    return Store.modelFromAutodeskBrowser
  }

  private get showOverlay(): boolean {
    return Store.showOverlay
  }

  beforeCreate() {
    localStorage.removeItem('cumulio-token')
  }

  created() {
    if (!this.asset) {
      this.$router.replace({ name: 'Dashboard' })
    }

    this.checkIsOwner()
  }

  async beforeMount() {
    this.fromWeb =
      window.parent.origin === 'http://localhost:8080' ||
      window.parent.origin === 'https://bimtable.app' ||
      window.parent.origin === 'https://bimtable-beta-env.web.app'

    setTimeout(() => {
      if (!this.allowViewer) this.allowViewer = true
    }, 15 * 1000)
  }

  async mounted() {
    this.hearWindowEvents()
    this.hearEvents()

    const container = this.$refs.viewer as HTMLElement
    this.viewer = await initViewer(container)

    await this.getModels()
    await this.modelsHaveMetadata()
  }

  private async checkIsOwner() {
    if (Store.hub.id) {
      const hubId = Store.hub.id
      const assetId = this.asset.id

      const {
        data: { userIsOwner }
      } = await this.$apollo.query({
        variables: { hubId, assetId },
        query: require('@/graphql/queries/user-is-owner.graphql')
      })

      Store.setIsOwner(userIsOwner)
    }
  }

  private async getModels() {
    if (!this.asset) return

    this.isLoadingModels = true
    const bucketKey = this.asset.bucketKey

    try {
      const {
        data: { getModels }
      } = await this.$apollo.query({
        variables: { bucketKey },
        query: require('@/graphql/queries/get-models.graphql')
      })

      this.models =
        getModels.length > 0
          ? [...getModels, ...this.asset.threeLeggedModels]
          : this.asset.threeLeggedModels

      if (this.models.length) {
        this.models = this.models.map(model => {
          let color = ''
          let active = true
          let lock = false

          const modelConfig = this.asset.configs.find(
            m => m.modelName === model.objectKey
          )

          if (modelConfig) {
            color = modelConfig.color
            active = modelConfig.active
            lock = modelConfig.lock
          }

          return {
            ...model,
            threeLegged:
              this.asset.threeLeggedModels.findIndex(
                m => m.objectId === model.objectId
              ) !== -1,
            isMain: false,
            showColorPicker: false,
            active,
            color,
            lock
          }
        })

        if (this.models.length && !this.isUploading) {
          await this.loadModels()
        }
      } else {
        this.show.modelsList = true
        this.$root.$emit('active-models-list')
      }
    } catch (error) {
      console.error('error-getting models', error)
    } finally {
      this.isLoadingModels = false
    }
  }

  private hearEvents() {
    this.$root.$on('refresh-models', () => {
      this.isLoadingModels = true
      this.models = []
      if (this.viewer) this.viewer.tearDown(true)
      this.getModels()
      this.isLoadingModels = false
    })

    this.$root.$on('resize', (position, resizing) => {
      this.resizing = resizing
      this.position = position
    })

    this.$root.$on(
      'pick-geometries',
      (selection: { viewerIds: number[]; objectId: string }) => {
        this.pickGeometries(selection)
      }
    )

    this.$root.$on('loading-update', (loading: boolean) => {
      this.loadingQueue = loading
    })

    this.$root.$on('custom-select-case', selectedIds => {
      const model = this.viewer.getVisibleModels()[1]

      selectedIds.forEach(id => {
        this.viewer.select(parseInt(id), model)
      })
    })

    this.$root.$on('isolate-items', ids => {
      if (this.viewer) {
        const index = this.models.findIndex(model => model.isMain)

        const models = this.viewer.getAllModels()

        this.viewer.isolate(ids, models[index])
      }
    })

    /** Changes color of selection */
    this.$root.$on('set-selection-color', color => {
      this.viewer.setSelectionColor(translateColor(color), 1)
    })

    this.$root.$on(
      'color-all-models',
      async (groups: { model: string; color: string }[]) => {
        if (this.viewer) {
          const models = this.viewer.getVisibleModels()

          for (const group of groups) {
            const model = models.find(
              m =>
                m
                  .getData('text/plain')
                  .loadOptions.bubbleNode.getRootNode()
                  .children[0].name() === group.model
            )

            if (model) {
              const tree = model.getInstanceTree()

              // @ts-ignore
              model.unconsolidate()

              if (tree) {
                tree.enumNodeChildren(tree.getRootId(), id => {
                  this.viewer.setThemingColor(
                    id,
                    translateColorCumulio(group.color),
                    model,
                    true
                  )
                })
              }

              this.viewer.fitToView()
            }
          }
        }
      }
    )

    this.$root.$on(
      'show-row-modal',
      (data: {
        index: number
        status: boolean
        tableName: string
        isGroups: boolean
      }) => {
        this.setRowModal(data)
      }
    )

    this.$root.$on(
      'show-row-modal-groups',
      (data: {
        index: number
        indexRow: number
        status: boolean
        isGroups: boolean
      }) => {
        this.setRowModalGroups(data)
      }
    )

    this.$root.$on('show-views', ({ objectId }) => {
      this.showViewsAlreadyUploadedModel(objectId)
    })

    this.$root.$on('look-at-object', (modelName, viewerId) => {
      if (!this.models.length) return

      const bimtableModel = this.models.find(m => m.objectKey === modelName)

      if (!bimtableModel) return

      const { objectId } = bimtableModel

      const models = this.viewer.getVisibleModels()

      const model = models.find(
        m => m.getData('text/plain').urn === makeUrn(objectId)
      )

      if (!model) return

      this.viewer.fitToView([viewerId], model, true)
    })

    this.$root.$on('show-360-modal', url => {
      this.show360ModalHandle(url)
    })

    this.$root.$on('overlay', () => {
      this.setOverlay()
    })

    window.addEventListener('keydown', function (e) {
      if (e.code == 'Escape') {
        return
      }
    })

    document.addEventListener('increase-loading-model', () => {
      this.loadedModelsCounter++
    })

    document.addEventListener('show-selection-error', () => {
      this.$toasted.error(this.$i18n.t('selectionError') as string)
    })

    this.$root.$on('restore-model-color', async (model: any) => {
      if (this.viewer) {
        const models = this.viewer.getVisibleModels()

        const mod = models.find(m => {
          return m.getData('text/plain').urn === makeUrn(model.objectId)
        })

        if (mod) {
          const modelId = `${mod.id}`

          if (this.wireframeModelsMaterials[modelId]) {
            mod.unconsolidate()

            const fragsList = mod.getFragmentList()

            for (const fragId in this.wireframeModelsMaterials[modelId]) {
              const material =
                this.wireframeModelsMaterials[modelId][fragId].material

              if (material) {
                this.viewer.impl.matman().addMaterial(uuidv4(), material, true)

                fragsList.setMaterial(parseInt(fragId), material)
              }
            }
          }
        }
      }
    })
  }

  private async modelsHaveMetadata() {
    const modelsIdList = this.models.length
      ? this.models.map(model => {
          return {
            objectKey: model.objectKey,
            objectId: model.objectId
          }
        })
      : []

    const assetId = this.asset.id

    const {
      data: { modelsHaveMetadata }
    } = await this.$apollo.query({
      variables: { modelsIdList, assetId },
      query: require('@/graphql/queries/models-have-metadata.graphql')
    })

    if (this.models.length && modelsHaveMetadata) {
      this.modelsAddMetadata(modelsHaveMetadata)

      const objectIdList = modelsHaveMetadata.map((mod: any) => mod.objectId)

      for (const mod of this.models) {
        if (objectIdList.includes(mod.objectId)) {
          mod.active = true
        } else {
          mod.lock = true
          mod.active = false
        }
      }
    }

    this.isLoadingModels = false

    return true
  }

  private pickGeometries({ viewerIds, objectId }) {
    if (this.viewer) {
      this.clickedAtTable = true
      Store.setSelectedGeometries([viewerIds[0].toString()])

      const models = this.viewer.getVisibleModels()

      const model = models.find(
        m => m.getData('text/plain').urn === makeUrn(objectId)
      )

      if (Store.fitGeometries) this.viewer.fitToView(viewerIds, model, true)
      if (Store.isolation) this.viewer.isolate(viewerIds, model)

      if (models.length === 1) {
        // todo: método anterior...
        // this.viewer.select(this.viewer.model.reverseMapDbId(viewerIds[0]))
        this.viewer.select(viewerIds[0])
      } else {
        this.viewer.impl.selector.setSelection(viewerIds, model)
      }
    }
  }

  private hearWindowEvents() {
    window.addEventListener(
      'message',
      ({ data }) => {
        this.switchMessages(data)
      },
      {
        once: false,
        passive: true
      }
    )
  }

  private switchMessages(data) {
    const { type } = data

    if (type === 'airtable-colors') {
      if (data.colors.length) {
        this.handleBimtableColors(data.colors)
      } else {
        this.$toasted.error(this.$i18n.t('errors.colorize') as string)
      }
    } else if (type === 'bimtable-select' && !this.listsAreEqual(data.idList)) {
      this.bimtableSelect(data.idList)
    } else if (type === 'bimtable-update') {
      if (Store.isOwner) {
        this.handleBimtableUpdate(data.update)
      }
    } else if (type === 'kronos-selection') {
      this.viewer.select(data.viewerIds)
    }
  }

  private async handleBimtableColors(colors) {
    const items = []

    colors.forEach(group => {
      const { viewerId, color } = group

      items.push({
        objectid: parseInt(viewerId),
        color: new THREE.Vector4(color.r / 255, color.g / 255, color.b / 255, 1)
      })
    })

    const models = this.viewer.getAllModels()

    if (models.length === 1) {
      this.viewer.hideAll()

      for (const item of items) {
        this.viewer.show(item.objectid)
        this.viewer.model.setThemingColor(item.objectid, item.color)
        this.viewer.fitToView()
      }
    } else {
      const index = this.models.findIndex(model => model.isMain)

      if (index !== -1) {
        const model = models[index]
        // @ts-ignore
        model.unconsolidate()

        // @ts-ignore
        this.viewer.impl.visibilityManager.isolate(-1, model)
        this.viewer.clearThemingColors(model)

        for (const item of items) {
          // @ts-ignore
          this.viewer.impl.visibilityManager.show(item.objectid, model)
          this.viewer.setThemingColor(item.objectid, item.color, model)
        }
      } else {
        this.$toasted.error(this.$i18n.t('errors.selectModel') as string)
      }
    }

    this.viewer.impl.invalidate(true)
  }

  private bimtableSelect(idList: number[]) {
    this.isSelectedFromBimtable = true

    if (this.activeModels.length === 1) {
      this.viewer.select(idList, this.viewer.model)

      if (idList.length) {
        this.viewer.fitToView(idList, this.viewer.model)
      }
    } else if (this.activeModels.length > 1 && this.viewer) {
      const index = this.models.findIndex(model => model.isMain)
      const models = this.viewer.getVisibleModels()

      if (index !== -1) {
        this.viewer.impl.selector.setSelection(idList, models[index])
        this.viewer.fitToView(idList, models[index])
      } else {
        this.$toasted.error(this.$i18n.t('errors.selectModel') as string)
      }
    }

    this.previousIdList = idList
    setTimeout(() => (this.isSelectedFromBimtable = false), 100)
  }

  private async handleBimtableUpdate(data) {
    try {
      const { cellValue, externalId, field, tableName } = data

      let update

      if (cellValue == null) {
        update = ''
      } else if (typeof cellValue === 'object') {
        let attachments = []

        cellValue.forEach(val => {
          if (
            val['size'] !== undefined &&
            val['thumbnails'] !== undefined &&
            val['url'] !== undefined
          ) {
            attachments.push({
              url: val['url'],
              thumbnail: val['thumbnails']['small']
            })
          }
        })

        update = JSON.stringify(attachments)
      } else if (typeof cellValue === 'string') {
        update = cellValue
      }

      const model = this.models.find(model => model.isMain)

      if (model) {
        await this.$apollo.mutate({
          variables: {
            modelObjectId: model.objectId,
            externalId,
            header: field,
            update,
            tableName
          },
          mutation: require('@/graphql/queries/update-model-data.graphql')
        })

        this.$toasted.success(this.$i18n.t('modelDataUpdated') as string)
      } else {
        this.$toasted.error(this.$i18n.t('errors.selectModel') as string)
      }
    } catch {
      console.error('error')
    }
  }

  private listsAreEqual(idList: number[]): boolean {
    let list1, list2

    list1 = idList.filter(id => {
      return this.previousIdList.indexOf(id) < 0
    })

    list2 = this.previousIdList.filter(id => {
      return idList.indexOf(id) < 0
    })

    return list1 && list2 && !list1.length && !list2.length
  }

  private modelsAddMetadata(
    modelsWithMetadata: [{ objectId: string; metadata: boolean }]
  ) {
    if (modelsWithMetadata) {
      for (const model of modelsWithMetadata) {
        const index = this.models.findIndex(m => m.objectId === model.objectId)
        this.models[index].metadata = true
      }

      if (!modelsWithMetadata.length) {
        this.models = this.models.map(m => {
          return {
            ...m,
            metadata: null
          }
        })
      }
    }

    this.isLoadingModels = false
  }

  private get file(): File {
    return Store.file
  }

  private async uploadModel(file: File | null) {
    this.$root.$emit('uploading-model')

    if (file) {
      const fileLength = file.size
      const assetTitle = this.asset.title

      this.isUploading = true

      try {
        const {
          data: { uploadFile }
        } = await this.$apollo.mutate({
          variables: {
            file,
            fileLength,
            assetTitle
          },
          mutation: require('@/graphql/mutations/upload-file.graphql')
        })

        const { objectId, objectKey, bucketKey } = uploadFile

        Store.refreshAssetBucketKey(bucketKey)

        const assetId = this.asset.id

        this.upload = {
          objectId,
          objectKey,
          assetId,
          guid: ''
        }

        await this.translationStatus()
      } catch (error) {
        this.$toasted.error(this.$i18n.t('errors.upload') as string)
        this.isUploading = false
      }
    }
  }

  private clearUpload() {
    this.isUploading = false
    this.upload = null
  }

  private async translationStatus() {
    const urn = makeUrn(this.upload.objectId)

    try {
      const access_token = await getTwoLeggedToken()

      console.log('⭐access_token⭐', access_token)

      this.statusInterval = setInterval(async () => {
        const {
          data: { progress }
        } = await axios.get(`${urlModelDerivatives}${urn}/manifest`, {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        })

        this.setProgressData(progress)
      }, 10 * 1000)
    } catch {
      this.$toasted.error(this.$i18n.t('errors.translate') as string)
      this.clearUpload()
    }
  }

  private setProgressData(progress) {
    this.progress =
      progress === '0% complete'
        ? (this.$i18n.t('inQueue') as string)
        : progress === '99% complete'
        ? (this.$i18n.t('closingModel') as string)
        : progress

    const numbers = progress.match(/\d/g)
    if (numbers) this.rate = numbers.join('')
  }

  private handleModelsToRender(objectId: string) {
    const index = this.models.findIndex(model => model.objectId === objectId)
    this.models[index].active = !this.models[index].active
  }

  private async checkAction(index: number | null) {
    switch (index) {
      case 0:
        this.selectionDone = false

        Store.setActiveRows([])
        Store.setActiveHeaders([])
        Store.setAnalyticsSync(false)
        this.analyticsSync = false

        this.models.forEach(model => (model.isMain = false))

        await this.$router.replace({ name: 'Home' })
        break
      case 1:
        this.show.modelsList = true
        this.show.cumulioDashboards = false
        break
      case 2:
        this.show.cumulioDashboards = true
        this.show.modelsList = false
        break
      case 3:
        this.show.integrationModal = true
        this.show.cumulioDashboards = false
        this.show.modelsList = false
        this.$root.$emit('reset-viewer-sidebar')
        break
      case null:
        this.show.cumulioDashboards = false
        this.show.modelsList = false
        break
    }
  }

  private colorModels() {
    if (this.viewer) {
      const models = this.viewer.getVisibleModels()

      if (this.models.length) {
        for (const model of this.models) {
          const mod = models.find(m => {
            return m.getData('text/plain').urn === makeUrn(model.objectId)
          })

          if (model.color !== 'wireframe') {
            this.colorModel({ model, color: model.color })
          } else if (model.color === 'wireframe') {
            if (mod) this.translucentModel(mod)
          }

          if (model.lock) {
            // this.viewer.lock(mod, true)
          }
        }

        this.models.forEach(model => {
          if (!model.active) {
            const mod = models.find(
              m => m.getData('text/plain').urn === makeUrn(model.objectId)
            )

            this.viewer.hide(1, mod)
          }
        })
      }
    }

    this.isFirstLoad = false
  }

  private colorModel(data: { model: ViewerModel; color: string }) {
    if (data) {
      const {
        color,
        model: { objectId }
      } = data

      const models = this.viewer.getAllModels()

      const model = models.find(
        m => m.getData('text/plain').urn === makeUrn(objectId)
      )

      if (model && color === '') {
        this.viewer.clearThemingColors(model)
      } else if (model && color !== 'wireframe') {
        const tree = model.getInstanceTree()

        if (tree) {
          tree.enumNodeChildren(tree.getRootId(), id => {
            this.viewer.setThemingColor(
              id,
              translateColorVector(
                color.replace('bg-', '').replace('-300', '')
              ),
              model,
              true
            )
          })
        }
      } else if (model && color === 'wireframe') {
        this.translucentModel(model)
      }
    }
  }

  private handleBimtableAction(action: string) {
    switch (action) {
      case 'metadata':
        this.sendMetadataToAirtable()
        break
      case 'colorize':
        window.parent.postMessage({ type: 'colors-from-airtable' }, '*')
        break
      case 'decolorize':
        this.decolorizeModels()
        break
      case 'compare':
        break
      case 'update':
        this.showConfirmUpdateDataModal = true
    }
  }

  private get mainModel(): ViewerModel {
    return this.models.find(model => model.isMain)
  }

  private sendMetadataToAirtable() {
    if (this.mainModel !== undefined) {
      this.sendMetadata(this.mainModel)
    } else if (this.models.length === 1) {
      this.sendMetadata(this.models[0])
    } else {
      this.$toasted.error(this.$i18n.t('errors.selectModel') as string)
      this.$root.$emit('stop-loading')
      this.show.modelsList = true
    }
  }

  private sendMetadata(model: ViewerModel) {
    const { objectId } = model
    const { ownerId, id } = this.asset

    const mergeInformation = {
      objectId,
      ownerId,
      assetId: id
    }

    window.parent.postMessage(
      { type: 'extract-metadata', mergeInformation },
      '*'
    )
  }

  private decolorizeModels() {
    const models = this.viewer.getVisibleModels()

    if (models.length === 1) {
      this.viewer.showAll()
      this.viewer.clearThemingColors(this.viewer.model)
    } else {
      const index = this.models.findIndex(m => m.isMain)
      const model = models[index]
      this.viewer.showModel(model, true)
      this.viewer.showAll()
      this.viewer.clearThemingColors(model)
    }
  }

  private get showPanel(): boolean {
    return (
      this.fromWeb &&
      this.models.length &&
      this.models.some(model => model.active) &&
      this.models.some(model => model.isMain) &&
      !this.show.cumulioDashboards
    )
  }

  private async preSelectView(selected: any) {
    this.attempts = 0
    await this.selectView(selected)
  }

  private async selectView(selected: any) {
    try {
      if (this.attempts > 5) {
        this.$toasted.error(this.$i18n.t('errors.metadataStored') as string)
        this.$root.$emit('allow-guid-selection')
        return
      }

      this.selectedView = selected.guid

      const { categories, properties, geometriesAmount, headersUnits } =
        await getCategoriesProperties(
          this.upload
            ? { ...this.upload, guid: selected.guid }
            : {
                guid: selected.guid,
                assetId: Store.assetForViewer.id,
                objectId: Store.modelFromAutodeskBrowser
                  ? Store.modelFromAutodeskBrowser.id
                  : this.modelForPanel.objectId,
                objectKey: Store.modelFromAutodeskBrowser
                  ? Store.modelFromAutodeskBrowser.name
                  : this.modelForPanel.objectKey
              }
        )

      this.categoriesPropertiesData = {
        categories,
        properties,
        geometriesAmount,
        headersUnits
      }

      this.attempts = 0
    } catch (error) {
      if (error.message === 'Error: GraphQL error: not-owner') {
        this.$toasted.error(this.$i18n.t('errors.notOwner') as string)
        this.show.views = false
      } else {
        console.error('error', error)
        await this.selectView(selected)
        this.attempts++
      }
    }
  }

  private async checkCategoriesProperties({ categories, properties }) {
    properties.forEach(props => {
      for (const prop of props) {
        const deleteIndexes: number[] = []

        for (const sub of prop.subProperties) {
          if (!sub.selected) {
            deleteIndexes.push(prop.subProperties.indexOf(sub))
          }
        }

        prop.subProperties = prop.subProperties.filter((p, i) => {
          if (!deleteIndexes.includes(i)) return p
        })
      }
    })

    let deleteIndexes = []

    properties.forEach((props, index) => {
      if (
        props.every(pr => {
          return pr.subProperties.every(sub => sub.length === 0)
        })
      ) {
        deleteIndexes.push(index)
      }
    })

    categories = categories.filter((cat, i) => {
      if (!deleteIndexes.includes(i)) {
        return cat
      }
    })

    properties = properties.filter((prop, i) => {
      if (!deleteIndexes.includes(i)) {
        return prop
      }
    })

    deleteIndexes = []

    properties = properties.map(pr => {
      return pr.filter(p => {
        if (p.subProperties.length) return p
      })
    })

    properties.forEach(props => {
      for (const pr of props) {
        delete pr.show

        pr.subProperties = pr.subProperties.map(sub => sub.title)
      }
    })

    categories = categories.map(category => category.title)

    await this.sendCategoriesProperties(categories, properties)
  }

  private async sendCategoriesProperties(categories, properties) {
    try {
      const upload = {
        guid: this.selectedView,
        assetId: Store.assetForViewer.id,
        objectId: this.upload
          ? this.upload.objectId
          : Store.modelFromAutodeskBrowser
          ? Store.modelFromAutodeskBrowser.id
          : this.modelForPanel.objectId,
        objectKey: this.upload
          ? this.upload.objectKey
          : Store.modelFromAutodeskBrowser
          ? Store.modelFromAutodeskBrowser.name
          : this.modelForPanel.objectKey
      }

      const assetId = Store.assetForViewer.id
      const groups = { categories, properties }

      const {
        data: { storeModelAtDb }
      } = await this.$apollo.mutate({
        variables: { groups, upload, assetId },
        mutation: require('@/graphql/mutations/store-model-at-db.graphql')
      })

      if (storeModelAtDb) {
        this.categoriesPropertiesData = null

        this.show.views = false

        this.models[
          this.models.findIndex(model => model.objectId === upload.objectId)
        ].active = true

        if (this.isUploading && !this.viewer) {
          initViewer(this.$refs.viewer as HTMLElement).then(viewer => {
            this.viewer = viewer

            this.setViewerEvents()

            loadModels(this.models, this.viewer)
          })
        } else {
          loadModels(this.models, this.viewer)
        }

        this.clearUpload()

        this.$toasted.success(this.$i18n.t('success.metadataStored') as string)
      }
    } catch {
      this.$toasted.error(this.$i18n.t('errors.metadataStored') as string)
      this.isUploading = false
      this.loadingQueue = false
    }
  }

  private setViewerEvents() {
    this.viewer.getHotkeyManager().popHotkeys('Autodesk.Escape')

    this.viewer.addEventListener(
      // @ts-ignore
      Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
      async () => {
        if (this.viewer) {
          this.viewer.unregisterContextMenuCallback('propertiesmanager')
          await this.viewer.loadExtension('Autodesk.DocumentBrowser')
          await this.viewer.loadExtension('Autodesk.AEC.LevelsExtension')

          createCustomNavbar(this.viewer)

          this.colorModels()

          this.loadedModelsCounter++

          Store.setProgress({
            rate: (
              (this.loadedModelsCounter / this.models.length) *
              100
            ).toString(),
            progress: this.$i18n.t('loadingModels') as string
          })

          if (this.loadedModelsCounter === this.models.length) {
            this.colorModels()
            this.viewer.fitToView()
            this.allowViewer = true
          }
        }
      }
    )

    this.viewer.addEventListener(
      // @ts-ignore
      Autodesk.Viewing.OBJECT_UNDER_MOUSE_CHANGED,
      data => {
        if (
          data.modelId !== -1 &&
          this.models[data.modelId - 1] &&
          this.models[data.modelId - 1].lock
        ) {
          this.viewer.disableHighlight(true)
        }

        this.viewer.disableHighlight(false)
      }
    )

    if (this.models.length === 1) {
      this.viewer.addEventListener(
        // @ts-ignore
        Autodesk.Viewing.SELECTION_CHANGED_EVENT,
        ({ dbIdArray, model }) => {
          const mod = this.models.find(
            m =>
              m.objectKey ===
              model
                .getData('text/plain')
                .loadOptions.bubbleNode.getRootNode()
                .children[0].name()
          )
          if (!mod.lock) {
            this.changeSelectionData()
            if (!this.fromWeb && !this.isSelectedFromBimtable) {
              this.idsToBimtable()
            } else if (this.fromWeb) {
              this.idsToPanel(dbIdArray)
            }
          } else {
            this.viewer.clearSelection()
          }
        }
      )
    } else if (this.models.length > 1) {
      this.viewer.addEventListener(
        // @ts-ignore
        Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
        ({ selections }) => {
          if (selections.length) {
            // todo: Mejora de selección en geometrías contenidas en otras que están bloqueadas

            for (const selection of selections) {
              const { model, dbIdArray } = selection

              const mod = this.models.find(
                m =>
                  m.objectKey ===
                  model
                    .getData('text/plain')
                    .loadOptions.bubbleNode.getRootNode()
                    .children[0].name()
              )

              // const list = this.viewer.model.getFragmentList()
              //
              // const originalBb = this.getOriginalWorldBoundingBox(
              //   dbIdArray,
              //   list
              // )
              //
              // const modifiedBb = this.getModifiedWorldBoundingBox(
              //   dbIdArray,
              //   list
              // )
              //
              // this.viewer.impl.createOverlayScene('b')
              //
              // this.createBoundingBox(originalBb)
              // this.createBoundingBox(modifiedBb)

              if (mod.lock) {
                for (const dbId of dbIdArray) {
                  this.viewer.toggleSelect(dbId, model, 1)
                }

                // todo: checker behind
                // const { parentElement } = this.viewer.canvas
                //
                // parentElement.addEventListener(
                //   'mousedown',
                //   ({ canvasX, canvasY }) => {
                //     const viewport = this.viewer.impl.clientToViewport(
                //       canvasX,
                //       canvasY
                //     )
                //
                //     this.viewport = viewport
                //
                //     // @ts-ignore
                //     const renderer = this.viewer.impl.renderer()
                //
                //     const dbId = renderer.idAtPixel(viewport.x, viewport.y)
                //
                //     if (this.outerDbId === dbId) {
                //       this.outerDbId = -1
                //     } else {
                //       this.outerDbId = dbId
                //
                //       this.viewer.hide(dbId)
                //
                //       this.innerSelection = true
                //     }
                //
                //     this.viewer.impl.sceneUpdated(true)
                //   }
                // )

                // if (this.innerSelection) this.innerSelection = false

                // this.viewer.show(this.outerDbId)
                //
                // const innerDb = this.viewer.impl
                //   // @ts-ignore
                //   .renderer()
                //   .idAtPixel(this.viewport.x, this.viewport.y)
                //
                // if (innerDb > -1) {
                //   this.viewer.select(innerDb)
                // } else if (this.outerDbId > -1) {
                //   this.viewer.select(this.outerDbId)
                // }
                //
                // const { parentElement } = this.viewer.canvas
                //
                // parentElement.addEventListener(
                //   'mousedown',
                //   ({ canvasX, canvasY }) => {
                //     const viewport = this.viewer.impl.clientToViewport(
                //       canvasX,
                //       canvasY
                //     )
                //
                //     console.log('⭐viewport⭐', viewport)
                //
                //     this.viewport = viewport
                //
                //     // @ts-ignore
                //     const renderer = this.viewer.impl.renderer()
                //
                //     const dbId = renderer.idAtPixel(viewport.x, viewport.y)
                //
                //     console.log('⭐dbId⭐', dbId)
                //
                //     if (this.outerDbId === dbId) {
                //       this.outerDbId = -1
                //     } else {
                //       this.outerDbId = dbId
                //
                //       this.viewer.hide(dbId)
                //
                //       this.innerSelection = true
                //     }
                //
                //     // this.viewer.impl.sceneUpdated(true)
                //   }
                // )
              } else {
                this.changeSelectionData()
                if (!this.fromWeb && !this.isSelectedFromBimtable) {
                  window.parent.postMessage(
                    { type: 'selection', dbIdArray },
                    '*'
                  )
                } else {
                  setTimeout(() => {
                    this.idsToPanel(dbIdArray)
                  }, 50)
                }
              }
            }
          }
        }
      )
    }
  }

  private idsToPanel(idList: number[]) {
    const selected: string[] = []

    for (const id of idList) {
      selected.push(id.toString())
    }
    Store.setSelectedGeometries(selected)

    setTimeout(() => {
      this.clickedAtTable = false
    }, 500)
  }

  private idsToBimtable() {
    const idList = this.viewer.getSelection()
    window.parent.postMessage({ type: 'selection', idList }, '*')
  }

  // private async showViews(objectId: string, objectKey: string) {
  //   this.views = await getViews(objectId)
  //
  //   this.upload = {
  //     objectId,
  //     objectKey,
  //     assetId: this.asset.id,
  //     guid: ''
  //   }
  //
  //   this.show.views = true
  // }

  private async showViewsAlreadyUploadedModel(objectId: string) {
    this.views = await getViews(objectId)
    this.show.views = true
  }

  private async storeThreeLeggedModel() {
    try {
      const assetId = this.asset.id

      const model = {
        objectId: Store.modelFromAutodeskBrowser.id,
        objectKey: Store.modelFromAutodeskBrowser.name
      }

      const {
        data: { storeThreeLeggedModel }
      } = await this.$apollo.mutate({
        variables: { assetId, model },
        mutation: require('@/graphql/mutations/store-three-legged-model.graphql')
      })

      if (storeThreeLeggedModel) {
        this.$toasted.success(this.$i18n.t('success.storedModel') as string)

        if (this.models.length > 1) {
          Store.addThreeLeggedModel(model)
        }
      }
    } catch (error) {
      this.$toasted.error(this.$i18n.t('errors.storedModel') as string)
    }
  }

  private addThreeLeggedModel() {
    this.models.push({
      objectId: this.modelFromAutodeskBrowser.id,
      objectKey: this.modelFromAutodeskBrowser.name,
      threeLegged: true,
      active: true,
      metadata: null,
      isMain: false,
      color: '',
      showColorPicker: false,
      lock: false
    })

    setTimeout(() => {
      loadModels(this.models, this.viewer)
    }, 500)
  }

  private get activeModels(): { active: boolean; objectId: string }[] {
    return this.models.map(model => {
      return {
        active: model.active,
        objectId: model.objectId
      }
    })
  }

  private get modelsColor(): string[] {
    return this.activeModels.length ? this.models.map(model => model.color) : []
  }

  private get modelForPanel(): ViewerModel | null {
    const model = this.models.find(m => m.isMain)
    return model ? model : null
  }

  private get activeSelection(): boolean {
    return Store.activeSelection
  }

  private get activeRows(): any[] {
    return Store.activeRows
  }

  private get activeHeaders(): any[] {
    return Store.activeHeaders
  }

  private async loadModels() {
    try {
      this.setViewerEvents()
      loadModels(this.models, this.viewer)
    } catch (error) {
      this.$toasted.error(this.$i18n.t('errors.loadingModels') as string)
    }
  }

  private async progressComplete() {
    clearInterval(this.statusInterval)
    this.rate = ''
    this.progress = ''

    this.views = await getViews(this.upload.objectId)

    this.models.push({
      objectKey: this.upload.objectKey,
      objectId: this.upload.objectId,
      metadata: false,
      active: true,
      lock: false,
      isMain: false,
      threeLegged: false,
      color: '',
      showColorPicker: false
    })

    loadModels(this.models, this.viewer)

    if (this.views === null) {
      this.$toasted.error(this.$i18n.t('errors.guids') as string)
      await this.getModels()
      this.clearUpload()
    } else {
      this.show.views = true
    }
  }

  private selectGeometriesFromCumulio(idsGroups: any) {
    this.viewer.clearSelection()

    const models = this.viewer.getVisibleModels()

    for (const model of models) {
      this.viewer.clearThemingColors(model)

      if (this.selectionCumulioMode === 'color' && this.colorInactive) {
        this.colorModel({
          model: this.models[models.indexOf(model)],
          color: 'bg-gray-300'
        })
      }
    }

    if (Object.keys(idsGroups).length) {
      if (models.length === 1) {
        const selectables = []

        for (const item in idsGroups[this.models[0].objectKey]) {
          selectables.push(parseInt(item))

          if (this.selectionCumulioMode === 'color') {
            this.viewer.setThemingColor(
              parseInt(item),
              translateColorCumulio(idsGroups[this.models[0].objectKey][item]),
              models[0]
            )
          }
        }

        if (this.selectionCumulioMode === 'selection') {
          this.viewer.select(selectables)
        }
      } else if (models.length > 1) {
        const selections = []

        let allowSelection = true

        for (const model in idsGroups) {
          const mod = models.find(m => {
            return (
              m
                .getData('text/plain')
                .loadOptions.bubbleNode.getRootNode()
                .children[0].name() === model
            )
          })

          if (this.selectionCumulioMode === 'selection') {
            const selectables = []

            for (const item in idsGroups[model]) {
              selectables.push(parseInt(item))
            }

            selections.push({
              model: mod,
              ids: selectables
            })
          } else if (this.selectionCumulioMode === 'color') {
            const selectables = []
            const boundingBoxes = []

            for (const item in idsGroups[model]) {
              if (!idsGroups[model][item]) continue

              const objectId = parseInt(item)
              selectables.push(objectId)

              this.viewer.setThemingColor(
                objectId,
                translateColorCumulio(idsGroups[model][item]),
                mod
              )

              this.viewer.impl.invalidate(true)
            }

            // console.log('⭐selectables⭐', selectables)
            // this.viewer.impl.selector.setAggregateSelection(selectables)

            // CREATE SHADER
            //             const createShader = () => {
            //               const edgeVertexShader = `
            //     attribute vec3 position;
            //
            //     uniform mat4 modelViewMatrix;
            //     uniform mat4 projectionMatrix;
            //
            //     void main() {
            //         gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            //     }
            // `
            //
            //               const edgeFragmentShader = `
            //     precision highp float;
            //     uniform vec3 edgeColor;
            //
            //     void main() {
            //         gl_FragColor = vec4(edgeColor, 1.0);
            //     }
            // `
            //
            //               // Crear el material con el shader de aristas
            //               const edgeMaterial = new THREE.ShaderMaterial({
            //                 vertexShader: edgeVertexShader,
            //                 fragmentShader: edgeFragmentShader,
            //                 uniforms: {
            //                   edgeColor: { value: new THREE.Color(0xff0000) }
            //                 }
            //               })
            //
            //               const createEdgesShader = objectId => {
            //                 const fragProxy = this.viewer.impl.getFragmentProxy(
            //                   this.viewer.model,
            //                   objectId
            //                 )
            //                 console.log('⭐fragProxy⭐', fragProxy)
            //                 // Convertir la geometría a una BufferGeometry
            //                 const geometry = new THREE.BufferGeometry().fromGeometry(
            //                   fragProxy.geometry
            //                 )
            //                 console.log('⭐geometry⭐', geometry)
            //                 // Crear una nueva escena overlay
            //                 const overlayScene = new THREE.Scene()
            //
            //                 // Configurar el renderizador de la escena overlay
            //                 const overlayRenderer = new THREE.WebGLRenderer({
            //                   alpha: true,
            //                   antialias: true
            //                 })
            //
            //                 overlayRenderer.setSize(
            //                   this.viewer.container.clientWidth,
            //                   this.viewer.container.clientHeight
            //                 )
            //
            //                 overlayRenderer.domElement.style.position = 'absolute'
            //                 overlayRenderer.domElement.style.top = 0
            //                 overlayRenderer.domElement.style.zIndex = 1 // Asegurar que la capa overlay esté sobre el visor principal
            //                 this.viewer.container.appendChild(overlayRenderer.domElement)
            //
            //                 const edges = new THREE.LineSegments(geometry, edgeMaterial) // Usar el material de aristas
            //                 overlayScene.add(edges) // Agregar las aristas a la escena overlay
            //
            //                 // Llamar a la función para crear aristas con shader para cada objectId
            //               }
            //               selectables.forEach(objectId => {
            //                 this.viewer.select(objectId, mod)
            //                 createEdgesShader(objectId)
            //               })
            //             }
            //             createShader()

            // CREATE SCENEOVERLAY
            // const createSceneOverlay = () => {
            //   // Crear una nueva escena overlay
            //   const overlayScene = new THREE.Scene()
            //
            //   // Configurar el renderizador de la escena overlay
            //   const overlayRenderer = new THREE.WebGLRenderer({
            //     alpha: true,
            //     antialias: true
            //   })
            //   overlayRenderer.setSize(
            //     this.viewer.container.clientWidth,
            //     this.viewer.container.clientHeight
            //   )
            //   overlayRenderer.domElement.style.position = 'absolute'
            //   overlayRenderer.domElement.style.top = 0
            //   overlayRenderer.domElement.style.zIndex = 1 // Asegurar que la capa overlay esté sobre el visor principal
            //   this.viewer.container.appendChild(overlayRenderer.domElement)
            //
            //   // Función para crear geometrías de aristas
            //   const createEdgesGeometry = objectId => {
            //     const fragProxy = this.viewer.impl.getFragmentProxy(
            //       this.viewer.model,
            //       objectId
            //     )
            //
            //     const geometry = new THREE.EdgesGeometry(fragProxy.geometry) // Crear geometría de aristas
            //     const material = new THREE.LineBasicMaterial({
            //       color: 0xff0000
            //     }) // Material para las aristas
            //
            //     const edges = new THREE.LineSegments(geometry, material)
            //     overlayScene.add(edges) // Agregar las aristas a la escena overlay
            //   }
            //
            //   // Llamar a la función para crear geometrías de aristas para cada objectId
            //   selectables.forEach(objectId => {
            //     this.viewer.select(objectId, mod, () => {
            //       createEdgesGeometry(objectId)
            //       renderOverlay() // Renderizar la escena overlay después de que se completen las selecciones
            //     })
            //   })
            //
            //   // Función para renderizar la escena overlay
            //   const renderOverlay = () => {
            //     overlayRenderer.render(overlayScene, this.viewer.impl.camera)
            //   }
            // }
            //
            // createSceneOverlay()

            // CREATE EDGES
            // const createEdgesGeometry = (objectId: number) => {
            //   const fragmentMap = this.viewer.model.getFragmentList()
            //
            //   console.log('⭐fragmentMap⭐', fragmentMap)
            //
            //   const fragProxy = this.viewer.impl.getFragmentProxy(
            //     this.viewer.model,
            //     objectId
            //   )
            //
            //   const material = new THREE.MeshBasicMaterial({
            //     color: 0xff0000,
            //     wireframe: true
            //   })
            //
            //   fragProxy.getMaterial(material) // Obtener el material actual del fragmento
            //   fragProxy.setMaterial(material) // Establecer el nuevo material
            //
            //   // // const extractLinesFromGeometry = geometry => {
            //   // //   const lines = new THREE.Geometry()
            //   // //
            //   // //   // Verificar si la geometría tiene posiciones
            //   // //   if (geometry.attributes.position) {
            //   // //     const positions = geometry.attributes.position.array
            //   // //     const numVertices = positions.length / 3
            //   // //
            //   // //     // Iterar sobre los vértices para crear líneas entre ellos
            //   // //     for (let i = 0; i < numVertices; i += 2) {
            //   // //       const vertex1 = new THREE.Vector3().fromArray(
            //   // //         positions,
            //   // //         i * 3
            //   // //       )
            //   // //       const vertex2 = new THREE.Vector3().fromArray(
            //   // //         positions,
            //   // //         (i + 1) * 3
            //   // //       )
            //   // //
            //   // //       lines.vertices.push(vertex1, vertex2)
            //   // //     }
            //   // //   }
            //   // //
            //   // //   return lines
            //   // // }
            //   //
            //   // // Obtener la geometría correspondiente al dbId
            //   // const fragmentList =
            //   //   this.viewer.model.getData().instanceTree.nodeAccess
            //   //
            //   // console.log('⭐fragmentList⭐', fragmentList)
            //   // const frags = fragmentList.fragments.fragId2dbId[objectId]
            //   // console.log('⭐frags⭐', frags)
            //   // // Verificar si hay varios fragmentos para este dbId
            //   // if (Array.isArray(frags)) {
            //   //   // Si hay varios fragmentos, iterar sobre ellos
            //   //   frags.forEach(fragId => {
            //   //     // Obtener la geometría del fragmento
            //   //     const geometry = fragmentList.getVizmeshGeometry(fragId)
            //   //
            //   //     // Extraer las líneas de la geometría
            //   //     const lines = extractLinesFromGeometry(geometry)
            //   //
            //   //     // Crear un objeto THREE.LineSegments con las líneas
            //   //     const lineSegments = new THREE.LineSegments(
            //   //       lines,
            //   //       new THREE.LineBasicMaterial({ color: 0xff0000 })
            //   //     )
            //   //
            //   //     // Añadir el objeto LineSegments a la overlayScene
            //   //     this.viewer.impl.addOverlay('o', lineSegments)
            //   //   })
            //   // } else {
            //   //   console.log('⭐else⭐')
            //   // }
            // }
            //
            // const material = new THREE.LineBasicMaterial({
            //   color: 0xff0000,
            //   colorWrite: false,
            //   depthTest: true,
            //   renderOrder: Number.MAX_VALUE
            // })
            //
            // this.viewer.impl.removeOverlay('o')
            // this.viewer.impl.createOverlayScene('o', material)
            //
            // selectables.forEach(objectId => {
            //   this.viewer.select(objectId, mod, () => {
            //     createEdgesGeometry(objectId)
            //   })
            // })

            // CREATE BOUNDINGBOX
            // const createBoundingBox = () => {
            //   const createWireframeBoxGeometry = (min, max) => {
            //     const vertices = [
            //       min,
            //       new THREE.Vector3(max.x, min.y, min.z),
            //       min,
            //       new THREE.Vector3(min.x, max.y, min.z),
            //       min,
            //       new THREE.Vector3(min.x, min.y, max.z),
            //       new THREE.Vector3(max.x, min.y, min.z),
            //       new THREE.Vector3(max.x, max.y, min.z),
            //       new THREE.Vector3(max.x, min.y, min.z),
            //       new THREE.Vector3(max.x, min.y, max.z),
            //       new THREE.Vector3(min.x, max.y, min.z),
            //       new THREE.Vector3(max.x, max.y, min.z),
            //       new THREE.Vector3(min.x, max.y, min.z),
            //       new THREE.Vector3(min.x, max.y, max.z),
            //       new THREE.Vector3(min.x, min.y, max.z),
            //       new THREE.Vector3(max.x, min.y, max.z),
            //       new THREE.Vector3(min.x, min.y, max.z),
            //       new THREE.Vector3(min.x, max.y, max.z),
            //       new THREE.Vector3(max.x, min.y, max.z),
            //       new THREE.Vector3(max.x, max.y, max.z),
            //       new THREE.Vector3(max.x, max.y, min.z),
            //       new THREE.Vector3(max.x, max.y, max.z),
            //       new THREE.Vector3(min.x, max.y, max.z),
            //       new THREE.Vector3(max.x, max.y, max.z)
            //     ]
            //
            //     const geometry = new THREE.BufferGeometry().setFromPoints(
            //       vertices
            //     )
            //
            //     return geometry
            //   }
            //
            //   const material = new THREE.LineBasicMaterial({
            //     color: 0xff0000,
            //     colorWrite: false,
            //     depthTest: true,
            //     renderOrder: Number.MAX_VALUE
            //   })
            //
            //   this.viewer.impl.removeOverlay('o')
            //
            //   this.viewer.impl.createOverlayScene('o', material)
            //
            //   selectables.forEach(objectId => {
            //     this.viewer.select(objectId, mod)
            //
            //     const boundingBox = this.viewer.utilities.getBoundingBox(false)
            //
            //     if (boundingBox) {
            //       const min = new THREE.Vector3(
            //         boundingBox.min.x,
            //         boundingBox.min.y,
            //         boundingBox.min.z
            //       )
            //       const max = new THREE.Vector3(
            //         boundingBox.max.x,
            //         boundingBox.max.y,
            //         boundingBox.max.z
            //       )
            //
            //       const geometry = createWireframeBoxGeometry(min, max)
            //
            //       const boxLines = new THREE.LineSegments(geometry, material)
            //
            //       this.viewer.impl.addOverlay('o', boxLines)
            //
            //       boundingBoxes.push(boxLines)
            //     }
            //   })
            // }
            // createBoundingBox()

            this.viewer.clearSelection()
            this.viewer.impl.invalidate(true)
          }
        }

        if (allowSelection) {
          this.viewer.impl.selector.setAggregateSelection(selections)
        }
      }
    } else {
      if (models.length === 1) {
        this.viewer.clearThemingColors(models[0])
      } else {
        for (const model of models) {
          this.viewer.clearThemingColors(model)
        }
      }
    }

    this.selectionDone = true
  }

  private confirmUpdate() {
    const userId = Store.user.id
    const hubId = Store.hub.id
    const { ownerId, id } = this.asset

    const model =
      this.models.length === 1
        ? this.models[0]
        : this.models.find(model => model.isMain)

    const modelObjectId = model.objectId
    const modelName = model.objectKey

    const updatePayload = {
      userId,
      assetId: id,
      hubId,
      ownerId,
      modelObjectId,
      modelName
    }

    window.parent.postMessage(
      {
        type: Store.isOwner ? 'owner-query-update' : 'team-member-query-update',
        updatePayload
      },
      '*'
    )

    this.showConfirmUpdateDataModal = false
  }

  private get showRooms(): boolean {
    return Store.showRooms
  }

  private async handleRoomsCase() {
    if (this.rooms.length) {
      for (const roomGroup of this.rooms) {
        const { model, ids } = roomGroup

        for (const id of ids) {
          this.showRooms
            ? this.viewer.show(id, model)
            : this.viewer.hide(id, model)

          if (this.showRooms) {
            await this.setRoomsOverlay(model, id)
          }
        }
      }
    }
  }

  private async setRoomsOverlay(model, id) {
    model.unconsolidate()

    if (this.viewer.model.is2d()) {
      const frags = this.viewer.model.getFragmentList()
      this.viewer.impl.matman().addMaterial('custom', roomMaterial, true)

      frags.allVisibleDirty = true

      // this.viewer.model.getLayerToNodeIdMapping(
      //   success => {
      //     console.log('success', success)
      //   },
      //   () => console.error('error-render')
      // )

      for (const _ of this.viewer.model.getData('text/plain').stringDbIds) {
        this.viewer.select(_, this.viewer.model)
      }

      for (const id of this.rooms[0].ids) {
        // @ts-ignore
        // frags.setMaterial(roomMaterial, id)

        const fragId = frags.fragments.dbId2fragId[id]

        fragId.setMaterial(roomMaterial, id)

        this.viewer.show(id, this.viewer.model)
        this.viewer.select(id, this.viewer.model)
      }

      // this.viewer.model
      //   .getData()
      //   .instanceTree.enumNodeFragments(dbid, fragId => console.log(fragId))
    } else {
      const instanceTree = await model.getInstanceTree()

      const fragsList = model.getFragmentList()
      const frags = []

      instanceTree.enumNodeFragments(id, fragId => {
        frags.push(fragId)
      })

      this.viewer.impl.matman().addMaterial('custom', roomMaterial, true)

      for (const frag of frags) {
        fragsList.setMaterial(frag, roomMaterial)
      }

      this.viewer.impl.invalidate(true)
    }
  }

  private resetModelMetadata(model: ViewerModel) {
    model.metadata = null
    model.isMain = false
  }

  private showConnectionModal(tableName: string) {
    this.showConnection = true
    this.activeTableName = tableName
  }

  /** Transparentizar modelo */
  private async translucentModel(model: any) {
    // @ts-ignore
    model.unconsolidate()

    const urn = model.getData('text/plain').urn

    const dataModel = this.models.find(m => urn === makeUrn(m.objectId))

    this.colorModel({ model: dataModel, color: 'gray' })

    const instanceTree = await model.getInstanceTree()

    const dbids = Object.keys(instanceTree.nodeAccess.dbIdToIndex).map(id =>
      parseInt(id)
    )

    const frags = []

    for (const id of dbids) {
      instanceTree.enumNodeFragments(id, fragId => {
        frags.push(fragId)
      })
    }

    this.viewer.impl.matman().addMaterial('custom', roomMaterial, true)

    const fragsList = model.getFragmentList()

    for (const frag of frags) {
      const material = fragsList.getMaterial(frag)

      if (material) {
        if (!this.wireframeModelsMaterials[model.id]) {
          this.wireframeModelsMaterials[model.id] = {}
        }

        this.wireframeModelsMaterials[model.id][frag] = { material }

        fragsList.setMaterial(frag, roomMaterial)
      }
    }

    this.viewer.impl.invalidate(true)

    this.viewer.setGroundShadow(false)

    this.viewer.refresh(true)
  }

  private get isolation(): boolean {
    return Store.isolation
  }

  private closeViews() {
    this.show.views = false
    this.upload = null
  }

  private setChartSelectionMode(active: boolean) {
    this.selectionCumulioMode = active ? 'selection' : 'color'
  }

  private setRowModal(data: {
    index: number
    status: boolean
    tableName: string
    isGroups: boolean
  }) {
    this.showRowModal = data.status
    this.dataSetModalRow = data
  }

  private setRowModalGroups(data: {
    index: number
    indexRow: number
    status: boolean
    isGroups: boolean
  }) {
    this.showRowModal = data.status
    this.dataSetModalRow = data
  }

  private async changeSelectionData() {
    const selections = await setSelections(this.viewer)

    if (Store.activeSelection) await getSelectionsData(selections)
  }

  private fitToModel(model: ViewerModel) {
    const models = this.viewer.getVisibleModels()

    const mod = models.find(
      m => m.getData('text/plain').urn === makeUrn(model.objectId)
    )

    if (!mod) return

    const ins = mod.getInstanceTree()

    this.viewer.fitToView(Object.values(ins.nodeAccess.dbIdToIndex), mod, true)
  }

  private show360ModalHandle(url: string) {
    this.show360Modal = true
    this.url360 = url
  }

  private setOverlay() {
    const overlayIds = []

    if (this.activeHeaders.length) {
      const index = this.activeHeaders.findIndex(
        header => header.header === 'Url_Foto360'
      )

      for (const row of this.activeRows) {
        if (row[index] !== '') {
          overlayIds.push(Number(row[1]))
        }
      }
    }

    this.addMarkerToGeometry(overlayIds)
  }

  private addSpheres(idList: number[]) {
    this.viewer.impl.createOverlayScene('o')

    const geometry = new THREE.SphereGeometry(1, 32, 32)
    const material = new THREE.MeshBasicMaterial({ color: 0xff0000 })

    const tree = this.viewer.model.getData().instanceTree
    const position = new THREE.Vector3()

    const nodebBox = new THREE.Box3()

    idList.forEach(objectId => {
      tree.enumNodeFragments(objectId, fragId => {
        const fragProxy = this.viewer.impl.getFragmentProxy(
          this.viewer.model,
          fragId
        )

        const boundingBox = new THREE.Box3()

        console.log('⭐boundingBox-1⭐', boundingBox)
        fragProxy.getWorldBounds(fragId, boundingBox)

        boundingBox.getCenter(position)
        nodebBox.union(boundingBox)

        const sphere = new THREE.Mesh(geometry, material)
        sphere.position.copy(nodebBox)

        console.log('⭐position-1⭐', position)
        this.viewer.impl.addOverlay('o', sphere)
      })
    })

    this.viewer.impl.invalidate(true, true, true)
  }

  private getWorldBoundingBox(fragId: number) {
    const fragList = this.viewer.model.getFragmentList()
    const nodebBox = new THREE.Box3()

    const fragbBox = new THREE.Box3()
    fragList.getWorldBounds(fragId, fragbBox)
    nodebBox.union(fragbBox)

    return nodebBox
  }

  private getOriginalWorldBoundingBox(fragIds: any, fragList: any) {
    const fragBoundingBox = new THREE.Box3()
    const nodeBoundingBox = new THREE.Box3()

    const fragmentBoxes = fragList.boxes

    fragIds.forEach(fragId => {
      const boffset = fragId * 6

      fragBoundingBox.min.x = fragmentBoxes[boffset]
      fragBoundingBox.min.y = fragmentBoxes[boffset + 1]
      fragBoundingBox.min.z = fragmentBoxes[boffset + 2]
      fragBoundingBox.max.x = fragmentBoxes[boffset + 3]
      fragBoundingBox.max.y = fragmentBoxes[boffset + 4]
      fragBoundingBox.max.z = fragmentBoxes[boffset + 5]

      nodeBoundingBox.union(fragBoundingBox)
    })

    return nodeBoundingBox
  }

  private getModifiedWorldBoundingBox(fragIds: any, fragList: any) {
    const fragbBox = new THREE.Box3()

    fragIds.forEach(fragId => {
      fragList.getWorldBounds(fragId, fragbBox)
    })

    return fragbBox
  }

  private async addMarkerToGeometry(idList: number[]) {
    if (Store.showOverlay) {
      const tree = this.viewer.model.getData().instanceTree

      for (const objectId of idList) {
        this.viewer.select(objectId)
        tree.enumNodeFragments(objectId, (fragId: number) => {
          const fragProxy = this.viewer.impl.getFragmentProxy(
            this.viewer.model,
            fragId
          )

          const renderProxy = this.viewer.impl.getRenderProxy(
            this.viewer.model,
            fragId
          )

          const getFragmentWorldMatrix = (model: any, fragId: number) => {
            const fragList = model.getFragmentList()
            const matrix = new THREE.Matrix4()
            fragList.getWorldMatrix(fragId, matrix)
            return matrix
          }
          const addSphereAtPosition = (
            position: any,
            scale: any,
            quaternion: any
          ) => {
            const sphereGeometry = new THREE.SphereGeometry(1, 32, 32)
            const sphereMaterial = new THREE.MeshBasicMaterial({
              color: 0xff0000
            })
            const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial)

            sphere.position.set(position.x, position.y, position.z)
            sphere.scale.set(scale.x, scale.y, scale.z)
            sphere.quaternion.set(
              quaternion.x,
              quaternion.y,
              quaternion.z,
              quaternion.w
            )

            this.viewer.impl.addOverlay('o', sphere)
          }

          const matrix = getFragmentWorldMatrix(this.viewer.model, fragId)

          const position = new THREE.Vector3()
          const quaternion = new THREE.Quaternion()
          const scale = new THREE.Vector3()
          matrix.decompose(position, quaternion, scale)

          addSphereAtPosition(position, scale, quaternion)
        })
      }

      const createMarker = (objectId: number, idList: number[]) => {
        const marker = document.createElement('div')
        marker.className = 'custom-marker'
        marker.style.position = 'absolute'
        marker.style.width = '20px'
        marker.style.height = '20px'
        marker.style.backgroundColor = 'red'
        marker.style.borderRadius = '50%'

        const tree = this.viewer.model.getData().instanceTree

        const createWireframeBoxGeometry = (min, max) => {
          const vertices = [
            min,
            new THREE.Vector3(max.x, min.y, min.z),
            min,
            new THREE.Vector3(min.x, max.y, min.z),
            min,
            new THREE.Vector3(min.x, min.y, max.z),
            new THREE.Vector3(max.x, min.y, min.z),
            new THREE.Vector3(max.x, max.y, min.z),
            new THREE.Vector3(max.x, min.y, min.z),
            new THREE.Vector3(max.x, min.y, max.z),
            new THREE.Vector3(min.x, max.y, min.z),
            new THREE.Vector3(max.x, max.y, min.z),
            new THREE.Vector3(min.x, max.y, min.z),
            new THREE.Vector3(min.x, max.y, max.z),
            new THREE.Vector3(min.x, min.y, max.z),
            new THREE.Vector3(max.x, min.y, max.z),
            new THREE.Vector3(min.x, min.y, max.z),
            new THREE.Vector3(min.x, max.y, max.z),
            new THREE.Vector3(max.x, min.y, max.z),
            new THREE.Vector3(max.x, max.y, max.z),
            new THREE.Vector3(max.x, max.y, min.z),
            new THREE.Vector3(max.x, max.y, max.z),
            new THREE.Vector3(min.x, max.y, max.z),
            new THREE.Vector3(max.x, max.y, max.z)
          ]

          const geometry = new THREE.BufferGeometry().setFromPoints(vertices)

          return geometry
        }

        const material = new THREE.LineBasicMaterial({
          color: 0xff0000,
          colorWrite: false,
          depthTest: true,
          renderOrder: Number.MAX_VALUE
        })

        this.viewer.impl.createOverlayScene('b', material)

        tree.enumNodeFragments(objectId, (fragId: number) => {
          const boundingBox = this.viewer.utilities.getBoundingBox(fragId)

          const center = boundingBox.getCenter(new THREE.Vector3())

          const geometry = createWireframeBoxGeometry(
            boundingBox.min,
            boundingBox.max
          )

          const boxLines = new THREE.LineSegments(geometry, material)

          this.viewer.impl.addOverlay('b', boxLines)

          const screenPoint = this.viewer.worldToClient(center)
          marker.style.left = `${screenPoint.x}px`
          marker.style.top = `${screenPoint.y}px`

          this.viewer.container.appendChild(marker)
        })
      }

      idList.forEach(dbId => createMarker(dbId, idList))
      // --------------------
      // const geometry = new THREE.SphereGeometry(1, 32, 32)
      // const material = new THREE.MeshBasicMaterial({ color: 0xff0000 })
      //
      // const sphere = new THREE.Mesh(geometry, material)
      // const tree = this.viewer.model.getData().instanceTree
      //
      // for (const objectId of idList) {
      //   tree.enumNodeFragments(objectId, (fragId: number) => {
      //     const pos = this.viewer.worldToClient(
      //       this.getWorldBoundingBox(fragId).center
      //     )
      //     this.viewer.impl.scene.add(sphere)
      //
      //     sphere.position.copy(pos)
      //   })
      // }
      //--------------
      // this.addSpheres(idList)
      //
      //
      // this.createBoundingBox(idList)
      //
      // const getFragmentWorldMatrix = (
      //   viewer: any,
      //   model: any,
      //   fragId: number
      // ) => {
      //   const fragList = model.getFragmentList()
      //   const matrix = new THREE.Matrix4()
      //   fragList.getWorldMatrix(fragId, matrix)
      //   return matrix
      // }
      // const getFragmentWorldPosition = (
      //   viewer: any,
      //   objectId: number
      // ): { x: number; y: number; z: number } => {
      //   const model = viewer.model
      //   const instanceTree = model.getData().instanceTree
      //   const fragList = model.getFragmentList()
      //
      //   let position = new THREE.Vector3()
      //
      //   instanceTree.enumNodeFragments(objectId, fragId => {
      //     const matrix = new THREE.Matrix4()
      //     fragList.getWorldMatrix(fragId, matrix)
      //
      //     const tempPos = new THREE.Vector3()
      //     const tempQuat = new THREE.Quaternion()
      //     const tempScale = new THREE.Vector3()
      //     matrix.decompose(tempPos, tempQuat, tempScale)
      //
      //     position.add(tempPos)
      //   })
      //
      //   const fragCount = instanceTree.getChildCount(objectId)
      //   if (fragCount > 0) {
      //     position.divideScalar(fragCount)
      //   }
      //
      //   return position
      // }
      // -----------------------------------
      //
      // const addSphereSprites = async (viewer: any, objectIds: number[]) => {
      //   console.log('⭐objectIds⭐', objectIds)
      //   console.log('⭐viewer⭐', viewer)
      //   const DataVisualization = await viewer.loadExtension(
      //     'Autodesk.DataVisualization'
      //   )
      //   const SpriteView = DataVisualization.SpriteView
      //
      //   const viewableData = new DataVisualization.Core.ViewableData()
      //   viewableData.spriteSize = 20
      //
      //   for (const objectId of objectIds) {
      //     const position = getFragmentWorldPosition(viewer, objectId) // Implementa esta función para obtener la posición
      //
      //     const sprite = new DataVisualization.Core.SpriteViewable(position, {
      //       color: new THREE.Color(0xff0000),
      //       highlightedColor: new THREE.Color(0x00ff00),
      //       tooltip: 'Mi Esfera'
      //     })
      //     console.log('⭐sprite⭐', sprite)
      //     viewableData.addViewable(sprite)
      //   }
      //
      //   await viewableData.finish()
      //   viewer.impl.addViewables(viewableData)
      // }
      // await addSphereSprites(this.viewer, idList)
      //
      // ----------------------------------
      // for (const objectId of idList) {
      //   console.log('⭐objectId⭐', objectId)
      //   this.viewer.select(objectId, this.viewer.model, true)
      //   tree.enumNodeFragments(objectId, (fragId: number) => {
      //     // const fragProxy = this.viewer.impl.getFragmentProxy(
      //     //   this.viewer.model,
      //     //   fragId
      //     // )
      //
      //     const matrix = getFragmentWorldMatrix(
      //       this.viewer,
      //       this.viewer.model,
      //       fragId
      //     )
      //
      //     const position = new THREE.Vector3()
      //     const quaternion = new THREE.Quaternion()
      //     const scale = new THREE.Vector3()
      //     matrix.decompose(position, quaternion, scale)
      //
      //     const geometry = new THREE.SphereGeometry(1, 32, 32) // Radio de 1, segmentos de 32
      //     const material = new THREE.MeshBasicMaterial({ color: 0xff0000 })
      //     const sphere = new THREE.Mesh(geometry, material)
      //
      //     // sphere.applyMatrix4(matrix)
      //     // sphere.applyQuaternion(quaternion)
      //     console.log('⭐position⭐', position)
      //     // Aplicar cambios a la sphere
      //     sphere.position.copy(position)
      //     // sphere.quaternion.copy(quaternion)
      //     // sphere.scale.copy(scale)
      //
      //     this.viewer.impl.addOverlay('o', sphere)
      //     this.viewer.impl.invalidate(true, true, true)
      //   })
      // }
      // this.viewer.select(idList, this.viewer.model)
      //
      // console.log('⭐idList⭐', idList)
      //
      // const tree = this.viewer.model.getData().instanceTree
      //
      // this.viewer.impl.removeOverlay('o')
      // this.viewer.impl.createOverlayScene('o')
      //
      //------------------------
      //
      // for (const objectId of idList) {
      //   tree.enumNodeFragments(objectId, (fragId: number) => {
      //     const fragProxy = this.viewer.impl.getFragmentProxy(
      //       this.viewer.model,
      //       fragId
      //     )
      //
      //     if (!fragProxy) {
      //       console.error('fragProxy is null or undefined for fragId:', fragId)
      //       return
      //     }
      //
      //     const renderProxy = this.viewer.utilities.viewerImpl.getRenderProxy(
      //       this.viewer.model,
      //       fragId
      //     )
      //
      //     console.log('⭐renderProxy⭐', renderProxy)
      //     // const a = new THREE.Vector3()
      //     //
      //     // renderProxy.getWorldPosition(a)
      //
      //     const matrix = new THREE.Matrix4()
      //     fragProxy.getWorldMatrix(matrix)
      //
      //     const position = new THREE.Vector3()
      //     const quaternion = new THREE.Quaternion()
      //     const scale = new THREE.Vector3()
      //     matrix.decompose(position, quaternion, scale)
      //
      //     console.log('⭐position⭐', position)
      //
      //     const sphereGeometry = new THREE.SphereGeometry(1, 32, 32)
      //     const sphereMaterial = new THREE.MeshBasicMaterial({
      //       color: 0xff0000,
      //       depthTest: false
      //     })
      //
      //     const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial)
      //     sphere.position.copy(position)
      //     console.log('⭐sphere.position⭐', sphere.position)
      //
      //     this.viewer.impl.addOverlay('o', sphere)
      //   })
      // }
      // const geometry = new THREE.SphereGeometry(1, 32, 32)
      // const material = new THREE.MeshBasicMaterial({ color: 0xff0000 })
      //
      // const position = new THREE.Vector3()
      //
      // const tree = this.viewer.model.getData().instanceTree
      //
      // console.log('⭐idList⭐', idList)
      // idList.forEach(objectId => {
      //   tree.enumNodeFragments(objectId, fragId => {
      //     const fragProxy = this.viewer.impl.getFragmentProxy(
      //       this.viewer.model,
      //       fragId
      //     )
      //     console.log('⭐fragProxy⭐', fragProxy)
      //
      //     const boundingBox = new THREE.Box3()
      //     fragProxy.getWorldBounds(boundingBox.min, boundingBox.max)
      //
      //     boundingBox.getCenter(position)
      //
      //     const sphere = new THREE.Mesh(geometry, material)
      //     sphere.position.copy(position)
      //
      //     this.viewer.impl.scene.add(sphere)
      //   })
      // })
      //
      // this.viewer.impl.invalidate(true, true, true)
    } else {
      this.viewer.impl.removeOverlay('o')
    }

    this.viewer.impl.invalidate(true)
  }

  private createBoundingBox(boundingBox: any) {
    const position = new THREE.Vector3()
    boundingBox.getCenter(position)

    const geo = new THREE.SphereGeometry(1, 32, 32)
    const mat = new THREE.MeshBasicMaterial({ color: 0xff0000 })

    const sphere = new THREE.Mesh(geo, mat)
    sphere.position.copy(position)

    const createWireframeBoxGeometry = (
      min: { x: number; y: number; z: number },
      max: { x: number; y: number; z: number }
    ) => {
      const vertices = [
        new THREE.Vector3(min.x, min.y, min.z),
        new THREE.Vector3(max.x, min.y, min.z),
        new THREE.Vector3(min.x, min.y, min.z),
        new THREE.Vector3(min.x, max.y, min.z),
        new THREE.Vector3(min.x, min.y, min.z),
        new THREE.Vector3(min.x, min.y, max.z),
        new THREE.Vector3(max.x, min.y, min.z),
        new THREE.Vector3(max.x, max.y, min.z),
        new THREE.Vector3(max.x, min.y, min.z),
        new THREE.Vector3(max.x, min.y, max.z),
        new THREE.Vector3(min.x, max.y, min.z),
        new THREE.Vector3(max.x, max.y, min.z),
        new THREE.Vector3(min.x, max.y, min.z),
        new THREE.Vector3(min.x, max.y, max.z),
        new THREE.Vector3(min.x, min.y, max.z),
        new THREE.Vector3(max.x, min.y, max.z),
        new THREE.Vector3(min.x, min.y, max.z),
        new THREE.Vector3(min.x, max.y, max.z),
        new THREE.Vector3(max.x, min.y, max.z),
        new THREE.Vector3(max.x, max.y, max.z),
        new THREE.Vector3(max.x, max.y, min.z),
        new THREE.Vector3(max.x, max.y, max.z),
        new THREE.Vector3(min.x, max.y, max.z),
        new THREE.Vector3(max.x, max.y, max.z)
      ]

      return new THREE.BufferGeometry().setFromPoints(vertices)
    }

    const material = new THREE.LineBasicMaterial({
      color: 0xff0000,
      depthTest: true,
      renderOrder: Number.MAX_VALUE
    })

    const min = new THREE.Vector3(
      boundingBox.min.x,
      boundingBox.min.y,
      boundingBox.min.z
    )
    const max = new THREE.Vector3(
      boundingBox.max.x,
      boundingBox.max.y,
      boundingBox.max.z
    )

    const geometry = createWireframeBoxGeometry(min, max)

    console.log('⭐geometry⭐', geometry)
    const boxLines = new THREE.LineSegments(geometry, material)

    this.viewer.impl.addOverlay('b', boxLines)
    this.viewer.impl.invalidate(true)
  }

  private get urlLikes(): string[] {
    return Store.urlLikes
  }

  private printLikes() {
    printLikes()
  }

  @Watch('isolation')
  onIsolationChange() {
    if (!this.isolation) {
      this.viewer.showAll()
    }
  }

  @Watch('models', { deep: true })
  async onModelsChange() {
    if (this.viewer && this.models.length > 0) {
      this.hasMetadata =
        this.models.length === 1
          ? this.models[0].metadata
          : this.models.find(m => m.isMain) !== undefined
          ? this.models.find(m => m.isMain).metadata
          : false
    } else {
      if (this.viewer) {
        const models = this.viewer.getVisibleModels()

        if (models.length) {
          for (const model of this.viewer.getVisibleModels()) {
            this.viewer.unloadModel(model)
          }
        }
      } else if (!this.viewer && this.models.length) {
        this.viewer = await initViewer(this.$refs.viewer as HTMLElement)

        this.setViewerEvents()

        loadModels(this.models, this.viewer)
      } else {
        this.viewer = await initViewer(this.$refs.viewer as HTMLElement)

        this.setViewerEvents()
      }
    }
  }

  @Watch('activeModels', { deep: true })
  async onActiveModelsChange(actual, previous) {
    if (!this.viewer) return

    Store.resetUrlLikes()

    const models = this.viewer.getVisibleModels()

    if (!models.length) return

    if (!previous.length) {
      previous = actual.map(a => {
        return {
          active: false,
          objectId: a.objectId
        }
      })
    }

    if (this.viewer && this.models.length && actual.length) {
      actual.forEach((actualModel, index) => {
        if (actualModel.active !== previous[index].active) {
          const model = models.find(
            m => m.getData('text/plain').urn === makeUrn(actualModel.objectId)
          )

          if (actualModel.active && model) {
            const hiddenNodes = this.viewer.getAggregateHiddenNodes()

            hiddenNodes.forEach(nodes => {
              this.viewer.hide(nodes.ids, nodes.model)
            })

            this.viewer.show(1, model)
          } else if (!actualModel.active && model) {
            this.viewer.hide(1, model)
          }
        }
      })
    }
  }

  @Watch('modelsColor')
  onModelsColorChange(actual: string[], previous: string[]) {
    if (actual.length && this.viewer) {
      let diff

      actual.forEach((color, index) => {
        if (color !== previous[index]) {
          diff = { model: this.models[index], color }
        }
      })

      this.colorModel(diff)
    }
  }

  @Watch('modelFromAutodeskBrowser')
  async onModelFromAutodeskBrowserChange() {
    this.show.uploadModal = false
    this.addThreeLeggedModel()
    await this.storeThreeLeggedModel()
    this.views = await getViews(this.modelFromAutodeskBrowser.id)
    this.show.views = true
  }

  @Watch('progress')
  onProgressChange() {
    if (this.progress === 'complete') {
      this.progressComplete()
    }
  }

  @Watch('file')
  onFileChange() {
    if (this.file) this.uploadModel(this.file)
  }

  @Watch('showRooms')
  onShowRoomsChange() {
    this.handleRoomsCase()
  }

  @Watch('showOverlay')
  onShowOverlayChange() {
    if (this.showOverlay) {
      this.setOverlay()
    }
  }

  beforeDestroy() {
    if (this.viewer) {
      this.viewer.tearDown(true)
    }

    this.loadedModelsCounter = 0

    Store.setProgress({
      progress: '',
      rate: ''
    })

    Store.setAssetForViewer(null)
  }
}
