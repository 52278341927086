
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { Hub } from '@/store/Models'
import PlanCard from '@/components/Dashboard/Assets/PlanCard.vue'
import { directive as onClickaway } from 'vue-clickaway'
import Button from '@/components/shared/Button.vue'
import ContactForm from '@/components/Dashboard/Assets/Modals/ContactForm.vue'

@Component({
  components: { ContactForm, Button, PlanCard },
  directives: { onClickaway }
})
export default class ChangeHubPlan extends Vue {
  @Prop() hub!: Hub

  private selectedPricingPlan: string = ''
  private clicked: boolean = false

  beforeMount() {
    this.selectedPricingPlan = new String(this.hub.pricingPlan) as string
  }

  @Emit()
  sendPricingPlan(): { hubId: string; pricingPlan: string } {
    this.clicked = true

    return {
      hubId: this.hub.id,
      pricingPlan: this.selectedPricingPlan
    }
  }

  @Emit() close() {}
}
