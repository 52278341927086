
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import IconHub from '@/assets/icons/planet.svg'
import IconAdd from '@/assets/icons/add.svg'
import IconCrown from '@/assets/icons/crown.svg'
import Store from '@/store/modules/Store'
import { Hub } from '@/store/Models'
import IconBars from '@/assets/icons/bars.svg'
import IconTitle from '@/assets/icons/signature.svg'
import IconChange from '@/assets/icons/change.svg'
import IconTrash from '@/assets/icons/trash-sm.svg'
import { directive as onClickaway } from 'vue-clickaway'
import Input from '@/components/shared/Input.vue'
import Button from '@/components/shared/Button.vue'
import IconSpinner from '@/assets/icons/spinner-solid.svg'
import IconChart from '@/assets/icons/chart-line.svg'
import IconUpdate from '@/assets/icons/update.svg'
import DeleteWarning from '@/components/Dashboard/Assets/Modals/DeleteWarning.vue'
import UpdateXlsx from '@/components/Dashboard/Assets/Modals/UpdateXlsx.vue'

@Component({
  components: {
    UpdateXlsx,
    Button,
    DeleteWarning,
    IconAdd,
    IconBars,
    IconChange,
    IconCrown,
    IconHub,
    IconTitle,
    IconTrash,
    IconUpdate,
    IconSpinner,
    Input,
    IconChart
  },
  directives: { onClickaway }
})
export default class Hubs extends Vue {
  @Prop() hubs!: Hub[]
  @Prop() loading!: boolean

  private showHint: boolean = false
  private collapsable: boolean = false
  private firstTime: boolean = true
  private showChangeTitle: boolean = false
  private title: string = ''
  private showDeleteWarning: boolean = false
  private showUpdateData: boolean = false

  private optionsHub: { title: string; icon?: Vue.Component }[] = [
    {
      title: this.$t('hubsOptions.changeTitle') as string,
      icon: IconTitle
    },
    {
      title: this.$i18n.t('showSharedData') as string,
      icon: IconChart
    },
    {
      title: this.$t('hubsOptions.changePlan') as string,
      icon: IconChange
    },

    {
      title: this.$t('hubsOptions.updateData') as string,
      icon: IconUpdate
    },
    {
      title: this.$t('hubsOptions.deleteHub') as string,
      icon: IconTrash
    }
  ]

  private get selectedHub(): any {
    return Store.hub
  }

  private get hubId(): string {
    return Store.hub.id
  }

  private get showOptionsHub() {
    return Store.showOptionsHub
  }

  async mounted() {
    this.$root.$on('change-plan-indication', (show: boolean) => {
      this.showHint = show
    })

    this.$root.$on('select-first-hub', () => {
      if (this.hubs.length) {
        this.chooseHub(this.selectedHub)
      }
    })
  }

  private getSelectedHubStyle(hub) {
    const selected = this.selectedHub
    return {
      'hover:bg-gray-200': hub.pricingPlan === 'free',
      'hover:bg-green-200': hub.pricingPlan === 'plus',
      'hover:bg-yellow-200': hub.pricingPlan === 'premium',
      'bg-blue-100':
        hub.title === selected.title && selected.pricingPlan === 'free',
      'bg-green-100':
        hub.title === selected.title && selected.pricingPlan === 'plus',
      'bg-yellow-100':
        hub.title === selected.title && selected.pricingPlan === 'premium'
    }
  }

  private createHub() {
    this.$root.$emit('show-model-create-hub')
  }

  private chooseHub(hub: any) {
    Store.setHub(hub)
  }

  private closeOptionsHub() {
    Store.setShowOptionsHub(false)
  }

  private handleOption(index: number) {
    if (index === 0) {
      this.showChangeTitle = true
      Store.setShowOptionsHub(false)
    } else if (index === 1) {
      this.$root.$emit('show-resources', this.selectedHub.id)
    } else if (index === 2) {
      this.changePlan(this.selectedHub)
      this.closeOptionsHub()
    } else if (index === 3) {
      this.showUpdateData = true
    } else if (index === 4) {
      this.showDeleteWarning = true
    }
  }

  private closeChangeTitle() {
    this.showChangeTitle = false
  }

  private newHubTitle(value: string) {
    this.title = value
  }

  private updateHubTitle() {
    if (this.selectedHub.title !== this.title) {
      this.updateHub(this.selectedHub.id, this.title)
    }
  }

  private async updateHub(hubId: string, hubTitle: string) {
    try {
      const {
        data: { updateHubTitle }
      } = await this.$apollo.mutate({
        variables: { hubId, hubTitle },
        mutation: require('@/graphql/mutations/update-hub-title.graphql')
      })

      if (updateHubTitle) {
        this.showChangeTitle = false
        this.$root.$emit('refresh-hubs')
        setTimeout(() => {
          this.$toasted.success(this.$i18n.t('updateHubTitle') as string)
        }, 250)
      } else {
        this.$toasted.error(this.$i18n.t('errors.updateHubTitle') as string)
      }
    } catch {
      this.$toasted.error(this.$i18n.t('errors.updateHubTitle') as string)
    }
  }

  private async deleteHub() {
    const hubId = this.selectedHub.id

    const {
      data: { deleteHub }
    } = await this.$apollo.mutate({
      variables: { hubId },
      mutation: require('@/graphql/mutations/delete-hub.graphql')
    })

    if (deleteHub) {
      this.refreshHubs()
      this.showDeleteWarning = false
      this.$toasted.success(this.$i18n.t('success.deleteHub') as string)
    }
  }

  private closeUpdateXlsx() {
    this.showUpdateData = false
  }

  @Watch('hubs')
  onHubsChange() {
    if (this.hubs.length && this.firstTime) {
      this.chooseHub(this.hubs[0])

      this.firstTime = false
    }
  }

  @Emit()
  changePlan(hub: Hub): Hub {
    return hub
  }

  @Emit() refreshHubs() {}
}
