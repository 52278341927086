var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"headers",staticClass:"table-cells-headers",style:(`width: ${_vm.wrapperWidth}px`)},[(!_vm.activeRows)?_c('p',{staticClass:"table-cells-active-headers",class:{ 'disabled': !_vm.isOwner || _vm.isDemoModel },domProps:{"textContent":_vm._s(_vm.$t('tableHasNoFields'))},on:{"click":_vm.showAddField}}):_vm._e(),(
      _vm.activeHeaders &&
      _vm.activeHeaders.length &&
      _vm.selectedTable.origin !== 'bimtable'
    )?_c('div',{staticClass:"panel-header-name"},[_c('p',{domProps:{"textContent":_vm._s('Name')}}),_c('div',{attrs:{"title":_vm.$t('titles.optionsFields')},on:{"click":function($event){return _vm.setHeaderRequested('name')}}},[_c('IconCaretDown',{staticClass:"panel-header-caret"})],1)]):_vm._e(),_vm._l((_vm.filteredHeaders),function(header,index){return _c('div',{key:index,staticClass:"panel-header-items",class:{
      'opacity-0': index === 0 && _vm.selectedTable.origin !== 'bimtable',
      'bg-white': index !== 0,
      'bg-blue-200': header.origin && header.origin === 'xlsx',
      'bg-green-200': header.origin && header.origin === 'bimtable'
    },style:((index === 1 || index === 2) && _vm.selectedTable.origin !== 'bimtable'
        ? 'display: none;'
        : '')},[(header.origin && header.origin === 'xlsx')?_c('IconXlsx',{staticClass:"panel-header-xlsx-headers"}):_vm._e(),(header.showOnlyOwner)?_c('IconStar',{staticClass:"h-4 mr-2 text-yellow-600",attrs:{"title":_vm.$t('ownerField')}}):_vm._e(),(header.origin && header.origin === 'bimtable')?_c('div',{staticClass:"panel-header-bimtable"},[_c('img',{staticClass:"bg-cover",attrs:{"src":require("@/assets/images/B.png"),"alt":""}})]):_vm._e(),_c('p',{staticClass:"pr-1 text-left truncate w-60",attrs:{"title":header.header},domProps:{"textContent":_vm._s(header.header)}}),_c('div',{attrs:{"title":_vm.$t('titles.optionsFields')},on:{"click":function($event){return _vm.handleHeader(header)}}},[_c('IconCaretDown',{staticClass:"panel-header-caret"})],1)],1)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tableOrigin === ''),expression:"tableOrigin === ''"}],staticClass:"bg-white table-cells-field",class:{ 'disabled': !_vm.isOwner || _vm.isDemoModel },attrs:{"title":!_vm.isOwner || _vm.isDemoModel ? _vm.$t('errors.notOwner') : ''},on:{"click":function($event){return _vm.showAddField()}}},[_c('p',{staticClass:"pr-4 ml-2 min-w-30",domProps:{"textContent":_vm._s(_vm.$t('addField'))}})])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }