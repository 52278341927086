
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import Button from '@/components/shared/Button.vue'
import Icon3d from '@/assets/icons/cube.svg'
import IconSpinner from '@/assets/icons/spinner-solid.svg'
import IconChevron from '@/assets/icons/chevron-down.svg'

@Component({
  components: { Button, IconSpinner, Icon3d, IconChevron }
})
export default class ViewsSelection extends Vue {
  @Prop() views!: any[]

  private selected: any = null
  private clicked: boolean = false
  private discardData: boolean = false

  private show = {
    '3d': true,
    '2d': false
  }

  mounted() {
    this.$root.$on('allow-guid-selection', () => {
      this.clicked = false
    })
  }

  private checkAction() {
    this.discardData ? this.close() : this.selectView()
  }

  @Emit() close() {}

  @Emit() selectView(): any {
    this.clicked = true
    return this.selected
  }
}
