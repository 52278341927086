export default `
  <html lang="es">
    <head>
        <title>Imprimir Likes</title>
        <style>
            @media print {
                body {
                    margin: 0;
                }
                .print-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100vh;
                    margin: 2rem;
                }
                img {
                    width: 210mm;
                    height: auto;
                    max-height: calc(297mm - 4rem);
                }
            }
        </style>
    </head>
    <body>
`
