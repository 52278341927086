
import { Vue, Component, Emit, Prop } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'
import { apolloProvider, onLogout } from '@/vue-apollo'
import { User } from '@/store/Models'
import Store from '@/store/modules/Store'
import IconLogout from '@/assets/icons/power-off.svg'
import Dropdown from '@/components/shared/Dropdown.vue'
import Avatar from '@/components/Dashboard/Sidebar/Avatar.vue'
import IconCheck from '@/assets/icons/check.svg'
import IconCancel from '@/assets/icons/x.svg'
import IconSpinner from '@/assets/icons/spinner-solid.svg'

@Component({
  components: {
    Avatar,
    Dropdown,
    IconLogout,
    IconCheck,
    IconCancel,
    IconSpinner
  },
  directives: { onClickaway }
})
export default class UserSettings extends Vue {
  @Prop() fromWeb!: boolean

  private langOptions: any[] = ['en', 'es', 'ca']
  private imageDom: string = ''
  private imageFile: File = null
  private uploadingFile: boolean = false
  private preSelectedLanguage: string = ''

  created() {
    this.setPreSelected(localStorage.getItem('BIMTable-lang'))
  }
  mounted() {
    this.refreshLanguages()
  }

  private refreshLanguages() {
    const lang = this.$i18n.locale
    this.langOptions.splice(
      this.langOptions.findIndex(l => l === lang),
      1
    )
    this.langOptions.unshift(lang)

    this.langOptions = this.langOptions.map(l => {
      return { name: this.$i18n.t(`lang.${l}`) as string }
    })
  }

  private logout() {
    const { defaultClient } = apolloProvider()

    onLogout(defaultClient)
    this.$router.replace({ name: 'Home' })
  }

  private get user(): User {
    return Store.user
  }

  private changeLanguage(language: string) {
    switch (language) {
      case this.$i18n.t('lang.en') as string:
        this.$i18n.locale = 'en'
        localStorage.setItem('BIMTable-lang', 'en')
        break
      case this.$i18n.t('lang.es') as string:
        this.$i18n.locale = 'es'
        localStorage.setItem('BIMTable-lang', 'es')
        break
      case this.$i18n.t('lang.ca') as string:
        this.$i18n.locale = 'ca'
        localStorage.setItem('BIMTable-lang', 'ca')
        break
    }
  }

  private setPreSelected(language: string) {
    switch (language) {
      case 'en':
        this.preSelectedLanguage = this.$i18n.t('lang.en') as string
        break
      case 'es':
        this.preSelectedLanguage = this.$i18n.t('lang.es') as string
        break
      case 'ca':
        this.preSelectedLanguage = this.$i18n.t('lang.ca') as string
        break
    }
  }

  private openBrowser() {
    document.getElementById('image').click()
  }

  private async sendFile(image: File) {
    if (image) {
      this.imageFile = image
      const decoded = await image.arrayBuffer()
      const data = new Uint8Array(decoded)
      const blob = new Blob([data.buffer], { type: 'image/png' })
      this.imageDom = URL.createObjectURL(blob)
    }
  }

  private async updateAvatar() {
    try {
      this.uploadingFile = true
      const image = this.imageFile

      const {
        data: { updateAvatar }
      } = await this.$apollo.mutate({
        variables: { image },
        mutation: require('@/graphql/mutations/update-avatar.graphql')
      })

      if (updateAvatar) {
        Store.setUserTempImage(updateAvatar)

        this.$toasted.success(this.$i18n.t('success.avatar') as string)
        this.$root.$emit('refresh-avatar')
      } else {
        this.$toasted.success(this.$i18n.t('errors.update') as string)
      }
    } catch (error) {
      this.$toasted.success(this.$i18n.t('errors.update') as string)

      console.error(error, 'error')
    }
  }

  // private showSharedData() {
  //   this.$toasted.error(this.$i18n.t('notAvailable.sharedData') as string)
  // }

  private editPersonalData() {
    this.$toasted.error(this.$i18n.t('notAvailable.personalData') as string)
  }

  // todoo: Enable emit when options are available

  // @Emit() showSharedData() {
  //   this.close()
  // }

  @Emit() deleteAnalytics() {}

  @Emit() regenerateAnalytics() {}
  @Emit() close() {}
  @Emit() showIntegrations() {}

  @Emit() showSharedData() {}

  beforeDestroy() {
    this.uploadingFile = false
  }
}
