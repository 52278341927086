
import { Vue, Component, Emit } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'
import Input from '@/components/shared/Input.vue'
import Button from '@/components/shared/Button.vue'
import isEmail from '@/helpers/isEmail'
import toUser from '@/helpers/toUser'
import Logo from '@/components/shared/Logo.vue'
import ReCaptcha from '@/components/Login/ReCaptcha.vue'

@Component({
  components: { Logo, Button, Input, ReCaptcha },
  directives: { onClickaway }
})
export default class RegisterModal extends Vue {
  private email: string = ''
  private password: string = ''
  private loading: boolean = false
  private username: string = ''
  private lastName: string = ''
  private organization: string = ''
  private phone: string = ''

  private get fieldsNotValid(): boolean {
    return !isEmail(this.email) || this.password.length < 6
  }

  private async register() {
    this.loading = true

    const user = toUser(
      this.email.toLowerCase().trim(),
      this.password.trim(),
      this.username.trim(),
      this.lastName.trim(),
      this.organization.trim(),
      this.phone.trim()
    )

    try {
      const {
        data: { register }
      } = await this.$apollo.mutate({
        variables: { user },
        mutation: require('@/graphql/mutations/register.graphql')
      })

      this.processRegister(register)
    } catch {
      this.$toasted.error(this.$i18n.t('errors.register') as string)
    } finally {
      this.loading = false
    }
  }

  private processRegister(register: boolean | null) {
    if (register === null) {
      this.$toasted.error(this.$i18n.t('errors.register') as string)
    } else if (register) {
      this.$toasted.success(this.$i18n.t('success.register') as string)
      this.closeModal()
    } else {
      this.$toasted.error(this.$i18n.t('errors.alreadyRegistered') as string)
      this.password = ''
    }
  }

  @Emit() closeModal() {
    if (this.$route.hash) this.$router.replace({ hash: '' })
  }
}
