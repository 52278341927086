
import { Vue, Component, Emit } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'
import Button from '@/components/shared/Button.vue'

@Component({
  components: { Button },
  directives: { onClickaway }
})
export default class OverWriteWarning extends Vue {
  @Emit() overwrite() {}
  @Emit() close() {}
}
