
import { Component, Emit, Vue, Watch } from 'vue-property-decorator'
import Store from '@/store/modules/Store'
import { directive as onClickaway } from 'vue-clickaway'
import IconLike from '@/assets/icons/heart.svg'
import Button from '@/components/shared/Button.vue'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'

@Component({ components: { IconLike, Button }, directives: { onClickaway } })
export default class LikeGallery extends Vue {
  private typeFullScreen: string = ''
  private imageUrl: string = ''
  private isZoomed: boolean = false
  private zoomStyle: any = null

  private get urlLikes(): string[] {
    return Store.urlLikes
  }

  mounted() {
    this.urlLikes.forEach((url, index) => {
      if (!this.checkIsImage(url)) {
        this.renderPDF(url, `canvas-${index}`)
      }
    })
  }

  private checkIsImage(url: string): boolean {
    return /\.(jpg|jpeg|png|gif)$/i.test(url.toLowerCase())
  }

  private async renderPDF(url: string, canvasRef: string) {
    try {
      const response = await fetch(url)
      const arrayBuffer = await response.arrayBuffer()

      await this.renderThumbnail(arrayBuffer, canvasRef)
    } catch (error) {
      console.error('error', error)
    }
  }

  private async renderThumbnail(arrayBuffer: any, canvasRef: any) {
    const canvas = document.getElementById(canvasRef) as HTMLCanvasElement
    const context = canvas.getContext('2d')

    try {
      const loadingTask = window['pdfjsLib'].getDocument({ data: arrayBuffer })
      const pdf = await loadingTask.promise
      const pageNumber = 1
      const page = await pdf.getPage(pageNumber)

      const scale = 1.5
      const viewport = page.getViewport({ scale })

      canvas.width = viewport.width
      canvas.height = viewport.height

      const renderContext = {
        canvasContext: context,
        viewport: viewport
      }

      const renderTask = page.render(renderContext)
      await renderTask.promise

      const imgData = canvas.toDataURL('image/jpeg')

      const img = new Image()
      img.onload = () => {
        context.drawImage(img, 0, 0)
      }

      img.src = imgData
    } catch (error) {
      console.error('error-pdf', error)
    }
  }

  private handleDeleteLikeList(index: number) {
    Store.removeFromUrlLikes(this.urlLikes[index])
  }

  private async downloadUrlLikes() {
    const zip = new JSZip()

    for (const url of this.urlLikes) {
      const response = await fetch(url)
      const blob = await response.blob()
      const filename = url.split('/').pop()
      zip.file(filename, blob)
    }

    const content = await zip.generateAsync({ type: 'blob' })
    saveAs(content, 'BIMtable-likes.zip')
    this.$toasted.success(this.$i18n.t('success.downloadSuccess') as string)
  }

  private handleResetLikes() {
    Store.resetUrlLikes()

    this.close()
  }

  private imageDetail(url: string) {
    const win = window.open(url, '_blank')
    win.focus()
  }

  private imageFullScreen(url: string) {
    this.imageUrl = url
    this.typeFullScreen = 'img'

    setTimeout(() => this.applyZoom(), 200)
  }

  private pdfFullScreen(url: string) {
    this.imageUrl = url
    this.typeFullScreen = 'pdf'
    this.renderPDF(url, 'canvas-fullscreen')

    setTimeout(() => this.applyZoom(), 200)
  }

  private closeFullScreen() {
    this.typeFullScreen = ''
    this.isZoomed = false
  }

  private applyZoom() {
    const imgContainer = document.getElementById('img-container')
    let zoomImage = document.getElementById('zoom-image')

    if (!zoomImage) {
      zoomImage = document.getElementById('')
    }

    let isDragging = false,
      scale = 1,
      initialPinchDistance = null

    let startX, startY, scrollLeft, scrollTop

    let initialScale = scale

    imgContainer.addEventListener('mousemove', e => {
      if (!isDragging) return

      e.preventDefault()

      const x = e.pageX - imgContainer.offsetLeft
      const y = e.pageY - imgContainer.offsetTop

      const walkX = (x - startX) * 2
      const walkY = (y - startY) * 2

      imgContainer.scrollLeft = scrollLeft - walkX
      imgContainer.scrollTop = scrollTop - walkY
    })

    imgContainer.addEventListener('mousedown', e => {
      isDragging = true

      startX = e.pageX - imgContainer.offsetLeft
      startY = e.pageY - imgContainer.offsetTop

      scrollLeft = imgContainer.scrollLeft
      scrollTop = imgContainer.scrollTop
    })

    imgContainer.addEventListener('mouseleave', () => {
      isDragging = false
    })

    imgContainer.addEventListener('mouseup', () => {
      isDragging = false
    })

    imgContainer.addEventListener('touchstart', e => {
      if (e.touches.length === 1) {
        isDragging = true

        startX = e.touches[0].pageX - imgContainer.offsetLeft
        startY = e.touches[0].pageY - imgContainer.offsetTop

        scrollLeft = imgContainer.scrollLeft
        scrollTop = imgContainer.scrollTop
      } else if (e.touches.length === 2) {
        isDragging = false

        initialPinchDistance = getPinchDistance(e.touches)

        initialScale = scale
      }
    })

    imgContainer.addEventListener('touchmove', e => {
      if (e.touches.length === 1 && isDragging) {
        e.preventDefault()

        const x = e.touches[0].pageX - imgContainer.offsetLeft
        const y = e.touches[0].pageY - imgContainer.offsetTop

        const walkX = (x - startX) * 2
        const walkY = (y - startY) * 2

        imgContainer.scrollLeft = scrollLeft - walkX
        imgContainer.scrollTop = scrollTop - walkY
      } else if (e.touches.length === 2) {
        e.preventDefault()

        const currentPinchDistance = getPinchDistance(e.touches)

        scale = initialScale * (currentPinchDistance / initialPinchDistance)
        scale = Math.min(Math.max(1, scale), 4)

        zoomImage.style.transform = `scale(${scale})`
      }
    })

    imgContainer.addEventListener('touchend', () => {
      isDragging = false
    })

    imgContainer.addEventListener('touchcancel', () => {
      isDragging = false
    })

    zoomImage.addEventListener('mousemove', applyZoom)

    function applyZoom(e) {
      const rect = e.target.getBoundingClientRect()

      const offsetX = e.clientX - rect.left
      const offsetY = e.clientY - rect.top

      const centerX = (offsetX / rect.width) * 100
      const centerY = (offsetY / rect.height) * 100

      zoomImage.style.transformOrigin = `${centerX}% ${centerY}%`
      zoomImage.style.transform = 'scale(2)'
    }

    zoomImage.addEventListener('mouseleave', () => {
      zoomImage.style.transform = 'scale(1)'
    })

    imgContainer.addEventListener('wheel', e => {
      e.preventDefault()

      scale += e.deltaY * -0.01
      scale = Math.min(Math.max(1, scale), 4)

      zoomImage.style.transform = `scale(${scale})`
    })

    function getPinchDistance(touches) {
      const dx = touches[0].pageX - touches[1].pageX
      const dy = touches[0].pageY - touches[1].pageY

      return Math.sqrt(dx * dx + dy * dy)
    }
  }

  private escapeEvent(event) {
    if (event.key === 'Escape') {
      this.typeFullScreen = ''
    }
  }

  @Watch('typeFullScreen')
  onTypeFullScreenChange() {
    if (this.typeFullScreen) {
      document.addEventListener('keydown', this.escapeEvent)
    } else {
      document.removeEventListener('keydown', this.escapeEvent)
    }
  }

  @Emit() close() {}
}
