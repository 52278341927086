
import { Vue, Component, Emit } from 'vue-property-decorator'
import Input from '@/components/shared/Input.vue'
import Button from '@/components/shared/Button.vue'
import isEmail from '@/helpers/isEmail'
import { onLogin } from '@/vue-apollo'
import Store from '@/store/modules/Store'
import Logo from '@/components/shared/Logo.vue'
// import LogoKronos from '../../assets/icons/kronos.svg'

@Component({
  components: { Button, Input, Logo /* LogoKronos */ }
})
export default class LoginForm extends Vue {
  private email: string = ''
  private password: string = ''
  private loading: boolean = false
  private nonReducedViewport: boolean = true

  private get fieldsNotValid(): boolean {
    return !this.isEmail || this.password.length < 6
  }

  private get isEmail() {
    return isEmail(this.email)
  }

  async created() {
    if (Store.invitation) {
      this.$toasted.success(this.$i18n.t('success.accessToGetShared') as string)
    }
  }

  mounted() {
    this.handleViewportHeight()

    document.getElementById('email').focus()
  }

  private handleViewportHeight() {
    this.nonReducedViewport = window.innerHeight > 500

    // @ts-ignore
    window.addEventListener('resize', ({ srcElement: { innerHeight } }) => {
      this.nonReducedViewport = innerHeight > 500
    })
  }

  private async login() {
    this.loading = true

    const user = {
      email: this.email.toLowerCase().trim(),
      password: this.password.trim()
    }

    try {
      const {
        data: { login }
      } = await this.$apollo.query({
        variables: { user },
        query: require('@/graphql/queries/login.graphql')
      })

      await onLogin(this.$apollo.provider.defaultClient, login)
      Store.setUser(login)
      this.$toasted.success(this.$i18n.t('success.login') as string)

      setTimeout(
        async () => await this.$router.replace({ name: 'Dashboard' }),
        200
      )
    } catch {
      this.$toasted.error(this.$i18n.t('errors.login') as string)
      this.password = ''
    } finally {
      this.loading = false
    }
  }

  @Emit() showRegisterModal() {}
  @Emit() showRecoverPassword() {}
}
