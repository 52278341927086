export default (email, password, username, lastName, organization, phone) => {
  return {
    email,
    password,
    username,
    lastName,
    organization,
    phone
  }
}
