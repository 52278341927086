
import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator'
import IconUnselected from '@/assets/icons/square.svg'
import IconSelected from '@/assets/icons/square-check.svg'
import IconStar from '@/assets/icons/star.svg'

@Component({
  components: { IconStar, IconSelected, IconUnselected }
})
export default class ImportOverwriteXlxs extends Vue {
  @Prop() title!: string
  @Prop() fields!: any[]
  @Prop() selectedFields?: boolean[]
  @Prop() mapFieldIndex!: number
  @Prop() tableXlsxHeaders: string[]

  private checkTableXlsxImport(header: any): boolean {
    return this.tableXlsxHeaders.includes(header.replace(/\r?\n/g, ' '))
  }
  @Emit()
  swapSelect(index: number): number {
    return index
  }

  @Emit()
  swapAllFieldsStatus(status: boolean): boolean {
    return status
  }

  @Emit()
  selectMainField(index: number): number {
    return index
  }
}
