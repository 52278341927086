import datasetColumnsData from './datasetColumnsData'
import setFilters from './setFilters'
import Cumulio from 'cumulio'

export default async (
  filters: { parameters: { datasetId: any; columnId: any }[] }[]
) => {
  try {
    const cumulio = new Cumulio({
      api_key: process.env.VUE_APP_CUMULIO_KEY as string,
      api_token: process.env.VUE_APP_CUMULIO_TOKEN as string
    })

    const where: any[] = []

    const { datasetId } = filters[0].parameters[0]

    const dataset_id = datasetId

    const { rows } = await datasetColumnsData(dataset_id)

    const requestedObjs = []

    for (const filter of filters) {
      const { columnId } = filter.parameters[0]

      requestedObjs.push({
        dataset_id,
        column_id: columnId,
        discretization: {
          type: 'none'
        }
      })
    }

    if (rows.length) {
      const { id } = rows[0].columns.find((row: { source_name: string }) => {
        return row.source_name === 'viewerId'
      })

      const modelNameCol = rows[0].columns.find(
        (row: { source_name: string }) => {
          return row.source_name === 'modelName'
        }
      ).id

      const column_id = id

      const dimensions = [
        {
          dataset_id,
          column_id,
          discretization: {
            type: 'none'
          }
        },
        {
          dataset_id,
          column_id: modelNameCol
        },
        ...requestedObjs
      ]

      setFilters(filters, where, dataset_id)

      // @ts-ignore
      const { data } = await cumulio.query({
        dimensions,
        where
      })

      return data
    }
  } catch (error) {
    console.error('error', error, filters)
  }
}

/*
import datasetColumnsData from './datasetColumnsData'
import setFilters from './setFilters'
// import Cumulio from 'cumulio'
import Luzmo from '@luzmo/nodejs-sdk'

export default async (data: any) => {
  try {
    const cumulio = new Luzmo({
      // api_key: process.env.VUE_APP_CUMULIO_KEY as string,
      // api_token: process.env.VUE_APP_CUMULIO_TOKEN as string
      api_key: process.env.VUE_APP_LUZMO_KEY,
      api_token: process.env.VUE_APP_LUZMO_TOKEN
    })

    const where: any[] = []

    console.log('⭐data⭐', data)

    const { datasetId, columnId } = data[0].filters[0].parameters[0]

    const dataset_id = datasetId

    const { rows } = await datasetColumnsData(dataset_id)

    if (rows.length) {
      const { id } = rows[0].columns.find((row: { source_name: string }) => {
        return row.source_name === 'viewerId'
      })

      const modelNameCol = rows[0].columns.find(
        (row: { source_name: string }) => {
          return row.source_name === 'modelName'
        }
      ).id

      const column_id = id

      const dimensions = [
        {
          dataset_id,
          column_id,
          discretization: {
            type: 'none'
          }
        },
        {
          dataset_id,
          column_id: modelNameCol
        },
        {
          dataset_id,
          column_id: columnId,
          discretization: {
            type: 'none'
          }
        }
      ]

      setFilters(data[0].filters, where, dataset_id)

      // @ts-ignore
      const get = await cumulio.query({
        dimensions,
        where
      })

      return get.data
    }
  } catch (error) {
    console.error('error', error, data.filters)
  }
}
*/
