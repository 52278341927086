
import { Vue, Component, Watch, Emit } from 'vue-property-decorator'
import Input from '@/components/shared/Input.vue'
import Store from '@/store/modules/Store'
import IconDown from '@/assets/icons/chevron-down.svg'
import IconUp from '@/assets/icons/chevron-up.svg'

@Component({
  components: { Input, IconDown, IconUp }
})
export default class Finder extends Vue {
  private search: string = ''
  private activeFoundIndex: number = 0

  private get results(): number {
    return Store.finderResults
  }

  mounted() {
    document.getElementById('input').focus()
  }

  private setFinderResults(): number {
    let counter: number = 0
    const searchIndexes: number[][] = []

    for (const row of Store.activeRows) {
      for (const value of row) {
        if (
          (typeof value === 'string' &&
            value.toLowerCase().includes(Store.search.toLowerCase())) ||
          (typeof value === 'number' && value.toString().includes(Store.search))
        ) {
          searchIndexes.push([
            Store.activeRows.indexOf(row),
            row.indexOf(value)
          ])

          counter++
        }
      }

      Store.setSearchIndexes(
        searchIndexes.filter(i => i[1] !== 1 && i[1] !== 2)
      )

      if (Store.searchIndexes.length) this.activeFoundIndex = 1
    }

    return counter
  }

  private handleInput(event: any) {
    this.search = event
  }

  @Watch('search')
  onSearchChange() {
    this.activeFoundIndex = 0
    Store.setSearch(this.search)
    Store.setFinderResults(this.search ? this.setFinderResults() : 0)
  }

  @Watch('activeFoundIndex')
  onActiveFoundIndexChange() {
    Store.setActiveSearchIndex(
      this.activeFoundIndex !== 0
        ? Store.searchIndexes[this.activeFoundIndex - 1]
        : []
    )
  }

  @Emit() close() {}
}
