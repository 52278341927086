
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import colors from '@/config/menus/colors'
import { ViewerModel } from '@/store/Models'
import ColorPicker from '@/components/Viewer/ColorPicker.vue'
import ModelActions from '@/components/Viewer/ModelActions.vue'
import IconEye from '@/assets/icons/eye.svg'
import IconEyeSlash from '@/assets/icons/eye-slash.svg'
import IconTick from '@/assets/icons/check.svg'
import { directive as onClickaway } from 'vue-clickaway'
import IconLock from '@/assets/icons/lock.svg'
import IconUnlock from '@/assets/icons/lock-open.svg'
import setModelConfig from '@/controllers/forgeViewer/setModelConfig'
import IconDb from '@/assets/icons/database.svg'
import IconTrash from '@/assets/icons/trash.svg'
import IconCheckboxOff from '@/assets/icons/square.svg'
import IconCheckboxOn from '@/assets/icons/square-check.svg'

@Component({
  components: {
    ModelActions,
    ColorPicker,
    IconEye,
    IconEyeSlash,
    IconLock,
    IconUnlock,
    IconTick,
    IconDb,
    IconTrash,
    IconCheckboxOff,
    IconCheckboxOn
  },
  directives: { onClickaway }
})
export default class ModelItem extends Vue {
  @Prop() model!: ViewerModel
  @Prop() fromWeb!: boolean
  @Prop() showSelectableCheckbox!: boolean
  @Prop() selectedModelsGroup!: string[]

  private colors: { title: string; value: string; bg: string }[] = colors
  private readonly showEyeSlash: boolean = false
  private readonly showActions: boolean = false
  private readonly isHovering: boolean = false

  private lockModel() {
    this.swapLockModel()
  }

  private handleModelColor(color: string) {
    this.model.color = color
    this.model.showColorPicker = false
    setModelConfig('color', this.model.objectKey, { color })

    if (!color) {
      this.$root.$emit('restore-model-color', this.model)
    }
  }

  private checkSwapTable() {
    this.swapTable()
  }

  private swapTable() {
    if (this.model.isMain) {
      this.hidePaneldata()
    } else {
      this.setMainModel()
    }
  }

  @Emit() resetPicker() {}
  @Emit() hidePaneldata() {}

  @Emit() selectModel(objectId: string): string {
    return objectId
  }

  @Emit() deleteModel() {
    return this.model
  }

  @Emit() setMainModel() {
    return this.model
  }

  @Emit() swapLockModel() {}

  @Emit() swapActive() {}

  @Emit() changeSelectionGroup(modelName: string, addToGroup: boolean) {
    return { modelName, addToGroup }
  }
}
