var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.activeHeaders && _vm.activeHeaders.length)?_c('div',{staticClass:"z-0 flex-col",class:{
    'h-0': _vm.minimize,
    'h-52': !_vm.minimize && !_vm.maximize
  },style:(`width: ${_vm.wrapperWidth}px; ${_vm.maximize ? 'height: 465px' : ''}`)},[(_vm.loading)?_c('IconSpinner',{staticClass:"mt-2 loader-spin-sm"}):_vm._e(),_vm._l((_vm.filteredActiveRows),function(row,index){return _c('div',{key:index,staticClass:"flex items-center block hover:bg-gray-100",class:{
      'bg-gray-100': _vm.activeSearchIndex && index === _vm.activeSearchIndex[0],
      'hidden': !_vm.filterList.includes(index) && _vm.filterActive
    },attrs:{"id":`id-${row[1]}`},on:{"click":function($event){return _vm.selectRow(row[1])}}},[_vm._l((row),function(r,pos){return _c('p',{key:pos,staticClass:"panel-cell min-w-64 max-w-64",class:{
        'panel-cell-name bg-white': pos === 0 && !_vm.selected(row[1]),
        'panel-cell-name bg-yellow-100': pos === 0 && _vm.selected(row[1]),
        'bg-yellow-100': _vm.selected(row[1]),
        'bg-blue-50':
          _vm.activeHeaders[pos].origin === 'xlsx' && !_vm.selected(row[1]),
        'hover:text-blue-500 hover:underline cursor-pointer': _vm.checkLink(r),
        'bg-yellow-200 font-bold':
          _vm.activeSearchIndex &&
          index === _vm.activeSearchIndex[0] &&
          pos === _vm.activeSearchIndex[1]
      },style:(pos === 1 || pos === 2 ? 'display: none;' : ''),domProps:{"textContent":_vm._s(r)},on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.goToLink(r)},"dblclick":function($event){$event.stopPropagation();return _vm.sendShowRowModal(index)}}})}),(_vm.bimtableRows)?_c('div',{staticClass:"flex"},_vm._l((_vm.splittedBimtableRows[index]),function(r,pos){return _c('Cell',{key:pos + _vm.activeHeaders.length,attrs:{"index":index,"pos":pos,"isSelected":_vm.selectedGeometries.includes(_vm.activeRows[index][1]),"activeSearchIndex":_vm.activeSearchIndex,"text":r},on:{"set-value":_vm.setInputValue}})}),1):_vm._e()],2)})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }