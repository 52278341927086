
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator'
import Store from '@/store/modules/Store'
import { ViewerModel } from '@/store/Models'
import Cell from '@/components/Viewer/TableData/TableCells/Cell.vue'
import IconSpinner from '@/assets/icons/spinner-solid.svg'

@Component({
  components: { Cell, IconSpinner }
})
export default class TableRecords extends Vue {
  @Prop() model!: ViewerModel
  @Prop() wrapperWidth!: number
  @Prop() maximize!: boolean
  @Prop() minimize!: boolean
  @Prop() scrollAmount!: number
  @Prop() filterActive!: boolean
  @Prop() scrollRowsCounter!: number
  @Prop() loading!: boolean

  @Prop() activeTable!: {
    title: string
    origin: string
    isEmpty: boolean
  }

  private clickedAtTable: boolean = false
  private lastSelection: any = null

  private get activeHeaders(): any[] {
    return Store.activeHeaders
  }

  private get activeRows(): any[][] {
    return Store.activeRows
  }

  private get selectedGeometries() {
    return Store.selectedGeometries
  }

  private get activeSearchIndex() {
    return Store.activeSearchIndex
  }

  private get filterList(): any[] {
    return Store.filterList
  }

  private get bimtableRows() {
    return Store.bimtableRows
  }

  private get selectedTableName(): string {
    return Store.selectedTableName
  }

  private get checkTableName(): string {
    if (this.selectedTableName.includes('Ifc')) {
      return ''
    } else {
      return this.selectedTableName
    }
  }

  mounted() {
    this.$root.$on(
      'drag-values',
      async ({ value, counter, /*isNegative,*/ index, pos }) => {
        for (let i = index + 1; i <= index + counter; i++) {
          if (i < this.activeRows.length) {
            this.bimtableRows[i][pos] = value
          }
        }

        this.$forceUpdate()
        await this.storeBimtableRowsValues()
      }
    )
  }

  private selected(viewerId: string) {
    return this.selectedGeometries.includes(viewerId)
  }

  private get filteredActiveRows(): any {
    const rows = []

    if (this.activeRows.length) {
      this.activeRows.slice(0, 20 + this.scrollRowsCounter).forEach(row => {
        const innerRow = []

        row.forEach((r, index) => {
          if (
            this.activeHeaders[index] &&
            !Store.hiddenHeaders.includes(this.activeHeaders[index].header)
          ) {
            innerRow.push(r)
          }
        })

        rows.push(innerRow)
      })
    }

    return rows
  }

  private selectRow(viewerId: string) {
    this.lastSelection = {
      viewerIds: [parseInt(viewerId)],
      objectId: this.model.objectId
    }

    this.selection()
  }

  private selection() {
    this.$root.$emit('pick-geometries', this.lastSelection)
    this.clickedAtTable = true
  }

  private scrollWrapper(amount: number) {
    // const wrapper = this.$refs.wrapper as HTMLElement

    const wrapper = document.getElementById('wrapper')

    this.setScrollAmount(amount)

    setTimeout(() => {
      wrapper.scroll({
        top: amount
      })
    }, 50)
  }

  private sendShowRowModal(index: number) {
    const status = true
    const tableName = this.activeTable.title
    const isGroups = false

    this.$root.$emit('show-row-modal', { index, status, tableName, isGroups })
  }

  private goToLink(value: string) {
    if (
      typeof value === 'string' &&
      value.startsWith('https://' || 'http://')
    ) {
      const win = window.open(value, '_blank')
      win.focus()
    }
  }

  private checkLink(value: string): boolean {
    return (
      typeof value === 'string' && value.startsWith('https://' || 'http://')
    )
  }

  private get splittedBimtableRows(): any {
    if (Store.bimtableRows.length > 20) {
      const rows = Array.from(Store.bimtableRows)
      return rows.slice(0, 20 + this.scrollRowsCounter)
    } else {
      return Store.bimtableRows
    }
  }

  private async setInputValue(payload: {
    value: string
    rowIndex: number
    headerIndex: number
    groupIndex: number | null
  }) {
    const { value, rowIndex, headerIndex } = payload

    this.bimtableRows[rowIndex][headerIndex] = value
    this.$forceUpdate()
    await this.storeBimtableRowsValues()
  }

  private async storeBimtableRowsValues() {
    const assetId = Store.assetForViewer.id
    const tableName = this.activeTable.title
    const modelId = this.model.objectId
    const modelName = this.model.objectKey
    const rows = JSON.stringify(this.bimtableRows)

    try {
      await this.$apollo.mutate({
        variables: { rows, assetId, modelId, modelName, tableName },
        mutation: require('@/graphql/mutations/edit-bimtable-value.graphql')
      })
    } catch (error) {
      console.log(error, 'error-edit-bimtable-value')
    }
  }

  @Watch('selectedGeometries')
  onSelectedGeometriesChange() {
    if (!this.clickedAtTable) {
      const firstSelected = this.selectedGeometries[0]
      let index = 0

      if (Store.activeRows.find(row => row[1] === firstSelected)) {
        index = Store.activeRows.findIndex(row => row[1] === firstSelected)

        this.scrollWrapper(index === -1 ? 0 : index * 24)
      }
    }

    setTimeout(() => {
      this.clickedAtTable = false
    }, 100)
  }

  @Emit() setScrollAmount(scroll: number): number {
    return scroll
  }
}
