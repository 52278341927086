
import { Vue, Component, Emit, Prop } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'

@Component({ directives: { onClickaway } })
export default class Modal360 extends Vue {
  @Prop() url360!: string

  mounted() {
    setTimeout(async () => {
      const a = await fetch(this.url360)

      const b = await a.blob()

      const r = new FileReader()

      r.onload = e => {
        const data = e.target.result

        // @ts-ignore
        const { pannellum } = window

        pannellum.viewer('360-modal', {
          type: 'equirectangular',
          panorama: data,
          autoLoad: true,
          showFullscreenCtrl: false,
          showControls: false,
          minPitch: -65,
          maxPitch: 65,
          minYaw: -160,
          maxYaw: 160
        })
      }

      r.readAsDataURL(b)
    }, 200)
  }
  @Emit() close() {}
}
