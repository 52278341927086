var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"modal-wrapper"},[(_vm.menu)?_c('div',{staticClass:"pb-6 modal-container-xl"},[_c('h1',{staticClass:"modal-title",domProps:{"textContent":_vm._s(_vm.$t('chooseCategories'))}}),_c('div',{staticClass:"flex items-center p-4 space-x-2 border rounded"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.discardData),expression:"discardData"}],staticClass:"cursor-pointer",attrs:{"type":"checkbox","name":"discardData","id":"discardData"},domProps:{"checked":Array.isArray(_vm.discardData)?_vm._i(_vm.discardData,null)>-1:(_vm.discardData)},on:{"change":function($event){var $$a=_vm.discardData,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.discardData=$$a.concat([$$v]))}else{$$i>-1&&(_vm.discardData=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.discardData=$$c}}}}),_c('p',{staticClass:"modal-subtitle",domProps:{"textContent":_vm._s(_vm.$t('chooseCategsInstructions'))}})]),_c('div',{staticClass:"categories-wrapper",class:{ 'disabled': _vm.clicked || _vm.discardData }},[_c('div',{staticClass:"mt-2 categories-scope"},[_c('p',{staticClass:"mb-16 text-center modal-subtitle",domProps:{"textContent":_vm._s(_vm.$t('categories'))}}),_vm._l((_vm.menu.categories),function(category,index){return _c('p',{key:index,staticClass:"flex items-center text-sm categories-title",class:{ 'bg-blue-100': _vm.activeIndex === index },on:{"click":function($event){_vm.activeIndex = index}}},[_c('span',{staticClass:"text-gray-700",domProps:{"textContent":_vm._s(category.title)}}),_c('span',{staticClass:"text-blue-400 truncate",domProps:{"textContent":_vm._s(
                `${_vm.metadata.geometriesAmount[index]} ${_vm.$t('geometries')}`
              )}})])})],2),(_vm.menu.categories.length)?_c('div',{staticClass:"categories-scope"},[_c('p',{staticClass:"text-center modal-subtitle",domProps:{"textContent":_vm._s(_vm.$t('properties'))}}),_c('div',{staticClass:"categories-header"},[_c('p',{staticClass:"categories-select-all",on:{"click":_vm.handleSelectAllProperties}},[_c('IconCheckbox',{staticClass:"categories-checkbox",class:{
                  'text-gray-300': _vm.menu.properties[_vm.activeIndex].every(
                    property => property.selected
                  )
                }}),_c('span',{domProps:{"textContent":_vm._s(_vm.selectAllProps ? _vm.$t('selectAll') : _vm.$t('deselectAll'))}})],1)]),_vm._l((_vm.menu.properties[_vm.activeIndex]),function(propGroup,index){return _c('div',{key:index,staticClass:"duration-default"},[_c('div',{staticClass:"categories-prop-group"},[_c('div',{staticClass:"flex items-center justify-between px-2",on:{"click":function($event){propGroup.show = !propGroup.show}}},[_c('div',{staticClass:"flex items-center w-full",class:{ 'border-b pb-1': propGroup.show }},[_c('IconCheckbox',{staticClass:"categories-checkbox",class:{
                      'text-gray-300': !propGroup.subProperties.some(
                        p => p.selected
                      )
                    },on:{"click":function($event){$event.stopPropagation();return _vm.switchSelectAllSubProperties(propGroup)}}}),_c('p',{staticClass:"text-sm",class:{
                      'text-gray-300': !propGroup.subProperties.some(
                        p => p.selected
                      )
                    },domProps:{"textContent":_vm._s(propGroup.propMain)}})],1),_c('IconChevron',{staticClass:"h-3"})],1),_c('Transition',{attrs:{"name":"fade"}},[(propGroup.show && propGroup.subProperties)?_c('div',{staticClass:"m-4 mb-0"},_vm._l((propGroup.subProperties),function(property,index){return _c('div',{key:index,staticClass:"categories-item",class:_vm.propertiesClasses(property, index),on:{"click":function($event){$event.stopPropagation();property.selected = !property.selected},"mouseover":function($event){_vm.showUnits = {
                        index,
                        main: propGroup.propMain,
                        prop: property.title
                      }},"mouseleave":function($event){_vm.showUnits = { index: null, main: null, prop: null }}}},[_c('IconCheckbox',{staticClass:"categories-checkbox",class:{ 'text-gray-300': !property.selected }}),(_vm.numeric(propGroup.propMain, property.title))?_c('span',{staticClass:"mr-2 text-blue-400",attrs:{"title":_vm.$t('numericProperty')},domProps:{"textContent":_vm._s('!')}}):_vm._e(),_c('p',{domProps:{"textContent":_vm._s(property.title)}}),_c('Transition',{attrs:{"name":"fade"}},[(
                          _vm.showUnits.index === index &&
                          _vm.showUnits.main === propGroup.propMain &&
                          _vm.showUnits.prop === property.title
                        )?_c('span',{staticClass:"categories-unit",domProps:{"textContent":_vm._s(_vm.getUnit(propGroup.propMain, property.title))}}):_vm._e()])],1)}),0):_vm._e()])],1)])})],2):_vm._e()]),(!_vm.metadata.categories.length || !_vm.metadata.properties.length)?_c('p',{staticClass:"text-xs text-red-400",domProps:{"textContent":_vm._s(_vm.$t('errors.metadata'))}}):_vm._e(),_c('Button',{class:{
          'disabled':
            _vm.clicked ||
            !_vm.metadata.categories.length ||
            !_vm.metadata.properties.length
        },attrs:{"text":_vm.discardData ? _vm.$t('continueWithoutStore') : _vm.$t('storeModelMetadata')},nativeOn:{"click":function($event){return _vm.checkSend.apply(null, arguments)}}}),(_vm.clicked)?_c('IconSpinner',{staticClass:"absolute loader-spin-sm bottom-4 right-4"}):_vm._e()],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }