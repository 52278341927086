
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator'
import Store from '@/store/modules/Store'
import { ViewerModel } from '@/store/Models'
import IconCaretDown from '@/assets/icons/caret-down.svg'
import { directive as onClickaway } from 'vue-clickaway'
import Finder from '@/components/Viewer/TableData/Finder.vue'
import IconHiddenFields from '@/assets/icons/align-slash.svg'
import IconSpinner from '@/assets/icons/spinner-solid.svg'
import setModelConfig from '@/controllers/forgeViewer/setModelConfig'
import Input from '@/components/shared/Input.vue'
import IconTrash from '@/assets/icons/trash-sm.svg'
import TableHeaders from '@/components/Viewer/TableData/TableCells/TableHeaders.vue'
import TableRecords from '@/components/Viewer/TableData/TableCells/TableRecords.vue'
import TableGroups from '@/components/Viewer/TableData/TableCells/TableGroups.vue'
import HeaderActions from '@/components/Viewer/TableData/TableCells/HeaderActions.vue'
import CreateCell from '@/components/Viewer/TableData/TableCells/CreateCell.vue'

@Component({
  apollo: {
    getHeaders: {
      skip() {
        const isEmpty = this.presetLoad

        return isEmpty === undefined || isEmpty === true
      },
      variables() {
        const tableName = this.activeTable.title
        return {
          objectId: this.model.objectId,
          modelName: this.model.objectKey,
          assetId: Store.assetForViewer.id,
          tableName
        }
      },
      query: require('@/graphql/queries/get-headers.graphql'),
      result({ data: { getHeaders } }) {
        this.loading = true
        if (getHeaders) {
          Store.setActiveHeaders(getHeaders)

          setTimeout(() => {
            this.getHiddenHeaders()
            this.getGroups()
            this.getFilters()
          }, 500)
        }
      }
    },
    getRows: {
      skip() {
        const isEmpty = this.presetLoad

        return isEmpty === undefined || isEmpty === true
      },
      variables() {
        if (!this.activeTable.title) return

        return {
          objectId: this.model.objectId,
          modelName: this.model.objectKey,
          assetId: Store.assetForViewer.id,
          tableName: this.activeTable.title
        }
      },

      query: require('@/graphql/queries/get-rows.graphql'),
      result({ data: { getRows } }) {
        const payload = JSON.parse(getRows)

        if (payload) {
          Store.setActiveRows(payload.rows)
          if (payload.bimtableRows !== undefined) {
            Store.setBimtableRows(payload.bimtableRows)
          }
        }
      }
    }
  },
  components: {
    CreateCell,
    Finder,
    HeaderActions,
    IconCaretDown,
    IconHiddenFields,
    IconSpinner,
    IconTrash,
    Input,
    TableGroups,
    TableHeaders,
    TableRecords
  },
  directives: { onClickaway }
})
export default class TableCells extends Vue {
  @Prop() model!: ViewerModel
  @Prop() activeTable!: {
    title: string
    origin: string
    isEmpty: boolean
  }
  @Prop() mode!: 'table' | 'connections'
  @Prop() minimize!: boolean
  @Prop() maximize!: boolean
  @Prop() sidebarOpen!: boolean

  private headerRequested: string = ''
  private wrapperWidth: number = 0
  private showFinder: boolean = false
  private scrollAmount: number = 0
  private scrollXAmount: number = 0
  private previousActionsIndex: string = ''
  private filterActive: boolean = false
  private groups: any = null
  private groupsPayload: any = null
  private valueInputGroup: string = ''
  private indexInput: any = null
  private indexHeader: any = null
  private indexRoot: any = null
  private scrollRowsCounter: number = 0
  private loading: boolean = false
  private showAddCell: boolean = false
  private selectedField: string = ''
  private presetLoad: boolean = false
  private noUpdateHeaders: boolean = false
  private selectedHeader: {
    header: string
    origin: string
    showOnlyOwner: boolean
  } = { header: '', origin: '', showOnlyOwner: false }

  private get filterList(): any[] {
    return Store.filterList
  }

  private get filterConditions(): any[] {
    return Store.filterConditions
  }

  private get tableViews(): any[] {
    return Store.tableViews
  }

  private get tableNames(): {
    title: string
    origin: string
    isEmpty: boolean
  }[] {
    return Store.tableName
  }

  private get selectedTableName(): string {
    return Store.selectedTableName
  }

  private get selectedTable(): {
    title: string
    origin: string
    isEmpty: boolean
  } {
    return Store.selectedTable
  }

  private get activeHeaders(): any[] {
    return Store.activeHeaders
  }

  private get activeRows(): any[][] {
    return Store.activeRows
  }

  private get hiddenHeaders(): any[] {
    return Store.hiddenHeaders
  }

  private get bimtableRows() {
    return Store.bimtableRows
  }

  async mounted() {
    this.$root.$on('show-finder', () => {
      this.showFinder = !this.showFinder
    })

    this.$root.$on('set-groups-table-view', payload => {
      if (payload) {
        this.groupsPayload = payload
        this.getGroups()
      }
    })

    this.$root.$on('refresh-table-groups', () => {
      setTimeout(() => {
        this.scrollTop()
        this.scrollRowsCounter = 0

        this.getGroups()
      }, 150)
    })

    this.$root.$on('delete-groups-payload', () => {
      this.groupsPayload = null
    })

    window.addEventListener('keydown', e => {
      if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)) {
        e.preventDefault()
        this.showFinder = !this.showFinder
      }
    })

    this.$root.$on('refresh-headers-rows', async ({ tableName }) => {
      const headers = await this.$apollo.queries.getHeaders.refetch({
        tableName,
        objectId: this.model.objectId,
        modelName: this.model.objectKey,
        assetId: Store.assetForViewer.id
      })

      if (headers) {
        await this.$apollo.queries.getRows.refetch({
          tableName,
          objectId: this.model.objectId,
          modelName: this.model.objectKey,
          assetId: Store.assetForViewer.id
        })
      }
    })

    this.$root.$on('remove-hidden-header', (header: string) => {
      this.removeHiddenHeader(header)
    })

    this.$root.$on('scroll-to-right-max', () => {
      const wrapper = this.$refs.wrapper as HTMLElement

      wrapper.scroll({
        left: this.wrapperWidth
      })
    })

    this.$root.$on('scroll-top-max', () => {
      this.scrollTop()
    })

    setTimeout(() => {
      const wrapper = this.$refs.wrapper as HTMLElement

      // @ts-ignore
      wrapper.onscroll = ({ target: { scrollLeft, scrollTop } }) => {
        this.scrollXAmount = scrollLeft

        this.scrollRowsCounter += this.maximize
          ? scrollTop / 10
          : scrollTop / 100
      }
    }, 50)

    this.$root.$on('exportCsv', () => {
      this.exportCsv()
    })

    this.$root.$on('disable-mode-table-view', () => {
      this.getHiddenHeaders()
      this.getGroups()
      this.getFilters()
    })

    this.$root.$on('show-create-cell', (field: string) => {
      this.selectedField = field
      this.showAddCell = true
    })

    this.$root.$on('no-update-rows', () => {
      this.noUpdateHeaders = true

      setTimeout(() => {
        this.noUpdateHeaders = false
      }, 600)
    })
  }

  private scrollTop() {
    const wrapper = this.$refs.wrapper as HTMLElement

    if (wrapper) {
      wrapper.scroll({
        top: 0
      })
    }
  }

  private removeHiddenHeader(header: string) {
    const modelConfig = Store.assetForViewer.configs.find(
      c => c.modelName === this.model.objectKey
    )

    if (modelConfig && modelConfig.hiddenHeaders) {
      let hiddenHeaders = modelConfig.hiddenHeaders.find(
        h => h.tableName === this.selectedTableName
      )

      if (hiddenHeaders && hiddenHeaders.fields) {
        const field = hiddenHeaders.fields.find(f => f === header)

        if (field === header) {
          hiddenHeaders.fields = hiddenHeaders.fields.filter(f => f !== field)

          if (!hiddenHeaders.fields.length) {
            modelConfig.hiddenHeaders = []

            const payload = {
              hiddenHeaders: {
                tableName: this.selectedTableName,
                fields: []
              }
            }
            setModelConfig('hidden-headers', this.model.objectKey, payload)
          }
        }
      }
    }

    if (Store.activeTableView !== null) {
      this.tableViews[Store.activeTableView].config.hiddenHeaders = []
    }

    if (this.groups) {
      this.setGroupsPayload()
    }
  }

  private getHiddenHeaders() {
    const modelConfig = Store.assetForViewer.configs.find(
      c => c.modelName === this.model.objectKey
    )

    if (modelConfig && modelConfig.hiddenHeaders) {
      const hidden = modelConfig.hiddenHeaders.find(
        h => h.tableName === this.activeTable.title
      )
      if (hidden && hidden.fields) {
        Store.setHiddenHeaders(hidden.fields)
      } else {
        Store.resetHiddenHeaders()
      }
    }
  }

  private getGroups() {
    const check =
      Store.activeTableView !== null &&
      this.tableViews[Store.activeTableView] &&
      this.tableViews[Store.activeTableView].config &&
      this.tableViews[Store.activeTableView].config.groups

    if (check) {
      const tableViewGroups = {
        groups: this.tableViews[Store.activeTableView].config.groups,
        tableName: this.activeTable.title
      }
      if (tableViewGroups.groups.length) {
        this.groupsPayload = tableViewGroups
        this.setGroupsPayload()

        Store.setGroupsItems(tableViewGroups)
      } else {
        Store.resetGroups()
        Store.resetGroupsItems()
      }
    } else {
      const modelConfig = Store.assetForViewer.configs.find(
        c => c.modelName === this.model.objectKey
      )

      if (modelConfig && modelConfig.groups) {
        const groups = modelConfig.groups.find(
          g => g.tableName === this.activeTable.title
        )
        if (groups) {
          this.groupsPayload = groups
          this.setGroupsPayload()

          Store.setGroupsItems(groups)
        } else {
          this.groups = []
          Store.resetGroups()
          Store.resetGroupsItems()
        }
      }
    }
  }

  private getFilters() {
    if (
      Store.activeTableView !== null &&
      this.tableViews[Store.activeTableView].config.filters
    ) {
      const tableViewFilters =
        this.tableViews[Store.activeTableView].config.filters
      if (tableViewFilters) {
        Store.setFilterConditions(tableViewFilters)
        this.filterActive = true

        this.$root.$emit('set-filters', tableViewFilters)
      }
    } else {
      const modelConfig = Store.assetForViewer.configs.find(
        c => c.modelName === this.model.objectKey
      )

      if (modelConfig && modelConfig.filters) {
        const filters = modelConfig.filters.find(
          // @ts-ignore
          f => f.tableName === this.activeTable.title
        )

        if (filters) {
          // @ts-ignore
          Store.setFilterConditions(filters.filters)
          this.filterActive = true

          this.$root.$emit('set-filters', filters)
        } else {
          Store.resetFilterConditions()
          this.filterActive = false
          this.$root.$emit('set-filters', filters)
        }
      }

      this.loading = false
    }
  }

  private get filteredHeaders(): any[] {
    if (this.activeHeaders) {
      return this.activeHeaders.filter(
        h => !Store.hiddenHeaders.includes(h.header)
      )
    } else {
      return []
    }
  }

  private setGroupsPayload() {
    if (this.groupsPayload) {
      let groups = {}

      const { header } = this.groupsPayload.groups[0]
      const index = this.activeHeaders.findIndex(h => h.header === header)

      for (const row of this.activeRows) {
        const value = row[index]

        if (groups[value] !== undefined) {
          if (Store.hiddenHeaders.length) {
            const filteredRow = []

            row.forEach((r, index) => {
              if (
                !Store.hiddenHeaders.find(
                  h => h === Store.activeHeaders[index].header
                )
              ) {
                filteredRow.push(r)
              }
            })

            groups[value].rows.push(filteredRow)
          } else {
            groups[value].rows.push(row)
          }

          if (groups[value].bimtableRows) {
            groups[value].bimtableRows.push(
              this.bimtableRows[this.activeRows.findIndex(r => r[1] === row[1])]
            )
          }
        } else {
          const rowIndex = this.activeRows.findIndex(r => r[1] === row[1])

          if (Store.hiddenHeaders.length) {
            const filteredRow = []

            row.forEach((r, index) => {
              if (
                !Store.hiddenHeaders.find(
                  h => h === Store.activeHeaders[index].header
                )
              ) {
                filteredRow.push(r)
              }
            })

            groups = {
              ...groups,
              [value]: {
                isCollapsed: false,
                rows: [filteredRow],
                bimtableRows:
                  this.bimtableRows && this.bimtableRows.length
                    ? [this.bimtableRows[rowIndex]]
                    : null
              }
            }
          } else {
            groups = {
              ...groups,
              [value]: {
                isCollapsed: false,
                rows: [row],
                bimtableRows:
                  this.bimtableRows && this.bimtableRows.length
                    ? [this.bimtableRows[rowIndex]]
                    : null
              }
            }
          }
        }
      }

      // Sort groups
      const { operator } = this.groupsPayload.groups[0]

      const defGroups = Object.keys(groups)

      switch (operator) {
        case this.$t('group.operators.aToZ') as string:
          defGroups.sort((a, b) => a.localeCompare(b))
          break
        case this.$t('group.operators.zToA') as string:
          defGroups.sort((a, b) => b.localeCompare(a))
          break
      }

      let resultGroups = {}

      for (const group of defGroups) {
        resultGroups[group] = groups[group]
      }

      this.groups = resultGroups

      Store.setGroups(resultGroups)
      this.$forceUpdate()
    }
  }

  private get filteredActiveRows(): any {
    const rows = []
    if (Store.activeRows.length) {
      Store.activeRows.slice(0, 20 + this.scrollRowsCounter).forEach(row => {
        const innerRow = []
        row.forEach((r, index) => {
          if (
            this.activeHeaders[index] &&
            !Store.hiddenHeaders.includes(this.activeHeaders[index].header)
          ) {
            innerRow.push(r)
          }
        })
        rows.push(innerRow)
      })
    }

    return rows
  }

  private exportCsv() {
    let text = `${this.filteredHeaders.map(h => h.header).join(';')}\n`

    for (const row of this.filteredActiveRows) {
      text += row.join(';')
      text += '\n'
    }

    const title = `${this.activeTable.title}.csv`

    const fileStr = new Blob([text], {
      type: 'text/plain'
    })

    const url = URL.createObjectURL(fileStr)
    const link = document.createElement('a')

    link.download = title
    link.href = url
    link.click()
  }

  @Watch('activeHeaders', { deep: true })
  async onActiveHeadersChange() {
    if (this.activeHeaders && !this.noUpdateHeaders) {
      this.wrapperWidth = 0

      if (this.hiddenHeaders.length) {
        for (const hidden of this.hiddenHeaders) {
          Store.addHiddenHeader(hidden)
        }
      }
    }

    setTimeout(() => {
      this.$root.$emit('set-wrapper-width')
    }, 600)
  }

  @Watch('activeTable')
  async onActiveTableChange() {
    this.headerRequested = ''
    this.groupsPayload = null
    this.wrapperWidth = 0
    this.scrollRowsCounter = 0

    this.presetLoad = this.activeTable.isEmpty

    Store.resetGroups()
    Store.resetGroupsItems()

    Store.setActiveRows([])
    Store.setActiveHeaders([])

    this.$root.$emit('delete-groups-payload')

    this.$root.$emit('set-wrapper-width')
  }

  @Watch('mode')
  onModeChange() {
    this.headerRequested = ''
    if (this.mode === 'table') this.$root.$emit('set-wrapper-width')
    else Store.setActiveRows([])
  }

  @Watch('showFinder')
  onShowFinderChange() {
    if (!this.showFinder) {
      Store.setSearch('')
      Store.setActiveSearchIndex([])
      Store.setFinderResults(0)
    }
  }

  @Watch('headerRequested')
  onActionsIndexChange() {
    if (this.headerRequested !== '') {
      setTimeout(() => {
        this.previousActionsIndex = this.headerRequested
      }, 200)
    }
  }

  @Watch('valueInputGroup')
  onValueInputGroupChanged() {
    if (this.valueInputGroup) {
      this.groups[this.indexRoot].rows[this.indexInput][this.indexHeader] =
        this.valueInputGroup
    }
  }

  @Watch('filterList', { immediate: true })
  onFilterListChanged() {
    const conditions = this.filterConditions
    this.filterActive = conditions.length && conditions[0].value !== ''
  }

  @Watch('filteredActiveHeaders')
  onFilteredActiveHeadersChange() {
    this.$root.$emit('set-wrapper-width')
  }

  @Emit() showAddField(status: boolean): boolean {
    this.$root.$emit('show-at-left', true)

    return status
  }
}
