
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import fileSender from '@/helpers/fileSender'

@Component
export default class UploadImageInput extends Vue {
  @Prop({ default: '' }) label!: string
  @Prop({ default: '' }) imageTitle!: string

  private onClick() {
    ;(this.$refs.input as HTMLInputElement).click()
  }

  @Emit()
  private sendFile(event: Event): File | null {
    return fileSender(event)
  }
}
