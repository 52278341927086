
import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'
import Input from '@/components/shared/Input.vue'
import Button from '@/components/shared/Button.vue'
import IconSpinner from '@/assets/icons/spinner-solid.svg'

@Component({
  components: { Button, Input, IconSpinner },
  directives: { onClickaway }
})
export default class DeleteHubsWarning extends Vue {
  @Prop() title!: string
  @Prop() subtitle!: string
  @Prop() confirm!: string
  @Prop() submitWord!: string
  @Prop({ default: false }) isNotifications!: boolean
  @Prop({ default: false }) isRegenerate?: boolean

  private value: string = ''
  private clicked: boolean = false
  private check: boolean = false

  mounted() {
    if (!this.value && this.isNotifications) {
      this.check = true
    }
  }

  private preConfirmDelete() {
    if (this.isRegenerate) {
      this.confirmRegenerate()
    } else if (this.value === this.submitWord && !this.isRegenerate)
      this.confirmDelete()
    else if (this.isNotifications) this.confirmDelete()
  }

  private checkTypeConfirm(): boolean {
    if (this.isRegenerate) {
      this.check = !this.isNotifications && this.value === this.$t('regenerate')
    } else if (!this.isRegenerate) {
      this.check = !this.isNotifications && this.value === this.$t('delete')
    }
    return this.check
  }

  @Watch('value')
  onValueChange() {
    this.checkTypeConfirm()
  }

  @Emit() confirmDelete() {
    this.clicked = true
  }

  @Emit() confirmRegenerate() {}

  @Emit() close() {}
}
