
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'
import { Asset } from '@/store/Models'
import { directive as onClickaway } from 'vue-clickaway'
import Input from '@/components/shared/Input.vue'
import Button from '@/components/shared/Button.vue'
import isEmail from '@/helpers/isEmail'
import Store from '@/store/modules/Store'
import Avatar from '@/components/Dashboard/Sidebar/Avatar.vue'
import IconSpinner from '@/assets/icons/spinner-solid.svg'
import IconChevron from '@/assets/icons/chevron-down.svg'
import i18n from '@/i18n'

@Component({
  apollo: {
    hubsCollaborators: {
      variables() {
        return { hubId: Store.hub.id }
      },
      query: require('@/graphql/queries/hubs-collaborators.graphql'),
      watchLoading(isLoading) {
        this.loading = isLoading
      }
    }
  },
  components: { Avatar, Button, Input, IconSpinner, IconChevron },
  directives: { onClickaway }
})
export default class ShareAsset extends Vue {
  @Prop() asset!: Asset

  private prefix: string = process.env.VUE_APP_CLOUDINARY_IMG_PREFIX
  private selectedMethod: 'email' | 'link' = 'email'
  private email: string = ''
  private collaborators: string[] = []
  private loading: boolean = true
  private showMode: boolean = false
  private selectedRole: string = i18n.t('reader') as string

  private get isEmail(): boolean {
    return isEmail(this.email)
  }

  private get alreadyInvited(): boolean {
    return this.asset.teamMembers.map(m => m.email).includes(this.email)
  }

  private get shareModes(): string[] {
    return [
      this.$i18n.t('reader') as string,
      this.$i18n.t('editor') as string,
      this.$i18n.t('owner') as string
    ].filter(mode => mode !== this.selectedRole)
  }

  private get isAutoInvite(): boolean {
    return Store.user.email === this.email
  }

  private get checkDisabledSubmit(): boolean {
    return (
      !this.isEmail || this.alreadyInvited || this.isAutoInvite || this.loading
    )
  }

  created() {
    try {
      this.refreshCollaborators()
    } catch (error) {
      this.$toasted.error(this.$i18n.t('errors.share') as string)
    }
  }

  private async refreshCollaborators() {
    const {
      data: { hubsCollaborators }
    } = await this.$apollo.queries.hubsCollaborators.refetch()

    const filtered = hubsCollaborators.filter(collaborator => {
      const emails = this.asset.teamMembers.map(m => m.email)

      if (!emails.includes(collaborator)) {
        return collaborator
      }
    })

    if (filtered.length) {
      const collaboratorsSet: Set<string> = new Set<string>()

      for (const collaborator of filtered) {
        collaboratorsSet.add(collaborator)
      }

      this.collaborators = Array.from(collaboratorsSet)
    }
  }

  private async invite() {
    this.loading = true
    const assetId = this.asset.id
    const email = this.email

    let role = ''

    switch (this.selectedRole) {
      case this.$i18n.t('editor') as string:
        role = 'editor'
        break
      case this.$i18n.t('reader') as string:
        role = 'reader'
        break
      case this.$i18n.t('owner') as string:
        role = 'owner'
        break
    }

    try {
      const {
        data: { invite }
      } = await this.$apollo.mutate({
        variables: { assetId, email, role },
        mutation: require('@/graphql/mutations/invite.graphql')
      })

      if (invite) {
        setTimeout(() => {
          this.$root.$emit('refresh-selected-asset', assetId)
          this.refreshCollaborators()
          this.$toasted.success(this.$i18n.t('success.invite') as string)
        }, 500)
      }
    } catch (error) {
      this.$toasted.error(this.$i18n.t('errors.share') as string)
    }
  }

  private hideShowMode() {
    if (this.showMode) this.showMode = false
  }

  @Watch('selectedRole')
  onSelectedRoleChange() {
    if (this.showMode) this.showMode = false
  }

  @Emit() close() {}
}
