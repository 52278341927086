
import { Vue, Component, Emit } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'
import Input from '@/../src/components/shared/Input.vue'
import Button from '@/../src/components/shared/Button.vue'

@Component({ components: { Input, Button }, directives: { onClickaway } })
export default class Suggestion extends Vue {
  private suggestion = {
    title: '',
    description: ''
  }

  private setSuggestion(type: string, e: any) {
    if (type === 'title') {
      this.suggestion[type] = e
    } else {
      this.suggestion[type] = e.target.value
    }
  }

  private async sendSuggestion() {
    const suggestion = this.suggestion

    try {
      const {
        data: { addSuggestion }
      } = await this.$apollo.mutate({
        variables: { suggestion },
        mutation: require('@/graphql/mutations/add-suggestion.graphql')
      })

      if (addSuggestion) {
        this.$toasted.success(this.$i18n.t('success.suggestSent') as string)
      }
    } catch (error) {
      this.$toasted.error(this.$i18n.t('errors.standard') as string)
    } finally {
      this.close()
    }
  }

  private get avoidSubmit(): boolean {
    return (
      this.suggestion.title === '' ||
      this.suggestion.description === '' ||
      this.suggestion.title.length > 99 ||
      this.suggestion.description.length > 999
    )
  }

  @Emit() close() {}
}
