var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"sidebar-navbar-notifications-wrapper",class:_vm.confirmDelete ? '-z-1' : 'z-0'},[_c('p',{domProps:{"textContent":_vm._s(
        _vm.notification.type === 'model-delete'
          ? _vm.$t('modelDelete')
          : _vm.notification.type === 'asset-delete'
          ? _vm.$t('assetDelete')
          : _vm.notification.type === 'hub-delete'
          ? _vm.$t('hubDelete')
          : _vm.$t('analyticsDelete')
      )}}),_c('p',{staticClass:"text-xs font-bold",domProps:{"textContent":_vm._s(
        _vm.notification.type === 'model-delete'
          ? _vm.$t('modelDeleted', { model: _vm.notification.concept })
          : _vm.notification.type === 'asset-delete'
          ? _vm.$t('assetDeleteRef', { asset: _vm.notification.concept })
          : _vm.notification.type === 'hub-delete'
          ? _vm.$t('hubDeleteRef', { hub: _vm.notification.concept })
          : _vm.$t('analyticsReset')
      )}}),_c('p',{staticClass:"mt-4 text-xs text-gray-400",domProps:{"textContent":_vm._s(_vm.notification.created_at)}}),_c('div',{staticClass:"absolute top-1 right-2",class:{ 'disabled': _vm.activeTab === 'read' },attrs:{"title":_vm.$t('markAsSeen')}},[_c('IconConfirm',{staticClass:"sidebar-navbar-notifications-seen",on:{"click":function($event){$event.stopPropagation();return _vm.notificationSeen.apply(null, arguments)}}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }