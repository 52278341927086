
import { Vue, Component, Watch, Emit } from 'vue-property-decorator'
import { load } from 'recaptcha-v3'

@Component({})
export default class ReCaptcha extends Vue {
  // private async useReCaptcha() {
  //   const recaptcha = grecaptcha.ready(() => {
  //     grecaptcha
  //       .execute('6LcASN8lAAAAAOO6CHVAu8jf0UYOurLY_oxNMe1I', {
  //         action: 'submit'
  //       })
  //       .then(token => {
  //         console.log(token, 'token')
  //       })
  //   })
  // }

  @Emit() register() {}
}
