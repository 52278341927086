
import { Vue, Component, Emit, Prop } from 'vue-property-decorator'
import { Asset } from '@/store/Models'
import Store from '@/store/modules/Store'
import IconTrash from '@/assets/icons/trash.svg'
import IconShare from '@/assets/icons/share.svg'
import IconEdit from '@/assets/icons/pencil.svg'

@Component({
  components: { IconTrash, IconShare, IconEdit }
})
export default class AssetShare extends Vue {
  @Prop() asset!: Asset
  @Prop() showActions!: boolean
  @Prop({ default: false }) isResource: boolean

  private demoAssetId: string = process.env.VUE_APP_DEMO_ASSET_ID
  private isAction: boolean = false

  private get pricingPlan(): string {
    return Store.hub.pricingPlan
  }

  private get show(): boolean {
    return this.showActions && this.pricingPlan !== 'free'
  }

  private action(action: string) {
    this.isAction = true
    this.getAction(action)

    setTimeout(() => {
      this.isAction = false
    }, 300)
  }

  @Emit()
  private getAction(action: string): string {
    return action
  }
}
