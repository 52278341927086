
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { Asset } from '@/store/Models'
import IconTrash from '@/assets/icons/trash.svg'
import IconShare from '@/assets/icons/share.svg'
import IconEdit from '@/assets/icons/pencil.svg'
import Store from '@/store/modules/Store'
import IconLocation from '@/assets/icons/location.svg'
import AssetShare from '@/components/Dashboard/Assets/AssetShare.vue'

@Component({
  components: { AssetShare, IconTrash, IconShare, IconEdit, IconLocation }
})
export default class AssetCard extends Vue {
  @Prop() asset!: Asset

  private prefix: string = process.env.VUE_APP_CLOUDINARY_IMG_PREFIX
  private showActions: boolean = false
  private isAction: boolean = false
  private urlNoImage: string = process.env.VUE_APP_NO_IMAGE_URL

  private get pricingPlan(): string {
    return Store.hub.pricingPlan
  }

  private handleOpenAsset() {
    if (this.isAction) return
    else {
      Store.setAssetForViewer(this.asset)
      setTimeout(() => this.$router.replace({ name: 'Viewer' }), 150)
    }
  }

  @Emit()
  private getAction(action: string): string {
    return action
  }
}
