export default `
Identification data:
In compliance with the duty of information contained in Article 10 of Law 34/2002, of July 11, of Services of the Information Society and Electronic Commerce, below are the general information data of the website (IloveIFC.com) and the fiscal data of the company are exposed, being responsible for the operation:

SPA Planeamento SL
B36477527
Serra 9, 1º der
36001 Pontevedra

Object of the Legal Notice:
The Legal Notice regulates the conditions, access and use of the website, its contents and services, paid or free, made available to Users; and the reservation policy of IloveIFC with its Clients.

Data protection:
In compliance with Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data, repealing Directive 95/46/EC (hereinafter RGPD), Law 34/2002 of 11 July, on information society services and electronic commerce (hereinafter, LSSI-CE) and the Organic Law 3/2018, on Personal Data Protection and Guarantee of Digital Rights (hereinafter, LOPDyGDD), SPA Planeamento SL guarantees the protection and confidentiality of personal data, of any kind provided to us by our customers, in accordance with the provisions of the General Regulation on Personal Data Protection.
SPA Planeamento Data Protection Policy is based on the principle of proactive responsibility, according to which the Data Controller is responsible for the compliance with the regulatory and jurisprudential framework, being able to prove it before the corresponding control authorities.
The data provided will be processed in accordance with the terms established in the RGPD. In this sense, SPA Planeamento has adopted the levels of protection legally required and has installed all the technical measures at its disposal to prevent the loss, misuse, alteration, and unauthorized access by third parties, exposed below. However, the user must be aware that security measures on the Internet are not impregnable.

Intellectual Property:
The information and contents contained in this website, as well as the source code, graphic designs, images, photographs, software and texts, are protected by Spanish legislation on intellectual and industrial property rights in favor of IloveIFC and the reproduction and/or publication, in whole or in part, of the website, or its computer processing, distribution, dissemination, modification, transformation or other rights legally recognized to its owner, is not permitted without his prior written permission. All intellectual property rights are expressly reserved by IloveIFC. The User, solely and exclusively, may use the material appearing on this website for personal and private use, being prohibited its use for commercial purposes or to engage in illegal activities.

Exclusion of warranties and liability:
IloveIFC is not responsible, in any case, for damages of any nature that may cause, but not limited to: errors or omissions in the contents, lack of availability of the portal or the transmission of viruses or malicious or harmful programs in the contents, despite having taken all the necessary technological measures to prevent it.

Modifications:
IloveIFC reserves the right to make unannounced changes it deems appropriate on its website, being able to change, delete or add both the content and services provided, and the way in which they are presented or located on its website.

Links to Third Parties:
In the case of links to third-party websites, the User shall become governed by the Terms of Use and Privacy Policy of the new site. IloveIFC shall not be responsible or liable for the use of such sites.

General:
IloveIFC will not engage in misleading advertising through its website. Therefore, it will not be considered misleading, advertising formal or numerical errors that may be found throughout the content of the various sections of the website produced as a result of maintenance and/or incomplete or faulty updating of the information contained in these sections. IloveIFC as a result of the provisions of this paragraph undertakes to correct it as soon as it becomes aware of such errors. IloveIFC is not responsible for the breach of any applicable rule that may be incurred by the User in their access to the website and/or the use of the information contained therein.

Applicable Law and Jurisdiction:
The parties, waiving their own jurisdiction, if possible, submit to the Courts and Tribunals of the city of Pontevedra.
The applicable law shall be Spanish law.
`
