import i18n from '../../i18n'
import IconHome from '../../assets/icons/home.svg'
import IconBuildings from '../../assets/icons/buildings.svg'
import IconCharts from '../../assets/icons/chart-line.svg'
import IconIntegration from '../../assets/icons/file-export.svg'

export default [
  {
    icon: IconHome,
    description: i18n.t('backHome') as string
  },
  {
    icon: IconBuildings,
    description: i18n.t('listUploadModels') as string
  },
  {
    icon: IconCharts,
    description: i18n.t('charts') as string
  },
  {
    icon: IconIntegration,
    description: i18n.t('createIntegrationInstance') as string
  }
]
