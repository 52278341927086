
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import IconCaretDown from '@/assets/icons/caret-down.svg'
import IconTrash from '@/assets/icons/trash.svg'
import IconCategory from '@/assets/icons/category.svg'
import Store from '@/store/modules/Store'
import { directive as onClickaway } from 'vue-clickaway'
import FilterInput from '@/components/Viewer/TableData/FilterInput.vue'

@Component({
  components: { FilterInput, IconCaretDown, IconCategory, IconTrash },
  directives: { onClickaway }
})
export default class FilterCondition extends Vue {
  @Prop() header: string
  @Prop() operator: string
  @Prop() input: string
  @Prop() index: number

  private showOptionStatus: boolean = false
  private showOptionOperator: boolean = false

  private operators: string[] = [
    this.$t('filter.operators.contains') as string,
    this.$t('filter.operators.doesNotContain') as string,
    this.$t('filter.operators.is') as string,
    this.$t('filter.operators.isNot') as string,
    this.$t('filter.operators.isEmpty') as string,
    this.$t('filter.operators.isNotEmpty') as string
  ]

  private get activeHeaders() {
    return Store.activeHeaders
  }

  private closeOptionStatus() {
    this.showOptionStatus = false
  }

  private closeOptionsOperator() {
    this.showOptionOperator = false
  }

  @Emit()
  setHeader(header: string, index: number): any[] {
    return [header, index]
  }

  @Emit()
  setOperator(operator: string, index: number): any[] {
    return [operator, index]
  }

  @Emit()
  setValue(value: string) {
    return value
  }

  @Emit()
  deleteCondition() {}
}
