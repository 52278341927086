
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator'
import IconMinus from '@/assets/icons/minimize.svg'
import IconMaximize from '@/assets/icons/expand.svg'
import Store from '@/store/modules/Store'
import { ViewerModel } from '@/store/Models'
import TableNames from '@/components/Viewer/TableData/TableNames.vue'
import TableTools from '@/components/Viewer/TableData/TableTools.vue'
import TableRows from '@/components/Viewer/TableData/TableRows.vue'
// import ConnectionModal from '@/components/Viewer/modals/ConnectionModal.vue'
import NewTableInput from '@/components/Viewer/TableData/NewTableInput.vue'
import FilterPopup from '@/components/Viewer/TableData/FilterPopup.vue'
import HiddenFieldsPanel from '@/components/Viewer/TableData/HiddenFieldsPanel.vue'
import IconBars from '@/assets/icons/bars.svg'
import HiddenTableNamesPopup from '@/components/Viewer/TableData/HiddenTableNamesPopup.vue'
import { directive as onClickaway } from 'vue-clickaway'
import setModelConfig from '@/controllers/forgeViewer/setModelConfig'
import CreateGroups from '@/components/Viewer/TableData/CreateGroups.vue'
import IconArrow from '@/assets/icons/arrow-right-from-line.svg'
import TableViews from '@/components/Viewer/TableData/TableViews.vue'
import setTableViewConfig from '@/controllers/forgeViewer/setTableViewConfig'
import ImportTable from '@/components/Viewer/TableData/TableCells/ImportTable.vue'

@Component({
  apollo: {
    getTablenames: {
      variables() {
        return {
          objectId: this.model.objectId,
          modelName: this.model.objectKey,
          assetId: Store.assetForViewer.id
        }
      },
      query: require('@/graphql/queries/get-tablenames.graphql'),
      result({ data: { getTablenames } }) {
        this.tableNames = getTablenames

        Store.setTableNames(getTablenames)
        Store.setPreImportTableNames(getTablenames)
      }
    },
    getTableViews: {
      skip() {
        return this.selectedTableName === ''
      },
      variables() {
        const tableName = this.selectedTableName
        const assetId = Store.assetForViewer.id
        const modelName = this.model.objectKey

        return {
          tableName: tableName,
          assetId: assetId,
          modelName: modelName
        }
      },
      query: require('@/graphql/queries/get-table-views.graphql'),
      result({ data: { getTableViews } }) {
        if (getTableViews) {
          const deletedTableView = this.deletedTableViews.find(
            d => d.tableName === getTableViews.tableName
          )

          if (deletedTableView) {
            for (const tableView of getTableViews) {
              if (deletedTableView.title === tableView.title) {
                Store.setTableViews([])
              } else {
                Store.setTableViews(getTableViews)
              }
            }
          } else {
            Store.setTableViews(getTableViews)
          }
        }
      }
    }
  },
  components: {
    CreateGroups,
    FilterPopup,
    HiddenTableNamesPopup,
    HiddenFieldsPanel,
    IconArrow,
    IconBars,
    IconMaximize,
    IconMinus,
    ImportTable,
    NewTableInput,
    TableNames,
    TableRows,
    TableTools,
    TableViews
  },
  directives: { onClickaway }
})
export default class TableData extends Vue {
  @Prop() model!: ViewerModel
  @Prop() sidebarOpen!: boolean

  private loading: boolean = false
  private minimize: boolean = false
  private maximize: boolean = false
  private tableNames: { title: string; origin: string; isEmpty: boolean }[] = []
  private activeIndex: number = null
  private rowsUpdatePayload: any = null
  private showNewTable: boolean = false
  private showHiddenFields: boolean = false
  private hiddenTableNames: string[] = []
  private showHiddenTableNamesPopup: boolean = false
  private showCreateGroups: boolean = false
  private showTableViews: boolean = false
  private selectedTableView: string = ''
  private showModalImport: boolean = false

  private get widthAccordingMaximized(): string {
    return `calc(100vw - ${this.sidebarOpen ? 24 : 4}rem)`
  }

  private get tableViews(): any[] {
    return Store.tableViews
  }

  private get activeTableView(): number {
    return Store.activeTableView
  }

  private get groupsItems(): any[] {
    return Store.groupsItems
  }

  private get deletedTableViews(): any[] {
    return Store.deletedTableViews
  }

  private get selectedTableName(): string {
    return Store.selectedTableName
  }

  private get hiddenHeaders(): any[] {
    return Store.hiddenHeaders
  }

  private get selectedTable(): {
    title: string
    origin: string
    isEmpty: boolean
  } {
    return Store.selectedTable
  }

  beforeMount() {
    this.fetchTableNames()
    this.setHiddenCategories()
  }

  async mounted() {
    this.$root.$on('refresh-tableNames', () => {
      this.fetchTableNames()
    })

    this.$root.$on('show-groups', () => {
      this.showCreateGroups = true
    })

    this.$root.$on('show-table-views', () => {
      this.showTableViews = true
    })

    this.$root.$on('show-modal-import', (payload: any) => {
      this.setActiveIndex(payload.title)
      this.$root.$emit('set-wrapper-width')

      setTimeout(() => {
        this.$root.$emit('scroll-tablenames-right')
        this.$root.$emit('show-new-column', true)

        if (payload.showModal) {
          this.showModalImport = true
        }
      }, 200)
    })

    this.$root.$on('close-modal-import', () => {
      this.showModalImport = false
    })

    this.$root.$on('set-active-last-index', () => {
      this.activeIndex = this.tableNames.length - 1
    })
  }

  private async fetchTableNames() {
    await this.$apollo.queries.getTablenames.refetch()
  }

  private setHiddenCategories() {
    const model = Store.assetForViewer.configs.find(
      m => m.modelName === this.model.objectKey
    )

    if (model) {
      this.hiddenTableNames = model.hiddenCategories
    }
  }

  private minimizeTable() {
    this.minimize = !this.minimize
    this.maximize = false

    Store.setSizeTable(this.minimize)
  }

  private maximizeTable() {
    this.maximize = !this.maximize
    this.minimize = false

    Store.setSizeTable(this.maximize)
    this.$root.$emit('move-viewer-buttons', this.maximize)
  }

  private async handleHiddenTableNames(index: number) {
    this.resetStoresTable()

    const tableName = this.tableNames[index + this.hiddenTableNames.length]
    const check = this.hiddenTableNames.includes(tableName.title)

    if (!check) {
      this.hiddenTableNames.push(tableName.title)
    } else {
      this.hiddenTableNames.splice(index, 1)
    }

    this.$root.$emit('refresh-headers-rows', {
      tableName: tableName.title
    })

    await setModelConfig('hidden-categories', this.model.objectKey, {
      category: tableName.title
    })
  }

  private hidePopup() {
    this.showHiddenTableNamesPopup = false
  }

  private async restoreTableName(tableName: string) {
    this.hiddenTableNames.splice(this.hiddenTableNames.indexOf(tableName), 1)

    await setModelConfig('hidden-categories', this.model.objectKey, {
      category: tableName
    })

    Store.setSelectedTableName(tableName)

    this.activeIndex = this.tableNames
      .filter(t => !this.hiddenTableNames.includes(t.title))
      .findIndex(t => t.title === tableName)

    this.$root.$emit('refresh-headers-rows', {
      tableName
    })

    this.hidePopup()
  }

  private setActiveIndex(title: string) {
    this.$root.$emit('enable-import', false)
    this.$root.$emit('close-modal-import')

    if (this.selectedTableName !== title) {
      this.resetStoresTable()

      Store.setSelectedTableName(title)

      const filteredTableNames = this.tableNames.filter(
        t => !this.hiddenTableNames.includes(t.title)
      )

      this.activeIndex = filteredTableNames.findIndex(t => t.title === title)

      this.$root.$emit('refresh-headers-rows', {
        tableName: title
      })
    }
  }

  private async checkHiddenHeadersChange() {
    if (this.hiddenHeaders.length) {
      this.showHiddenFields = false
      this.$root.$emit('refresh-table-groups')
    }

    const payload = {
      hiddenHeaders: {
        tableName:
          this.tableNames[this.activeIndex + this.hiddenTableNames.length]
            .title,
        fields: this.hiddenHeaders
      }
    }

    if (Store.activeTableView !== null) {
      const title = await this.tableViews[Store.activeTableView].title

      await setTableViewConfig(
        'hidden-headers',
        this.tableNames[this.activeIndex + this.hiddenTableNames.length].title,
        this.model.objectKey,
        title,
        { hiddenHeaders: this.hiddenHeaders }
      )
    } else {
      if (this.hiddenHeaders.length) {
        await setModelConfig('hidden-headers', this.model.objectKey, payload)

        const index = Store.assetForViewer.configs.findIndex(
          c => c.modelName === this.model.objectKey
        )

        if (index !== -1) {
          const pos = Store.assetForViewer.configs[
            index
          ].hiddenHeaders.findIndex(
            h =>
              h.tableName ===
              this.tableNames[this.activeIndex + this.hiddenTableNames.length]
                .title
          )

          if (pos !== -1) {
            Store.assetForViewer.configs[index].hiddenHeaders[pos].fields =
              this.hiddenHeaders
          } else {
            Store.assetForViewer.configs[index].hiddenHeaders.push({
              ...payload.hiddenHeaders
            })
          }
        } else {
          Store.assetForViewer.configs.push({
            hiddenHeaders: [{ ...payload.hiddenHeaders }],
            modelName: this.model.objectKey,
            active: true,
            color: '',
            hiddenCategories: [],
            groups: Store.groupsItems,
            lock: false,
            filters: []
          })
        }
      }
    }
  }

  private scrollToRightMax() {
    this.$root.$emit('scroll-to-right-max')
  }

  private hideGroupsPanel() {
    this.showCreateGroups = false
  }

  private hideTableViews() {
    this.showTableViews = false
  }

  private resetStoresTable() {
    Store.setActiveHeaders([])
    Store.setActiveRows([])

    Store.setHiddenHeaders([])

    Store.setFilterConditions([])
    Store.setFilterList([])

    Store.setGroups([])
    Store.setGroupsItems([])

    Store.setActiveTableView(null)

    this.selectedTableView = ''
  }

  private hideHiddenFieldsPanel() {
    this.showHiddenFields = false
  }

  @Watch('tableNames')
  onTableNamesChange() {
    if (this.tableNames.length) {
      // Reset Stores at change tablename
      Store.setActiveRows([])
      Store.setActiveHeaders([])

      this.selectedTableView = ''

      if (this.activeIndex === null) {
        this.activeIndex = 0

        // Control the initial selection of the selected
        // tableName to refetch and get the tableViews
        Store.setSelectedTable(this.tableNames[0])
        Store.setSelectedTableName(this.tableNames[0].title)
      }
    }
  }

  @Watch('hiddenHeaders')
  onHiddenHiddenHeadersChange() {
    this.checkHiddenHeadersChange()
  }

  @Emit()
  showConnectionModal(index: number): string {
    return this.tableNames[index].title
  }
}
