export default (color: string) => {
  switch (color) {
    case 'red':
      // @ts-ignore
      return new THREE.Vector4(0.8, 0.3, 0.3, 1)
    case 'blue':
      // @ts-ignore
      return new THREE.Vector4(0.3, 0.3, 0.8, 1)
    case 'green':
      // @ts-ignore
      return new THREE.Vector4(0.3, 0.7, 0.3, 1)
    case 'yellow':
      // @ts-ignore
      return new THREE.Vector4(1, 0.6, 0.3, 1)
    case 'pink':
      // @ts-ignore
      return new THREE.Vector4(1, 0.3, 0.8, 1)
    case 'purple':
      // @ts-ignore
      return new THREE.Vector4(0.8, 0.25, 1, 1)
    case 'lime':
      // @ts-ignore
      return new THREE.Vector4(0.3, 0.8, 0.3, 1)
    case 'gray':
      // @ts-ignore
      return new THREE.Vector4(0.5, 0.5, 0.5, 1)
    case 'wireframe':
      return null
  }
}
