import { Scope } from 'forge-apis'

const scopes: Scope[] = [
  'user-profile:read',
  // 'user:read',
  // 'user:write',
  'viewables:read',
  'data:read',
  'data:write',
  'data:create',
  'data:search',
  'bucket:create',
  'bucket:read',
  'bucket:update',
  'bucket:delete',
  'code:all',
  'account:read',
  'account:write'
]

export default scopes
