import Cumulio from 'cumulio'

const client = new Cumulio({
  api_key: process.env.VUE_APP_CUMULIO_KEY,
  api_token: process.env.VUE_APP_CUMULIO_TOKEN
})

export default async (datasetId: string) => {
  try {
    return await client.get('securable', {
      where: {
        type: 'dataset',
        id: datasetId
      },
      attributes: ['name', 'rows', 'created_at'],
      include: [
        {
          model: 'Column'
        }
      ]
    })
  } catch (error) {
    console.error('error-cumulio', error)
  }
}

/*
// import Cumulio from 'cumulio'

import Luzmo from '@luzmo/nodejs-sdk'

const client = new Luzmo({
  api_key: process.env.VUE_APP_LUZMO_KEY,
  api_token: process.env.VUE_APP_LUZMO_TOKEN
})

console.log('⭐client**!/⭐', client)

export default async (datasetId: string) => {
  try {
    return await client.get('securable', {
      where: {
        type: 'dataset',
        id: datasetId
      },
      attributes: ['name', 'rows', 'created_at'],
      include: [
        {
          model: 'Column'
        }
      ]
    })
  } catch (error) {
    console.error('error-cumulio', error)
  }
}
*/
