
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'
import Store from '@/store/modules/Store'
import Input from '@/components/shared/Input.vue'

@Component({
  components: { Input },
  directives: { onClickaway }
})
export default class Cell extends Vue {
  @Prop() text!: string | number
  @Prop() index!: number
  @Prop() pos!: number
  @Prop() activeSearchIndex!: any[]
  @Prop() isSelected!: boolean
  @Prop({ default: false }) fromGroups!: boolean
  @Prop({ default: '' }) groupIndex: any

  private showDrop: boolean = false
  private threshold: number = 24
  private counter: number = 0
  private originY: number = 0
  private isNegative: boolean = false
  private showInput: boolean = false
  private inputText: string = ''
  private onDrag: boolean = false

  mounted() {
    this.$root.$on('input-enter', () => {
      if (this.inputText !== this.text && this.showInput) {
        this.setValue()
      }

      this.showInput = false
    })
  }

  private hideDrop() {
    if (this.showDrop) this.showDrop = false

    if (this.inputText !== this.text && this.showInput) {
      this.setValue()
    }

    if (this.showInput) this.showInput = false
  }

  private dragStart(event: DragEvent) {
    this.counter = 0
    Store.setSelectedGeometries([])
    this.onDrag = true
    this.originY = event.pageY
  }

  private calcDrag(event: DragEvent) {
    let diff = event.pageY - this.originY

    // todo: Back-drop case
    // let isNegative = false
    // let previousIsNegative = this.isNegative

    if (diff < 0) {
      diff = -diff
      // this.isNegative = true
    }

    const previousCounter = this.counter

    this.counter = Math.floor(diff / this.threshold)

    if (
      this.counter - previousCounter !== 1 &&
      this.counter - previousCounter !== -1 &&
      this.counter - previousCounter !== 0
    ) {
      this.counter = previousCounter
      // this.isNegative = previousIsNegative
    }

    // if (this.counter + this.index > Store.bimtableRows.length) {
    //   this.counter = Store.bimtableRows.length - this.index - 1
    // }
  }

  private sendValues() {
    this.onDrag = false

    this.$root.$emit('drag-values', {
      value: this.text,
      counter: this.counter,
      index: this.index,
      isNegative: this.isNegative,
      pos: this.pos,
      groupIndex: this.groupIndex
    })
  }

  private handleShowInput() {
    this.showDrop = false
    this.showInput = true

    setTimeout(() => {
      const input = document.getElementById('input') as HTMLInputElement
      input.select()
    }, 50)
  }

  @Emit()
  setValue(): any {
    return {
      value: this.inputText,
      rowIndex: this.index,
      headerIndex: this.pos
    }
  }
}
