
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'
import IconChevron from '@/assets/icons/chevron-down.svg'

@Component({
  components: { IconChevron },
  directives: { onClickaway }
})
export default class Dropdown extends Vue {
  @Prop() options!: any[]
  @Prop({ default: '' }) title!: string
  @Prop({ default: false }) isShort!: boolean
  @Prop({ default: '' }) preSelected!: string
  @Prop({ default: false }) isTransparent!: boolean
  @Prop({ default: 'text-xs' }) sizeTitle!: string

  private selected: string = ''
  private show: boolean = false

  private handleClose() {
    if (this.show) {
      this.show = false
    }
  }

  mounted() {
    this.setSelected()
  }

  private setSelected() {
    this.selected =
      this.preSelected !== '' ? this.preSelected : this.options[0].name
  }

  // @Watch('options')
  // onOptionsChange() {
  //   if (!this.preSelected) {
  //     this.selected = this.options[0].name
  //   }
  // }

  @Watch('selected')
  onSelectedChange() {
    this.selection()
  }

  // @Watch('show')
  // onShowChange() {
  //   this.setSelected()
  // }

  @Emit()
  selection(): string {
    return this.selected
  }
}
