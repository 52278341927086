
import { Vue, Component, Emit, Watch } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'
import Avatar from '@/components/Dashboard/Sidebar/Avatar.vue'
import IconClose from '@/assets/icons/x.svg'
import { Team } from '@/store/Models'
import Store from '@/store/modules/Store'
import Button from '@/components/shared/Button.vue'
import Input from '@/components/shared/Input.vue'
import isEmail from '@/helpers/isEmail'
import IconTeam from '@/assets/icons/buildings.svg'
import Dropdown from '@/components/shared/Dropdown.vue'
import IconTrash from '@/assets/icons/trash.svg'
import IconEdit from '@/assets/icons/pencil.svg'
import ConfirmUpdate from '@/components/Viewer/modals/ConfirmUpdate.vue'

@Component({
  components: {
    ConfirmUpdate,
    Dropdown,
    Input,
    Button,
    Avatar,
    IconClose,
    IconEdit,
    IconTeam,
    IconTrash
  },
  directives: { onClickaway }
})
export default class SharedResources extends Vue {
  @Emit() closeModal() {}

  private teams: Team[] = []
  private teamMembership: Team[] = []
  private members: {
    email: string
    status: string
    avatar: string
    role: string
  }[] = []
  private email: string = Store.user.email
  private showCreateGroupInput: boolean = false
  private teamTitle: string = ''
  private teamRole: string = ''
  private selectedTeam: any = null
  private memberEmail: string = ''
  private memberIndex: number = null
  private showAddMember: boolean = false
  private showOptionsIndex: number = null
  private showEditIndex: number = null
  private showConfirmUpdate: boolean = false
  private showConfirmDelete: boolean = false
  private userId: string = ''
  private textDelete: string = ''

  private roleOptions: any[] = [
    { name: this.$i18n.t('roles.editor') },
    { name: this.$i18n.t('roles.reader') },
    { name: this.$i18n.t('roles.owner') }
  ]

  private get user() {
    return Store.user
  }
  async created() {
    if (this.user) {
      this.userId = this.user.id
    }

    const {
      data: { teams }
    } = await this.$apollo.query({
      query: require('@/graphql/queries/teams.graphql')
    })

    this.teams = teams

    const {
      data: { teamMembership }
    } = await this.$apollo.query({
      query: require('@/graphql/queries/team-membership.graphql')
    })

    for (const team of teamMembership) {
      this.teamMembership.push({ ...team, shared: true })
    }
  }

  private async createTeam() {
    const teamTitle = this.teamTitle

    try {
      const {
        data: { createTeam }
      } = await this.$apollo.mutate({
        variables: { teamTitle },
        mutation: require('@/graphql/mutations/create-team.graphql')
      })

      if (createTeam) {
        this.teams.push({ title: teamTitle, members: [] })
        this.$toasted.success(this.$i18n.t('success.teamCreated') as string)
      }
    } catch {
      this.$toasted.error(this.$i18n.t('errors.teamCreated') as string)
    } finally {
      this.showCreateGroupInput = false
      this.teamTitle = ''
    }
  }

  private get isEmail(): boolean {
    return isEmail(this.memberEmail)
  }

  private async emailToMember() {
    const teamTitle = this.selectedTeam.title
    const memberEmail = this.memberEmail
    const role = this.teamRole.toLowerCase()

    try {
      const {
        data: { emailToMember }
      } = await this.$apollo.mutate({
        variables: { teamTitle, memberEmail, role },
        mutation: require('@/graphql/mutations/email-to-member.graphql')
      })

      if (emailToMember !== null) {
        this.members.push({
          email: memberEmail,
          status: this.$i18n.t('pending') as string,
          avatar: emailToMember,
          role: role
        })

        this.$toasted.success(this.$i18n.t('success.invite') as string)
      }
    } catch (error) {
      this.$toasted.error(this.$i18n.t('errors.invite') as string)
    } finally {
      this.memberEmail = ''
      this.teamRole = ''
      this.showAddMember = false
    }
  }

  private updateRole(event) {
    this.teamRole = event
    if (this.showEditIndex !== null) this.showConfirmUpdate = true
  }

  private checkDelete(index: number) {
    if (this.selectedTeam.shared) {
      this.textDelete = this.$i18n.t('rescindPermission') as string
    } else {
      this.textDelete = this.$i18n.t('sureToDeletePermissions') as string
    }

    this.memberIndex = index
    this.showConfirmDelete = true
  }

  private checkUserOwner(): boolean {
    return this.selectedTeam.ownerId === this.userId
  }

  private async deleteShared() {
    const teamTitle = this.selectedTeam.title
    const userId = this.userId
    const member = JSON.stringify(this.selectedTeam.members[this.memberIndex])

    try {
      const {
        data: { removeShared }
      } = await this.$apollo.mutate({
        variables: { teamTitle, userId, member },
        mutation: require('@/graphql/mutations/remove-shared.graphql')
      })

      if (removeShared) {
        const check = this.checkUserOwner()

        if (check) {
          this.$toasted.success(this.$i18n.t('success.removeShared') as string)
        } else {
          this.$toasted.success(
            this.$i18n.t('success.removePermission') as string
          )
        }
        this.selectedTeam.members.splice(this.memberIndex, 1)
        this.showConfirmDelete = false
      }
    } catch (error) {
      //
    }
  }

  private setEditRole(index: number) {
    this.showEditIndex = index
    this.memberEmail = this.members[index].email
  }

  private async sendEditRole() {
    const teamTitle = this.selectedTeam.title
    const memberEmail = this.memberEmail
    const role = this.teamRole.toLowerCase()

    try {
      const {
        data: { updateRoleShared }
      } = await this.$apollo.mutate({
        variables: { teamTitle, memberEmail, role },
        mutation: require('@/graphql/mutations/update-role-shared.graphql')
      })

      if (updateRoleShared !== null) {
        const findMemberIndex = this.members.findIndex(
          member => member.email === memberEmail
        )

        const modifyRole = this.members[findMemberIndex]

        modifyRole.role = role

        this.members.splice(findMemberIndex, 1)
        this.members.push(modifyRole)

        this.$toasted.success(this.$i18n.t('success.updateRole') as string)
      }
    } catch (error) {
      this.$toasted.error(this.$i18n.t('errors.update') as string)
    }
    this.resetEditIndex()
  }

  private checkOwnerId() {
    return this.selectedTeam.ownerId === this.userId
  }

  private checkRole(role) {
    if (role) return true
  }

  private memberRoleFormat(role: string): string {
    return role.charAt(0).toUpperCase() + role.slice(1)
  }

  private resetEditIndex() {
    this.showConfirmUpdate = false
    this.showEditIndex = null
    this.showOptionsIndex = null
    this.teamRole = null
    this.memberEmail = null
  }

  @Watch('selectedTeam')
  onSelectedTeamChange() {
    if (this.selectedTeam) {
      this.members = this.selectedTeam.members
    }
  }

  @Watch('showCreateGroupInput')
  onShowCreateGroupInputChange() {
    if (this.showCreateGroupInput) {
      setTimeout(() => {
        const input = document.getElementById('input')
        input.focus()
      }, 400)
    }
  }

  @Watch('showAddMember')
  onShowAddMemberChange() {
    if (this.showAddMember) {
      setTimeout(() => {
        const input = document.getElementById('input_2')
        input.focus()
      }, 400)
    }
  }
}
