
import { Vue, Component, Watch, Emit } from 'vue-property-decorator'
import Store from '@/store/modules/Store'
import SelectionTab from '@/components/Viewer/SelectionData/SelectionTab.vue'

@Component({ components: { SelectionTab } })
export default class SelectionData extends Vue {
  private selectedId: any = null
  private selectedProperties: any = null
  private selectedModelName: string = ''
  private showDataBIM: boolean = false
  private show360Modal = false

  private get selectionData() {
    return Store.selectionData
  }

  private resetSelectionData() {
    Store.setActiveSelection(false)
  }

  private setProperties() {
    const findId =
      this.selectedId !== null
        ? this.selectedId
        : this.selectionData[0].viewerId

    this.selectedId = findId

    const data = this.selectionData.find(
      (d: { viewerId: any }) => d.viewerId === findId
    )

    if (data) {
      this.selectedProperties = JSON.parse(data.properties)
      this.selectedModelName = data.modelName
    } else {
      this.selectedId = this.selectionData[0].viewerId
    }
  }

  @Emit() sendShowModal(status: boolean): boolean {
    return status
  }

  @Watch('selectionData')
  onSelectionDataChange() {
    this.selectedProperties = null
    this.setProperties()
  }

  @Watch('selectedId')
  onSelectedIdChange() {
    this.setProperties()
  }

  @Watch('show360Modal')
  onShow360Change() {
    this.sendShowModal(this.show360Modal)
  }

  beforeDestroy() {
    Store.setSelectionData(null)
    Store.setActiveSelection(false)
  }
}
