
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'
import DropdownAssets from '@/components/shared/DropdownAssets.vue'
import IconUpload from '@/assets/icons/file-upload.svg'
import IconSpinner from '@/assets/icons/spinner-solid.svg'
import IconFolder from '@/assets/icons/folder.svg'
import IconCheck from '@/assets/icons/check.svg'
import { directive as onClickaway } from 'vue-clickaway'
import Store from '@/store/modules/Store'
import { Asset, ViewerModel } from '@/store/Models'
import DeleteModelMetadataWarning from '@/components/Viewer/modals/DeleteModelMetadataWarning.vue'
import ModelItem from '@/components/Viewer/ModelItem.vue'
import setModelConfig from '@/controllers/forgeViewer/setModelConfig'
import Input from '@/components/shared/Input.vue'
import IconEye from '@/assets/icons/eye.svg'
import IconEyeSlash from '@/assets/icons/eye-slash.svg'
import IconUnlock from '@/assets/icons/lock-open.svg'
import IconLock from '@/assets/icons/lock.svg'

@Component({
  components: {
    Input,
    ModelItem,
    DeleteModelMetadataWarning,
    DropdownAssets,
    IconUpload,
    IconSpinner,
    IconFolder,
    IconCheck,
    IconEye,
    IconEyeSlash,
    IconUnlock,
    IconLock
  },
  directives: { onClickaway }
})
export default class ModelsList extends Vue {
  @Prop() models!: ViewerModel[]
  @Prop({ default: false }) isUploading!: boolean
  @Prop() isLoadingModels!: boolean

  private fromWeb: boolean = false
  private modelToDelete: ViewerModel = null
  private deleteObjectKey: string = ''
  private statusTexts: string[] = []
  private scrollHeight: number = 0
  private scrollOffset: number = 280
  private scrollGroupsOffset: number = 80
  private showModelsGroups: boolean = false
  private modelsGroupName: string = ''
  private selectedModels: string[] = []
  private groupNames: any[] = []
  private asset = null

  async beforeMount() {
    this.fromWeb =
      window.parent.origin === 'http://localhost:8080' ||
      window.parent.origin === 'https://bimtable.app' ||
      window.parent.origin === 'https://bimtable-beta-env.web.app'

    this.asset = this.assetForViewer
  }

  private get notDemoAsset(): boolean {
    return Store.assetForViewer.id !== process.env.VUE_APP_DEMO_ASSET_ID
  }

  private get allowUpload(): boolean {
    return this.fromWeb && Store.hub.pricingPlan !== 'free' && this.notDemoAsset
  }

  mounted() {
    if (this.models.length) {
      this.resetPicker()
    }

    this.$root.$on('reset-status-texts', () => {
      this.statusTexts = []
    })

    this.$root.$on('refresh-models', () => {
      this.asset = Store.assetForViewer
    })

    this.scrollHeight = window.innerHeight - this.scrollOffset

    window.addEventListener('resize', () => {
      this.scrollHeight = window.innerHeight - this.scrollOffset
    })
  }

  private async refreshAssetForViewer(assetId: string) {
    const {
      data: { assetById }
    } = await this.$apollo.query({
      variables: { assetId },
      query: require('@/graphql/queries/asset-by-id.graphql')
    })

    Store.setAssetForViewer(assetById)
    this.modelToDelete = null
    this.deleteObjectKey = ''

    setTimeout(() => {
      this.$root.$emit('refresh-models')
    }, 500)
  }

  private deleteModel() {
    try {
      this.deleteModelFunc()
    } catch (error) {
      this.$toasted.error(this.$i18n.t('errors.deleteModel') as string)
      this.modelToDelete = null
      this.deleteObjectKey = ''
      this.$root.$emit('refresh-models')
    }
  }

  private async deleteModelFunc() {
    const { objectId, objectKey } = this.modelToDelete
    const { bucketKey } = Store.assetForViewer
    const assetId = Store.assetForViewer.id
    const hubId = Store.hub.id

    const isThreeLeggedModel =
      Store.assetForViewer.threeLeggedModels.find(
        m => m.objectId === objectId
      ) !== undefined

    const {
      data: { deleteModel }
    } = await this.$apollo.mutate({
      variables: {
        isThreeLeggedModel,
        objectId,
        bucketKey,
        assetId,
        objectKey,
        hubId
      },
      mutation: require('@/graphql/mutations/delete-model.graphql')
    })

    if (deleteModel) {
      setTimeout(async () => {
        await this.refreshAssetForViewer(assetId)
      }, 500)
    }
  }

  private setMainModel(model: ViewerModel) {
    this.models.forEach(m => (m.isMain = false))
    const index = this.models.findIndex(m => m.objectId === model.objectId)
    this.models[index].isMain = true

    const { objectKey } = this.models[index]

    const modelConfig = Store.assetForViewer.configs.find(
      c => c.modelName === objectKey
    )

    if (!modelConfig) {
      Store.assetForViewer.configs.push({
        modelName: objectKey,
        active: true,
        hiddenCategories: [],
        color: '',
        lock: false,
        groups: [],
        hiddenHeaders: [],
        filters: []
      })
    }

    this.fitToModel(model)
  }

  private swapLockModel(model: ViewerModel) {
    model.lock = !model.lock

    const { lock, objectKey } = model

    setModelConfig('lock', objectKey, { lock })
  }

  private swapActive(model: ViewerModel) {
    model.active = !model.active

    const { active, objectKey } = model

    setModelConfig('active', objectKey, { active })
  }

  private async createModelsGroup() {
    try {
      const assetId = Store.assetForViewer.id
      const modelsGroupName = this.modelsGroupName
      const selectedModels = this.selectedModels

      const {
        data: { createModelsGroup }
      } = await this.$apollo.mutate({
        variables: { assetId, modelsGroupName, selectedModels },
        mutation: require('@/graphql/mutations/create-models-group.graphql')
      })

      if (createModelsGroup) {
        this.$toasted.success(
          this.$i18n.t('createdModelGroup', { modelsGroupName }) as string
        )
      }
    } catch (error) {
      console.error('error', error)
    }
  }

  private changeSelectedModels({ modelName, addToGroup }) {
    if (addToGroup) {
      this.selectedModels.push(modelName)
    } else {
      this.selectedModels.splice(this.selectedModels.indexOf(modelName, 1))
    }
  }

  private get disableSubmit() {
    return (
      !this.selectedModels.length ||
      !this.modelsGroupName ||
      this.groupNames.map(g => g.name).includes(this.modelsGroupName)
    )
  }

  private get modelsAtGroups() {
    const modelsNames = []

    Store.assetForViewer.groupModels.forEach(g =>
      modelsNames.push(...g.modelsNames)
    )

    return modelsNames
  }

  private get assetModelsGroups() {
    return this.assetForViewer.groupModels.map(g => {
      return {
        ...g,
        show: false,
        active: true,
        locked: false
      }
    })
  }

  private get assetForViewer(): Asset {
    return this.asset ? this.asset : Store.assetForViewer
  }

  private getGroupModels(group: { groupName: string; modelsNames: string[] }) {
    const models = []

    group.modelsNames.forEach(m => {
      const model = this.models.find(model => model.objectKey === m)
      if (model) models.push(model)
    })

    return models
  }

  private get uuid() {
    return Math.random()
  }

  private handleShowGroup(group, key: number) {
    group.show = !group.show

    Store.assetForViewer.groupModels[key] = group

    Store.setAssetForViewer({
      ...Store.assetForViewer
    })
  }

  private unactiveGroup(group, key: number) {
    for (const modelName of group.modelsNames) {
      const model = this.models.find(m => m.objectKey === modelName)

      if (model && model.active) {
        this.swapActive(model)
      }
    }

    group.active = false

    Store.assetForViewer.groupModels[key] = group

    Store.setAssetForViewer({
      ...Store.assetForViewer
    })
  }

  private activeGroup(group, key: number) {
    for (const modelName of group.modelsNames) {
      const model = this.models.find(m => m.objectKey === modelName)

      if (model && !model.active) {
        this.swapActive(model)
      }
    }

    group.active = true

    Store.assetForViewer.groupModels[key] = group

    Store.setAssetForViewer({
      ...Store.assetForViewer
    })
  }

  private lockGroup(group, key: number) {
    for (const modelName of group.modelsNames) {
      const model = this.models.find(m => m.objectKey === modelName)

      if (model && !model.lock) {
        this.swapLockModel(model)
      }
    }

    group.locked = true

    Store.assetForViewer.groupModels[key] = group

    Store.setAssetForViewer({
      ...Store.assetForViewer
    })
  }

  private unlockGroup(group, key: number) {
    for (const modelName of group.modelsNames) {
      const model = this.models.find(m => m.objectKey === modelName)

      if (model && model.lock) {
        this.swapLockModel(model)
      }
    }

    group.locked = false

    Store.assetForViewer.groupModels[key] = group

    Store.setAssetForViewer({
      ...Store.assetForViewer
    })
  }

  @Watch('assetForViewer')
  onAssetForViewerChange() {
    this.asset = this.assetForViewer
  }

  @Emit()
  selectModel(objectId: string): string {
    return objectId
  }

  @Emit()
  close() {
    this.$root.$emit('reset-viewer-sidebar')
  }

  @Emit()
  hidePaneldata(index: number): number {
    return index
  }

  @Emit()
  resetMetadata(model: ViewerModel) {
    return model
  }

  @Emit() showUploadModal() {}
  @Emit() resetPicker() {}
  @Emit()
  fitToModel(model: ViewerModel) {
    return model
  }
}
