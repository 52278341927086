
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import IconFind from '@/assets/icons/magnifying-glass.svg'
import IconSave from '@/assets/icons/floppy-disk.svg'
import Store from '@/store/modules/Store'
import { ViewerModel } from '@/store/Models'
import IconHiddenFields from '@/assets/icons/align-slash.svg'
import IconFilter from '@/assets/icons/filter.svg'
import IconCustom from '@/assets/icons/person.svg'
import IconTarget from '@/assets/icons/bullseye-arrow.svg'
import IconIsolate from '@/assets/icons/ring.svg'
import ColorPicker from '@/components/Viewer/ColorPicker.vue'
import IconGroups from '@/assets/icons/layer-group.svg'
import IconTable from '@/assets/icons/table.svg'
import IconImport from '@/assets/icons/table-import.svg'

@Component({
  components: {
    ColorPicker,
    IconFind,
    IconFilter,
    IconGroups,
    IconHiddenFields,
    IconImport,
    IconSave,
    IconCustom,
    IconTable,
    IconTarget,
    IconIsolate
  }
})
export default class TableTools extends Vue {
  @Prop() active!: any
  @Prop() headersToUpdate!: string[]
  @Prop() model!: ViewerModel

  private showColorPicker: boolean = false
  private selectedColor: string = ''
  private enableImport: boolean = false

  private get hiddenHeaders() {
    return Store.hiddenHeaders
  }

  private get filterConditions(): any[] {
    return Store.filterConditions
  }

  private get fitGeometries(): boolean {
    return Store.fitGeometries
  }

  private get isolation(): boolean {
    return Store.isolation
  }

  private get tableViews(): any[] {
    return Store.tableViews
  }

  private get activeTableView(): number {
    return Store.activeTableView
  }

  private get activeHeaders(): any[] {
    return Store.activeHeaders
  }

  private get groups(): any {
    return Store.groups
  }

  async mounted() {
    this.$root.$on('enable-import', (status: boolean) => {
      this.enableImport = status
    })
  }

  private search() {
    this.$root.$emit('show-finder')
  }

  private async updateMetadata() {
    try {
      this.$root.$emit('loading-update', true)

      const assetId = Store.assetForViewer.id
      const tableName = this.active.title
      const modelId = this.model.objectId
      const headers = JSON.stringify(this.activeHeaders)
      const rows = JSON.stringify(Store.activeRows)
      const modelName = this.model.objectKey

      const {
        data: { updateMetadata }
      } = await this.$apollo.mutate({
        variables: { assetId, tableName, modelId, headers, rows, modelName },
        mutation: require('@/graphql/mutations/update-metadata.graphql')
      })

      if (updateMetadata) {
        this.$toasted.success(this.$i18n.t('modelDataUpdated') as string)
        Store.setHasChanged(false)

        const { rows, headers } = JSON.parse(updateMetadata)

        Store.setActiveHeaders(headers)
        Store.setActiveRows(rows)
      }
    } catch {
      console.error('error')
    } finally {
      this.$root.$emit('loading-update', false)
    }
  }

  private filter() {
    this.$root.$emit('show-filter')
  }

  private switchFit() {
    Store.switchFitGeometries()
  }

  private switchIsolate() {
    Store.switchIsolation()
  }

  private setGeometriesColor(color: string) {
    this.showColorPicker = false
    this.selectedColor = color

    this.$root.$emit('set-selection-color', color)
  }

  private sendGroups() {
    this.$root.$emit('show-groups')
  }

  private sendTableViews() {
    this.$root.$emit('show-table-views')
  }

  private showImportTable() {
    this.$root.$emit('show-modal-import', {
      title: this.active.title,
      showModal: true
    })

    this.enableImport = true
  }

  @Emit() showHiddenFields() {}
}
