import Vue from 'vue'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'

import VueApollo from 'vue-apollo'
import axios from 'axios'
import { setContext } from 'apollo-link-context'
import { createUploadLink } from 'apollo-upload-client'
import { ApolloLink } from 'apollo-link'

Vue.config.productionTip = false

const uploadLink = createUploadLink({ uri: process.env.VUE_APP_GRAPHQL_HTTP })

const authLink = setContext((_, { headers }) => {
  if (!localStorage.getItem(tokenName)) {
    return { headers }
  } else {
    const token = localStorage.getItem(tokenName)

    return {
      headers: {
        ...headers,
        authorization: token,
        'Access-Control-Allow-Origin': '*'
      }
    }
  }
})

const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, uploadLink]),
  cache: new InMemoryCache(),
  connectToDevTools: true
})

export const apolloProvider = () => {
  return new VueApollo({
    defaultClient: apolloClient
  })
}

Vue.use(VueApollo)

const tokenName = process.env.VUE_APP_LOCAL_STORAGE_AUTH_KEY

export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(tokenName, token)

    await axios.get(process.env.VUE_APP_SERVER, {
      headers: {
        authorization: `Bearer ${token}`
      }
    })
  }

  // if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)

  try {
    await apolloClient.resetStore()
  } catch (error) {
    console.error(
      '%cError on cache reset (login)',
      'color: orange;',
      error.message
    )
  }
}

export async function onLogout(apolloClient) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(tokenName)
  }

  // if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)

  try {
    await apolloClient.resetStore()
  } catch (error) {
    console.error(
      '%cError on cache reset (logout)',
      'color: orange;',
      error.message
    )
  }
}
