var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex flex-col bg-gray-50 bg-opacity-50 w-full h-max-110 relative"},[(_vm.showBimSelections)?_c('div',{staticClass:"p-4 overflow-y-scroll my-2",class:{ 'border': _vm.showDataBIM }},[_c('p',{staticClass:"cursor-pointer items-center flex text-gray-800 hover:underline",class:_vm.showDataBIM
          ? 'h-8 hover:text-red-600 text-xs'
          : 'h-16 p-4 border-b hover:text-blue-600',domProps:{"textContent":_vm._s('Datos BIM')},on:{"click":function($event){_vm.showDataBIM = !_vm.showDataBIM}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDataBIM),expression:"showDataBIM"}],staticClass:"grid grid-cols-2 gap-4 w-full relative"},[_c('IconCopy',{staticClass:"cursor-pointer absolute -top-8 right-8 h-6 hover:bg-blue-100",on:{"click":_vm.copyDataBIM,"mouseover":function($event){_vm.isCopyDataBIM = true},"mouseleave":function($event){_vm.isCopyDataBIM = false}}}),_vm._l((_vm.headersBIM),function(header,index){return _c('div',{key:index,staticClass:"grid grid-cols-2 gap-2",class:{ 'bg-yellow-50 underline': _vm.isCopyDataBIM },staticStyle:{"text-decoration-style":"dotted"}},[_c('div',{staticClass:"flex flex-col text-sm space-y-2"},[_c('p',{staticClass:"truncate w-40 bg-blue-200",attrs:{"title":header.header},domProps:{"textContent":_vm._s(header.header)}}),_c('p',{staticClass:"truncate text-xs w-40",attrs:{"title":_vm.selectedProperties.row[index]},domProps:{"textContent":_vm._s(_vm.selectedProperties.row[index])}})])])})],2)]):_vm._e(),_c('div',{staticClass:"p-2 overflow-y-scroll overflow-x-hidden",class:{ 'border': _vm.showDataNoBIM }},[_c('div',{staticClass:"grid grid-cols-2 gap-2 justify-between relative max-h-80 overflow-y-auto text-xs"},[_vm._l((_vm.selectedProperties.headers.filter(
          head => head.origin !== ''
        )),function(header,index){return _c('div',{key:index,class:{
          'bg-yellow-50 underline': _vm.isCopyDataNoBIM,
          'hidden':
            index === _vm.indexTotal ||
            header.header === 'IfcGUID ' ||
            header.header === 'IfcGUID' ||
            header.header === 'URL PLANO'
        },staticStyle:{"text-decoration-style":"dotted"}},[_c('div',{staticClass:"flex flex-col space-y-1 mt-1"},[_c('p',{staticClass:"truncate bg-blue-200 font-bold rounded text-gray-700 px-2",attrs:{"title":header.header},domProps:{"textContent":_vm._s(header.header)}}),_c('p',{staticClass:"truncate px-2",attrs:{"title":_vm.getValue(index, header.header)},domProps:{"textContent":_vm._s(_vm.getValue(index, header.header))}})])])}),_c('div',{staticClass:"flex w-full sticky space-x-2"},[_c('div',{staticClass:"panel-selection-tab-resource",attrs:{"title":"Accede a la url de plano"},on:{"click":function($event){return _vm.goUrlPlane()}}},[_c('IconMap'),_c('p',{domProps:{"textContent":_vm._s('Plano')}})],1),_c('div',{staticClass:"panel-selection-tab-resource",attrs:{"title":"Imagen360 Panellum"},on:{"click":_vm.go360Image}},[_c('IconImage',{staticClass:"w-12"}),_c('p',{domProps:{"textContent":_vm._s('Imagen 360')}})],1)])],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.indexTotal !== null),expression:"indexTotal !== null"}],staticClass:"flex flex-col w-full sticky bg-gray-100 rounded mt-4 h-20 z-5000 -bottom-4"},[_c('p',{staticClass:"bg-blue-200 w-full font-semibold rounded border text-gray-700 px-2",domProps:{"textContent":_vm._s('Total € (w/o VAT - KS)')}}),_c('p',{staticClass:"text-3xl font-semibold text-gray-700 px-2 mt-2",domProps:{"textContent":_vm._s(
          _vm.setValue(_vm.selectedProperties.row[_vm.headersBIM.length + _vm.indexTotal])
        )}}),_c('div',{staticClass:"absolute bottom-12 -right-4"},[_c('div',{staticClass:"cursor-pointer absolute top-1 right-24 flex rounded",on:{"click":function($event){return _vm.handleLike()}}},[_c('IconLike',{attrs:{"fill":_vm.isLike ? 'red' : 'none',"stroke":"red"}})],1),_c('IconMagnifying',{staticClass:"cursor-pointer absolute top-2 right-16 h-6 hover:bg-blue-100 rounded",on:{"click":_vm.lookAtObject}}),_c('IconCopy',{staticClass:"cursor-pointer absolute top-2 right-8 h-6 hover:bg-blue-100 rounded",on:{"click":_vm.copyDataNoBIM,"mouseover":function($event){_vm.isCopyDataNoBIM = true},"mouseleave":function($event){_vm.isCopyDataNoBIM = false}}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }