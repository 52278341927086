
import { Vue, Component, Emit, Prop } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'
import Store from '@/store/modules/Store'
import fileSender from '@/helpers/fileSender'
import AutodeskThreeLeggedBrowser from '@/components/Viewer/modals/AutodeskThreeLeggedBrowser.vue'
import IconAutodesk from '@/assets/icons/autodesk-logo.svg'

@Component({
  components: { AutodeskThreeLeggedBrowser, IconAutodesk },
  directives: { onClickaway }
})
export default class UploadModel extends Vue {
  @Prop() openedModels!: string[]

  private active: number = 0
  private input: HTMLElement = null

  mounted() {
    this.input = document.getElementById('hidden-input')

    this.input.addEventListener('change', event => {
      Store.setFile(fileSender(event))
    })
  }

  private openBrowser() {
    this.input.click()
    this.close()
  }

  @Emit() close() {}
}
