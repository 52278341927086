
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class IconSpinnerPro extends Vue {
  @Prop({ default: false }) sm!: boolean

  mounted() {
    // @ts-ignore
    const lottie = window.lottie

    const select = function (s) {
        return document.querySelector(s)
      },
      path = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/35984/',
      animationWindow = select('#animationWindow'),
      anim = lottie.loadAnimation({
        container: animationWindow,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        //initialSegment: [0, 1140],
        path: path + 'fish_spinner.json'
      })
  }
}
