var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.handleClose),expression:"handleClose"}],staticClass:"relative",on:{"click":function($event){_vm.show = !_vm.show}}},[_c('IconBell',{staticClass:"sidebar-navbar-notifications-icon"}),(_vm.notificationsLength > 0)?_c('div',{staticClass:"sidebar-navbar-notifications-amount",class:{ 'animate-bounce': _vm.bounce }},[_c('span',{domProps:{"textContent":_vm._s(
        _vm.notifications.length + _vm.notificationsRead.length > 99
          ? '+99'
          : _vm.notifications.length + _vm.notificationsRead.length
      )}})]):_vm._e(),_c('Transition',{attrs:{"name":"fade"}},[(_vm.show)?_c('div',{staticClass:"sidebar-navbar-notifications-popup",class:_vm.fromDashboard ? 'bg-white' : 'bg-opacity-40 bg-white',staticStyle:{"margin-top":"2px"}},[_c('div',{staticClass:"sticky top-0 flex"},[_c('div',{staticClass:"sidebar-navbar-notifications-unread",class:{
            'font-bold border border-b-0': _vm.tab === 'unread',
            'opacity-50 border-b': _vm.tab !== 'unread'
          },on:{"click":function($event){$event.stopPropagation();_vm.tab = 'unread'}}},[_c('p',{domProps:{"textContent":_vm._s(_vm.$t('unread'))}}),(_vm.notifications.length)?_c('span',{staticClass:"sidebar-span",class:{ 'animate-bounce': _vm.bounce },domProps:{"textContent":_vm._s(_vm.notifications.length > 99 ? '+99' : _vm.notifications.length)}}):_vm._e()]),_c('div',{staticClass:"sidebar-navbar-notifications-read",class:{
            'font-bold border border-b-0': _vm.tab === 'read',
            'opacity-50 border-b': _vm.tab !== 'read'
          },on:{"click":function($event){$event.stopPropagation();_vm.tab = 'read'}}},[_c('p',{staticClass:"mr-1",domProps:{"textContent":_vm._s(_vm.$t('read'))}}),(_vm.notificationsRead.length)?_c('span',{staticClass:"sidebar-span",class:{ 'animate-bounce': _vm.bounce },domProps:{"textContent":_vm._s(
              _vm.notificationsRead.length > 99 ? '+99' : _vm.notificationsRead.length
            )}}):_vm._e()]),_c('div',{staticClass:"items-center flex-grow border-b border-gray-100",attrs:{"title":_vm.$t('titles.deleteAllNotifications')}},[_c('div',{staticClass:"sidebar-navbar-notifications-delete",on:{"click":function($event){$event.stopPropagation();_vm.showConfirmationPopup = true}}},[_c('IconTrash',{staticClass:"h-4 hover:text-red-500"})],1)]),(_vm.showConfirmationPopup)?_c('div',[_c('DeleteWarning',{attrs:{"title":_vm.$t('deleteAllNotifications'),"subtitle":`${_vm.$t('removeAllStatusNotifications')}: <b>${
              _vm.tab === 'unread' ? _vm.$t('unread') : _vm.$t('read')
            }</b>`,"confirm":_vm.$t('confirm'),"isNotifications":true},on:{"confirm-delete":_vm.deleteNotifications,"close":_vm.hiddenConfirmationPopup}})],1):_vm._e()]),(_vm.tab === 'unread')?_c('div',{staticClass:"pr-2 overflow-y-auto max-h-80vh"},[(!_vm.notifications.length)?_c('p',{staticClass:"mt-2 text-sm text-gray-500",domProps:{"textContent":_vm._s(_vm.$t('noNotifications'))}}):_vm._e(),_vm._l((_vm.notifications),function(notification,index){return _c(_vm.setComponent(notification.type),{key:index,tag:"Component",attrs:{"notification":notification,"confirmDelete":_vm.showConfirmationPopup}})})],2):_c('div',{staticClass:"pr-2 overflow-y-auto max-h-80vh"},[(!_vm.notificationsRead.length)?_c('p',{staticClass:"mt-2 text-sm text-gray-500",domProps:{"textContent":_vm._s(_vm.$t('noNotifications'))}}):_vm._e(),_vm._l((_vm.notificationsRead),function(notification,index){return _c(_vm.setComponent(notification.type),{key:index,tag:"Component",attrs:{"notification":notification,"active-tab":_vm.tab,"confirmDelete":_vm.showConfirmationPopup}})})],2)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }