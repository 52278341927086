
import { Vue, Component } from 'vue-property-decorator'
import Store from '@/store/modules/Store'
import IconShare from '@/assets/icons/share.svg'

@Component({
  components: { IconShare }
})
export default class Invite extends Vue {
  mounted() {
    setTimeout(() => {
      this.start()
    }, 1000)
  }

  private async start() {
    try {
      const { memberId, tempId, assetId } = this.$route.params
      const invitation = { memberId, tempId, assetId }

      const {
        data: { userIdFromInvitation }
      } = await this.$apollo.query({
        variables: { assetId, memberId, tempId },
        query: require('@/graphql/queries/user-id-from-invitation.graphql')
      })

      Store.setInvitation({ ...invitation, userId: userIdFromInvitation })

      if (Store.user !== null) {
        await this.$router.replace({ name: 'Dashboard' })
      } else {
        await this.isAlreadyRegistered(invitation)
      }
    } catch (error) {
      this.$toasted.error(this.$i18n.t('errors.invite') as string)
      await this.$router.replace({ name: 'Home' })
    }
  }

  private async isAlreadyRegistered(invitation: {
    memberId: string
    tempId: string
    assetId: string
  }) {
    const { memberId, tempId, assetId } = invitation

    try {
      const {
        data: { isAlreadyRegistered }
      } = await this.$apollo.query({
        variables: { memberId, tempId, assetId },
        query: require('@/graphql/queries/is-already-registered.graphql')
      })

      await this.$router.replace({
        name: 'Login',
        hash: isAlreadyRegistered ? '' : '#register'
      })
    } catch {
      this.$toasted.error(this.$i18n.t('errors.invite') as string)
    }
  }
}
