
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'
import Store from '@/store/modules/Store'
import { directive as onClickaway } from 'vue-clickaway'
import { ViewerModel } from '@/store/Models'
import i18n from '@/i18n'
import IconSortAZ from '@/assets/icons/arrow-up-a-z.svg'
import IconSortZA from '@/assets/icons/arrow-down-z-a.svg'
import IconHiddenFields from '@/assets/icons/align-slash.svg'
import IconLock from '@/assets/icons/lock.svg'
import IconTrash from '@/assets/icons/trash-sm.svg'

@Component({
  directives: { onClickaway }
})
export default class HeaderActions extends Vue {
  @Prop() header!: string
  @Prop() groups!: any
  @Prop() model!: ViewerModel
  @Prop() selectedHeader: {
    header: string
    origin: string
    showOnlyOwner: boolean
  }

  @Prop() previousActionsIndex!: string

  private scrollAmount: number = 0
  private headerRequested: string = ''
  private hideDataList: any[] = []

  private headerActions: { title: string; icon: Vue.Component }[] = [
    {
      title: i18n.t('sortAZ') as string,
      icon: IconSortAZ
    },
    {
      title: i18n.t('sortZA') as string,
      icon: IconSortZA
    },
    {
      title: i18n.t('hideField') as string,
      icon: IconHiddenFields
    },
    {
      title: i18n.t('hideData') as string,
      icon: IconLock
    },
    {
      title: i18n.t('delete') as string,
      icon: IconTrash
    }
  ]

  private get actionsHeadersStyle(): string {
    return this.headerRequested === 'name'
      ? 'left: 0px'
      : `left: calc(${
          (this.filteredHeaders.findIndex(
            h => h.header === this.headerRequested
          ) -
            2) *
          16
        }rem - ${this.scrollAmount}px);`
  }

  private get filteredHeaders(): any[] {
    if (Store.activeHeaders) {
      return Store.activeHeaders.filter(
        h => !Store.hiddenHeaders.includes(h.header)
      )
    } else {
      return []
    }
  }

  private get hiddenHeaders(): any[] {
    return Store.hiddenHeaders
  }

  private get activeHeaders(): any[] {
    return Store.activeHeaders
  }

  private get checkBimtableHeader(): boolean {
    if (this.headerRequested) {
      const { origin } = Store.activeHeaders.find(
        h => h.header === this.headerRequested
      )

      return origin === 'bimtable'
    } else {
      return false
    }
  }

  private get selectedTableName(): string {
    return Store.selectedTableName
  }

  private get assetForViewer(): any {
    return Store.assetForViewer
  }

  private handleHideActionsHeaders() {
    if (this.headerRequested === this.previousActionsIndex) {
      this.headerRequested = ''
    }

    this.resetHeader()
  }

  private execAction(actionIndex: number) {
    switch (actionIndex) {
      case 0:
        this.sortRows(true)
        break
      case 1:
        this.sortRows(false)
        break
      case 2:
        this.hideField()
        break

      case 3:
        this.hideData()
        break

      case 4:
        this.deleteField()
        break
    }

    this.headerRequested = ''
    this.resetHeader()
    this.$forceUpdate()
  }

  private sortRows(firstMinor: boolean) {
    const rows = Array.from(Store.activeRows)

    if (firstMinor) {
      const sortedRows = rows.sort()
      Store.setActiveRows(sortedRows)
    } else {
      const sortedRows = rows.sort()
      sortedRows.reverse()
      Store.setActiveRows(sortedRows)
    }

    this.headerRequested = ''
    this.resetHeader()
  }

  private hideField() {
    const checkOrigin = (header: string): boolean => {
      const findHeader = this.activeHeaders.find(
        acHeader => acHeader.header === header
      )

      return findHeader.origin !== 'bimtable'
    }

    if (this.header !== 'name' && checkOrigin(this.header)) {
      if (!this.hiddenHeaders.includes(this.header)) {
        Store.addHiddenHeader(this.header)
      }
      if (this.groups) {
        this.setGroupsPayload()
      }
    } else {
      this.$toasted.error(this.$i18n.t('errors.hiddenFields') as string)
    }
  }

  private deleteField() {
    // todo: Delete field origin bimtable and bimtablerows
    this.$toasted.error(this.$i18n.t('errors.deleteField') as string)
  }

  private async hideData() {
    const index = Store.activeHeaders.findIndex(h => h.header === this.header)

    const tableName = this.selectedTableName
    const header = Store.activeHeaders[index].header
    const assetId = Store.assetForViewer.id
    const modelName = this.model.objectKey

    try {
      this.$apollo
        .mutate({
          variables: { tableName, header, assetId, modelName },
          mutation: require('@/graphql/mutations/change-show-only-owner-header.graphql')
        })
        .then(() => {
          this.$root.$emit('no-update-rows')

          Store.activeHeaders[index].showOnlyOwner =
            !Store.activeHeaders[index].showOnlyOwner
        })
    } catch (error) {
      console.error('⭐error⭐')
    }
  }

  private setHideList(index: number) {
    const hide = {
      tableName: this.selectedTableName,
      header: Store.activeHeaders[index].header,
      showOnlyAdmins: true,
      assetId: this.assetForViewer.id
    }

    if (!this.hideDataList.length) {
      this.hideDataList.push(hide)
    } else {
      for (const hideData of this.hideDataList) {
        if (
          hide.tableName !== hideData.tableName &&
          hide.header !== hideData.header
        ) {
          this.hideDataList.push(hide)
        } else {
          let list = this.hideDataList

          list.map(hi => {
            let ind = list.findIndex(hi => hi.header === this.headerRequested)
            list.splice(ind, 1)
          })
          this.hideDataList = list
        }
      }
    }
  }

  private get checkHideData(): boolean {
    if (this.hideDataList.length) {
      for (const hideData of this.hideDataList) {
        if (
          hideData.header === this.headerRequested &&
          hideData.tableName === this.selectedTableName
        ) {
          return true
        } else {
          return false
        }
      }
    } else {
      return false
    }
    return true
  }

  @Watch('header')
  onHeaderChanged() {
    this.headerRequested = this.header
  }

  @Emit() setGroupsPayload() {}

  @Emit() resetHeader() {}
}
