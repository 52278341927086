var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"min-w-64 z-5000"},[_c('p',{staticClass:"mb-2 text-xs",domProps:{"textContent":_vm._s(_vm.title)}}),(_vm.overwriteRead)?_c('div',{staticClass:"h-5 bg-white w-full"}):_vm._e(),(_vm.fields && _vm.fields.length)?_c('div',{staticClass:"modal-map-fields-wrapper",class:!_vm.overwriteRead ? 'max-h-64' : 'max-h-60'},[(_vm.includeSelect && !_vm.hiddenAllCheckbox)?_c('div',{staticClass:"modal-map-fields-container"},[(!_vm.hiddenCheckbox)?_c('div',{staticClass:"w-1/6"},[(_vm.selectedFields.some(s => s))?_c('IconSelected',{staticClass:"h-5 w-6 cursor-pointer hover:text-red-400",on:{"click":function($event){return _vm.allSelectedFields(false)}}}):_vm._e(),(_vm.selectedFields.every(s => !s))?_c('IconUnselected',{staticClass:"h-5 w-6 cursor-pointer hover:text-green-400",on:{"click":function($event){return _vm.allSelectedFields(true)}}}):_vm._e()],1):_vm._e(),(!_vm.hiddenCheckbox)?_c('p',{staticClass:"font-bold",domProps:{"textContent":_vm._s(
          _vm.selectedFields.some(s => s) ? _vm.$t('deselectAll') : _vm.$t('selectAll')
        )}}):_vm._e()]):_vm._e(),_vm._l((_vm.fields),function(field,index){return _c('div',{key:index,staticClass:"modal-map-fields-items",class:_vm.isUpdateXlsx ? '' : 'p-1',attrs:{"field":field}},[_c('div',{staticClass:"flex space-x-2 ml-1 py-1",class:_vm.isUpdateXlsx ? '' : 'w-1/6'},[(
            _vm.selectedFields &&
            _vm.selectedFields[index] &&
            _vm.includeSelect &&
            !_vm.hiddenCheckbox
          )?_c('IconSelected',{staticClass:"h-5 w-6 cursor-pointer hover:text-red-400",on:{"click":function($event){return _vm.swapSelect(index)}}}):_vm._e(),(
            _vm.selectedFields &&
            !_vm.selectedFields[index] &&
            _vm.includeSelect &&
            !_vm.hiddenCheckbox
          )?_c('IconUnselected',{staticClass:"h-5 w-6 cursor-pointer hover:text-green-400",on:{"click":function($event){return _vm.swapSelect(index)}}}):_vm._e(),(!_vm.hideStar)?_c('IconStar',{staticClass:"w-5 h-5 rounded-full cursor-pointer",class:index === _vm.mapFieldIndex
              ? 'text-yellow-500 bg-yellow-50 hover:text-gray-400'
              : 'text-gray-400 hover:text-yellow-500',on:{"click":function($event){return _vm.selectMainField(index)}}}):_vm._e()],1),_c('p',{staticClass:"w-5/6 text-xs",class:index === _vm.mapFieldIndex && !_vm.hideStar ? 'font-semibold' : '',domProps:{"textContent":_vm._s(field)}})])})],2):_c('p',{staticClass:"text-sm text-red-500",domProps:{"textContent":_vm._s(_vm.$t('noFields'))}})])
}
var staticRenderFns = []

export { render, staticRenderFns }