
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'
import IconErase from '@/assets/icons/trash.svg'
import IconSpinner from '@/assets/icons/spinner-solid.svg'

@Component({
  components: { IconErase, IconSpinner }
})
export default class CumulioDashboardItem extends Vue {
  @Prop() dashboard!: any
  @Prop() ssoToken!: { id: string; token: string }

  private showDelete: boolean = false
  private idToErase: string = ''
  private imgSrc: string = ''
  private clickedToDelete: boolean = false

  mounted() {
    this.imgSrc = `https://app.cumul.io/thumbnail/securable/256px/${this.dashboard.id}.png?key=${this.ssoToken.id}&token=${this.ssoToken.token}`
  }

  private handleDelete() {
    this.clickedToDelete = true

    setTimeout(() => this.deleteDashboard(), 500)
  }

  @Watch('showDelete')
  onshowDeleteChange() {
    if (this.showDelete) {
      this.clickedToDelete = false
    }
  }

  @Emit()
  dashboardDetail(id: string): string {
    return id
  }

  @Emit() deleteDashboard() {
    this.clickedToDelete = false
  }
}
