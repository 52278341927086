
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import IconTrash from '@/assets/icons/trash.svg'

@Component({
  components: { IconTrash }
})
export default class UploadImagePlaceholder extends Vue {
  @Prop() image!: string

  @Emit() deleteImage() {
    this.$root.$emit('delete-file')
  }
}
