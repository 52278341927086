export default (sheet: any) => {
  let sheetGroups = { 'A': [] },
    maxCounter = 0

  const regexNum = /\d+/,
    regexChars = /[A-Z]+/,
    regexFirstRow = /[A-Z]+1$/

  for (const key in sheet) {
    if (!key.startsWith('!') && !regexFirstRow.test(key)) {
      const ref = key.match(regexChars)[0]

      const xlsxIndex = parseInt(key.match(regexNum)[0])

      if (xlsxIndex > maxCounter) {
        maxCounter = xlsxIndex
      }

      if (sheetGroups[ref] !== undefined) {
        sheetGroups[ref] = { ...sheetGroups[ref], [xlsxIndex]: sheet[key].w }
      } else {
        sheetGroups = {
          ...sheetGroups,
          [ref]: {
            [xlsxIndex]: sheet[key].w
          }
        }
      }
    }
  }

  const fillGroup = group => {
    for (let i = 2; i <= maxCounter; i++) {
      if (group[i.toString()] === undefined) {
        group = { ...group, [i]: '' }
      }
    }

    return group
  }

  for (const key in sheetGroups) {
    sheetGroups[key] = fillGroup(sheetGroups[key])
  }

  return sheetGroups
}
