var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"w-full px-2 mt-2 space-y-4",class:{
    'h-0': _vm.minimize,
    'h-50': !_vm.minimize && !_vm.maximize,
    'h-108': _vm.maximize
  }},_vm._l((_vm.groups),function(group,index){return _c('div',{key:index,staticClass:"relative"},[(Object.keys(_vm.groups).indexOf(index) < 20 + _vm.scrollRowsCounter)?_c('div',{staticClass:"table-groups-wrapper",style:(`width: ${_vm.wrapperWidth - 8}px`)},[_c('div',{staticClass:"table-groups-container",style:(`width: ${_vm.wrapperWidth - 8}px`),attrs:{"title":_vm.$t('titles.collapseGroup')},on:{"click":function($event){group.isCollapsed = !group.isCollapsed}}},[_c('div',{staticClass:"table-groups-item"},[_c('div',{staticClass:"flex text-gray-500"},[_c('p',{staticClass:"table-groups-header",domProps:{"textContent":_vm._s(_vm.groupsPayload.groups[0].header)}}),_c('div',{staticClass:"flex pl-4 space-x-1"},[_c('p',{staticClass:"text-gray-500 text-mini",domProps:{"textContent":_vm._s(_vm.$t('group.amount'))}}),_c('p',{staticClass:"text-xs",domProps:{"textContent":_vm._s(group.rows.length)}})])]),_c('div',{staticClass:"flex space-x-2 z-1000"},[_c('IconCaretDown',{staticClass:"panel-header-caret",class:{ 'rotate-180 transform': group.isCollapsed }}),(index === '')?_c('p',{staticClass:"text-xs font-semibold",domProps:{"textContent":_vm._s(_vm.$t('untitled'))}}):_c('p',{staticClass:"text-xs font-semibold",domProps:{"textContent":_vm._s(index)}})],1)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!group.isCollapsed),expression:"!group.isCollapsed"}],style:(`width: ${_vm.wrapperWidth}px`)},_vm._l((group.rows),function(row,i){return _c('div',{key:i,staticClass:"flex",class:{
            'hidden': !_vm.filterList.includes(i) && _vm.filterActive,
            'bg-gray-100': _vm.activeSearchIndex && i === _vm.activeSearchIndex[0],
            'bg-yellow-100': _vm.selected(row[1])
          },attrs:{"id":`id-${row[1]}`},on:{"click":function($event){return _vm.selectRow(row[1])},"dblclick":function($event){return _vm.sendShowRowModalGroups(index, i)}}},[_vm._l((row),function(r,pos){return _c('p',{key:pos,staticClass:"panel-cell",class:{
              'hidden': pos === 1 || pos === 2,
              'panel-cell-name bg-white min-w-62 max-w-62':
                pos === 0 && !_vm.selected(row[1]),
              'panel-cell-name bg-yellow-100 min-w-62 max-w-62':
                pos === 0 && _vm.selected(row[1]),
              'min-w-64 max-w-64': pos !== 0,
              'bg-blue-50':
                _vm.activeHeaders[pos] &&
                _vm.activeHeaders[pos].origin === 'xlsx' &&
                !_vm.selected(row[1]),
              'hover:text-blue-500 hover:underline cursor-pointer':
                _vm.checkLink(r)
            },style:(pos === 1 || pos === 2 ? 'display: none;' : ''),domProps:{"textContent":_vm._s(r)},on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.goToLink(r)}}})}),(
              _vm.bimtableRows &&
              Object.keys(_vm.groups).indexOf(i) < 20 + _vm.scrollRowsCounter
            )?_c('div',{staticClass:"flex"},_vm._l((group.bimtableRows[i]),function(r,pos){return _c('Cell',{key:pos + _vm.activeHeaders.length,attrs:{"index":i,"activeSearchIndex":_vm.activeSearchIndex,"text":r,"pos":pos,"isSelected":_vm.selectedGeometries.includes(group.rows[i][1]),"group-index":index},on:{"set-value":function($event){return _vm.setInputValueGroup($event, index)}}})}),1):_vm._e()],2)}),0)]):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }