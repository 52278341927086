
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'

@Component({
  directives: { onClickaway }
})
export default class ModalLegal extends Vue {
  @Prop() title!: string
  @Prop() description!: string

  @Emit() close() {}
}
