
import { Vue, Component, Prop } from 'vue-property-decorator'
import Notifications from '@/components/Dashboard/Navbar/Notifications/Notifications.vue'
import Information from '@/components/Viewer/Information.vue'

@Component({
  components: { Information, Notifications }
})
export default class ViewerNavbar extends Vue {
  // @ts-ignore
  @Prop({ default: null }) viewer!: Autodesk.Viewing.GuiViewer3D
}
