
import { Vue, Component, Watch, Emit, Prop } from 'vue-property-decorator'
import IconSearch from '@/assets/icons/magnifying-glass.svg'

@Component({
  components: {
    IconSearch
  }
})
export default class Seeker extends Vue {
  @Prop({ default: false }) changeModeView!: boolean

  private inFocus: boolean = false
  private searchTerm: any = ''

  private inputStyles: string =
    'background: none !important; border: none !important; box-shadow: none !important;'

  @Watch('searchTerm')
  onSearchTermsChange() {
    this.sendSearch()
  }

  @Emit() sendSearch(): string {
    return this.searchTerm
  }
}
