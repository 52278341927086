
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import Button from '@/components/shared/Button.vue'
import IconDelete from '@/assets/icons/trash.svg'
import IconAccept from '@/assets/icons/check.svg'
import IconMail from '@/assets/icons/user-tag.svg'

@Component({
  components: { Button, IconMail }
})
export default class UpdateNotification extends Vue {
  @Prop() notification!: any
  @Prop() activeTab!: 'read' | 'unread'

  private teamMateEmail: string = ''
  private iconDelete: Vue.Component = IconDelete
  private iconAccept: Vue.Component = IconAccept
  private clicked: boolean = false

  async created() {
    try {
      const userId = this.notification.team_member

      const {
        data: { emailById }
      } = await this.$apollo.query({
        variables: { userId },
        query: require('@/graphql/queries/email-by-id.graphql')
      })

      this.teamMateEmail = emailById
    } catch {
      console.error('error')
    }
  }

  private async deleteUpdate() {
    this.clicked = true
    const updateId = this.notification.notificationId
    const modelName = this.notification.model_name
    const userId = this.notification.team_member

    try {
      const {
        data: { deleteTempUpdate }
      } = await this.$apollo.mutate({
        variables: { updateId, modelName, userId },
        mutation: require('@/graphql/queries/delete-temp-update.graphql')
      })

      if (deleteTempUpdate) {
        this.$toasted.success(this.$i18n.t('success.deletedTemp') as string)
        this.refreshNotifications()
      }
    } catch {
      console.error('error')
    } finally {
      this.clicked = false
    }
  }

  private async confirmUpdate() {
    try {
      this.clicked = true
      const userId = this.notification.team_member
      const updateId = this.notification.notificationId
      const modelName = this.notification.model_name

      const {
        data: { confirmUpdate }
      } = await this.$apollo.mutate({
        variables: { updateId, modelName, userId },
        mutation: require('@/graphql/mutations/confirm-update.graphql')
      })

      if (confirmUpdate) {
        this.$toasted.success(this.$i18n.t('success.updatedData') as string)
        this.refreshNotifications()
      }
    } catch {
      console.error('error')
    } finally {
      this.clicked = false
    }
  }

  @Emit() refreshNotifications() {}
}
