var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{ 'w-full': _vm.isFull, 'border h-full': _vm.row }},[(!_vm.row)?_c('div',{staticClass:"flex flex-col"},[(_vm.label)?_c('label',{staticClass:"input-label",attrs:{"for":_vm.inputId}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.isRequired)?_c('strong',{staticClass:"text-blue-500 cursor-help",attrs:{"title":_vm.$t('required')},domProps:{"textContent":_vm._s('*')}}):_vm._e()]):_vm._e(),_c('p',{staticClass:"mb-1 text-xs text-gray-400",domProps:{"innerHTML":_vm._s(_vm.description),"textContent":_vm._s(_vm.description)}})]):_vm._e(),_c('div',{class:({ 'w-32': !_vm.isFull }, { 'w-64 h-5 z-5000': _vm.row })},[_c('input',{staticClass:"input-field",class:{
        'text-center': !_vm.isFull,
        'min-h-6 h-4 mt-1 border-0 bg-transparent': _vm.row,
        'min-h-10 h-10 py-2': !_vm.row,
        'bg-red-50': _vm.reachedLimit
      },style:(_vm.increasePadding
          ? 'padding-right: 125px'
          : _vm.row
          ? 'box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);'
          : ''),attrs:{"id":_vm.inputId,"maxlength":_vm.maxLength,"type":_vm.inputType,"autocomplete":_vm.autoComplete,"placeholder":_vm.placeholder,"required":_vm.isRequired},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.onInput($event.target.value)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.emitEnter.apply(null, arguments)}}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.reachedLimit),expression:"reachedLimit"}],staticClass:"text-xs text-red-400 mt-1",domProps:{"textContent":_vm._s(_vm.$t('reachedLimit'))}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }