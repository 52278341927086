
import { Vue, Component, Watch, Emit, Prop } from 'vue-property-decorator'
import Store from '@/store/modules/Store'

@Component({})
export default class CreateCell extends Vue {
  @Prop() activeTable!: {
    title: string
    origin: string
    isEmpty: boolean
  }

  private cell = {}

  private get bimtableRows() {
    return Store.bimtableRows
  }

  private get activeRows(): any[][] {
    return Store.activeRows
  }

  private addCell() {
    Store.addBimtableRows([''])
  }
}
