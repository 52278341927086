
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Plan } from '@/store/Models'
import IconCheck from '@/assets/icons/check.svg'

@Component({
  components: { IconCheck }
})
export default class PlanCard extends Vue {
  @Prop() plan!: Plan

  private get currency(): string {
    return typeof this.plan.price == 'number' ? '€' : ''
  }

  private get month(): string {
    return typeof this.plan.price == 'number'
      ? `/ ${this.$i18n.t('month') as string}`
      : ''
  }
}
