
import { Vue, Component, Prop } from 'vue-property-decorator'
import IconMenu from '@/assets/icons/info-circle.svg'
import Store from '@/store/modules/Store'
import setSelections from '@/controllers/dataManagement/setSelections'
import getSelectionsData from '@/controllers/dataManagement/getSelectionsData'
@Component({ components: { IconMenu } })
export default class Information extends Vue {
  // @ts-ignore
  @Prop({ default: null }) viewer!: Autodesk.Viewing.GuiViewer3D
  private get activeSelection(): boolean {
    return Store.activeSelection
  }

  private async handleActiveSelection() {
    if (this.activeSelection) {
      Store.setActiveSelection(false)
    } else {
      const selections = await setSelections(this.viewer)
      if (selections.length) {
        Store.setActiveSelection(true)
        await getSelectionsData(selections)
      } else {
        //todo: traslations
        this.$toasted.error('Necesitas una selección')
      }
    }
  }
}
