
import { Vue, Component } from 'vue-property-decorator'
import { Dataset } from '@/store/Models'
import Store from '@/store/modules/Store'

@Component({
  apollo: {
    datasets: {
      query: require('@/graphql/queries/datasets.graphql')
    }
  }
})
export default class Datasets extends Vue {
  private datasets: Dataset[] = []

  async mounted() {
    const {
      data: { datasets }
    } = await this.$apollo.queries.datasets.refetch()

    this.datasets = datasets
  }

  private assetName(assetId: string): string {
    const asset = Store.assets.find(asset => asset.id === assetId)
    return asset.title
  }

  private detail(id: string) {
    this.$router.replace({ name: 'DatasetDetail', params: { id } })
  }
}
