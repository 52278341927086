import autodeskClientThree from '../../helpers/autodeskClientThree'
import { ProjectsApi } from 'forge-apis'
import Store from '../../store/modules/Store'

export default async (teamId: string, projectId: string) => {
  try {
    const projectsApi = new ProjectsApi()

    const {
      body: { data }
    } = await projectsApi.getProjectTopFolders(
      teamId,
      projectId,
      autodeskClientThree,
      Store.autodeskCredentials
    )

    return data.map(folder => {
      return {
        name: folder.attributes.name,
        id: folder.id,
        type: folder.type
      }
    })
  } catch {
    console.error('error-get-folders')
    throw new Error('error-get-folders')
  }
}
