
import { Vue, Component, Emit, Watch, Prop } from 'vue-property-decorator'
import SidebarMenuItem from '@/components/Dashboard/Sidebar/SidebarMenuItem.vue'
import { MenuItem } from '@/store/Models'
import menu from '@/config/menus/dashboard'
import Avatar from '@/components/Dashboard/Sidebar/Avatar.vue'
import UserSettings from '@/components/Dashboard/Sidebar/UserSettings.vue'
import Logo from '@/components/shared/Logo.vue'
import Store from '@/store/modules/Store'
import IconAnalytics from '@/assets/icons/chart-pie.svg'
import IconBack from '@/assets/icons/arrow-left.svg'
import Notifications from './Notifications/Notifications.vue'
import DeleteWarning from '@/components/Dashboard/Assets/Modals/DeleteWarning.vue'
import SharedResources from '@/components/Dashboard/Assets/Modals/SharedResources.vue'
import CreateIntegrationModal from '@/components/Dashboard/Navbar/Modals/CreateIntegrationModal.vue'

@Component({
  components: {
    CreateIntegrationModal,
    DeleteWarning,
    Logo,
    UserSettings,
    Avatar,
    SidebarMenuItem,
    SharedResources,
    Notifications
  }
})
export default class Navbar extends Vue {
  @Prop() fromWeb!: boolean
  @Prop({ default: false }) fromLegal: boolean

  private menu: MenuItem[] = menu
  private showProfile: boolean = false
  private showDeleteWarning: boolean = false
  private showSharedResources: boolean = false
  private selectedHubIndex: number | string = 0
  private showIntegrations: boolean = false
  private showRegenerateWarning: boolean = false

  mounted() {
    this.$root.$on('show-resources', (hubId: string) => {
      this.selectedHubIndex = hubId
      this.showSharedResources = true
    })
  }

  private handleActive(index: number) {
    if (this.menu[index].title === (this.$i18n.t('back') as string)) {
      Store.setBackToAnalytics(true)
    } else {
      this.menu.every(item => ({ ...item, active: false }))

      this.menu = menu.map((item, pos) => {
        return {
          ...item,
          active: pos === index
        }
      })

      this.switchTabs()
    }
  }

  private get changeAnalyticsAction(): boolean {
    return Store.changeAnalyticsAction
  }

  private async deleteAnalytics() {
    try {
      const {
        data: { deleteAnalytics }
      } = await this.$apollo.mutate({
        mutation: require('@/graphql/mutations/delete-analytics.graphql')
      })

      if (deleteAnalytics) {
        this.$toasted.success(this.$i18n.t('success.deleteAnalytics') as string)
      }
    } catch (error) {
      this.$toasted.error(this.$i18n.t('errors.browser') as string)
    } finally {
      this.showDeleteWarning = false
    }
  }

  private async regenerateAnalytics() {
    try {
      const {
        data: { regenerateAnalytics }
      } = await this.$apollo.mutate({
        mutation: require('@/graphql/mutations/regenerate-analytics.graphql')
      })

      if (regenerateAnalytics) {
        this.$toasted.success(this.$i18n.t('success.regenerate') as string)
      }
    } catch (error) {
      this.$toasted.error(this.$i18n.t('errors.browser') as string)
    } finally {
      this.showRegenerateWarning = false
    }
  }

  private sharedResources() {
    this.showSharedResources = !this.showSharedResources
  }

  private changeHubIndex(id: string) {
    this.selectedHubIndex = id
  }

  @Watch('changeAnalyticsAction')
  onchangeAnalyticsActionChange() {
    if (this.changeAnalyticsAction) {
      this.menu[1].title = this.$i18n.t('back') as string
      this.menu[1].icon = IconBack
    } else {
      this.menu[1].title = this.$i18n.t('analytics') as string
      this.menu[1].icon = IconAnalytics
    }
  }

  @Emit() switchTabs(): MenuItem {
    return this.menu.filter(item => item.active)[0]
  }
}
