export default (color: string) => {
  const values = color
    .substring(color.indexOf('(') + 1, color.indexOf(')'))
    .split(',')
    .map(v => parseInt(v))

  const cal = (value: number) => {
    return value / 255
  }

  // @ts-ignore
  return new THREE.Vector4(cal(values[0]), cal(values[1]), cal(values[2]), 1)
}
