import getSelectionsData from '@/controllers/dataManagement/getSelectionsData'

export default async viewer => {
  const sels = viewer.getAggregateSelection()

  const selections = []
  sels.forEach(s => {
    const modelName = s.model
      .getData()
      .loadOptions.bubbleNode.getRootNode()
      .children[0].name()

    selections.push({
      modelName,
      viewerId: s.selection
    })
  })

  return selections
}
