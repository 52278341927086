
import { Vue, Component } from 'vue-property-decorator'
import IconVerify from '../../assets/icons/zoom-check.svg'

@Component({
  components: { IconVerify }
})
export default class VerifyAccount extends Vue {
  mounted() {
    this.verify()
  }

  private async verify() {
    try {
      const { userId, emailToken } = this.$route.params

      const {
        data: { verify }
      } = await this.$apollo.mutate({
        variables: { userId, emailToken },
        mutation: require('@/graphql/mutations/verify-account.graphql')
      })

      verify
        ? this.$toasted.success(this.$i18n.t('success.verifyUser') as string)
        : this.$toasted.error(this.$i18n.t('errors.verifyUser') as string)
    } catch {
      this.$toasted.error(this.$i18n.t('errors.verifyUser') as string)
    } finally {
      await this.$router.replace({ name: 'Login' })
    }
  }
}
