
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import mapboxConfig from '@/config/mapbox'
import Store from '@/store/modules/Store'

// @ts-ignore
const mapboxgl = window.mapboxgl

@Component({})
export default class MapBox extends Vue {
  @Prop() assets!: any[]

  private map!: any
  private loaded: boolean = false

  mounted() {
    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN

    this.$root.$on('highlight-pushpin', coordinates => {
      if (
        coordinates &&
        this.map &&
        coordinates.latitude !== 0 &&
        coordinates.longitude !== 0
      ) {
        this.map.flyTo({
          center: [coordinates.longitude, coordinates.latitude],
          essential: true
        })
      }
    })

    this.$root.$on('refresh-map', () => {
      this.map.resize()
    })

    setTimeout(() => {
      this.initMap()
    }, 250)
  }

  private initMap() {
    // @ts-ignore
    this.map = new mapboxgl.Map({
      style: 'mapbox://styles/mapbox/light-v10',
      center: [-4.42034, 39.72016],
      zoom: 5.5,
      pitch: 15,
      bearing: 0,
      container: 'mapbox',
      antialias: true,
      lang: this.$i18n.locale
    })

    this.map.on('load', () => {
      let layers = this.map.getStyle().layers
      let labelLayerId

      for (let i = 0; i < layers.length; i++) {
        if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
          labelLayerId = layers[i].id
          break
        }
      }

      this.map.addLayer(mapboxConfig, labelLayerId)

      this.map.setFog({ 'horizon-blend': 1 })

      this.map.addSource('mapbox-dem', {
        'type': 'raster-dem',
        'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
        'tileSize': 512,
        'maxzoom': 14
      })

      this.map.setTerrain({
        'source': 'mapbox-dem',
        'exaggeration': 1.5
      })

      this.loaded = true
    })
  }

  private setMarkers() {
    const filteredAssets = this.assets.filter(
      asset =>
        asset.coordinates.latitude !== 0 && asset.coordinates.longitude !== 0
    )

    for (const asset of filteredAssets) {
      const {
        coordinates: { latitude, longitude, address }
      } = asset

      const popup = new mapboxgl.Popup({
        offset: 75,
        closeButton: false,
        closeOnClick: false
      }).setText(`${address} ${asset.description}`)

      const imgTag = document.createElement('img')

      imgTag.setAttribute(
        'src',
        asset.image
          ? `${process.env.VUE_APP_CLOUDINARY_IMG_PREFIX}${asset.image}`
          : process.env.VUE_APP_NO_IMAGE_URL
      )

      imgTag.className = 'rounded-full h-16 w-16'

      imgTag.innerHTML = 'Marker1'
      imgTag.id = 'marker'

      const pTag = document.createElement('p')
      const text = document.createTextNode(asset.title)
      pTag.appendChild(text)
      pTag.className = 'rounded-md mb-2 text-gray-600'

      const div = document.createElement('div')
      div.className =
        'bg-white bg-opacity-75 p-2 rounded flex flex-col items-center justify-center hover:bg-opacity-100'
      div.appendChild(pTag)
      div.appendChild(imgTag)

      // @ts-ignore
      const marker = new mapboxgl.Marker(div, {
        clickable: true,
        map: this.map,
        anchorPoint: { x: longitude, y: latitude }
      })
        .setLngLat([longitude, latitude])
        .setPopup(popup)
        .addTo(this.map)

      marker.getElement().addEventListener('click', () => {
        Store.setAssetForViewer(asset)
        this.$router.replace({ name: 'Viewer' })
      })

      marker.getElement().addEventListener('mouseover', () => {
        popup.setLngLat([longitude, latitude]).addTo(this.map)

        this.map.flyTo({
          center: [asset.coordinates.longitude, asset.coordinates.latitude],
          essential: true,
          zoom: 18,
          duration: 10000,
          pitch: 45
        })
      })

      marker.getElement().addEventListener('mouseleave', () => {
        popup.remove()
      })
    }
  }

  @Watch('assets')
  onAssetsChange() {
    if (this.assets && this.map._markers) {
      this.map._markers.forEach(marker => marker.remove())
      this.setMarkers()
    }
  }
}
