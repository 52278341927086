
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Landing extends Vue {
  created() {
    const token = localStorage.getItem(
      process.env.VUE_APP_LOCAL_STORAGE_AUTH_KEY
    )

    this.$router.replace({ name: token ? 'Dashboard' : 'Login' })
  }
}
