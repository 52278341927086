import Store from '../../store/modules/Store'
import { ApiResponse, HubsApi, ProjectsApi } from 'forge-apis'
import autodeskClientThree from '../../helpers/autodeskClientThree'

export default async () => {
  const hubsApi = new HubsApi()
  const projectsApi = new ProjectsApi()

  const credentials = Store.autodeskCredentials

  try {
    const {
      body: { data }
    }: ApiResponse = await hubsApi.getHubs({}, autodeskClientThree, credentials)

    const teams = data.map(hub => {
      return {
        name: hub.attributes.name,
        id: hub.id,
        type: hub.type
      }
    })

    const { body } = await projectsApi.getHubProjects(
      teams[Store.teamIndex].id,
      {},
      autodeskClientThree,
      credentials
    )

    const projects = body.data.map(project => {
      return {
        name: project.attributes.name,
        id: project.id
      }
    })

    const rawData = await projectsApi.getProjectTopFolders(
      teams[Store.teamIndex].id,
      projects[Store.projectIndex].id,
      autodeskClientThree,
      credentials
    )

    const folders = rawData.body.data.map(folder => {
      return {
        name: folder.attributes.name,
        id: folder.id,
        type: folder.type
      }
    })

    return {
      teams,
      projects,
      folders
    }
  } catch {
    console.error('error')
  }
}
