
import { Vue, Component } from 'vue-property-decorator'
import LoginForm from '@/components/Login/LoginForm.vue'
import RegisterModal from '@/components/Login/RegisterModal.vue'
import RecoverPasswordModal from '@/components/Login/RecoverPasswordModal.vue'

@Component({
  components: { RegisterModal, LoginForm, RecoverPasswordModal }
})
export default class Login extends Vue {
  private showRegisterModal: boolean = false
  private showRecoverPasswordModal: boolean = false

  beforeMount() {
    if (this.$route.hash === '#register') {
      this.showRegisterModal = true
    }

    if (this.$route.hash === '#forgot-password') {
      this.showRecoverPasswordModal = true
    }

    localStorage.removeItem(process.env.VUE_APP_LUZMO_COOKIE)
  }
}
