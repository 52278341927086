
import { Vue, Component, Watch } from 'vue-property-decorator'
import initViewer from '@/controllers/forgeViewer/initViewer'
import loadModels from '@/controllers/forgeViewer/loadModels'

@Component({
  components: {}
})
export default class ViewerInstance extends Vue {
  private urns: string[] = []

  // @ts-ignore
  private viewer!: Autodesk.Viewing.GuiViewer3D

  private externalIds: any[] = []

  async created() {
    const { apiKey, hash } = this.$route.params

    const {
      data: { allowViewerInstance }
    } = await this.$apollo.query({
      variables: { apiKey, hash },
      query: require('@/graphql/queries/allow-viewer-instance.graphql')
    })

    if (!allowViewerInstance.length) {
      await this.$router.push({ name: 'Home' })
    } else {
      this.urns = allowViewerInstance
    }
  }

  private async mountViewer() {
    await initViewer(this.$refs.viewer as HTMLElement).then(viewer => {
      this.viewer = viewer

      loadModels(
        this.urns.map(urn => {
          return {
            objectId: urn
          }
        }),
        viewer
      )

      this.mountWindowEvents()
    })
  }

  private mountWindowEvents() {
    window.addEventListener(
      'message',
      ({ data }) => {
        const { type } = data

        if (type === 'kronos-selection') {
          this.viewer.select(data.viewerIds)
        }
      },
      {
        once: false,
        passive: true
      }
    )
  }

  @Watch('urns')
  onChange() {
    if (this.urns.length) this.mountViewer()
  }
}
