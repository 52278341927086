
import { Vue, Component, Prop } from 'vue-property-decorator'
import TableCells from '@/components/Viewer/TableData/TableCells/TableCells.vue'
import { ViewerModel } from '@/store/Models'
import AddFieldPopup from '@/components/Viewer/TableData/AddFieldPopup.vue'
import { directive as onClickaway } from 'vue-clickaway'
import Store from '@/store/modules/Store'

@Component({
  components: { AddFieldPopup, TableCells },
  directives: { onClickaway }
})
export default class TableRows extends Vue {
  @Prop() minimize!: boolean
  @Prop() maximize!: boolean
  @Prop() activeTable!: {
    title: string
    origin: string
    isEmpty: boolean
  }
  @Prop() model!: ViewerModel
  @Prop() sidebarOpen!: boolean
  @Prop() rowsToUpdate!: any

  private showAddFieldPopup: boolean = false
  private addFieldAtLeft: boolean = false
  private isAtLeft: boolean = false

  mounted() {
    this.$root.$on('show-at-left', (isAtLeft: boolean) => {
      this.isAtLeft = isAtLeft
    })
  }

  private get groupsItems(): any {
    return Store.groupsItems
  }

  private get tableHeight(): string {
    let style = ''

    if (this.minimize) {
      style = 'height: 0px;'
    } else if (this.maximize) {
      style = 'height: 500px; padding-bottom: 10px'
    } else if (!this.minimize && !this.maximize) {
      style = 'height: 240px;'
    }

    return style
  }

  private closeAddField() {
    if (this.showAddFieldPopup) this.showAddFieldPopup = false
  }

  private async addField(title: string) {
    Store.setSelectedTableName(title)

    const fieldTitle = title
    const tableName = this.activeTable.title
    const assetId = Store.assetForViewer.id
    const modelName = this.model.objectKey
    const modelId = this.model.objectId

    this.$root.$emit('scroll-top-max')
    this.$root.$emit('show-create-cell', title)

    // cross-@Mutation pattern
    const originHeaders = Store.activeHeaders

    if (!originHeaders.find(h => h.header === title)) {
      const newHeader = { header: title, origin: 'bimtable' }
      originHeaders.push(newHeader)

      // Añade los headers al store
      Store.setActiveHeaders(originHeaders)
      this.showAddFieldPopup = false

      const defaultEmptyRows = []

      if (Store.bimtableRows && Store.bimtableRows.length) {
        for (const row of Store.bimtableRows) {
          row.push('')
        }
      } else {
        if (this.activeTable.isEmpty === true) {
          // case new custom table
          this.$root.$emit('show-create-cell', fieldTitle)
        } else {
          for (const row of Store.activeRows) {
            defaultEmptyRows.push([''])
          }
        }

        Store.setBimtableRows(defaultEmptyRows)
      }
    } else {
      this.$root.$emit('headers-includes-input', title)
    }

    if (this.groupsItems.groups && this.groupsItems.groups.length) {
      if (Store.bimtableRows && Store.bimtableRows.length) {
        for (const row of Store.bimtableRows) {
          row.push('')
        }
      }

      this.$root.$emit('refresh-table-groups')

      setTimeout(() => {}, 400)
    }

    const { addField } = await this.$apollo.mutate({
      variables: { fieldTitle, tableName, assetId, modelName, modelId },
      mutation: require('@/graphql/mutations/add-field.graphql')
    })

    setTimeout(() => {
      this.$root.$emit('set-wrapper-width')
    }, 500)
  }

  private handleAddField(toLeft: boolean) {
    this.showAddFieldPopup = true
    this.addFieldAtLeft = toLeft
  }
}
