
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'

@Component
export default class FilterInput extends Vue {
  @Prop({ default: '' }) value!: string
  @Prop({ default: 'text' }) inputType!: string
  @Prop({ default: false }) inputImport!: boolean

  @Emit()
  onInput(value: string): string {
    return value
  }
}
