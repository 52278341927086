
import { Vue, Component, Emit, Watch, Prop } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'
import Input from '@/components/shared/Input.vue'
import UploadImageInput from '@/components/shared/UploadImageInput.vue'
import UploadImagePlaceholder from '@/components/shared/UploadImagePlaceholder.vue'
import Button from '@/components/shared/Button.vue'
import { GoogleSearchData, Asset } from '@/store/Models'
import Store from '@/store/modules/Store'
import IconRefresh from '@/assets/icons/refresh.svg'
import fileSender from '@/helpers/fileSender'
import axios from 'axios'
import IconLocationSite from '@/assets/icons/location-site.svg'

@Component({
  components: {
    Button,
    IconLocationSite,
    IconRefresh,
    Input,
    UploadImageInput,
    UploadImagePlaceholder
  },
  directives: { onClickaway }
})
export default class EditAsset extends Vue {
  @Prop() asset!: Asset

  private imageDom: string = ''
  private loading: boolean = false
  private showConfirmClose: boolean = false
  private prefix: string = process.env.VUE_APP_CLOUDINARY_IMG_PREFIX
  private waitUpload: boolean = false
  private mirrorAsset: Asset = null
  private address: string | null = null
  private showResultsFind: boolean = false
  private search: string = ''
  private debounce: any = null
  private reachedInputDesc: boolean = false
  private reachedInputTitle: boolean = false

  private results: {
    place_name_main: string
    place_name_sub: string
    center: [number, number]
  }[] = []

  created() {
    this.mirrorAsset = this.asset
  }

  private async handleImage(image: File | null) {
    if (image) {
      this.mirrorAsset.editedImage = image
      const decoded = await image.arrayBuffer()
      const data = new Uint8Array(decoded)
      const blob = new Blob([data.buffer], { type: 'image/png' })
      this.imageDom = URL.createObjectURL(blob)
    }
  }

  private resetImage() {
    this.asset.image = null
    this.imageDom = ''
  }

  private get valuesNotValid(): boolean {
    return this.asset.title.length < 3
  }

  private get searchData(): GoogleSearchData {
    return Store.searchData
  }

  private get getImageName(): string {
    return this.mirrorAsset.image ? this.mirrorAsset.image.name : ''
  }

  private async updateAsset() {
    this.loading = true
    const hubId = Store.hub.id
    const { id, title, description, coordinates } = this.mirrorAsset

    let imageChanged: boolean = true

    if (!this.mirrorAsset.editedImage) {
      imageChanged = false
    }

    const assetId = id

    const asset = {
      title,
      description,
      coordinates,
      image: this.mirrorAsset.editedImage
        ? this.mirrorAsset.editedImage
        : this.asset.image
    }

    // @ts-ignore
    delete asset.coordinates.__typename

    try {
      const {
        data: { updateAsset }
      } = await this.$apollo.mutate({
        variables: { asset, assetId, hubId, imageChanged },
        mutation: require('@/graphql/mutations/update-asset.graphql')
      })

      if (updateAsset) {
        this.$toasted.success(this.$i18n.t('success.updateAsset') as string)
        this.$root.$emit('refresh-assets')
        this.close()
      }
    } catch (error) {
      if (error.message === 'GraphQL error: Error: not-owner') {
        this.$toasted.error(this.$i18n.t('errors.notOwner') as string)
      } else {
        this.$toasted.error(this.$i18n.t('errors.createAsset') as string)
      }
    } finally {
      this.loading = false
      this.waitUpload = false
    }
  }

  private showConfirm() {
    this.showConfirmClose = true
  }

  private quitConfirm() {
    this.showConfirmClose = false
  }

  private uploadAssetImage() {
    ;(this.$refs.input as HTMLInputElement).click()
  }

  private newAssetImage(event: Event) {
    this.waitUpload = true
    const file = fileSender(event)
    this.handleImage(file)
  }

  private debounceSearch(search: string) {
    this.search = search
    this.mirrorAsset.coordinates.address = search

    clearTimeout(this.debounce)

    this.debounce = setTimeout(() => {
      this.address = search.replaceAll(' ', '%20') // todo: cambiarlo a safe-url-encoded
      if (this.address != null && this.address.length > 3)
        this.submitGeolocation(search)
    }, 600)
  }

  private async submitGeolocation(search: string) {
    const {
      data: { features }
    } = await axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${search}.json?access_token=${process.env.VUE_APP_MAPBOX_ACCESS_TOKEN}`
    )

    this.results = features.map((f: { place_name: any; center: any }) => {
      const { place_name, center } = f

      const place_name_main = place_name.substr(0, place_name.indexOf(','))
      const place_name_sub = place_name.substr(place_name.indexOf(',') + 1)

      return { place_name_main, place_name_sub, center }
    })
  }

  private changeLocation(result: {
    place_name_main: string | null
    place_name_sub: string | null
    center: any
  }) {
    const address = result.place_name_main
    const longitude = result.center[0]
    const latitude = result.center[1]

    this.asset.coordinates.longitude = longitude
    this.asset.coordinates.latitude = latitude
    this.asset.coordinates.address = address

    this.showResultsFind = false

    Store.setSearchData({
      address,
      latitude,
      longitude
    })
  }

  private closeResults() {
    this.showResultsFind = false
  }

  @Watch('searchData')
  onSearchDataChange() {
    const { address, latitude, longitude } = this.searchData
    this.asset.coordinates = { address, latitude, longitude }
  }

  @Watch('search')
  onSearchChange() {
    if (this.search !== '') {
      this.showResultsFind = true
    }
  }

  @Emit() close() {}
}
