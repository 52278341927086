
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'
import IconQuestion from '@/assets/icons/question.svg'
import IconCaretDown from '@/assets/icons/caret-down.svg'
import Store from '@/store/modules/Store'
import IconRecord from '@/assets/icons/info.svg'
import Group from '@/components/Viewer/TableData/Group.vue'
import IconAdd from '@/assets/icons/add.svg'
import setModelConfig from '@/controllers/forgeViewer/setModelConfig'
import setTableViewConfig from '@/controllers/forgeViewer/setTableViewConfig'
import { ViewerModel } from '@/store/Models'

@Component({
  components: {
    IconAdd,
    IconCaretDown,
    IconQuestion,
    IconRecord,
    Group
  },
  directives: { onClickaway }
})
export default class CreateGroups extends Vue {
  @Prop() model!: ViewerModel
  @Prop() infoGroups!: any

  private inputFind: string = ''
  private filterActiveHeaders: any[] = []
  private headersUsedList: string[] = []
  private clicked: boolean = false
  private groups: any = null

  async mounted() {
    this.$root.$on('delete-groups-payload', () => {
      this.groups = null
      this.clicked = false
    })

    if (!Object.keys(this.infoGroups).length) {
      this.clicked = false
    } else {
      this.clicked = true
    }
  }

  private get activeHeaders(): any[] {
    return Store.activeHeaders
  }

  private get groupsStore(): any {
    return Store.groups
  }

  private get groupsItems(): any {
    return Store.groupsItems
  }

  private get tableViews(): any[] {
    return Store.tableViews
  }

  private get selectedTableName(): string {
    return Store.selectedTableName
  }
  private groupField() {
    const { header } = this.activeHeaders[0]
    const operator = this.$t('group.operators.aToZ') as string

    this.headersUsedList[0] = header

    if (Store.activeTableView !== null) {
      const payload = {
        tableName: this.selectedTableName,
        groups: [
          {
            header,
            operator,
            child: null,
            root: true
          }
        ]
      }

      this.tableViews[Store.activeTableView].config.groups = payload.groups
      this.$root.$emit('set-groups-table-view', payload)
    } else {
      if (this.groupsStore.length) {
        this.groups = this.groupsStore.groups
      }

      const modelConfig = Store.assetForViewer.configs.find(
        c => c.modelName === this.model.objectKey
      )

      if (modelConfig) {
        let groups = modelConfig.groups.find(
          g => g.tableName === this.selectedTableName
        )

        const payload = {
          tableName: this.selectedTableName,
          groups: [
            {
              header,
              operator,
              child: null,
              root: true
            }
          ]
        }

        if (!groups) {
          modelConfig.groups.push(payload)
        }

        this.groups = payload.groups

        Store.setGroupsItems(payload)
        this.clicked = true
      }
    }
  }

  private addSubgroup() {
    if (this.groupsStore) {
      this.groups = this.groupsStore
    }

    const defaultChild = {
      header: '',
      operator: this.$t('group.operators.aToZ') as string,
      child: null
    }

    if (!this.groups.child) {
      this.groups.child = defaultChild
    } else {
      const recursive = group => {
        if (group.child) {
          recursive(group.child)
        } else {
          group.child = defaultChild
        }
      }

      recursive(this.groups.child)
    }
  }

  private async updateSetModelConfig() {
    if (this.groups && Store.activeTableView === null) {
      await setModelConfig('groups', this.model.objectKey, {
        tableName: this.selectedTableName,
        groups: this.groups
      })
    } else if (this.groups && Store.activeTableView !== null) {
      const title = this.tableViews[Store.activeTableView].title
      const groups = this.groups.groups

      if (groups) {
        await setTableViewConfig(
          'groups',
          this.selectedTableName,
          this.model.objectKey,
          title,
          {
            groups: groups
          }
        )
      }
    }
    this.$root.$emit('refresh-table-groups')
  }
  @Watch('inputFind')
  onInputFindChanged() {
    if (this.inputFind == '') {
      this.filterActiveHeaders = []
    } else {
      for (const header of this.activeHeaders) {
        if (header.header.includes(this.inputFind)) {
          this.filterActiveHeaders.push(header)
        }
      }
    }
  }

  @Watch('groups', { deep: true })
  async onGroupsChanged() {
    await this.updateSetModelConfig()
  }

  @Watch('groupsItems', { deep: true })
  onGroupsItemsChanged() {
    if (this.groupsItems) {
      this.groups = this.groupsItems
    }
  }
}
