
import { Vue, Component, Emit, Prop } from 'vue-property-decorator'
import Store from '@/store/modules/Store'
import { directive as onClickaway } from 'vue-clickaway'
import Input from '@/components/shared/Input.vue'

@Component({
  components: { Input },
  directives: { onClickaway }
})
export default class RowModal extends Vue {
  @Prop() data!: any

  private get activeRows(): any[][] {
    return Store.activeRows
  }

  private get activeHeaders(): any[] {
    return Store.activeHeaders
  }

  private get groups(): any[] {
    return Store.groups
  }

  private get bimtableRows() {
    return Store.bimtableRows
  }

  private checkRow(row: any): any {
    if (row !== undefined && row !== '' && row !== false) {
      return row
    } else {
      return '_'
    }
  }

  private goToLink(value: string) {
    const win = window.open(value, '_blank')
    win.focus()
  }

  private checkLink(value: string): boolean {
    if (
      typeof value === 'string' &&
      value.startsWith('https://' || 'http://')
    ) {
      return true
    } else {
      return false
    }
  }

  private setInputRow(row: any) {
    console.log(row, 'set-input-row')
  }

  @Emit()
  close() {}
}
