import termsAndConditions from '../legalItems/termsAndConditions'
import privacyPolicy from '../legalItems/privacyPolicy'
import cookiePolicy from '../legalItems/cookiePolicy'
import legalNotice from '../legalItems/legalNotice'

export default [
  {
    title: 'Legal notice',
    description: legalNotice
  },
  {
    title: 'Privacy',
    description: privacyPolicy
  },
  {
    title: 'Terms',
    description: termsAndConditions
  },
  {
    title: 'Cookies',
    description: cookiePolicy
  }
]
