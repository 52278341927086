
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import IconEye from '@/assets/icons/eye.svg'
import { ViewerModel } from '@/store/Models'
import IconRestore from '@/assets/icons/history.svg'

@Component({
  components: { IconEye, IconRestore }
})
export default class HiddenTableNamesPopup extends Vue {
  @Prop() hiddenTableNames!: string[]
  @Prop() model!: ViewerModel

  async tableDataRestore() {
    this.$root.$emit('show-views', {
      objectId: this.model.objectId,
      objectKey: this.model.objectKey
    })
  }

  @Emit()
  restoreTable(tableName: string): string {
    return tableName
  }
}
