
import { Vue, Component, Emit } from 'vue-property-decorator'
import { directive as onClickaway } from 'vue-clickaway'
import Store from '@/store/modules/Store'
import Button from '@/components/shared/Button.vue'

@Component({
  components: { Button },
  directives: { onClickaway }
})
export default class ConfirmUpdate extends Vue {
  private isOwner = Store.isOwner

  private get text(): string {
    return this.$i18n.t(
      this.isOwner ? 'ownerAutoUpdates' : 'updatesMustBeApproved'
    ) as string
  }

  @Emit() confirmUpdate() {}
  @Emit() close() {}
}
