
import { Vue, Component } from 'vue-property-decorator'
import IconShared from '@/assets/icons/share.svg'
import Store from '@/store/modules/Store'

@Component({
  apollo: {
    sharedAssets: {
      query: require('@/graphql/queries/shared-assets.graphql')
    }
  },
  components: { IconShared }
})
export default class SharedAssets extends Vue {
  private assets = []

  mounted() {
    this.$root.$on('refresh-shared-assets', () => {
      this.refreshSharedAssets()
    })

    this.refreshSharedAssets()
  }

  private async refreshSharedAssets() {
    const {
      data: { sharedAssets }
    } = await this.$apollo.queries.sharedAssets.refetch()

    this.assets = sharedAssets
  }

  private async chooseAsset(asset: any) {
    try {
      const assetId = asset.id
      const ownerId = asset.ownerId

      const {
        data: { hubIdFromAsset }
      } = await this.$apollo.query({
        variables: { assetId, ownerId },
        query: require('@/graphql/queries/hub-id-from-asset.graphql')
      })

      Store.setHub({
        id: hubIdFromAsset,
        assetIds: [asset.id],
        title: asset.title,
        isShared: true
      })
    } catch {
      console.error('error')
    }
  }

  private getRole(r: string): string {
    let role = ''

    switch (r) {
      case 'editor':
        role = this.$i18n.t('editor') as string
        break
      case 'reader':
        role = this.$i18n.t('reader') as string
        break
      case 'owner':
        role = this.$i18n.t('owner') as string
        break
    }

    return role
  }
}
