import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import i18n from '../i18n'
import checkLocalStorage from '../helpers/checkLocalStorage'
import beforeEnterLoader from '@/helpers/beforeEnterLoader'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  const div = beforeEnterLoader()

  const language = localStorage.getItem('BIMTable-lang')

  if (language) {
    i18n.locale = language
  } else {
    if (navigator.language === 'es' || navigator.language === 'es-ES') {
      i18n.locale = 'es'
    } else if (navigator.language === 'en' || navigator.language === 'en-US') {
      i18n.locale = 'en'
    } else if (navigator.language === 'ca') {
      i18n.locale = 'ca'
    }
  }

  const token = localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_AUTH_KEY)

  if (token !== null && token !== '') {
    await checkLocalStorage(token)
  }

  document.body.removeChild(div)

  next()
})

export default router
